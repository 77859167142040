import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { AreaHeader, Content, LoadingSpinner } from "../components"

const useStyles = makeStyles((theme) => ({
  dateNavigator: {
    marginBottom: theme.spacing(2),
  },
  skeleton: {
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
  },
}))

const AreaSuspenseFallback = ({ showDateNavigator = false }) => {
  const classes = useStyles()

  return (
    <>
      <AreaHeader />

      {showDateNavigator && (
        <Box className={classes.dateNavigator}>
          <Skeleton variant="rect" width="100%" height={48} className={classes.skeleton} />
        </Box>
      )}

      <Content full start mb={5}>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box display="flex" justifySelf="flex-start" alignSelf="center" mt={3}>
            <LoadingSpinner size={85} />
          </Box>
        </Box>
      </Content>
    </>
  )
}

export default AreaSuspenseFallback
