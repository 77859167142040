import React from "react"
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Button } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { ErrorBoundary, InlineTitle, RowBox } from ".."
import { AssetDetails } from "./AssetDetails"
import { AssetPanel } from "./AssetPanel"
import { useAuth } from "../../services"

const AssetViewer = ({ asset, onClose, isInline, variant }) => {
  const { hasPermission } = useAuth()
  const handleOnClose = (event, isCancel) => {
    onClose(isCancel)
  }

  if (!asset) {
    return null
  }

  const hasEditDetails = hasPermission("asset_update")

  const display = (
    <>
      <Box data-cy="AssetViewer-display">
        <AssetPanel asset={asset} layout="landscape" mb={2} />
        <ErrorBoundary>
          <Box pt={2} data-cy="AssetViewer-page">
            <AssetDetails asset={asset} />
          </Box>
        </ErrorBoundary>
      </Box>
    </>
  )

  if (isInline) {
    return (
      <>
        <InlineTitle boxProps={{ ml: 0, mb: 2 }}>
          <Trans>Asset details</Trans>
          <InlineTitleCloser onClose={handleOnClose} />
        </InlineTitle>
        {display}

        <RowBox mt={2} pb={2} justifyContent="flex-end">
          <Button
            component={NavLink}
            variant="contained"
            color="primary"
            to={`/assets/${asset.id}/edit`}
            disabled={!hasEditDetails}
          >
            <Trans>Edit details</Trans>
          </Button>
        </RowBox>
      </>
    )
  }

  if (variant === "peek") {
    return (
      <Box>
        <AssetPanel asset={asset} layout="landscape" mb={2} />
      </Box>
    )
  }

  return (
    <Dialog open={asset !== null} onClose={handleOnClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <Trans>Asset details</Trans>
        <DialogTitleCloser onClose={handleOnClose} />
      </DialogTitle>
      <DialogContent>{display}</DialogContent>
      {hasEditDetails && (
        <DialogActions>
          <Box pt={1} pr={2} pb={2}>
            <Button component={NavLink} variant="contained" color="primary" to={`/assets/${asset.id}/edit`}>
              <Trans>Edit details</Trans>
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export { AssetViewer }
