import React, { useState } from "react"
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Tooltip, useTheme } from "@material-ui/core"
import fileDownload from "js-file-download"
import axios from "axios"
import { Trans, t } from "@lingui/macro"
import { Icon, LoadingSpinner, useSnackbar } from ".."
import { toId } from "../../utils"
import { useLazyQueryReportJobsDetailPdf } from "../../data/report/useQueryReportJobsDetailPdf"
import ReportToolsPdfIconButton from "../Buttons/ReportToolsPdfIconButton"

const ExportJobsTablePdfButton = ({
  variant = "button",
  gt,
  lt,
  process,
  location,
  disabled,
  menuItemText = "All jobs",
  onClick,
  onDownloaded,
  ...props
}) => {
  const [downloading, setDownloading] = useState(false)
  const theme = useTheme()
  const [load, { loading }] = useLazyQueryReportJobsDetailPdf()
  const snackbar = useSnackbar()

  const busyChipProps = { style: { backgroundColor: theme.palette.scheduled.main } }

  const handleClick = async () => {
    onClick && onClick()
    setDownloading(true)
    try {
      snackbar.showMessage({
        message: t`Exporting PDF, please wait`,
        icon: <Icon name="pdf" />,
        chipProps: busyChipProps,
        autoHideDuration: 30000,
      })

      const result = await load({
        variables: { input: { process: toId(process), location: toId(location), gt, lt } },
      })

      if (!result?.data) {
        snackbar.showMessage({ message: t`Unknown error generating PDF` })
        setDownloading(false)
        return
      }

      const data = result?.data?.report?.jobs?.detail?.pdf

      if (!data.exportUrl) {
        snackbar.showMessage({ message: data.message || t`Unknown error generating PDF` })
        setDownloading(false)
        return
      }

      const busySnackbarConfig = {
        message: t`Downloading, please wait`,
        icon: <Icon name="download" />,
        chipProps: busyChipProps,
        autoHideDuration: 60000 * 10,
      }

      snackbar.showMessage(busySnackbarConfig)

      const response = await axios({
        url: data.exportUrl,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          snackbar.showMessage({
            ...busySnackbarConfig,
            message: t`Downloading PDF (${percentCompleted}%)`,
          })
        },
      })

      fileDownload(response.data, data.fileName)

      snackbar.showMessage({ message: t`Download ready`, icon: <Icon name="download" />, autoHideDuration: 5000 })

      setDownloading(false)
    } catch (err) {
      setDownloading(false)
    }
    onDownloaded && onDownloaded()
  }

  if (variant === "menuitem") {
    return (
      <MenuItem onClick={handleClick} disabled={disabled || downloading}>
        <ListItemIcon>
          <Icon name="download" />
        </ListItemIcon>
        <ListItemText primary={menuItemText} />
      </MenuItem>
    )
  }

  if (variant === "icon") {
    return (
      <ReportToolsPdfIconButton
        onClick={handleClick}
        loading={downloading}
        disabled={disabled || downloading}
        {...props}
      />
    )
  }

  return (
    <Tooltip title={t`Download PDF`}>
      <Button variant="contained" onClick={handleClick} disabled={disabled || downloading} {...props}>
        {!downloading && <Icon name="pdf" />}
        {downloading && <LoadingSpinner size="24px" delay={false} />}
        <Box ml={1}>
          {!downloading && <Trans>Download</Trans>}
          {downloading && loading && <Trans>Exporting</Trans>}
          {downloading && !loading && <Trans>Downloading</Trans>}
        </Box>
      </Button>
    </Tooltip>
  )
}

export default ExportJobsTablePdfButton
