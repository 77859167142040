import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_195)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 16C1 15.4477 1.44772 15 2 15H8C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17H2C1.44772 17 1 16.5523 1 16Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H10C10.5523 11 11 11.4477 11 12C11 12.5523 10.5523 13 10 13H2C1.44772 13 1 12.5523 1 12Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 20C1 19.4477 1.44772 19 2 19H10C10.5523 19 11 19.4477 11 20C11 20.5523 10.5523 21 10 21H2C1.44772 21 1 20.5523 1 20Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9309 2.31773C26.3125 2.57692 26.4674 3.0636 26.3056 3.49568L22.9433 12.4752L29.1321 14.1335C29.1454 14.1371 29.1588 14.1407 29.1723 14.1443C29.4074 14.2072 29.661 14.2751 29.8645 14.3559C30.0782 14.4408 30.4413 14.6149 30.6611 15.0153C30.8917 15.4352 30.9102 15.9171 30.7957 16.3255C30.6975 16.6755 30.4844 16.9316 30.3229 17.104C30.1637 17.2739 29.9532 17.4645 29.7304 17.6663L17.1666 29.0471C16.8246 29.3568 16.3151 29.3924 15.9334 29.1332C15.5517 28.8741 15.3969 28.3874 15.5587 27.9553L18.9209 18.9757L12.7322 17.3175C12.7189 17.3139 12.7055 17.3103 12.692 17.3067C12.4569 17.2438 12.2033 17.1759 11.9998 17.0951C11.7861 17.0102 11.423 16.8361 11.2031 16.4357C10.9726 16.0158 10.9541 15.5338 11.0686 15.1255C11.1668 14.7755 11.3799 14.5194 11.5414 14.347C11.7006 14.1771 11.9111 13.9865 12.1339 13.7846L24.6977 2.40389C25.0397 2.09414 25.5492 2.05854 25.9309 2.31773ZM22.9887 6.65054L13.5028 15.2432C13.4362 15.3035 13.377 15.3572 13.3241 15.4055L20.5025 17.329C20.7749 17.4019 21.0038 17.5865 21.1328 17.8372C21.2619 18.0879 21.2791 18.3815 21.1802 18.6455L18.8756 24.8004L28.3615 16.2078C28.4281 16.1475 28.4873 16.0937 28.5402 16.0455L21.3618 14.122C21.0894 14.049 20.8605 13.8645 20.7315 13.6138C20.6024 13.363 20.5852 13.0695 20.6841 12.8054L22.9887 6.65054Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_195" x1="1" y1="16" x2="9" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_99_195" x1="1" y1="12" x2="11" y2="12" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint2_linear_99_195" x1="1" y1="20" x2="11" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
