import { Box, Paper, IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import Config from "react-global-configuration"
import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useHistory, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  linkButton: {
    color: theme.palette.primary.main,
    fontWeight: "normal",
    margin: 0,
    padding: 0,
    lineHeight: "inherit",
    verticalAlign: "inherit",
  },
}))

const cookieName = "hide_banners"

const HelpBanner = ({ name }) => {
  const classes = useStyles()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const [{ hide_banners: hideBanners }, setCookie] = useCookies([cookieName])
  const theme = useTheme()
  const hide = useMediaQuery(theme.breakpoints.down("xs"))
  const history = useHistory()
  const location = useLocation()
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    // get cookie
    if (!hideBanners || !hideBanners.includes(name)) {
      setBanner(() => {
        const result = { icon: `banners-${name}` }
        switch (name) {
          case "comms":
            return {
              ...result,
              heading: <Trans>One communications platform for your entire workforce</Trans>,
              body: (
                <>
                  <Trans>
                    The Communications Hub is your place to send important messages, read updates and discuss business
                    with your workforce.
                  </Trans>
                  {/* Read our{" "}
                  <a
                    href="https://help.operandio.com/article/58-creating-messages-sending-to-specific-employees-areas-of-work-or-business-locations"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide on creating a new message &amp; sending to specific employees or locations
                  </a>
                  . */}
                </>
              ),
            }
          case "knowledge":
            return {
              ...result,
              heading: <Trans>The home for all your business knowledge</Trans>,
              body: (
                <>
                  <Trans>
                    Create articles and guides that your staff can reference at anytime to get the job done right.
                  </Trans>
                  {/* Read
                  our{" "}
                  <a
                    href="https://help.operandio.com/article/55-create-manage-knowledge-base-articles"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide on how to create &amp; manage Knowledge Base articles
                  </a>
                  . */}
                </>
              ),
            }
          case "people":
            return {
              ...result,
              heading: <Trans>People management made easy</Trans>,
              body: (
                <>
                  <Trans>Easily manage your staff, locations and areas of work within your business.</Trans>
                  {/* Get started by reading our{" "}
                  <a
                    href="https://help.operandio.com/article/50-adding-inviting-employees-to-your-operandio-account"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide on adding &amp; inviting employees
                  </a>
                  . */}
                </>
              ),
            }
          case "templates":
            return {
              ...result,
              heading: <Trans>Time to replace paper checklists</Trans>,
              body: (
                <>
                  <Trans>
                    The Job Manager is where you create and edit all the processes and tasks that keep your business
                    running. Create from scratch or use our starter templates.
                  </Trans>
                  {/* You can also read our{" "}
                  <a
                    href="https://help.operandio.com/article/42-creating-a-process"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    guide on creating a process
                  </a>
                  . */}
                </>
              ),
            }
          default:
            throw new Error(`Unmapped help banner: ${name}`)
        }
      })
    }
  }, [classes.linkButton, hideBanners, history, location.pathname, name])

  const handleClose = () => {
    setCookie(cookieName, hideBanners ? [...hideBanners, name] : [name], {
      maxAge: 2147483647,
    })
    setBanner(null)
  }

  if (device || kiosk || hide || !banner) return null

  return (
    <Box mb={4}>
      <Paper elevation={0}>
        <blockquote className={classes.root}>
          <RowBox>
            <Icon name={banner.icon} />
            <ColumnBox ml={2}>
              <Box className={classes.heading} mb={1}>
                {banner.heading}
              </Box>
              <Box className={classes.body}>{banner.body}</Box>
            </ColumnBox>
            <FlexBox alignSelf="flex-start" ml="auto">
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </FlexBox>
          </RowBox>
        </blockquote>
      </Paper>
    </Box>
  )
}

export { HelpBanner }
