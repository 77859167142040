import React, { useEffect } from "react"
import { Divider, makeStyles, MenuItem } from "@material-ui/core"
import { t } from "@lingui/macro"
import { OutlinedSelect } from "./OutlinedSelect"
import { useQueryEventNames } from "../../data"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const EventOutlinedSelect = ({
  onDataLoaded,
  allLabel = "All events",
  hasAll = true,
  disabled: disabledInput = false,
  multiple = false,
  ...rest
}) => {
  const { data, loading } = useQueryEventNames()
  const classes = useStyles()

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const eventNames = data?.events?.listName || []
  const disabled = disabledInput || loading || !eventNames.length

  return (
    <OutlinedSelect
      label={t`Events`}
      native={false}
      value="all"
      classes={classes}
      disabled={disabled}
      multiple={multiple}
      selectProps={{
        renderValue: (option) => {
          if (multiple) {
            return option
              .map((item) => {
                if (item === "all") {
                  return allLabel
                }

                return eventNames.find((eventName) => eventName === item) || item
              })
              .join(", ")
          }

          if (option === "all") {
            return allLabel
          }

          const selected = eventNames.find((item) => (option.value ? item.id === option.value : item.id === option))
          return selected?.name || option
        },
      }}
      {...rest}
    >
      {hasAll && <MenuItem value="all">{allLabel}</MenuItem>}
      {hasAll && <Divider />}
      {eventNames.map((eventName) => (
        <MenuItem key={eventName} value={eventName}>
          {eventName}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { EventOutlinedSelect }
