import React from "react"
import MuiAvatar from "@material-ui/core/Avatar"
import { Badge, useTheme } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { Avatar, Icon } from ".."
import { useAuth } from "../../services"

const StyledBadge = withStyles(() => ({
  badge: {
    right: 5,
    top: 5,
  },
}))(Badge)

const NotificationFrom = ({ notification }) => {
  const avatar = NotificationFromAvatar(notification)
  return notification.read ? (
    avatar
  ) : (
    <StyledBadge color="secondary" variant="dot" overlap="circular" content=" ">
      {avatar}
    </StyledBadge>
  )
}

const NotificationFromAvatar = ({ from, className }) => {
  const {
    settings: { organisation },
  } = useAuth()
  const theme = useTheme()

  if (from) {
    return <Avatar {...from} className={className} />
  }
  if (organisation.logo) {
    return <Avatar avatar={{ key: organisation.logo }} fullName={organisation.name} className={className} />
  }
  return (
    <MuiAvatar style={{ backgroundColor: theme.palette.background.default }}>
      <Icon name="operandio" size={24} link={false} />
    </MuiAvatar>
  )
}

export default NotificationFrom
export { NotificationFromAvatar }
