import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2632)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3ZM1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 8.5C13 7.39572 13.8957 6.5 15 6.5H17C18.1043 6.5 19 7.39572 19 8.5V13H23.5C24.6043 13 25.5 13.8957 25.5 15V17C25.5 18.1043 24.6043 19 23.5 19H19V23.5C19 24.6043 18.1043 25.5 17 25.5H15C13.8957 25.5 13 24.6043 13 23.5V19H8.5C7.39572 19 6.5 18.1043 6.5 17V15C6.5 13.8957 7.39572 13 8.5 13H13V8.5ZM17 8.5H15V15H8.5V17H15V23.5H17V17H23.5V15H17V8.5Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_722_2632" x1="25.5" y1="25.5" x2="25.5" y2="6.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
