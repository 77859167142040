import React from "react"
import { Box, Grid, List, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useParams, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { SubNavigationListItem, SubNavigation } from ".."
import { useQueryLabelCategories } from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  horizontalNavContainer: {
    zIndex: 200,
  },
  horizontalNavItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    overflowX: "auto",
    "& li": {
      display: "inline-table",
      minWidth: "100px",
      borderRadius: theme.spacing(0.5),
      "& a": {
        justifyContent: "space-around",
        borderRadius: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  },
}))

const LabelsSubNav = ({ variant }) => {
  const classes = useStyles()
  const { location: userLocation } = useAuth()
  const { data } = useQueryLabelCategories({
    variables: {
      filter: {
        locations: [toId(userLocation)],
      },
    },
    pollInterval: 30000,
  })
  const { id, type } = useParams()
  const location = useLocation()

  // Lists the categories in this organisation
  const categoriesNav = data?.labelCategories && (
    <List>
      <SubNavigationListItem text="All" to="/labels" active={location.pathname === "/labels"} />
      {data.labelCategories.map(({ id: catId, name, numberOfLabels }) => (
        <SubNavigationListItem
          key={catId}
          to={`/labels/category/${catId}`}
          text={name}
          badgeCount={numberOfLabels}
          badgeVariant="text"
          active={id === catId}
        />
      ))}
    </List>
  )

  // Link through to label templates
  const libraryNav = data?.labelCategories && (
    <List>
      <SubNavigationListItem to="/labels/templates" active={type === "templates"} text={<Trans>All templates</Trans>} />
    </List>
  )

  // Show on left hand side in desktop views
  if (variant === "vertical") {
    return (
      <Hidden smDown>
        <SubNavigation title={<Trans>Categories</Trans>} loading={!categoriesNav}>
          {categoriesNav}
        </SubNavigation>
        {libraryNav && (
          <SubNavigation title={<Trans>Template library</Trans>} loading={!libraryNav}>
            {libraryNav}
          </SubNavigation>
        )}
      </Hidden>
    )
  }

  // Show as horizontal scroller on mobile and kiosk
  if (variant === "horizontal") {
    return (
      <Grid container direction="row" className={classes.horizontalNavContainer}>
        <Grid item sm={12} xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row" mb={1}>
            <List className={classes.horizontalNavItem}>
              <SubNavigationListItem
                variant="horizontal"
                text={<Trans>All</Trans>}
                to="/labels"
                active={location.pathname === "/labels"}
              />
              {data?.labelCategories.map(({ id: catId, name }) => (
                <SubNavigationListItem
                  variant="horizontal"
                  to={`/labels/category/${catId}`}
                  key={catId}
                  text={name}
                  value={catId}
                  active={id === catId}
                />
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    )
  }
  return null
}

export { LabelsSubNav }
