import { Divider, MenuItem } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { useQueryTrainingCourses } from "../../data"
import { toId } from "../../utils"
import { OutlinedSelect } from "../OutlinedSelect"

const TrainingOutlinedSelect = ({
  cy = "OutlinedSelect-training",
  onDataLoaded,
  hasNone = true,
  filterActiveUserTrainings,
  disabled = false,
  ...rest
}) => {
  const { data, loading } = useQueryTrainingCourses()
  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (data) {
      onDataLoaded && onDataLoaded(data.trainingCourses)
      if (!filterActiveUserTrainings?.length) {
        setCourses([...data.trainingCourses])
        return
      }
      setCourses([
        ...data.trainingCourses.filter(
          (course) =>
            !filterActiveUserTrainings.some(
              (training) => !training.status.completedAt && toId(training.course) === toId(course),
            ),
        ),
      ])
    }
  }, [data, filterActiveUserTrainings, onDataLoaded])

  if (data && !data.trainingCourses.length) {
    return null
  }

  return (
    <OutlinedSelect
      label={t`Training course`}
      id="training"
      native={false}
      menuPortal={false}
      disabled={loading || disabled || !courses.length}
      data-cy={cy}
      {...rest}
    >
      {hasNone && <MenuItem value="">(None)</MenuItem>}
      {hasNone && <Divider />}
      {courses.map((course) => (
        <MenuItem key={toId(course)} data-cy="MenuItem-course" value={toId(course)}>
          {course.name}
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { TrainingOutlinedSelect }
