import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { FlexBox, RowBox } from "../Boxes"
import { FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  conditions: {
    backgroundColor: theme.palette.grey[50],
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  heading: {
    fontSize: 14,
  },
}))

const ExpansionPanelOptions = ({ icon, heading, children, ...rest }) => {
  const classes = useStyles()

  return (
    <Box mt={1} p={2} className={classes.conditions} {...rest}>
      <RowBox alignItems="center" mb={2}>
        {icon && (
          <FlexBox mr={1}>
            <Icon name={icon} />
          </FlexBox>
        )}
        <FieldSectionHeading mb={0} className={classes.heading}>
          {heading}
        </FieldSectionHeading>
      </RowBox>
      {children}
    </Box>
  )
}

export { ExpansionPanelOptions }
