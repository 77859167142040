import React from "react"
import { Popover, Divider, Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"
import NotificationList from "../NotificationList"
import { useMutationReadAllNotification } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { ErrorBoundary } from "../ErrorBoundary"

const usePopoverStyles = makeStyles(() => ({
  root: {
    width: 380,
    maxHeight: "85vh",
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2, 2),
    fontWeight: "600",
    fontSize: "16px",
  },
  notifications: {},
  footer: {
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}))

const NotificationsPopover = ({ open, anchorEl, onClose, loading, error, data }) => {
  const classes = useStyles()
  const popoverClasses = usePopoverStyles()
  const [readAllNotifications, { loading: readAllLoading }] = useMutationReadAllNotification()

  const id = open ? "notifications-popover" : undefined

  const hasUnreadNotifications = data?.notifications?.items?.some((n) => !n.read)

  const handleMarkAllRead = async () => {
    if (!hasUnreadNotifications) {
      return
    }
    await readAllNotifications()
  }

  const handleViewAll = () => {
    onClose && onClose()
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={popoverClasses}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <div className={classes.header}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Trans>Notifications</Trans>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleMarkAllRead} disabled={readAllLoading}>
              {!readAllLoading && <Trans>Mark all as read</Trans>}
              {readAllLoading && <LoadingSpinner size="25px" delay={false} />}
            </Button>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.notifications}>
        <ErrorBoundary>
          <NotificationList popover {...{ loading, error, data, onNavigate: onClose }} />
        </ErrorBoundary>
      </div>
      <div className={classes.footer}>
        <Button component={NavLink} to="/notifications" onClick={handleViewAll} color="primary">
          <Trans>View all notifications</Trans>
        </Button>
      </div>
    </Popover>
  )
}

export default NotificationsPopover
