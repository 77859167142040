import { gql } from "@apollo/client"

const EVENTS_LIST_FIELDS = gql`
  fragment EventsListFields on Event {
    id
    name
    ip
    reference
    user {
      id
      firstName
      lastName
      fullName
    }
    content
    createdAt
  }
`

export { EVENTS_LIST_FIELDS }
