import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_142)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.087 30.944H19.5C19.2348 30.944 18.9804 30.8386 18.7929 30.6511C18.6054 30.4636 18.5 30.2092 18.5 29.944V21.262H13.5V29.944C13.5 30.2092 13.3946 30.4636 13.2071 30.6511C13.0196 30.8386 12.7652 30.944 12.5 30.944H4.913C4.64778 30.944 4.39343 30.8386 4.20589 30.6511C4.01836 30.4636 3.913 30.2092 3.913 29.944V19.9C3.913 19.6348 4.01836 19.3804 4.20589 19.1929C4.39343 19.0054 4.64778 18.9 4.913 18.9C5.17822 18.9 5.43257 19.0054 5.62011 19.1929C5.80764 19.3804 5.913 19.6348 5.913 19.9V28.944H11.5V20.262C11.5 19.9968 11.6054 19.7424 11.7929 19.5549C11.9804 19.3674 12.2348 19.262 12.5 19.262H19.5C19.7652 19.262 20.0196 19.3674 20.2071 19.5549C20.3946 19.7424 20.5 19.9968 20.5 20.262V28.944H26.089V19.904C26.089 19.6388 26.1944 19.3844 26.3819 19.1969C26.5694 19.0094 26.8238 18.904 27.089 18.904C27.3542 18.904 27.6086 19.0094 27.7961 19.1969C27.9836 19.3844 28.089 19.6388 28.089 19.904V29.944C28.089 30.0755 28.0631 30.2057 28.0127 30.3271C27.9623 30.4486 27.8885 30.5589 27.7954 30.6518C27.7023 30.7447 27.5919 30.8183 27.4703 30.8685C27.3487 30.9186 27.2185 30.9443 27.087 30.944Z"
      fill={primary}
    />
    <path
      d="M1.99979 16.9998C1.80204 16.9997 1.60874 16.9411 1.44433 16.8312C1.27992 16.7213 1.15178 16.5651 1.07611 16.3824C1.00044 16.1997 0.980636 15.9987 1.0192 15.8048C1.05777 15.6108 1.15298 15.4326 1.29279 15.2928L15.2928 1.29279C15.4803 1.10532 15.7346 1 15.9998 1C16.265 1 16.5193 1.10532 16.7068 1.29279L30.7068 15.2928C30.8889 15.4814 30.9897 15.734 30.9875 15.9962C30.9852 16.2584 30.88 16.5092 30.6946 16.6946C30.5092 16.88 30.2584 16.9852 29.9962 16.9875C29.734 16.9897 29.4814 16.8889 29.2928 16.7068L15.9998 3.41379L4.42479 14.9888L11.2448 14.9578C11.51 14.9578 11.7644 15.0631 11.9519 15.2507C12.1394 15.4382 12.2448 15.6926 12.2448 15.9578C12.2448 16.223 12.1394 16.4774 11.9519 16.6649C11.7644 16.8524 11.51 16.9578 11.2448 16.9578L1.99979 16.9998Z"
      fill={primary}
    />
    <path
      d="M25 17C24.8685 17.0001 24.7383 16.9742 24.6168 16.924C24.4953 16.8737 24.3849 16.8 24.292 16.707L16.292 8.70697C16.1043 8.51946 15.9989 8.26509 15.9988 7.99982C15.9987 7.73455 16.104 7.48011 16.2915 7.29247C16.479 7.10483 16.7334 6.99936 16.9986 6.99927C17.2639 6.99917 17.5183 7.10446 17.706 7.29197L25.706 15.292C25.8461 15.4317 25.9416 15.6099 25.9804 15.8039C26.0192 15.9979 25.9995 16.1991 25.9239 16.382C25.8483 16.5648 25.7202 16.7212 25.5557 16.8312C25.3913 16.9412 25.1979 16.9999 25 17Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_142"
        x1="15.9988"
        y1="11.9996"
        x2="25.9998"
        y2="11.9996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
