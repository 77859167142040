import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_718_2267)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 15H27.95C27.468 9.172 22.828 4.532 17 4.05V2C17 1.448 16.552 1 16 1C15.448 1 15 1.448 15 2V4.05C9.172 4.532 4.532 9.172 4.05 15H2C1.448 15 1 15.448 1 16C1 16.552 1.448 17 2 17H4.05C4.532 22.828 9.172 27.468 15 27.95V30C15 30.552 15.448 31 16 31C16.552 31 17 30.552 17 30V27.95C22.828 27.468 27.468 22.828 27.95 17H30C30.552 17 31 16.552 31 16C31 15.448 30.552 15 30 15ZM16 26C10.477 26 6 21.523 6 16C6 10.477 10.477 6 16 6C21.523 6 26 10.477 26 16C26 21.523 21.523 26 16 26Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11ZM9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_718_2267" x1="23" y1="23" x2="23" y2="9" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
