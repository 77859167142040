import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1341_5112)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 25H10C9.45 25 9 24.55 9 24C9 23.45 9.45 23 10 23H22C22.55 23 23 23.45 23 24C23 24.55 22.55 25 22 25Z"
      fill={secondary}
    />
    <path
      d="M29 17.0002H28.92C28.97 16.6702 29 16.3402 29 16.0002C29 14.1202 28.25 12.4102 27.04 11.1602L29.89 5.45024C30.14 4.96024 29.94 4.36024 29.44 4.11024C28.94 3.86024 28.35 4.06024 28.1 4.56024L25.42 9.91024C25.04 9.69024 24.63 9.51024 24.21 9.37024L25.95 3.28024C26.1 2.75024 25.79 2.20024 25.26 2.04024C24.73 1.89024 24.18 2.20024 24.02 2.73024L22.22 9.02024C22.14 9.02024 22.06 9.01024 21.98 9.01024C21.09 9.01024 20.24 9.18024 19.45 9.49024C18.18 5.23024 14.03 2.94024 13.83 2.84024C13.45 2.64024 12.99 2.70024 12.68 2.99024C12.54 3.12024 9.63997 5.83024 9.00997 9.69024C7.91997 9.13024 6.83997 8.78024 5.96997 8.63024C5.54997 8.55024 5.12997 8.75024 4.91997 9.12024C4.81997 9.30024 2.70997 13.1202 3.85997 17.0202H2.96997C2.41997 17.0202 1.96997 17.4702 1.96997 18.0202C1.96997 21.8202 3.53997 29.0202 9.46997 29.0202H22.47C28.4 29.0202 29.97 21.8202 29.97 18.0202C29.97 17.4702 29.52 17.0202 28.97 17.0202L29 17.0002ZM27 16.0002C27 16.3402 26.96 16.6702 26.89 17.0002H24.82C24.93 16.6902 25 16.3502 25 16.0002C25 14.3502 23.65 13.0002 22 13.0002C20.35 13.0002 19 14.3502 19 16.0002C19 16.3502 19.07 16.6902 19.18 17.0002H17.11C17.04 16.6702 17 16.3402 17 16.0002C17 13.2402 19.24 11.0002 22 11.0002C24.76 11.0002 27 13.2402 27 16.0002ZM21 16.0002C21 15.4502 21.45 15.0002 22 15.0002C22.55 15.0002 23 15.4502 23 16.0002C23 16.5502 22.55 17.0002 22 17.0002C21.45 17.0002 21 16.5502 21 16.0002ZM13.55 4.99024C14.7 5.77024 17.03 7.69024 17.66 10.5202C16.04 11.8002 15 13.7802 15 16.0002C15 16.3402 15.04 16.6702 15.08 17.0002H14.07C13.93 14.2302 12.58 12.2602 10.92 10.9102C11.02 8.23024 12.67 5.98024 13.53 4.99024H13.55ZM6.36997 10.7602C8.00997 11.2502 11.8 12.8202 12.08 17.0002H6.01997C5.03997 14.5802 5.85997 11.9602 6.36997 10.7602ZM22.5 27.0002H9.49997C5.17997 27.0002 4.24997 21.3502 4.04997 19.0002H5.07997C5.17997 19.0302 5.27997 19.0502 5.38997 19.0502C5.49997 19.0502 5.58997 19.0302 5.69997 19.0002H27.66H27.94C27.74 21.3502 26.81 27.0002 22.49 27.0002H22.5Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_1341_5112" x1="9" y1="24" x2="23" y2="24" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
