import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  notStartedRoot: {},
  inProgressRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.scheduled.main,
  },
  submittedRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
  },
}))

const NestedProcessStatusChip = ({ job, ...rest }) => {
  const classes = useStyles()

  let root = null
  let label = null
  if (job.completedSteps === job.totalSteps) {
    root = classes.submittedRoot
    label = `All ${job.totalSteps} steps`
  } else if (job.completedSteps > 0) {
    root = classes.inProgressRoot
    label = `${job.completedSteps} of ${job.totalSteps} steps`
  } else {
    root = classes.notStartedRoot
    label = `${job.completedSteps} of ${job.totalSteps} steps`
  }

  return <Chip classes={{ root }} label={label} {...rest} />
}

export { NestedProcessStatusChip }
