import React, { useState, useMemo } from "react"
import {
  Box,
  Collapse,
  TextField,
  Grid,
  Divider,
  useTheme,
  useMediaQuery,
  MenuItem,
  ListSubheader,
  makeStyles,
  IconButton,
  Chip,
} from "@material-ui/core"
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab"
import { Trans, t } from "@lingui/macro"
import {
  MentionTextInput,
  OutlinedSelect,
  ExpansionPanel,
  UploadingList,
  UploadingImagesList,
  Icon,
  RowBox,
  ActionCard,
  TimeAgo,
  LocationOutlinedSelect,
  Checkbox,
} from ".."
import {
  PROCESS_STEP_NON_RESPONSE_TYPES,
  PROCESS_STEP_LOGIC_RESPONSE_TYPES,
  PROCESS_STEP_RESPONSE_OPTIONS,
  PROCESS_STEP_RESPONSE_TYPE,
  PROCESS_STEP_RESPONSE_TYPES_WITH_SCORING,
  useQueryResponseSets,
  PROCESS_STEP_INPUT_TYPE_LABELS,
  PROCESS_STEP_INPUT_TYPE,
  PROCESS_STEP_SELECTION_SOURCES,
} from "../../data"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { processFormats } from "../../data/processes/processFormats"
import { initialStepState, initialStepLogicState } from "./ProcessCreator"
import { RemoveIconButton } from "../Buttons"
import { CreatorAtAndUploaders } from "../Creators"
import { toId, useMountEffect, multipleSelectChange, mapToIds } from "../../utils"
import { ProcessSearch } from "./ProcessSearch"
import { ConditionSwitch } from "../Switch/ConditionSwitch"
import { FieldSectionHeading } from "../Headings"
import { ExpansionPanelOptions } from "../ExpansionPanel/ExpansionPanelOptions"
import { CommonChip } from "../Chips/CommonChip"
import { ProcessStepCreatorLogic } from "./ProcessStepCreatorLogic"
import { ProcessStepCreatorLogicSummary } from "./ProcessStepCreatorLogicSummary"
import { useAuth } from "../../services"
import { ResponseSetCustomScoring } from "./ResponseSetCustomScoring"
import { TooltipIconButton } from "../Buttons/TooltipIconButton"
import { titleCase } from "../../utils/string"
import { IconMenuItemContent } from "../OutlinedSelect/IconMenuItemContent"

const useFieldStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 14,
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))

const formatResponseTypeAndSet = (responseType, responseSet) => {
  return `${responseType}${responseSet ? `|${toId(responseSet)}` : ""}`
}

const debounceUpdateTimers = {}
const debounceUpdateTimeout = 200
const debounceTimersInitDelay = 200

const getDebounceKey = (index, propName) => {
  return `debounce_${index}_${propName}`
}

const Fields = ({ onAdd, onUpdate, index, initialState, disabled, enableRichContent }) => {
  const { hasFeature } = useAuth()
  const theme = useTheme()
  const classes = useFieldStyles()
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const { data: responseSetsData } = useQueryResponseSets()
  const [state, setState] = useState({
    name: "",
    description: "",
    responseType: "",
    responseSet: null,
    process: null,
    format: { unit: null, decimals: null },
    canEnterManually: true,
    canUseDevice: false,
  })
  const [nestedProcess, setNestedProcess] = useState(null)
  const [enableDebounces, setEnableDebounces] = useState(false)
  const hasProbes = hasFeature("probes")

  useMountEffect(() => {
    setState(() => ({ ...initialState }))
    debounceUpdateTimers[getDebounceKey(index, "_init")] = setTimeout(() => {
      setEnableDebounces(true)
    }, debounceTimersInitDelay)
    return () => {
      Object.keys(debounceUpdateTimers)
        .filter((key) => key.startsWith(getDebounceKey(index, "")))
        .forEach((key) => clearTimeout(debounceUpdateTimers[key]))
    }
  })

  const handleAdd = (event, addIndex) => {
    if (event.key !== "Enter") {
      return
    }
    onAdd && onAdd(addIndex)
  }

  const handleUpdate = (propName, value, options) => {
    setState((prev) => ({ ...prev, [propName]: value }))

    if (!enableDebounces || !options?.debounceUpdate) {
      return onUpdate(index, propName, value)
    }

    const debounceKey = getDebounceKey(propName)

    clearTimeout(debounceUpdateTimers[debounceKey])

    debounceUpdateTimers[debounceKey] = setTimeout(() => {
      onUpdate(index, propName, value)
    }, debounceUpdateTimeout)
  }

  const optionValue = useMemo(() => {
    if (state.responseType !== PROCESS_STEP_RESPONSE_TYPE.NUMBER) {
      return null
    }
    return processFormats.find((item) => item.name === state.format?.unit) || null
  }, [state.format, state.responseType])

  const handleFormatUpdate = (propName, value) => {
    if (state.responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER) {
      const newFormat = { ...state.format, [propName]: value }
      handleUpdate("format", newFormat)
    }
  }

  const handleStepTypeChange = (event) => {
    const value = event.target.value

    if (PROCESS_STEP_NON_RESPONSE_TYPES.includes(value)) {
      handleUpdate("responseMandatory", initialStepState.responseMandatory)
      handleUpdate("hasScoring", initialStepState.hasScoring)
      handleUpdate("canFail", initialStepState.canFail)
      handleUpdate("format", initialStepState.format)
    }

    if (value !== PROCESS_STEP_RESPONSE_TYPE.NUMBER) {
      handleUpdate("format", initialStepState.format)
    }

    // TODO: Enable this when ready
    // if (value === PROCESS_STEP_RESPONSE_TYPE.TEMPERATURE_TIMER) {
    //   handleMultipleSelectionChange("selectFrom", PROCESS_STEP_SELECTION_SOURCES.FOOD_ITEMS)
    //   handleMultipleSelectionChange("allowMultiple", false)
    // }

    if (value.includes("|")) {
      const [type, set] = value.split("|")
      handleUpdate("responseType", type)
      handleUpdate("scoring", [])
      handleUpdate("responseSet", set)
      return
    }

    handleUpdate("responseType", value)
    handleUpdate("scoring", [])
    handleUpdate("responseSet", null)
  }

  const handleProcessSelected = (item) => {
    if (item.steps.some((step) => step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS)) {
      setNestedProcess(item)
    } else {
      setNestedProcess(null)
      handleUpdate("process", item)
    }
  }

  const handleInputsChange = (event) => {
    if (event.target.value === PROCESS_STEP_INPUT_TYPE.DEVICE) {
      handleUpdate("canUseDevice", true)
      handleUpdate("canEnterManually", true)
      return
    }

    handleUpdate("canUseDevice", false)
    handleUpdate("canEnterManually", true)
  }

  const handleToggleManualEntry = () => {
    handleUpdate("canEnterManually", !state.canEnterManually)
  }

  const handleMultipleSelectionChange = (key, value) => {
    const newSelection = { ...state.selection, [key]: value }
    handleUpdate("selection", newSelection)
  }

  const handleCustomItemDelete = (itemIndex) => {
    const newCustomItems = state?.selection?.customItems?.filter((_, customItemIndex) => customItemIndex !== itemIndex)
    handleMultipleSelectionChange("customItems", newCustomItems)
  }

  const responseSets = responseSetsData?.responseSets?.list || []

  const getItemIconProps = (icon) => {
    switch (icon) {
      case "score":
        return { name: "score", title: t`This response type supports scoring` }
      case "logic":
        return { name: "logic", title: t`This response type supports conditional logic` }
      case "bluetooth":
        return { name: "bluetooth", title: t`This response type supports Bluetooth device input` }
      default:
        return null
    }
  }

  return (
    <>
      <Box mb={2}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              label={t`Step name`}
              value={state.name}
              autoFocus={!initialState.name && !mdDown}
              onChange={(event) => handleUpdate("name", event.target.value, { debounceUpdate: true })}
              onKeyUp={(event) => handleAdd(event, index)}
              fullWidth
              disabled={disabled}
              inputProps={{ "data-cy": "TextField-step-name" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedSelect
              label={t`Type of step`}
              native={false}
              id={`responseType${index}`}
              value={formatResponseTypeAndSet(state.responseType, state.responseSet)}
              onChange={handleStepTypeChange}
              disabled={disabled}
              selectInputProps={{ "data-cy": "OutlinedSelect-step-responseType" }}
            >
              {PROCESS_STEP_RESPONSE_OPTIONS.map((item, optionIndex) => {
                if (item.divider) {
                  return (
                    <ListSubheader key={`divider_${optionIndex}`} disableSticky>
                      {item.divider}
                    </ListSubheader>
                  )
                }

                if (item.useResponseSet) {
                  return responseSets.map((responseSet) => (
                    <MenuItem key={toId(responseSet)} value={`${item.type}|${toId(responseSet)}`}>
                      <IconMenuItemContent
                        label={responseSet.name}
                        icon="custom-outlined"
                        iconProps={{ customName: responseSet.icon || "rule" }}
                        adornments={[getItemIconProps("score")]}
                      />
                    </MenuItem>
                  ))
                }

                return (
                  <MenuItem key={item.type} value={item.type}>
                    <IconMenuItemContent
                      label={item.label || item.type}
                      icon={item.icon}
                      adornments={item.adornments?.map(getItemIconProps) || []}
                    />
                  </MenuItem>
                )
              })}
            </OutlinedSelect>
          </Grid>
        </Grid>
      </Box>

      {/* Formatting section for applicable response types */}
      {state.responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER && (
        <Box mt={2} mb={2}>
          {hasProbes && (
            <>
              <Divider />
              <FieldSectionHeading my={2} className={classes.heading}>
                <Trans>Options</Trans>
              </FieldSectionHeading>

              <Box mt={1} mb={2}>
                <OutlinedSelect
                  label={t`Number entry method`}
                  native={false}
                  id={`inputs${index}`}
                  value={state.canUseDevice ? PROCESS_STEP_INPUT_TYPE.DEVICE : PROCESS_STEP_INPUT_TYPE.MANUAL}
                  onChange={handleInputsChange}
                >
                  <MenuItem value={PROCESS_STEP_INPUT_TYPE.MANUAL}>
                    {PROCESS_STEP_INPUT_TYPE_LABELS[PROCESS_STEP_INPUT_TYPE.MANUAL]}
                  </MenuItem>
                  <MenuItem value={PROCESS_STEP_INPUT_TYPE.DEVICE}>
                    {PROCESS_STEP_INPUT_TYPE_LABELS[PROCESS_STEP_INPUT_TYPE.DEVICE]}
                  </MenuItem>
                </OutlinedSelect>
                {state.canUseDevice && (
                  <Box mt={1} mb={2}>
                    <Checkbox
                      color="primary"
                      type="label"
                      label={t`Allow manual entry`}
                      checked={state.canEnterManually}
                      onChange={handleToggleManualEntry}
                    />
                  </Box>
                )}
              </Box>
            </>
          )}

          <Divider />
          <FieldSectionHeading my={2} className={classes.heading}>
            <Trans>Format</Trans>
          </FieldSectionHeading>

          <Box mt={1} mb={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id={`responseFormatUnit${index}`}
                  value={optionValue}
                  getOptionLabel={(option) => option?.display || ""}
                  getOptionSelected={(option) => option.name === optionValue.name}
                  options={processFormats || []}
                  onChange={(event, value) => handleFormatUpdate("unit", value?.name || null)}
                  groupBy={(option) => option.type}
                  renderInput={(params) => <TextField {...params} variant="outlined" label={t`Unit`} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <OutlinedSelect
                  label={t`Decimal places`}
                  native={false}
                  id={`responseFormatDecimals${index}`}
                  value={state.format?.decimals != null ? state.format.decimals : ""}
                  onChange={(e) => handleFormatUpdate("decimals", e.target.value === "" ? null : e.target.value)}
                >
                  <MenuItem value="">No limit</MenuItem>
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </OutlinedSelect>
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Box>
      )}

      {/* Multiple selection input */}
      {state.responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION && (
        <Box mt={2} mb={2}>
          <Divider />
          <FieldSectionHeading mb={0} mt={2} className={classes.heading}>
            Options
          </FieldSectionHeading>
          <Box mt={1} mb={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <OutlinedSelect
                  label={t`Select from`}
                  native={false}
                  value={state.selection?.selectFrom ?? "users"}
                  onChange={(e) => handleMultipleSelectionChange("selectFrom", e.target.value)}
                >
                  {Object.values(PROCESS_STEP_SELECTION_SOURCES).map((item) => (
                    <MenuItem key={item} value={item}>
                      {titleCase(item.replace("_", " "))}
                    </MenuItem>
                  ))}
                </OutlinedSelect>
              </Grid>
              {state?.selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS && (
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    multiple
                    id={`selectionCustomItems${index}`}
                    value={state?.selection?.customItems ?? []}
                    options={[]}
                    onChange={(event, value) => {
                      handleMultipleSelectionChange("customItems", value)
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, tagIndex) => (
                        <Chip
                          label={option}
                          {...getTagProps({ tagIndex })}
                          onDelete={() => {
                            handleCustomItemDelete(tagIndex)
                          }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t`Items`}
                        placeholder={t`Type item and press enter...`}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Checkbox
                  color="primary"
                  type="label"
                  label={t`Allow multiple selections`}
                  checked={state?.selection?.allowMultiple ?? false}
                  onChange={(e) => handleMultipleSelectionChange("allowMultiple", e.target.checked)}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Box>
      )}

      {/* Step description */}
      {enableRichContent && (
        <MentionTextInput
          variant="outlined"
          label={t`Step description`}
          minHeight={136}
          value={state.description}
          onChange={(e) => handleUpdate("description", e.target.value, { debounceUpdate: true })}
          fullWidth
          multiline
          disabled={disabled}
          data-cy="TextField-step-description"
        />
      )}

      {/* Process selection input for nested processes */}
      {state.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && (
        <Box mt={2}>
          {!state.process && (
            <>
              <ProcessSearch
                placeholder={t`Search for the existing process...`}
                onItemSelected={handleProcessSelected}
              />
              {nestedProcess && (
                <Alert severity="error" className={classes.alert}>
                  <AlertTitle>
                    <Trans>This process contains nested steps</Trans>
                  </AlertTitle>{" "}
                  <Trans>
                    Only a single level of nesting is supported. Please select another process or remove any nested
                    steps from <strong>{nestedProcess.name}</strong> to use it here.
                  </Trans>
                </Alert>
              )}
            </>
          )}
          {state.process && (
            <>
              <ActionCard
                title={state.process.name}
                detail={
                  <>
                    {state.process.stepCount} steps,{" "}
                    {state.process.createdAt !== state.process.updatedAt ? "updated" : "created"}{" "}
                    <TimeAgo date={state.process.updatedAt} />
                  </>
                }
                rightChildren={
                  <>
                    <IconButton onClick={() => handleUpdate("process", null)} data-cy="Button-remove-process">
                      <Icon name="cancel" />
                    </IconButton>
                  </>
                }
              />
            </>
          )}
        </Box>
      )}
    </>
  )
}

const useExpansionStyles = makeStyles((theme) => ({
  root: {
    border: ({ hasLogic }) => (!hasLogic ? `1px solid ${theme.palette.grey[300]}` : 0),
    borderRadius: 4,
    background: ({ responseType, expanded }) =>
      responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION && !expanded
        ? theme.palette.grey[100]
        : theme.palette.background.default,
    transition: ({ expanded }) => (expanded ? "0.2s background ease-in-out" : null),
  },
}))

const useExpansionSummaryStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
}))

const useExpansionDetailsStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    border: ({ hasLogic }) => (hasLogic ? `1px solid rgba(${theme.palette.tertiary.mainRgb},0.4)` : 0),
    borderRadius: ({ hasLogic }) => (hasLogic ? 4 : null),
  },
  detailGrid: {
    display: "grid",
  },
  detailGridItem: {
    overflow: "hidden",
  },
  logicChip: {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.text.white,
  },
  divider: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const ProcessStepCreator = ({
  stepIndex: index,
  onUpdate,
  onRemove,
  onClone,
  onAdd,
  onUploadingStateChange,
  processType,
  step: {
    name,
    description,
    responseType,
    responseMandatory,
    responseSet,
    scoring,
    process,
    canFail,
    hasScoring,
    hasExclusions,
    exclusions,
    format,
    expanded,
    uploads,
    logic,
    canEnterManually,
    canUseDevice,
    selection,
  },
  sortable = true,
  stepDisabled: disabled,
  locations,
}) => {
  const { hasFeature } = useAuth()
  const hasLogicOption = hasFeature("process_logic") && PROCESS_STEP_LOGIC_RESPONSE_TYPES.includes(responseType)
  const hasLogic = hasLogicOption && logic?.length > 0
  const expansionClasses = useExpansionStyles({ responseType, expanded, hasLogic })
  const classes = useStyles({ hasLogic, expanded })
  const expansionSummaryClasses = useExpansionSummaryStyles()
  const expansionDetailsClasses = useExpansionDetailsStyles()
  const { data: responseSetsData } = useQueryResponseSets()
  const [uploading, setUploading] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const responseSets = responseSetsData?.responseSets?.list || []
  const responseSetItem = responseSets.find((item) => toId(item) === toId(responseSet))

  const handleBeforeUpload = (items) => {
    if (onUploadingStateChange) onUploadingStateChange(true)
    setIsUploading(true)
    setUploading(() => items)
  }

  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }

  const handleItemUploaded = (item) => {
    onUpdate(index, "uploads", (state) => {
      state[index].uploads = [...state[index].uploads, item]
      return [...state]
    })
  }

  const handleRemoveUpload = (id) => {
    onUpdate(index, "uploads", [...uploads.filter((upload) => upload.id !== id)])
  }

  const handleNotUploading = () => {
    setIsUploading(false)
    if (onUploadingStateChange) onUploadingStateChange(false)
  }

  const handleToggleExpanded = () => {
    onUpdate(index, "expanded", !expanded)
  }

  const handleManadatoryChange = () => {
    onUpdate(index, "responseMandatory", !responseMandatory)
  }

  const handleEnableFailureChange = () => {
    onUpdate(index, "canFail", !canFail)
  }

  const handleEnableScoringChange = () => {
    const newHasScoring = !hasScoring
    onUpdate(index, "hasScoring", newHasScoring)
    if (!newHasScoring) {
      onUpdate(index, "scoring", [])
    }
  }

  const handleEnableCustomScoringChange = () => {
    if (scoring?.length > 0) {
      onUpdate(index, "scoring", [])
      return
    }

    onUpdate(index, "scoring", [
      ...responseSetItem.responses.map((response) => ({
        response: toId(response),
        score: response.score,
        noScore: response.noScore,
        failure: response.failure,
      })),
    ])
  }

  const handleUpdateScoring = (updatedScoring) => {
    onUpdate(index, "scoring", [...updatedScoring])
  }

  const handleExclusionsChange = () => {
    onUpdate(index, "hasExclusions", !hasExclusions)
  }

  const handleExclusionsLocationsChange = (event) => {
    onUpdate(index, "exclusions", [...multipleSelectChange(exclusions, event)])
  }

  const handleExclusionsRegionsChange = (regionLocations) => {
    onUpdate(index, "exclusions", [...mapToIds(regionLocations)])
  }

  const handleLogicChange = () => {
    if (logic?.length > 0) {
      onUpdate(index, "logic", [])
      return
    }

    onUpdate(index, "logic", [{ ...JSON.parse(JSON.stringify(initialStepLogicState)) }])
  }

  const handleAddLogic = () => {
    onUpdate(index, "logic", [...logic, { ...JSON.parse(JSON.stringify(initialStepLogicState)) }])
  }

  const handleUpdateLogic = (updatedLogic) => {
    onUpdate(index, "logic", [...updatedLogic])
  }

  const handleClone = () => {
    onClone && onClone(index)
  }

  const handleRemove = () => {
    onRemove && onRemove(index)
  }

  const getResponseTypeLabel = (value) => {
    const [type, set] = value.split("|")

    const responseTypeItem = PROCESS_STEP_RESPONSE_OPTIONS.find((item) => item.type === type)

    if (responseTypeItem?.useResponseSet && set) {
      const item = responseSets.find((rs) => toId(rs) === set)
      return `${item?.name || value}${responseTypeItem?.appendToName ? ` ${responseTypeItem.appendToName}` : ""}`
    }

    return responseTypeItem?.description || responseTypeItem?.label || value
  }

  const handleLogicSummaryEdit = () => {
    onUpdate(index, "expanded", true)
  }

  const hasRichContent = responseType !== PROCESS_STEP_RESPONSE_TYPE.PROCESS

  const nonResponseType = PROCESS_STEP_NON_RESPONSE_TYPES.includes(responseType)
  const responseOption = PROCESS_STEP_RESPONSE_OPTIONS.find((item) => item.type === responseType)

  const hasScoringOption = PROCESS_STEP_RESPONSE_TYPES_WITH_SCORING.includes(responseType)
  const hasCustomScoringOption = hasScoring && hasScoringOption && !!responseSetItem
  const hasCustomScoring = hasCustomScoringOption && scoring?.length > 0

  const failureResponses =
    responseOption?.canResponseFail && responseSetItem ? responseSetItem.responses.filter((item) => item.failure) : []
  const hasResponseFailOption = failureResponses.length > 0
  const hasNestedFailOption = responseOption?.canNestedFail
  const failureResponsesText =
    failureResponses.length > 0 ? failureResponses.map((item) => item.text).join(" or ") : null

  const ConditionDivider = () => <Divider className={classes.divider} />

  return (
    <Box mb={1} className={classes.root}>
      <ExpansionPanel
        {...{
          title: `${name}${responseMandatory ? "*" : ""}`,
          description: getResponseTypeLabel(formatResponseTypeAndSet(responseType, responseSet)),
          sortable,
          sortableTitle: `Step ${index + 1}`,
          expanded,
          onChange: () => handleToggleExpanded(),
          placeholders: { title: t`Step name`, description: t`Response type` },
          TransitionProps: { unmountOnExit: true },
          expansionClasses,
          expansionSummaryClasses,
          expansionDetailsClasses,
          chips: (
            <>
              {hasLogic && <CommonChip label="Logic" size="small" className={classes.logicChip} />}
              {responseMandatory && (
                <Box ml={1}>
                  <CommonChip label={t`Required`} size="small" />
                </Box>
              )}
              {canFail && (
                <Box ml={1}>
                  <CommonChip label={t`Autofail Process`} size="small" />
                </Box>
              )}
              {!hasScoring && PROCESS_STEP_RESPONSE_TYPES_WITH_SCORING.includes(responseType) && (
                <Box ml={1}>
                  <CommonChip label={t`No score`} size="small" />
                </Box>
              )}
              {hasCustomScoring && (
                <Box ml={1}>
                  <CommonChip label={t`Custom scoring`} size="small" />
                </Box>
              )}
              {hasExclusions && (
                <Box ml={1}>
                  <CommonChip label={t`Exclude locations`} size="small" />
                </Box>
              )}
            </>
          ),
        }}
      >
        <Box flexGrow={1}>
          <Box px={2}>
            <Fields
              processType={processType}
              index={index}
              initialState={{
                ...{
                  name,
                  description,
                  responseType,
                  responseSet,
                  process,
                  format,
                  canEnterManually,
                  canUseDevice,
                  selection,
                },
              }}
              onUpdate={onUpdate}
              onAdd={onAdd}
              enableRichContent={hasRichContent}
              disabled={disabled}
            />

            {hasRichContent && uploads && uploads.length > 0 && (
              <Box my={1}>
                <UploadingImagesList
                  uploaded={uploads}
                  uploading={uploading}
                  height={120}
                  onRemoveUpload={handleRemoveUpload}
                  gridItemProps={{
                    xs: 3,
                  }}
                />
                <UploadingList
                  uploaded={uploads}
                  uploading={uploading}
                  images={false}
                  onRemoveUpload={handleRemoveUpload}
                />
              </Box>
            )}
            <RowBox mt={1}>
              {hasRichContent && (
                <CreatorAtAndUploaders
                  onBeforeUpload={handleBeforeUpload}
                  onProgress={handleUploadProgress}
                  onItemUploaded={handleItemUploaded}
                  onAfterUpload={handleNotUploading}
                  onError={handleNotUploading}
                  disableUploaders={isUploading || disabled}
                  disableAt={disabled}
                />
              )}
              <Box ml="auto">
                <TooltipIconButton onClick={handleClone} tooltip={t`Clone step`} disabled={disabled}>
                  <Icon name="clone" />
                </TooltipIconButton>
                <RemoveIconButton onConfirmedClick={handleRemove} subject="step" disabled={disabled}>
                  <Icon name="delete" />
                </RemoveIconButton>
              </Box>
            </RowBox>
          </Box>
          <ExpansionPanelOptions icon="magic" heading={t`Conditions`} pb={hasLogic ? 0 : 2}>
            {!nonResponseType && (
              <>
                <ConditionSwitch
                  label={t`Required`}
                  hint={t`Users will not be able to skip this step`}
                  checked={responseMandatory}
                  onChange={handleManadatoryChange}
                />
                {hasResponseFailOption && (
                  <ConditionSwitch
                    label={t`Autofail Process`}
                    hint={
                      <Trans>
                        If used in an audit, responding <strong>{failureResponsesText}</strong> will fail the process
                      </Trans>
                    }
                    checked={canFail}
                    onChange={handleEnableFailureChange}
                  />
                )}
                {hasNestedFailOption && (
                  <ConditionSwitch
                    label={t`Autofail Audit`}
                    hint={t`Fail the entire audit if the nested process fails`}
                    checked={canFail}
                    onChange={handleEnableFailureChange}
                  />
                )}
                {hasScoringOption && (
                  <ConditionSwitch
                    label={t`No score`}
                    hint={t`Scores for this step will not be shown in reporting`}
                    checked={!hasScoring}
                    onChange={handleEnableScoringChange}
                  />
                )}
                {hasCustomScoringOption && (
                  <ConditionSwitch
                    label={t`Custom scoring`}
                    hint={t`Assign custom scoring for the responses available`}
                    checked={hasCustomScoring}
                    onChange={handleEnableCustomScoringChange}
                  />
                )}
                {hasCustomScoring && (
                  <Collapse in={hasCustomScoring}>
                    <ResponseSetCustomScoring
                      responseSet={responseSetItem}
                      scoring={scoring}
                      onChange={handleUpdateScoring}
                    />
                    <ConditionDivider />
                  </Collapse>
                )}
              </>
            )}
            <ConditionSwitch
              label={t`Exclude locations`}
              hint={t`Remove this step at certain locations`}
              checked={hasExclusions}
              onChange={handleExclusionsChange}
            />

            {exclusions && (
              <Collapse in={hasExclusions}>
                <Box mt={2} mb={hasLogicOption ? 1 : 0}>
                  <Box mt={2}>
                    <Grid container className={classes.detailGrid} direction="row" spacing={2}>
                      <Grid item xs={12} className={classes.detailGridItem}>
                        <LocationOutlinedSelect
                          hasAll={false}
                          value={exclusions[0] === "all" ? [] : mapToIds(exclusions)}
                          onChange={handleExclusionsLocationsChange}
                          onRegionChange={handleExclusionsRegionsChange}
                          multiple
                          required
                          data-cy="Select-step-exclusions"
                        />
                      </Grid>

                      {exclusions.length > 1 && locations && (
                        <Grid item xs={12} className={expansionClasses.detailGridItem}>
                          <Trans>
                            This step will be removed at{" "}
                            <TruncateNames
                              names={locations.locations
                                .filter((location) => location.id && mapToIds(exclusions).includes(location.id))
                                .map((location) => location.name)}
                              max={10}
                            />
                          </Trans>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Collapse>
            )}

            {!nonResponseType && (
              <>
                {hasLogicOption && (
                  <ConditionSwitch
                    label={t`Add logic`}
                    hint={t`Add conditional logic based on the user's answer`}
                    checked={logic?.length > 0}
                    onChange={handleLogicChange}
                  />
                )}

                {hasLogicOption && logic?.length > 0 && (
                  <Box mt={2} mx={-2}>
                    <ProcessStepCreatorLogic
                      logic={logic}
                      format={format}
                      onUpdate={handleUpdateLogic}
                      onAdd={handleAddLogic}
                    />
                  </Box>
                )}
              </>
            )}
          </ExpansionPanelOptions>
        </Box>
      </ExpansionPanel>
      {!expanded && hasLogic && (
        <Box>
          <ProcessStepCreatorLogicSummary logic={logic} format={format} onEdit={handleLogicSummaryEdit} />
        </Box>
      )}
    </Box>
  )
}

export { ProcessStepCreator }
