import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip, Tooltip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: ({ cursor }) => cursor,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  icon: {
    width: 16,
    color: theme.palette.text.white,
  },
}))

const TrainingScoreChip = ({
  tooltip = "Score achieved",
  label,
  className,
  cursor = "pointer",
  successful = false,
}) => {
  const classes = useStyles({ successful, cursor })

  return tooltip ? (
    <Tooltip title={tooltip}>
      <Chip label={label} classes={classes} className={className} />
    </Tooltip>
  ) : (
    <Chip label={label} classes={classes} className={className} />
  )
}

export { TrainingScoreChip }
