import React from "react"
import { render } from "react-dom"
import * as Sentry from "@sentry/browser"
import { i18n } from "@lingui/core"
import { BrowserRouter } from "react-router-dom"
import { CookiesProvider } from "react-cookie"
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ApolloProvider } from "@apollo/client"
import moment from "moment"
import mixpanel from "mixpanel-browser"

import * as serviceWorker from "./serviceWorker"

import initConfig from "./config"

import { defaultLocale } from "./i18n"
import { DataClient, WebVersion } from "./services"

// master
import Master from "./areas/Master"
import { ErrorBoundary, ErrorInitConfig, ConnectivityError, SnackbarProvider } from "./components"
import Theme from "./Theme"
import { ThemeProvider } from "./ThemeContext"
import { OfflineModeProvider } from "./components/Offline/OfflineModeProvider"
import { RouteChangedMessageProvider } from "./components/Routing/RouteChangedMessageProvider"
import InternationalisationBooter from "./components/Internationalisation/InternationalisationBooter"

// Sentry application monitoring
if (
  !["localhost", "local.operandiodev.com"].includes(window.location.hostname) &&
  !window.location.hostname.startsWith("192.168.")
) {
  Sentry.init({
    dsn: "https://3926b4cb78f443b48c3c11a6624f2052@o378222.ingest.sentry.io/5201332",
    environment: window.location.hostname,
  })
}

// Help Scout support beacon
window.Beacon("init", "fdad8a9d-7235-4769-93dc-1809cdef3b8d")

// logs
console.log(`[APP] v${WebVersion}`)

// polyfill fromEntries
Object.fromEntries =
  Object.fromEntries ||
  function (arr) {
    return arr.reduce(function (acc, curr) {
      acc[curr[0]] = curr[1]
      return acc
    }, {})
  }

// init config
initConfig().then(
  (config) => {
    // init stripe
    const stripe = loadStripe(config.stripe.publishableKey)

    // init moment
    moment.suppressDeprecationWarnings = true

    // early init i18n
    i18n.activate(defaultLocale)

    // init mixpanel
    if (config.analytics.mixpanel.enabled) {
      mixpanel.init(config.analytics.mixpanel.token, config.analytics.mixpanel.settings)
      console.log("[MP]", "Init")
    }

    // create server client
    const dataClient = new DataClient(config)

    // device client info mock
    const mockDeviceClientInfo = config.version?.check?.mockDeviceClientInfo
    if (mockDeviceClientInfo) {
      window.deviceClientInfo = mockDeviceClientInfo
      console.log("[APP]", "Mocking device client info", window.deviceClientInfo)
    }

    // render
    render(
      <ThemeProvider>
        <Theme>
          <ErrorBoundary>
            <InternationalisationBooter>
              <ApolloProvider client={dataClient.client}>
                <CookiesProvider>
                  <SnackbarProvider>
                    <StripeElementsProvider stripe={stripe}>
                      <BrowserRouter>
                        <RouteChangedMessageProvider>
                          <OfflineModeProvider dataClient={dataClient}>
                            <Master />
                          </OfflineModeProvider>
                        </RouteChangedMessageProvider>
                      </BrowserRouter>
                    </StripeElementsProvider>
                    <ConnectivityError server={dataClient} />
                  </SnackbarProvider>
                </CookiesProvider>
              </ApolloProvider>
            </InternationalisationBooter>
          </ErrorBoundary>
        </Theme>
      </ThemeProvider>,
      document.getElementById("root"),
    )
  },
  (err) => {
    render(
      <Theme>
        <ErrorInitConfig message={err} />
      </Theme>,
      document.getElementById("root"),
    )
  },
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
