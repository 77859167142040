import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@material-ui/core"
import React, { useRef } from "react"
import { t } from "@lingui/macro"
import { ColumnBox, Icon, QRButton, useSnackbar } from ".."

const useInputStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.readonly,
    backgroundColor: theme.palette.grey[100],
    fontSize: 14,
  },
}))

const useStyles = makeStyles(() => ({
  inlineCopyButton: {
    marginRight: -6,
    marginLeft: 6,
  },
}))

const ReadOnlyOutlinedInput = ({
  id,
  label,
  variant = "outlined",
  hasCopy = false,
  hasQRCode = false,
  multiline = false,
  fullWidth = false,
  ...rest
}) => {
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const inputRef = useRef(null)
  const { showMessage } = useSnackbar()

  const handleCopy = () => {
    inputRef.current.select()
    document.execCommand("copy")
    showMessage({ message: t`Copied to your clipboard`, icon: <Icon name="clipboard" /> })
    inputRef.current.blur()
  }

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <OutlinedInput
        inputRef={inputRef}
        variant={variant}
        classes={inputClasses}
        multiline={multiline}
        notched
        {...rest}
        endAdornment={
          <>
            {hasQRCode && !multiline ? (
              <InputAdornment position="end">
                <QRButton iconSize="small" value={rest.value} />
              </InputAdornment>
            ) : null}
            {hasCopy && !multiline ? (
              <InputAdornment position="end">
                <Tooltip title={t`Copy to clipboard`} placement="top">
                  <IconButton onClick={handleCopy} className={classes.inlineCopyButton} size="small">
                    <Icon name="clipboard" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ) : null}
          </>
        }
      />
      {hasCopy && multiline && (
        <ColumnBox alignItems="flex-end">
          <IconButton onClick={handleCopy}>
            <Icon name="clipboard" />
          </IconButton>
        </ColumnBox>
      )}
    </FormControl>
  )
}

const ReadOnlyTextField = ({ ...rest }) => {
  const classes = useInputStyles()

  return (
    <TextField
      classes={classes}
      inputProps={{
        readOnly: true,
      }}
      {...rest}
    />
  )
}

export { ReadOnlyOutlinedInput, ReadOnlyTextField }
