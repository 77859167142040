import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
  },
}))

const ActiveChip = ({ className, label = <Trans>Active</Trans> }) => {
  const classes = useStyles()

  return <Chip label={label} classes={classes} className={className} size="small" />
}

export { ActiveChip }
