import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_200)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 25C16.5523 25 17 24.5523 17 24C17 23.4477 16.5523 23 16 23C15.4477 23 15 23.4477 15 24C15 24.5523 15.4477 25 16 25ZM16 27C17.6569 27 19 25.6569 19 24C19 22.3431 17.6569 21 16 21C14.3431 21 13 22.3431 13 24C13 25.6569 14.3431 27 16 27Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 16V8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8V16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16ZM16 5C14.3431 5 13 6.34315 13 8V16C13 17.6569 14.3431 19 16 19C17.6569 19 19 17.6569 19 16V8C19 6.34315 17.6569 5 16 5Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29ZM16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7982 7.39934C11.1299 7.84086 11.0409 8.46775 10.5994 8.79952C8.41025 10.4445 7 13.0575 7 15.9999C7 18.9428 8.41075 21.5563 10.6006 23.2012C11.0422 23.5329 11.1313 24.1597 10.7996 24.6013C10.4679 25.0429 9.84099 25.132 9.39941 24.8003C6.72805 22.7937 5 19.5981 5 15.9999C5 12.4023 6.72743 9.20728 9.39798 7.2006C9.83951 6.86883 10.4664 6.95781 10.7982 7.39934ZM21.2018 7.39934C21.5336 6.95781 22.1605 6.86883 22.602 7.2006C25.2726 9.20728 27 12.4023 27 15.9999C27 19.5981 25.272 22.7937 22.6006 24.8003C22.159 25.132 21.5321 25.0429 21.2004 24.6013C20.8687 24.1597 20.9578 23.5329 21.3994 23.2012C23.5892 21.5563 25 18.9428 25 15.9999C25 13.0575 23.5898 10.4445 21.4006 8.79952C20.9591 8.46775 20.8701 7.84087 21.2018 7.39934Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_200" x1="27" y1="7" x2="27" y2="25.0008" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
