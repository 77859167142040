import React, { memo, useMemo, useState } from "react"
import { TableRow, TableCell, Box, makeStyles, Typography } from "@material-ui/core"
import moment from "moment-timezone"
import useMeasure from "react-use-measure"
import { formatJobTitle, toId, useWindowSize } from "../../../utils"
import { PROCESS_STEP_RESPONSE_TYPE } from "../../../data"
import { ErrorBoundary, Icon, RowBox } from "../.."
import { JobTooltip } from "./Tooltips"
import { StepTableCellContent } from "./StepTableCellContent"
import { TableVirtualizer } from "../../Lists/TableVirtualizer"
import { TablePagination } from "../../Table/TablePagination"
import { TablePageIndicator } from "../../Table/TablePageIndicator"

const useTableStyles = makeStyles((theme) => ({
  stepCell: {
    position: "sticky",
    left: 0,
    maxWidth: 400,
    backgroundColor: theme.palette.background.default,
    overflowWrap: "break-word",
  },
  stepName: {
    maxWidth: 400,
    minWidth: 200,
    overflowWrap: "break-word",
  },
  nestedIcon: {
    fill: theme.palette.text.faint,
  },
  jobName: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  columnCell: {
    minWidth: 250,
  },
}))

const locationPageSize = 10

const JobStepDataTable = memo(function JobStepDataTable({ data, location, fullscreen }) {
  const classes = useTableStyles()
  const [{ offset, limit }, setOffsetLimit] = useState({ offset: 0, limit: locationPageSize })
  const { height: windowHeight } = useWindowSize()
  const [paginationRef, { height: paginationHeight }] = useMeasure({ debounce: 1 })

  const { rows, columns } = useMemo(() => {
    const {
      process: { steps },
      jobs,
    } = data

    const getJobSteps = (step) =>
      jobs.map(({ id, dueAt, steps: jobSteps }) => ({
        id,
        dueAt,
        step: jobSteps.find((jobStep) => toId(jobStep.step) === toId(step)) || {},
      }))

    const getNestedJobSteps = (step, jobSteps) => {
      return jobSteps
        .filter((jobStep) => jobStep.step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && jobStep.step.job)
        .map(
          ({
            step: {
              job: { id, steps: nestedJobSteps },
            },
          }) => ({
            id,
            step: nestedJobSteps.find((jobStep) => toId(jobStep.step) === toId(step)) || {},
          }),
        )
    }

    const flattenedSteps = steps.reduce((acc, step) => {
      if (step.responseType === PROCESS_STEP_RESPONSE_TYPE.INSTRUCTION) {
        return acc
      }

      const jobSteps = getJobSteps(step)

      acc.push({
        type: "root",
        step,
        jobs: jobSteps,
      })

      if (step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && step.process?.steps) {
        const filteredNestedSteps = step.process.steps.filter((item) => item.__typename !== "ProcessProcessStep")

        for (const nestedStep of filteredNestedSteps) {
          acc.push({
            type: "nested",
            step: nestedStep,
            jobs: getNestedJobSteps(nestedStep, jobSteps),
          })
        }
      }

      return acc
    }, [])

    return {
      rows: flattenedSteps,
      columns: data.jobs,
    }
  }, [data])

  const handlePageChange = (_, page) => {
    setOffsetLimit({
      offset: page * limit,
      limit,
    })
  }

  if (!data?.process) {
    return
  }

  const count = columns.length
  const currentColumnsPage = columns.slice(offset, offset + limit)
  const hasPagination = count > locationPageSize

  const tableHeight = (windowHeight || 1280) - (fullscreen ? 150 : 450) - (paginationHeight || 0)

  return (
    <>
      <TableVirtualizer
        style={{ height: tableHeight }}
        items={rows}
        fixedHeaderContent={() => (
          <TableRow>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white" }}>&nbsp;</TableCell>
            {currentColumnsPage.map((job) => {
              const { id, availableFrom } = job
              return (
                <TableCell key={id} align="center" className={classes.columnCell}>
                  <JobTooltip job={job} location={location}>
                    <Box display="flex" flexDirection="column">
                      <Box>{moment(availableFrom).tz(location.timeZone).format("D MMM YY")}</Box>
                      <Typography className={classes.jobName}>{formatJobTitle(job)}</Typography>
                    </Box>
                  </JobTooltip>
                </TableCell>
              )
            })}
          </TableRow>
        )}
        itemContent={(_, item) => (
          <ErrorBoundary key={toId(item.step)}>
            <TableCell className={classes.stepCell}>
              <RowBox className={classes.stepName} title={item.name}>
                {item.type === "nested" && (
                  <Box mr={0.5}>
                    <Icon name="nested" className={classes.nestedIcon} />
                  </Box>
                )}
                <Box>
                  {item.step.responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION && (
                    <>
                      <strong>{item.step.name}</strong>
                    </>
                  )}
                  {item.step.responseType !== PROCESS_STEP_RESPONSE_TYPE.SECTION && <>{item.step.name}</>}
                </Box>
              </RowBox>
            </TableCell>
            {item.jobs.slice(offset, offset + limit).map((jobStep) => {
              return (
                <TableCell align="center">
                  <RowBox justifyContent="center">
                    <StepTableCellContent jobStep={jobStep} />
                  </RowBox>
                </TableCell>
              )
            })}
          </ErrorBoundary>
        )}
      />

      <Box ref={paginationRef}>
        {hasPagination && (
          <TablePagination
            count={count}
            rowsPerPage={limit}
            page={offset / limit}
            boxProps={{
              pt: 1,
            }}
            onPageChange={handlePageChange}
          >
            <TablePageIndicator subject="jobs" total={count} limit={limit} offset={offset} hideWhenNone />
          </TablePagination>
        )}
      </Box>
    </>
  )
})

export { JobStepDataTable }
