import React from "react"
import { makeStyles, Box, Typography } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Avatar } from ".."
import { UserStatusChip } from "../Chips"
import TimeAgo from "../TimeAgo"
import { useAuth } from "../../services"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const useStyles = makeStyles((theme) => ({
  panel: {
    ...theme.cards.viewerCard,
    width: ({ layout }) => (layout === "landscape" ? "100%" : 322),
    textAlign: ({ layout }) => (layout === "portrait" ? "center" : "left"),
  },
  panelContents: {
    display: "flex",
    flexDirection: ({ layout }) => (layout === "portrait" ? "column" : "row"),
    alignItems: ({ layout }) => (layout === "portrait" ? "center" : "flex-start"),
    padding: theme.spacing(3),
    gap: theme.spacing(2),
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  nameTitle: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: "500",
    lineHeight: "30px",
  },
  groups: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
  lastSeen: {
    color: theme.palette.text.primary,
    fontSize: 13,
    lineHeight: "16px",
    whiteSpace: "nowrap",
  },
}))

const UserPanel = ({
  layout = "portrait",
  user: { id, firstName, lastName, avatar, groups, locations, status, invite, activeAt },
  className = "",
  mr = 5,
  ...rest
}) => {
  const classes = useStyles({ layout })
  const { isCurrentUser } = useAuth()
  const isPortrait = layout === "portrait"
  const isLandscape = layout === "landscape"

  return (
    <Box mr={mr} className={`${classes.panel} ${className}`} {...rest}>
      <Box className={classes.panelContents}>
        <Avatar {...{ firstName, lastName, avatar }} className={classes.avatar} />
        <Box flexGrow={1}>
          <Box
            mb={isPortrait ? 0.5 : 0}
            display="flex"
            flexGrow={1}
            justifyContent={isPortrait ? "center" : "flex-start"}
          >
            <Typography component="h1" className={classes.nameTitle}>
              {firstName} {lastName}
            </Typography>
          </Box>
          <Box className={classes.groups} mb={0.5}>
            {groups && groups.map((group) => group.name).join(", ")}
          </Box>
          <Box className={classes.locations}>
            {locations && locations.length > 0 && (
              <TruncateNames names={locations.map((location) => location.name)} max={3} />
            )}
          </Box>
        </Box>
        <Box
          alignItems={isLandscape ? "flex-end" : "center"}
          justifyItems={isLandscape ? "flex-end" : "flex-start"}
          display="flex"
          flexDirection="column"
        >
          <Box mb={0.5}>
            <UserStatusChip user={{ status, invite }} />
          </Box>
          <Box className={classes.lastSeen}>
            <Trans>Last seen</Trans>:{" "}
            {activeAt ? <TimeAgo date={isCurrentUser(id) ? new Date() : activeAt} /> : <Trans>Never</Trans>}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { UserPanel }
