import React from "react"
import { Box, Divider, Button, Grid, makeStyles } from "@material-ui/core"
import { toId } from "../../utils"
import { ProcessCollapse } from "./ProcessCollapse"
import { Icon } from ".."
import { RESPONSE_SET_DISPLAY_AS } from "../../data"

const useRowStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(0.5),
    "&:last-child": {
      marginBottom: theme.spacing(-0.5),
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.palette.text.white,
    background: theme.palette.info.main,
    boxShadow: "none",
    transition: "all 0.2s",
    "&:hover": {
      boxShadow: "none",
      transition: "all 0.2s ease",
      backgroundColor: theme.palette.info.dark,
    },
    "&:focus": {
      transition: "all 0.2s ease",
      boxShadow: `0px 0px 0px 3px rgba(${theme.palette.info.mainRgb},0.5)`,
      outline: `2px solid transparent`,
    },
  },
}))

const MultipleChoiceButtonsStep = ({
  step,
  expand,
  responses,
  value,
  buttonClass,
  onClick,
  disabled,
  classes,
  collapseTimeout = "auto",
}) => {
  const rowClasses = useRowStyles()

  const handleClick = (...args) => {
    onClick && onClick(...args)
  }

  if (!responses || !responses.length) {
    return null
  }

  const maxResponses = 10
  const numberRows = Math.ceil(responses.length / maxResponses)
  const itemsPerRow = Math.ceil(responses.length / numberRows)

  let rows = []

  if (responses.length > maxResponses) {
    for (let i = 0; i < responses.length; i += itemsPerRow) {
      const chunk = responses.slice(i, i + itemsPerRow)
      rows.push(chunk)
    }
  } else {
    rows = [responses]
  }

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        {rows.map((row, index) => (
          <Grid key={index} container spacing={1} className={rowClasses.row}>
            {row.map((response) => (
              <MultipleChoiceButton
                key={toId(response)}
                answer={response.text}
                setResponseValue={response.value}
                value={value}
                onClick={handleClick}
                className={buttonClass}
                disabled={disabled}
                showIcon={step?.responseSet?.displayAs === RESPONSE_SET_DISPLAY_AS.ICON}
                xs
              />
            ))}
          </Grid>
        ))}
      </Box>
    </ProcessCollapse>
  )
}

const MultipleChoiceButton = ({
  answer,
  setResponseValue,
  value,
  onClick,
  className,
  disabled: disabledInput,
  showIcon = false,
  ...rest
}) => {
  const selected = !!value && value.toLowerCase() === setResponseValue.toLowerCase()
  const disabled = !!value && !selected
  const buttonClasses = useStyles()

  const handleClick = (event) => {
    onClick && onClick(event, setResponseValue)
  }
  const isSelected = selected ? `${className} ${buttonClasses.selected}` : className

  const renderDisplayAsIcon = (text) => {
    const iconName = text.replace("_", "-")

    return <Icon name={iconName} />
  }

  return (
    <Grid item {...rest}>
      <Button
        color="inherit"
        variant={disabled ? "text" : "contained"}
        fullWidth
        onClick={handleClick}
        className={isSelected}
        disabled={disabled || disabledInput}
      >
        {showIcon ? <>{renderDisplayAsIcon(answer)}</> : <>{answer}</>}
      </Button>
    </Grid>
  )
}

export { MultipleChoiceButtonsStep, MultipleChoiceButton }
