import chroma from "chroma-js"

const hexToRgb = (hex) => {
  return chroma(hex).rgb().join(",")
}

const getContrast = (primaryColor, textColor, threshold = 2) => {
  const contrast = chroma.contrast(primaryColor, textColor)
  if (contrast < threshold) {
    return false
  }
  return true
}

const generateShade = (baseColor, stop) => {
  if (stop === 500) {
    return baseColor
  }

  const factor = Math.abs(500 - stop) / 500
  const shade = stop < 500 ? chroma(baseColor).mix("white", factor).hex() : chroma(baseColor).mix("black", factor).hex()
  return shade
}

const setFavicon = (icon) => {
  let link = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement("link")
    link.rel = "icon"
    document.head.appendChild(link)
  }
  link.href = icon
}

const setName = (name) => {
  document.title = name
}

export { hexToRgb, generateShade, getContrast, setFavicon, setName }
