import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_180)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5075 4.08732C15.7661 3.97153 16.0616 3.97087 16.3208 4.08551L30.3823 10.307C30.7365 10.4637 30.9683 10.8108 30.9774 11.198C30.9865 11.5851 30.7713 11.9427 30.4249 12.1159L26.2834 14.1867C25.7894 14.4337 25.1887 14.2335 24.9418 13.7395C24.6948 13.2455 24.895 12.6448 25.389 12.3979L27.6312 11.2768L15.9186 6.09456L4.35751 11.2715L15.9179 17.0543L18.0555 15.9934C18.5502 15.7479 19.1503 15.9499 19.3958 16.4447C19.6413 16.9394 19.4393 17.5394 18.9446 17.7849L16.3607 19.0672C16.0796 19.2067 15.7494 19.2062 15.4688 19.0658L1.57496 12.1158C1.22936 11.943 1.0143 11.5864 1.02257 11.2001C1.03084 10.8138 1.26096 10.4668 1.61365 10.3088L15.5075 4.08732ZM5.49999 15.5C6.05227 15.5 6.49998 15.9477 6.49998 16.5V21.8949L15.9999 26.8711L25.5 21.8949V16.5C25.5 15.9477 25.9477 15.5 26.5 15.5C27.0523 15.5 27.5 15.9477 27.5 16.5V22.5C27.5 22.872 27.2935 23.2132 26.964 23.3858L16.4639 28.8858C16.1733 29.038 15.8265 29.038 15.5359 28.8858L5.03599 23.3858C4.70647 23.2132 4.5 22.872 4.5 22.5V16.5C4.5 15.9477 4.94771 15.5 5.49999 15.5Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1162 10.9212C15.3746 10.4331 15.9797 10.2469 16.4678 10.5053L23.4678 14.2112C23.7952 14.3845 24 14.7246 24 15.095V20.3891C24 20.9414 23.5522 21.3891 23 21.3891C22.4477 21.3891 22 20.9414 22 20.3891V15.6971L15.5321 12.2729C15.044 12.0145 14.8578 11.4093 15.1162 10.9212Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_180"
        x1="24"
        y1="10.3889"
        x2="24"
        y2="21.3891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
