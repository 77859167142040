import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_160)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 31H2C1.73478 31 1.48043 30.8946 1.29289 30.7071C1.10536 30.5196 1 30.2652 1 30V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H30C30.2652 1 30.5196 1.10536 30.7071 1.29289C30.8946 1.48043 31 1.73478 31 2V30C31 30.2652 30.8946 30.5196 30.7071 30.7071C30.5196 30.8946 30.2652 31 30 31ZM3 29H29V3H3V29Z"
      fill={primary}
    />
    <path
      d="M26 27H24.5C24.2348 27 23.9804 26.8946 23.7929 26.7071C23.6054 26.5196 23.5 26.2652 23.5 26C23.5 25.7348 23.6054 25.4804 23.7929 25.2929C23.9804 25.1054 24.2348 25 24.5 25H25V24.5C25 24.2348 25.1054 23.9804 25.2929 23.7929C25.4804 23.6054 25.7348 23.5 26 23.5C26.2652 23.5 26.5196 23.6054 26.7071 23.7929C26.8946 23.9804 27 24.2348 27 24.5V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27Z"
      fill={primary}
    />
    <path
      d="M20.452 27H18.023C17.7578 27 17.5034 26.8946 17.3159 26.7071C17.1283 26.5196 17.023 26.2652 17.023 26C17.023 25.7348 17.1283 25.4804 17.3159 25.2929C17.5034 25.1054 17.7578 25 18.023 25H20.452C20.7172 25 20.9715 25.1054 21.1591 25.2929C21.3466 25.4804 21.452 25.7348 21.452 26C21.452 26.2652 21.3466 26.5196 21.1591 26.7071C20.9715 26.8946 20.7172 27 20.452 27ZM13.976 27H11.548C11.2828 27 11.0284 26.8946 10.8409 26.7071C10.6533 26.5196 10.548 26.2652 10.548 26C10.548 25.7348 10.6533 25.4804 10.8409 25.2929C11.0284 25.1054 11.2828 25 11.548 25H13.976C14.2412 25 14.4955 25.1054 14.6831 25.2929C14.8706 25.4804 14.976 25.7348 14.976 26C14.976 26.2652 14.8706 26.5196 14.6831 26.7071C14.4955 26.8946 14.2412 27 13.976 27Z"
      fill={primary}
    />
    <path
      d="M7.5 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V24.5C5 24.2348 5.10536 23.9804 5.29289 23.7929C5.48043 23.6054 5.73478 23.5 6 23.5C6.26522 23.5 6.51957 23.6054 6.70711 23.7929C6.89464 23.9804 7 24.2348 7 24.5V25H7.5C7.76522 25 8.01957 25.1054 8.20711 25.2929C8.39464 25.4804 8.5 25.7348 8.5 26C8.5 26.2652 8.39464 26.5196 8.20711 26.7071C8.01957 26.8946 7.76522 27 7.5 27Z"
      fill={primary}
    />
    <path
      d="M6 22.423C5.73478 22.423 5.48043 22.3176 5.29289 22.1301C5.10536 21.9426 5 21.6882 5 21.423V19.577C5 19.3118 5.10536 19.0574 5.29289 18.8699C5.48043 18.6824 5.73478 18.577 6 18.577C6.26522 18.577 6.51957 18.6824 6.70711 18.8699C6.89464 19.0574 7 19.3118 7 19.577V21.423C7 21.6882 6.89464 21.9426 6.70711 22.1301C6.51957 22.3176 6.26522 22.423 6 22.423Z"
      fill={primary}
    />
    <path
      d="M6 17.5C5.73478 17.5 5.48043 17.3946 5.29289 17.2071C5.10536 17.0196 5 16.7652 5 16.5V15C5 14.7348 5.10536 14.4804 5.29289 14.2929C5.48043 14.1054 5.73478 14 6 14H7.5C7.76522 14 8.01957 14.1054 8.20711 14.2929C8.39464 14.4804 8.5 14.7348 8.5 15C8.5 15.2652 8.39464 15.5196 8.20711 15.7071C8.01957 15.8946 7.76522 16 7.5 16H7V16.5C7 16.7652 6.89464 17.0196 6.70711 17.2071C6.51957 17.3946 6.26522 17.5 6 17.5Z"
      fill={primary}
    />
    <path
      d="M20.452 16H18.024C17.7588 16 17.5044 15.8946 17.3169 15.7071C17.1293 15.5196 17.024 15.2652 17.024 15C17.024 14.7348 17.1293 14.4804 17.3169 14.2929C17.5044 14.1054 17.7588 14 18.024 14H20.452C20.7172 14 20.9715 14.1054 21.1591 14.2929C21.3466 14.4804 21.452 14.7348 21.452 15C21.452 15.2652 21.3466 15.5196 21.1591 15.7071C20.9715 15.8946 20.7172 16 20.452 16ZM13.977 16H11.548C11.2828 16 11.0284 15.8946 10.8409 15.7071C10.6533 15.5196 10.548 15.2652 10.548 15C10.548 14.7348 10.6533 14.4804 10.8409 14.2929C11.0284 14.1054 11.2828 14 11.548 14H13.977C14.2422 14 14.4965 14.1054 14.6841 14.2929C14.8716 14.4804 14.977 14.7348 14.977 15C14.977 15.2652 14.8716 15.5196 14.6841 15.7071C14.4965 15.8946 14.2422 16 13.977 16Z"
      fill={primary}
    />
    <path
      d="M26 17.5C25.7348 17.5 25.4804 17.3946 25.2929 17.2071C25.1054 17.0196 25 16.7652 25 16.5V16H24.5C24.2348 16 23.9804 15.8946 23.7929 15.7071C23.6054 15.5196 23.5 15.2652 23.5 15C23.5 14.7348 23.6054 14.4804 23.7929 14.2929C23.9804 14.1054 24.2348 14 24.5 14H26C26.2652 14 26.5196 14.1054 26.7071 14.2929C26.8946 14.4804 27 14.7348 27 15V16.5C27 16.7652 26.8946 17.0196 26.7071 17.2071C26.5196 17.3946 26.2652 17.5 26 17.5Z"
      fill={primary}
    />
    <path
      d="M26 22.423C25.7348 22.423 25.4804 22.3176 25.2929 22.1301C25.1054 21.9426 25 21.6882 25 21.423V19.577C25 19.3118 25.1054 19.0574 25.2929 18.8699C25.4804 18.6824 25.7348 18.577 26 18.577C26.2652 18.577 26.5196 18.6824 26.7071 18.8699C26.8946 19.0574 27 19.3118 27 19.577V21.423C27 21.6882 26.8946 21.9426 26.7071 22.1301C26.5196 22.3176 26.2652 22.423 26 22.423Z"
      fill={primary}
    />
    <path
      d="M26 7H6C5.73478 7 5.48043 6.89464 5.29289 6.70711C5.10536 6.51957 5 6.26522 5 6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H26C26.2652 5 26.5196 5.10536 26.7071 5.29289C26.8946 5.48043 27 5.73478 27 6C27 6.26522 26.8946 6.51957 26.7071 6.70711C26.5196 6.89464 26.2652 7 26 7Z"
      fill={secondary}
    />
    <path
      d="M19 11H6C5.73478 11 5.48043 10.8946 5.29289 10.7071C5.10536 10.5196 5 10.2652 5 10C5 9.73478 5.10536 9.48043 5.29289 9.29289C5.48043 9.10536 5.73478 9 6 9H19C19.2652 9 19.5196 9.10536 19.7071 9.29289C19.8946 9.48043 20 9.73478 20 10C20 10.2652 19.8946 10.5196 19.7071 10.7071C19.5196 10.8946 19.2652 11 19 11Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_160" x1="5" y1="6" x2="27" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_99_160" x1="5" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
