import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Collapse, Typography } from "@material-ui/core"
import { LoadingSkeleton } from "../LoadingSkeleton"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  subNavigation: {
    ...theme.cards.navigationCard,
    position: "sticky",
    padding: ({ noPadding }) => (noPadding ? theme.spacing(0, 2) : theme.spacing(2)),
  },
}))

const SubNavigation = ({
  title,
  children,
  className,
  titleClassName,
  mr = 6,
  mb = 2,
  loading = false,
  noPadding = false,
  ...rest
}) => {
  const classes = useStyles({ noPadding })

  return (
    <Box className={`${classes.subNavigation} ${className}`} mr={mr} mb={mb} {...rest}>
      {!!title && (
        <Typography variant="h1" className={`${classes.title} ${titleClassName}`}>
          {title}
        </Typography>
      )}
      {loading && <LoadingSkeleton px={2} py={2} quantity={4} height={32} isThemed />}
      <Collapse in={!loading}>{!loading && children}</Collapse>
    </Box>
  )
}

export { SubNavigation }
