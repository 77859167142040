import React, { useState } from "react"
import { Box, Checkbox, FormControlLabel, FormGroup, makeStyles } from "@material-ui/core"
import { t, Trans } from "@lingui/macro"
import { ExpansionPanel, Icon, RowBox } from ".."
import { CreatorMaster, CreatorActions } from "../Creators"

const useStyles = makeStyles((theme) => ({
  panels: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  summary: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: theme.spacing(0.5),
    columnGap: theme.spacing(1),
    marginTop: theme.spacing(0.25),
  },
  iconSelected: {
    fill: theme.palette.success.main,
  },
  iconUnselected: {
    fill: theme.palette.error.main,
  },
  checkbox: {
    // Not a huge fan, but somewhat necessary for mobile text wrapping.
    alignItems: "flex-start",
    "& .MuiFormControlLabel-label": {
      marginTop: 10,
    },
  },
}))

const CustomizePDF = ({ job, open, onClose, onSubmit }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleToggleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const [formState, setFormState] = useState({
    // Summary
    summaryUserSubmission: !!job?.hasSubmit,

    // Scoring
    scoringTable: !!job?.hasScoring,
    scoringPossibleScores: !!job?.hasScoring,

    // Actions & Notes
    actionsUserSubmission: true,
    actionsSummary: true,
    notesUserSubmission: true,
    notesSummary: true,

    // Steps
    stepsDescriptions: true,
    stepsUserSubmission: true,
  })

  const {
    summaryUserSubmission,
    scoringTable,
    scoringPossibleScores,
    actionsUserSubmission,
    actionsSummary,
    notesUserSubmission,
    notesSummary,
    stepsDescriptions,
    stepsUserSubmission,
  } = formState

  // Customization option grouping for short summary
  const optionGroups = {
    summaryGroup: [{ key: "summaryUserSubmission", description: t`User details` }],
    scoringGroup: [
      { key: "scoringTable", description: t`Scoring table` },
      { key: "scoringPossibleScores", description: t`Possible scores` },
    ],
    actionsNotesGroup: [
      { key: "actionsUserSubmission", description: t`Action user details` },
      { key: "actionsSummary", description: t`Actions summary` },
      { key: "notesUserSubmission", description: t`Note user details` },
      { key: "notesSummary", description: t`Notes summary` },
    ],
    stepsGroup: [
      { key: "stepsDescriptions", description: t`Step descriptions` },
      { key: "stepsUserSubmission", description: t`Step user details` },
    ],
  }

  const handleChange = (event) => {
    if (event.target.name === "scoringTable" && !event.target.checked) {
      setFormState({ ...formState, scoringTable: false, scoringPossibleScores: false })
      return
    }
    setFormState({ ...formState, [event.target.name]: event.target.checked })
  }

  const handleClose = (event, isCancel) => {
    setExpanded(false)
    onClose && onClose(isCancel)
  }

  const handleSubmit = () => {
    onSubmit && onSubmit(formState)
    onClose && onClose()
  }

  if (!job) {
    return null
  }

  const getGroupSummary = (groupName) => {
    const group = optionGroups[groupName]

    // If all options in the group are selected
    if (group.every(({ key }) => formState[key])) {
      return (
        <RowBox className={classes.summary}>
          <RowBox>
            <Icon name="tick" className={classes.iconSelected} fontSize="small" />
            <Box ml={0.5}>
              <Trans>All options selected</Trans>
            </Box>
          </RowBox>
        </RowBox>
      )
    }

    // Or show unselected options
    return (
      <RowBox className={classes.summary}>
        {group.map(({ key, description }, index) => {
          const isSelected = formState[key]

          if (isSelected) {
            return
          }

          return (
            <RowBox key={index}>
              <Icon name="cross" className={classes.iconUnselected} fontSize="small" />
              <Box ml={0.5}>{description}</Box>
            </RowBox>
          )
        })}
      </RowBox>
    )
  }

  const form = (
    <>
      <Box className={classes.panels}>
        {/* Summary */}
        {job.hasSubmit && (
          <ExpansionPanel
            {...{
              className: classes.content,
            }}
            title={t`Summary`}
            description={getGroupSummary("summaryGroup")}
            expanded={expanded === "summary"}
            onChange={handleToggleExpanded("summary")}
            classes={classes.content}
          >
            <FormGroup>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={summaryUserSubmission}
                    onChange={handleChange}
                    color="primary"
                    name="summaryUserSubmission"
                  />
                }
                label={<Trans>Show which user submitted the job</Trans>}
              />
            </FormGroup>
          </ExpansionPanel>
        )}

        {/* Scoring */}
        {job.hasScoring && (
          <ExpansionPanel
            {...{
              className: classes.content,
            }}
            title={t`Scoring`}
            description={getGroupSummary("scoringGroup")}
            expanded={expanded === "scoring"}
            onChange={handleToggleExpanded("scoring")}
            classes={classes.content}
          >
            <FormGroup>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox checked={scoringTable} onChange={handleChange} color="primary" name="scoringTable" />
                }
                label={<Trans>Show score achieved and scoring table</Trans>}
              />
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={scoringPossibleScores}
                    onChange={handleChange}
                    color="primary"
                    name="scoringPossibleScores"
                    disabled={!scoringTable}
                  />
                }
                label={<Trans>Show possible scores</Trans>}
              />
            </FormGroup>
          </ExpansionPanel>
        )}

        {/* Actions & Notes */}
        <ExpansionPanel
          {...{
            className: classes.content,
          }}
          title={t`Actions & Notes`}
          description={getGroupSummary("actionsNotesGroup")}
          expanded={expanded === "actionsnotes"}
          onChange={handleToggleExpanded("actionsnotes")}
          classes={classes.content}
        >
          <FormGroup>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={actionsUserSubmission}
                  onChange={handleChange}
                  color="primary"
                  name="actionsUserSubmission"
                />
              }
              label={<Trans>Show which users raised actions</Trans>}
            />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox checked={actionsSummary} onChange={handleChange} color="primary" name="actionsSummary" />
              }
              label={<Trans>Show a summary of all actions</Trans>}
            />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={notesUserSubmission}
                  onChange={handleChange}
                  color="primary"
                  name="notesUserSubmission"
                />
              }
              label={<Trans>Show which users submitted notes</Trans>}
            />
            <FormControlLabel
              className={classes.checkbox}
              control={<Checkbox checked={notesSummary} onChange={handleChange} color="primary" name="notesSummary" />}
              label={<Trans>Show a summary of all notes</Trans>}
            />
          </FormGroup>
        </ExpansionPanel>

        {/* Steps */}
        <ExpansionPanel
          {...{
            className: classes.content,
          }}
          title={t`Steps`}
          description={getGroupSummary("stepsGroup")}
          expanded={expanded === "steps"}
          onChange={handleToggleExpanded("steps")}
          classes={classes.content}
        >
          <FormGroup>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={stepsDescriptions}
                  onChange={handleChange}
                  color="primary"
                  name="stepsDescriptions"
                />
              }
              label={<Trans>Show step descriptions</Trans>}
            />
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={stepsUserSubmission}
                  onChange={handleChange}
                  color="primary"
                  name="stepsUserSubmission"
                />
              }
              label={<Trans>Show which users submitted or skipped steps</Trans>}
            />
          </FormGroup>
        </ExpansionPanel>
      </Box>
      {/* <Divider /> */}
      <CreatorActions
        id="custompdf-actions"
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitLabel={t`Export PDF`}
      />
    </>
  )

  return <CreatorMaster id="custompdf-dialog" open={open} title={t`Export PDF`} form={form} onClose={handleClose} />
}

export { CustomizePDF }
