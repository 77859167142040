import React, { memo } from "react"
import { makeStyles } from "@material-ui/core"
import moment from "moment-timezone"
import { useDateUtils } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  overdue: {
    color: theme.palette.error.main,
  },
}))

const FormatTime = ({ value, showOverdue = false, overdueSuffix = "", timeZone }) => {
  const {
    location: { timeZone: locationTimeZone },
  } = useAuth()
  const { formatTime } = useDateUtils()
  const classes = useStyles()
  const isOverdue =
    showOverdue &&
    value &&
    moment(value)
      .tz(timeZone || locationTimeZone)
      .isSameOrBefore(moment())

  try {
    return (
      <span
        className={isOverdue ? classes.overdue : null}
        title={moment.tz(value, timeZone || locationTimeZone).format()}
      >
        {value && formatTime(value, timeZone || locationTimeZone)}
        {isOverdue && overdueSuffix && <> {overdueSuffix}</>}
      </span>
    )
  } catch (ex) {
    return <>Data invalid.</>
  }
}

const FormatTimeMemo = memo(FormatTime)

export { FormatTimeMemo as FormatTime }
