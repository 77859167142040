import React from "react"
import moment from "moment-timezone"

export default ({ value, compactTodayTimeZone, timeZone }) => {
  const date = timeZone ? moment.tz(value, timeZone) : moment(value)
  const now = timeZone ? moment.tz(timeZone) : moment()
  let format = date.year() === now.year() ? "MMM D, h:mmA" : "D MMM YY, h:mmA"
  if (compactTodayTimeZone && moment.tz(date, compactTodayTimeZone).isSame(moment.tz(compactTodayTimeZone), "day")) {
    format = "h:mmA"
  }
  try {
    return <span title={date.format()}>{value && date.format(format)}</span>
  } catch (ex) {
    return <>Data invalid.</>
  }
}
