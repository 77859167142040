import React from "react"
import { Box } from "@material-ui/core"
import pluralize from "pluralize"
import { Trans } from "@lingui/macro"
import { ModuleHeading, OrganisationLogo, RowBox } from ".."
import { useAuth } from "../../services"

const ItemCountHeader = ({ count = 0, subject, loading, pluralise = true }) => {
  const {
    settings: { organisation },
  } = useAuth()

  // TODO: Review how to pass the subject variable to enable the use of the Plural component. For now, use pluralize as a temporary solution.
  const formattedSubject = (pluralise ? pluralize(subject, count) : subject).toLowerCase()

  return (
    <RowBox mb={4}>
      <Box>
        <OrganisationLogo />
      </Box>
      <Box>
        <Box ml={1}>
          <ModuleHeading noMargin>{organisation.name}</ModuleHeading>
        </Box>
        <Box ml={1}>
          {!loading && (
            <>
              {count || "No"} {formattedSubject}
            </>
          )}
          {loading && <Trans>Loading...</Trans>}
        </Box>
      </Box>
    </RowBox>
  )
}

export { ItemCountHeader }
