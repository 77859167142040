import React, { useState, useEffect } from "react"
import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { MoreHoriz } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { useAuth } from "../../services"
import { preventDefault } from "../../utils"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  rootPadding: {
    padding: ({ p }) => theme.spacing(p),
  },
  iconRoot: {
    cursor: "pointer",
  },
  disabling: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
    },
  },
  deleting: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.white,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.white,
    },
  },
}))

const AuthorTools = ({
  variant = "icon-button",
  icon = "more",
  item,
  author,
  admins,
  onEdit,
  onCopy,
  onDisable,
  editLabel = <Trans>Edit</Trans>,
  copyLabel = <Trans>Make a copy</Trans>,
  disableLabel = <Trans>Disable</Trans>,
  onDelete,
  subject,
  p = 1.5,
  className,
  loading,
  disabled,
}) => {
  const classes = useStyles({ p })
  const [moreAnchorEl, setMoreAnchorEl] = useState(null)
  const [disabling, setDisabling] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { isCurrentUser, hasPermission, isCurrentUserInRole } = useAuth()
  const [canEdit, setCanEdit] = useState(false)
  const [canCopy, setCanCopy] = useState(false)
  const [canDisable, setCanDisable] = useState(false)
  const [canDelete, setCanDelete] = useState(false)

  useEffect(() => {
    const isUser = isCurrentUser(author)
    const isSysadmin = isCurrentUserInRole("sysadmin")
    setCanEdit(
      onEdit && (isUser || admins?.edit === true || (admins?.edit && hasPermission(admins.edit)) || isSysadmin),
    )
    setCanCopy(
      onCopy && (isUser || admins?.edit === true || (admins?.edit && hasPermission(admins.edit)) || isSysadmin),
    )
    setCanDisable(
      onDisable && (isUser || admins?.edit === true || (admins?.edit && hasPermission(admins.edit)) || isSysadmin),
    )
    setCanDelete(
      onDelete && (isUser || admins?.delete === true || (admins?.delete && hasPermission(admins.delete)) || isSysadmin),
    )
  }, [admins, author, hasPermission, isCurrentUser, isCurrentUserInRole, onCopy, onDelete, onDisable, onEdit])

  const handleEdit = () => {
    setMoreAnchorEl(null)
    onEdit(item)
  }

  const handleCopy = () => {
    setMoreAnchorEl(null)
    onCopy(item)
  }

  const handleDisable = (event) => {
    if (!disabling) setDisabling(true)
    else {
      handleClose(event)
      onDisable(item)
    }
  }

  const handleDelete = (event) => {
    if (!deleting) setDeleting(true)
    else {
      handleClose(event)
      onDelete(item)
    }
  }

  const handleClick = (event) => setMoreAnchorEl(event.currentTarget)

  const handleClose = () => {
    setMoreAnchorEl(null)
    if (disabling) setDisabling(false)
    if (deleting) setDeleting(false)
  }

  if (!canEdit && !canCopy && !canDisable && !canDelete) return null

  return (
    <RowBox onClick={preventDefault}>
      {variant === "icon-button" && (
        <IconButton
          onClick={handleClick}
          aria-label="Show more"
          className={className}
          classes={{ root: classes.rootPadding }}
          disabled={disabled || loading}
        >
          {!loading && <Icon name={icon} />}
          {loading && <LoadingSpinner size={24} delay={false} />}
        </IconButton>
      )}
      {variant === "icon" && (
        <MoreHoriz
          onClick={handleClick}
          aria-label="Show more"
          classes={{ root: classes.iconRoot }}
          className={className}
          disabled={disabled}
        />
      )}
      <Menu id="more-menu" anchorEl={moreAnchorEl} keepMounted open={Boolean(moreAnchorEl)} onClose={handleClose}>
        {canEdit && <MenuItem onClick={handleEdit}>{editLabel}</MenuItem>}
        {canCopy && <MenuItem onClick={handleCopy}>{copyLabel}</MenuItem>}
        {canDisable && (
          <MenuItem onClick={handleDisable} classes={disabling ? { root: classes.disabling } : null}>
            {disabling ? `Confirm ${disableLabel.toLowerCase()}` : disableLabel}{" "}
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem onClick={handleDelete} classes={deleting ? { root: classes.deleting } : null}>
            {deleting ? <Trans>Confirm delete {subject}</Trans> : <Trans>Delete</Trans>}{" "}
          </MenuItem>
        )}
      </Menu>
    </RowBox>
  )
}

export { AuthorTools }
