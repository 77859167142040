import React from "react"
import { Divider, MenuItem, makeStyles } from "@material-ui/core"
import { ACTION_PRIORITY, ACTION_PRIORITY_LABELS } from "../../data"
import { OutlinedSelect } from "./OutlinedSelect"
import { IconMenuItemContent } from "./IconMenuItemContent"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const ActionPriorityOutlinedSelect = ({
  multiple = false,
  hasAll = false,
  allLabel = "All priorities",
  allValue = "all",
  onDataLoaded,
  disabled,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <OutlinedSelect label="Priority" native={false} multiple={multiple} classes={classes} disabled={disabled} {...rest}>
      {multiple && hasAll && <MenuItem value={allValue}>{allLabel}</MenuItem>}
      {multiple && hasAll && <Divider />}
      {!multiple &&
        Object.values(ACTION_PRIORITY).map((value) => (
          <MenuItem key={value} value={value}>
            <IconMenuItemContent
              label={ACTION_PRIORITY_LABELS[value]}
              value={value}
              icon={`priority-${value}`}
              iconProps={{ color: value === ACTION_PRIORITY.CRITICAL ? "secondary" : "inherit" }}
            />
          </MenuItem>
        ))}
      {multiple &&
        Object.values(ACTION_PRIORITY).map((value) => (
          <MenuItem key={value} value={value}>
            {ACTION_PRIORITY_LABELS[value]}
          </MenuItem>
        ))}
    </OutlinedSelect>
  )
}

export { ActionPriorityOutlinedSelect }
