import { useQuery, gql } from "@apollo/client"
import { EVENTS_LIST_FIELDS } from "./fragments/eventsFieldsFragment"

const EVENTS_QUERY = gql`
  query EventsList($filter: EventsQueryFilterInput!, $limit: Int, $offset: Int) {
    events {
      list(filter: $filter, limit: $limit, offset: $offset) {
        ...EventsListFields
      }
    }
  }
  ${EVENTS_LIST_FIELDS}
`

const EVENTS_COUNT_QUERY = gql`
  query EventsCount($filter: EventsQueryFilterInput!) {
    events {
      count(filter: $filter)
    }
  }
`

const EVENT_NAMES_QUERY = gql`
  query EventNames {
    events {
      listName
    }
  }
`

const useQueryEvents = (options) => useQuery(EVENTS_QUERY, options)

const useQueryEventsCount = (options) => useQuery(EVENTS_COUNT_QUERY, options)

const useQueryEventNames = (options) => useQuery(EVENT_NAMES_QUERY, options)

export { useQueryEvents, useQueryEventsCount, useQueryEventNames }
