import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { Feedback, ThumbUp } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: (props) => (props.successful ? theme.palette.success.main : theme.palette.primary.main),
    padding: theme.spacing(1),
  },
  icon: {
    width: 16,
    color: theme.palette.text.white,
  },
}))

const ChatChip = ({ label, className, successful = false }) => {
  const classes = useStyles({ successful })

  return <Chip label={label} classes={classes} className={className} icon={successful ? <ThumbUp /> : <Feedback />} />
}

export { ChatChip }
