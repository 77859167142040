import React from "react"
import { makeStyles, Tooltip } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { useAuth } from "../../services"
import { PaperBox } from "../Boxes"
import { MentionUserPanel } from "../UserPanel"

const useStyles = makeStyles((theme) => ({
  navLink: {
    display: "inline-block",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: "none",
    fontWeight: "normal",
    fontFamily: theme.typography.fontFamily,
    fontSize: "inherit",
    lineHeight: "inherit",
    cursor: "pointer",
    outline: "none",
    margin: 0,
    padding: 0,
  },
}))

const UserTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    maxWidth: "none",
  },
}))(Tooltip)

const UserLink = ({ user, ...rest }) => {
  const classes = useStyles()
  const { isCurrentUser } = useAuth()
  if (!user) return null
  return isCurrentUser(user.id) ? (
    <>You </>
  ) : (
    <UserTooltip
      title={
        <PaperBox>
          <MentionUserPanel user={user} />
        </PaperBox>
      }
      enterTouchDelay={100}
      leaveTouchDelay={2000}
    >
      <button type="button" className={classes.navLink}>
        {user.fullName}
      </button>
    </UserTooltip>
  )
}

export { UserLink }
