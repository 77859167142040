import React, { useState } from "react"
import Axios from "axios"
import { Box, Paper, Collapse, IconButton, Button, makeStyles } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import Config from "react-global-configuration"
import { useSnackbar } from "../SnackbarProvider"
import { locales } from "../../i18n"
import { Icon } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    justifyContent: "space-between",
    width: "100%",
  },
  locale: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

let ogTexts = []

const LanguageTranslator = ({ id }) => {
  const { translateApiKey: googleTranslateApiKey } = Config.get("google")
  const snackbar = useSnackbar()
  const classes = useStyles()
  const currentLocale = i18n.locale
  const [translated, setTranslated] = useState(false)
  const [visible, setVisible] = useState(true)
  const currentLocaleDetail = locales.find((item) => item.locale === currentLocale)

  const textNodes = []

  const handleClose = () => {
    setVisible(false)
  }

  const handleReverse = () => {
    const sectionElement = document.getElementById(id)
    reverseTranslation(sectionElement)
  }

  const handleTranslate = () => {
    const sectionElement = document.getElementById(id)
    translateSection(sectionElement, currentLocale)
  }

  // Send to be translated
  const translateTextChunks = async (textArray, targetLanguage) => {
    const chunkSize = 100 // Translate only takes 128 strings max
    const translationPromises = []

    for (let i = 0; i < textArray.length; i += chunkSize) {
      const chunk = textArray.slice(i, i + chunkSize)

      // Make a request for each chunk
      const translationPromise = Axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=${googleTranslateApiKey}`,
        { q: chunk, target: targetLanguage },
      )
        .then((res) => res.data.data.translations.map((t) => t.translatedText))
        .catch((error) => {
          snackbar.showMessage({ message: "Unable to translate this content", color: "secondary" })
          return chunk
        })

      translationPromises.push(translationPromise)
    }

    const translatedChunks = await Promise.all(translationPromises)
    setTranslated(true)
    return translatedChunks.flat()
  }

  const extractTextNodes = (element) => {
    const findNodes = (node) => {
      // Exclude any "notranslate" elements - they're already translated and saves API usage
      if (node.classList && node.classList.contains("notranslate")) {
        return
      }
      // If node is text
      if (node.nodeType === 3 && node.nodeValue.trim()) {
        textNodes.push(node)
      }
      // If it has kids
      else if (node.nodeType === 1 && node.tagName !== "SCRIPT" && node.tagName !== "STYLE") {
        for (const child of node.childNodes) {
          findNodes(child)
        }
      }
    }

    findNodes(element)

    // Keep a track of the original text content in case we want to reverse the translation
    if (!translated) {
      textNodes.forEach((node) => {
        ogTexts.push(`${node.nodeValue}`)
      })
    }

    return textNodes
  }

  const reverseTranslation = (element) => {
    const nodes = extractTextNodes(element)
    nodes.forEach((node, index) => {
      node.nodeValue = ogTexts[index]
    })
    ogTexts = []
    setTranslated(false)
  }

  const translateSection = async (element, targetLanguage) => {
    const targetNodes = extractTextNodes(element)
    const originalTexts = targetNodes.map((node) => node.nodeValue.trim())

    if (originalTexts.length === 0) return

    const translatedTexts = await translateTextChunks(originalTexts, targetLanguage)

    targetNodes.forEach((node, index) => {
      node.nodeValue = translatedTexts[index]
    })
  }

  const getLocaleIcon = () => {
    if (currentLocale) {
      const current = locales.find((item) => item.locale === currentLocale)
      return current ? <Icon name={current.icon} /> : null
    }
    return null
  }

  // For now, we assume that content is in EN - we will need to detect if its not and hide this section
  return currentLocale !== "en" ? (
    <Collapse in={visible}>
      <Box mb={2}>
        <Paper elevation={0}>
          <Box p={1} className={classes.root}>
            <Box className={classes.locale}>
              {getLocaleIcon()}
              <Trans>Translate to</Trans> {currentLocaleDetail.name}?
            </Box>
            <Box className={classes.actions}>
              {translated && (
                <Button size="small" variant="text" color="primary" onClick={handleReverse}>
                  <Trans>Change back</Trans>
                </Button>
              )}
              {!translated && (
                <Button size="small" variant="text" color="primary" onClick={handleTranslate}>
                  <Trans>Translate</Trans>
                </Button>
              )}
              <IconButton size="small" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Collapse>
  ) : null
}

export { LanguageTranslator }
