import { Alert } from "@material-ui/lab"
import { MentionOutput } from "../Mentions"

const LabelsWarning = ({ template, labelsTemplate }) => {
  const labelWarning = labelsTemplate?.find((item) => item.id === template)?.labelWarning

  if (!labelWarning) return null

  return (
    <Alert severity="error">
      <MentionOutput content={labelWarning} />
    </Alert>
  )
}

export { LabelsWarning }
