import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_183)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8C6 7.44772 6.44772 7 7 7H13C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9H7C6.44772 9 6 8.55228 6 8Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 24C6 23.4477 6.44772 23 7 23H17C17.5523 23 18 23.4477 18 24C18 24.5523 17.5523 25 17 25H7C6.44772 25 6 24.5523 6 24Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 19C6 18.4477 6.44772 18 7 18H17C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13C6 12.4477 6.44772 12 7 12H13C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14H7C6.44772 14 6 13.5523 6 13Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0001 4.00206C20.2396 4.00206 18.0018 6.23985 18.0018 9.0003C18.0018 11.7607 20.2396 13.9985 23.0001 13.9985C25.7605 13.9985 27.9983 11.7607 27.9983 9.0003C27.9983 6.23985 25.7605 4.00206 23.0001 4.00206ZM15.9985 9.0003C15.9985 5.13347 19.1332 1.99878 23.0001 1.99878C26.8669 1.99878 30.0016 5.13347 30.0016 9.0003C30.0016 12.8671 26.8669 16.0018 23.0001 16.0018C19.1332 16.0018 15.9985 12.8671 15.9985 9.0003Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.99993C2 2.44765 2.44772 1.99993 3 1.99993H15C15.5523 1.99993 16 2.44765 16 2.99993C16 3.55222 15.5523 3.99993 15 3.99993H4V27.9999H17C17.5523 27.9999 18 28.4476 18 28.9999C18 29.5522 17.5523 29.9999 17 29.9999H3C2.44772 29.9999 2 29.5522 2 28.9999V2.99993Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16.9999C21.5523 16.9999 22 17.4476 22 17.9999V26.9999C22 27.5516 22.4483 27.9999 23 27.9999C23.5517 27.9999 24 27.5516 24 26.9999V17.9999C24 17.4476 24.4477 16.9999 25 16.9999C25.5523 16.9999 26 17.4476 26 17.9999V26.9999C26 28.6562 24.6563 29.9999 23 29.9999C21.3437 29.9999 20 28.6562 20 26.9999V17.9999C20 17.4476 20.4477 16.9999 21 16.9999Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_183" x1="6" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_99_183" x1="6" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint2_linear_99_183" x1="6" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint3_linear_99_183" x1="6" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
