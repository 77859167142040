import React from "react"
import { makeStyles, Box } from "@material-ui/core"
import { ModuleHeading } from "../Headings"

const useStyles = makeStyles((theme) => ({
  detail: theme.cards.viewerCard,
  detailInner: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
  description: {
    marginTop: 4,
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
}))

const FoodItemPanel = ({ layout = "portrait", foodItem: { name, locations, description }, ...rest }) => {
  const classes = useStyles({ layout })

  return (
    <Box className={classes.detail} {...rest}>
      <Box className={classes.detailInner}>
        <ModuleHeading noMargin>{name}</ModuleHeading>
        {locations && locations.length > 0 && (
          <Box className={classes.locations}>{locations.map((location) => location.name).join(", ")}</Box>
        )}
        {description && <Box className={classes.description}>{description}</Box>}
      </Box>
    </Box>
  )
}

export { FoodItemPanel }
