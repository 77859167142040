import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_153)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 30V16C23 15.4477 22.5523 15 22 15C21.4477 15 21 15.4477 21 16V29H11V16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16V30C9 30.5523 9.44772 31 10 31H22C22.5523 31 23 30.5523 23 30Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 13V23C4 24.1046 4.89543 25 6 25C6.55228 25 7 25.4477 7 26C7 26.5523 6.55228 27 6 27C3.79086 27 2 25.2091 2 23V2C2 1.44772 2.44772 1 3 1H29C29.5523 1 30 1.44771 30 2V23C30 25.2091 28.2091 27 26 27C25.4477 27 25 26.5523 25 26C25 25.4477 25.4477 25 26 25C27.1046 25 28 24.1046 28 23V13H4ZM4 11H28V3H4V11Z"
      fill={primary}
    />
    <path
      d="M16 7C16 6.44772 16.4477 6 17 6H18C18.5523 6 19 6.44772 19 7C19 7.55228 18.5523 8 18 8H17C16.4477 8 16 7.55228 16 7Z"
      fill={primary}
    />
    <path
      d="M20 7C20 6.44772 20.4477 6 21 6H22C22.5523 6 23 6.44772 23 7C23 7.55228 22.5523 8 22 8H21C20.4477 8 20 7.55228 20 7Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 17C14.5523 17 15 17.4477 15 18V26C15 26.5523 14.5523 27 14 27C13.4477 27 13 26.5523 13 26V18C13 17.4477 13.4477 17 14 17Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 17C18.5523 17 19 17.4477 19 18V26C19 26.5523 18.5523 27 18 27C17.4477 27 17 26.5523 17 26V18C17 17.4477 17.4477 17 18 17Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_153" x1="15" y1="27" x2="15" y2="17" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_99_153" x1="19" y1="27" x2="19" y2="17" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
