import { Box, Tooltip, makeStyles } from "@material-ui/core"
import React from "react"
import { t } from "@lingui/macro"
import { ActionCard } from "."
import { AuthorTools } from "../Author"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { CommonChip } from "../Chips/CommonChip"

const useStyles = makeStyles((theme) => ({
  paused: {
    border: `1px solid ${theme.palette.scheduled.main}`,
  },
  tick: {
    color: theme.palette.success.main,
  },
}))

const IntegrationActionCard = ({ integration, onEdit, detail, loading, ...rest }) => {
  const classes = useStyles()
  const {
    id,
    title,
    paused,
    integrationProvider: { name, description },
  } = integration

  const handleEdit = () => {
    onEdit && onEdit(integration)
  }

  return (
    <ActionCard
      key={id}
      title={name}
      detail={
        <>
          <Box>{description || t`Integration`}</Box>
          {title && (
            <Box mt={1}>
              <Tooltip title={`${title.name} - ${title.description}`}>
                <div>
                  <CommonChip label={title.value} size="small" />
                </div>
              </Tooltip>
            </Box>
          )}
        </>
      }
      cursor={null}
      stub={
        <ColumnBox mr={1} justifyContent="flex-start">
          {!paused && <Icon name="tick" className={classes.tick} />}
          {paused && <Icon name="integration" />}
        </ColumnBox>
      }
      completed={!paused}
      isScheduled={paused}
      className={paused ? classes.paused : null}
      rightChildren={
        <RowBox alignItems="flex-start">
          <AuthorTools
            icon="configure"
            item={integration}
            admins={{
              edit: "integration_update",
            }}
            editLabel={t`Configure`}
            onEdit={handleEdit}
            subject="integration"
            disabled={loading}
          />
        </RowBox>
      }
      {...rest}
    />
  )
}

export { IntegrationActionCard }
