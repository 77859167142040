import React, { useEffect, useState } from "react"
import { Container, Box, Grid, makeStyles, useTheme, useMediaQuery, TextField, InputAdornment } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import SearchIcon from "@material-ui/icons/Search"
import { useHistory } from "react-router-dom"
import Highlighter from "react-highlight-words"
import { useDebounce } from "@react-hook/debounce"
import { t } from "@lingui/macro"
import { ModuleHeading } from "../Headings"
import { useLazyQuerySearch, useMutationAddUserSearch } from "../../data"
import { useAuth } from "../../services"
import TimeAgo from "../TimeAgo"
import { PaperBox } from "../Boxes"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: theme.palette.primary.backgroundLight,
    borderTop: `1px solid rgba(${theme.palette.primary.mainRgb},0.2)`,
    borderBottom: `1px solid rgba(${theme.palette.primary.mainRgb},0.2)`,
    marginTop: "-1px",
  },
  searchInput: {
    height: 56,
  },
  searchInputSpaceHolder: {
    height: 88,
  },
  highlight: {
    fontWeight: "600",
    marginLeft: 0,
  },
  date: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
}))

const useInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))

const AreaSearch = ({ fullHeading }) => {
  const theme = useTheme()
  const history = useHistory()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  const inputClasses = useInputStyles()
  const {
    location,
    settings: { searches },
  } = useAuth()
  const [load, { loading, data }] = useLazyQuerySearch()
  const [addUserSearch] = useMutationAddUserSearch()
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useDebounce("", 300)

  useEffect(() => {
    if (searchText.trim()) {
      load({ variables: { searchText, location: toId(location), limit: 9 } })
    }
  }, [searchText, location, load])

  const handleInputChange = (event, newValue) => {
    setSearchText(newValue)
  }

  const handleChange = (event, option) => {
    if (!searches?.length || !searches.find((search) => search.text.toLowerCase() === searchText.toLowerCase())) {
      addUserSearch({ variables: { searchText } })
    }
    if (option?.id) {
      history.push(`/knowledge/article/${option.id}`)
    }
  }

  const knowledge = (data && data.searchAreas.knowledge.items) || []

  return (
    <Box pt={xs ? 2 : 5} pb={xs ? 2 : 5} pl={2} pr={2} className={classes.search}>
      <Container disableGutters>
        <Grid
          container
          direction="column"
          justifyContent={xs ? "flex-start" : "center"}
          alignItems={xs ? "flex-start" : "center"}
        >
          {!xs && fullHeading && <ModuleHeading>{fullHeading}</ModuleHeading>}
          <Grid container direction="row" justifyContent={xs ? "flex-start" : "center"}>
            <Grid item md={6} sm={8} xs={12}>
              <Autocomplete
                freeSolo
                open={open}
                onOpen={() => {
                  setOpen(true)
                }}
                onClose={() => {
                  setOpen(false)
                }}
                onInputChange={handleInputChange}
                onChange={handleChange}
                filterOptions={(options) => options}
                getOptionSelected={(option, value) => option.id === value.id}
                options={knowledge}
                loading={loading}
                PaperComponent={PaperBox}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t`Search articles, documents and guides...`}
                    margin="none"
                    variant="outlined"
                    className={xs ? null : classes.searchInput}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      classes: inputClasses,
                      type: "text",
                    }}
                  />
                )}
                renderOption={(option) => (
                  <Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
                    <Box>
                      <Highlighter
                        highlightClassName={classes.highlight}
                        searchWords={searchText.split(" ")}
                        autoEscape
                        textToHighlight={option.title}
                      />
                    </Box>
                    {option.date && (
                      <Box ml="auto" pl={1} display="flex" className={classes.date}>
                        <TimeAgo date={option.date} />
                      </Box>
                    )}
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export { AreaSearch }
