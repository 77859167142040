import { useQuery, gql } from "@apollo/client"
import { PREP_LIST_FIELDS } from "./fragments/prepListFieldsFragment"
import { PREPLIST_CREATED_SUBSCRIPTION } from "./useSubscriptionPrepList"
import { makeSubscribeToMore } from "../shared/subscribeToMore"

export const PREP_LISTS_QUERY = gql`
  query PrepLists($filter: PrepListFilterInput!) {
    prepLists {
      list(filter: $filter) {
        ...PrepListFields
      }
    }
  }
  ${PREP_LIST_FIELDS}
`

const prepListCreatedSubscriptionUpdateQuery = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) {
    return prev
  }
  const { prepListCreated: newItem } = subscriptionData.data
  const next = JSON.parse(JSON.stringify(prev))
  next.prepLists.list = [newItem, ...(next?.prepLists?.list ?? [])].sort((a, b) => a.name.localeCompare(b.name))
  return next
}

const subscribe =
  (result, options = {}) =>
  () => [
    result.subscribeToMore(
      makeSubscribeToMore({
        document: PREPLIST_CREATED_SUBSCRIPTION,
        updateQuery: prepListCreatedSubscriptionUpdateQuery,
        ...options,
      }),
    ),
  ]

const prepListsTypePolicies = {
  scoped: {
    PrepListsQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
    },
  },
}

const useQueryPrepLists = (options) => {
  const result = useQuery(PREP_LISTS_QUERY, options)

  return {
    subscribe: subscribe(result, {
      variables: options?.variables,
    }),
    ...result,
  }
}

export { useQueryPrepLists, prepListsTypePolicies }
