import React, { useEffect, useState } from "react"
import { Grid, Container, Typography, Hidden, useMediaQuery, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Icon } from ".."
import { useQueryActionCounts, useQueryNotifications } from "../../data"
import { RowBox, ColumnBox } from "../Boxes"
import { ErrorBoundary } from "../ErrorBoundary"
import OfflineModeBanner from "../Offline/OfflineModeBanner"

const useStyles = makeStyles((theme) => ({
  area: {
    ...theme.cards.areaCard,
    borderBottom: ({ bottomBorder }) => (bottomBorder ? theme.cards.areaCard.borderBottom : 0),
    height: ({ collapse, xs }) => (collapse ? 0 : xs ? "auto" : 100),
  },
  grid: {
    height: ({ xs }) => (xs ? "auto" : 100),
    padding: ({ xs }) => (xs ? `${theme.spacing(1)}px 0` : "none"),
  },
  title: {
    fontSize: ({ xs, sm }) => (xs ? 24 : sm ? 22 : 28),
    fontWeight: "900",
    padding: 0,
  },
  titleIcon: {
    marginRight: theme.spacing(1),
  },
  titleHasSubtitle: {
    lineHeight: "1.07",
    margin: "3px 0 3px 0",
  },
  beta: {
    fontSize: 12,
    fontWeight: "600",
    opacity: 0.6,
    marginLeft: theme.spacing(0.5),
  },
  subTitle: {
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "1.5",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: ({ xs, sm }) => (xs ? 335 : sm ? 360 : 750),
  },
}))

const AreaHeader = ({
  title,
  titleIcon,
  subTitle,
  desktopButtons,
  mobileButtons,
  bottomBorder = true,
  collapse = false,
  beta = false,
  cy = "AreaHeader",
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.only("sm"))
  const classes = useStyles({ collapse, xs, sm, bottomBorder })

  const [notificationUnsubscribes, setNotificationUnsubscribes] = useState(null)
  const [actionCountsUnsubscribes, setActionCountsUnsubscribes] = useState(null)

  const {
    called: calledNotifications,
    data: dataNotifications,
    subscribe: subscribeNotifications,
  } = useQueryNotifications()
  const {
    called: calledActionCounts,
    data: dataActionCounts,
    subscribe: subscribeActionCounts,
  } = useQueryActionCounts()

  useEffect(() => {
    if (calledNotifications && !notificationUnsubscribes) {
      setNotificationUnsubscribes(subscribeNotifications())
      return () => {
        if (notificationUnsubscribes) {
          notificationUnsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [calledNotifications, notificationUnsubscribes, subscribeNotifications])

  useEffect(() => {
    if (calledActionCounts && !actionCountsUnsubscribes) {
      setActionCountsUnsubscribes(subscribeActionCounts())
      return () => {
        if (actionCountsUnsubscribes) {
          actionCountsUnsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [actionCountsUnsubscribes, calledActionCounts, subscribeActionCounts])

  const hasUnconfirmed =
    Boolean(dataNotifications?.notifications.unconfirmed) || Boolean(dataActionCounts?.actionCounts.unresolved)

  const className = `${classes.area} ${hasUnconfirmed ? classes.areaUnconfirmed : ""}`

  const titleIconItem = titleIcon ? (
    <Icon name={titleIcon} className={classes.titleIcon} fontSize="large" reversed={!theme.hasCustomColors} />
  ) : null

  return (
    <ErrorBoundary>
      <OfflineModeBanner />
      <Grid className={className} data-cy={cy}>
        {!collapse && (
          <Container>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.grid}>
              <Grid item>
                <Grid container spacing={0} align="left" direction="column">
                  <Grid item>
                    <RowBox alignItems="center">
                      {!xs && titleIconItem}
                      <ColumnBox>
                        <RowBox>
                          <Typography
                            component="h1"
                            className={`${classes.title} ${subTitle ? classes.titleHasSubtitle : ""}`}
                            data-cy={`${cy}-title`}
                          >
                            {title}
                          </Typography>
                          {beta && (
                            <Typography className={classes.beta}>
                              <sup>BETA</sup>
                            </Typography>
                          )}
                        </RowBox>
                        {subTitle && (
                          <Typography component="span" className={classes.subTitle}>
                            {subTitle}
                          </Typography>
                        )}
                      </ColumnBox>
                    </RowBox>
                  </Grid>
                </Grid>
              </Grid>
              {desktopButtons && (
                <Hidden xsDown>
                  <Grid item>
                    <Grid container alignItems="center" direction="column">
                      {desktopButtons}
                    </Grid>
                  </Grid>
                </Hidden>
              )}
              {mobileButtons && <Hidden smUp>{mobileButtons}</Hidden>}
            </Grid>
          </Container>
        )}
      </Grid>
    </ErrorBoundary>
  )
}

export default AreaHeader
