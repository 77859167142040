import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_164)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4999 18.2001C18.5828 17.1468 19.3293 15.7963 19.6451 14.319C19.961 12.8418 19.8321 11.3041 19.2747 9.90003C18.7172 8.49601 17.7563 7.28862 16.5132 6.43033C15.2701 5.57203 13.8005 5.10131 12.2901 5.0776C10.7796 5.05389 9.29604 5.47825 8.0266 6.2971C6.75715 7.11595 5.7588 8.29258 5.15758 9.67841C4.55636 11.0643 4.37924 12.5972 4.64859 14.0836C4.91793 15.57 5.62166 16.9433 6.67092 18.0301C3.42692 19.8091 1.44092 26.1641 1.44092 29.1001C1.44092 29.2314 1.46678 29.3615 1.51704 29.4828C1.56729 29.6041 1.64095 29.7143 1.73381 29.8072C1.82667 29.9001 1.93691 29.9737 2.05823 30.024C2.17956 30.0742 2.3096 30.1001 2.44092 30.1001C2.57224 30.1001 2.70228 30.0742 2.8236 30.024C2.94493 29.9737 3.05517 29.9001 3.14802 29.8072C3.24088 29.7143 3.31454 29.6041 3.3648 29.4828C3.41505 29.3615 3.44092 29.2314 3.44092 29.1001C3.44092 25.7781 6.07492 19.5461 8.41292 19.5461C8.48533 19.54 8.5568 19.5256 8.62592 19.5031C9.69693 20.0692 10.8881 20.3702 12.0995 20.3808C13.3109 20.3915 14.5071 20.1114 15.5879 19.5641C17.8959 19.7641 20.4429 25.8331 20.4429 29.1001C20.4429 29.3653 20.5483 29.6197 20.7358 29.8072C20.9233 29.9947 21.1777 30.1001 21.4429 30.1001C21.7081 30.1001 21.9625 29.9947 22.15 29.8072C22.3376 29.6197 22.4429 29.3653 22.4429 29.1001C22.4429 26.2521 20.5759 20.1881 17.4999 18.2001ZM6.52892 12.7381C6.52892 11.6222 6.85982 10.5314 7.47977 9.60353C8.09972 8.67571 8.98088 7.95256 10.0118 7.52553C11.0428 7.0985 12.1772 6.98677 13.2716 7.20447C14.3661 7.42217 15.3714 7.95951 16.1604 8.74856C16.9495 9.53761 17.4868 10.5429 17.7045 11.6374C17.9222 12.7318 17.8105 13.8663 17.3834 14.8972C16.9564 15.9281 16.2333 16.8093 15.3054 17.4292C14.3776 18.0492 13.2868 18.3801 12.1709 18.3801C10.6751 18.3782 9.24115 17.7832 8.18347 16.7255C7.12579 15.6679 6.53077 14.2339 6.52892 12.7381Z"
      fill={primary}
    />
    <path
      d="M26.6869 12.6131C27.2676 12.0063 27.7219 11.29 28.0233 10.5061C28.3248 9.72213 28.4676 8.88609 28.4432 8.04653C28.4188 7.20697 28.2278 6.38062 27.8813 5.61551C27.5348 4.8504 27.0397 4.16177 26.4248 3.58965C25.8098 3.01752 25.0873 2.57331 24.2993 2.28281C23.5112 1.99232 22.6732 1.86133 21.8341 1.89747C20.9949 1.93361 20.1713 2.13615 19.4112 2.49333C18.651 2.85051 17.9693 3.35519 17.4059 3.97807C17.2274 4.17446 17.1342 4.43372 17.1469 4.6988C17.1596 4.96388 17.277 5.21308 17.4734 5.39157C17.6698 5.57006 17.929 5.66322 18.1941 5.65056C18.4592 5.63791 18.7084 5.52046 18.8869 5.32407C19.3731 4.78931 19.9851 4.38439 20.6674 4.14601C21.3498 3.90764 22.0808 3.84337 22.7942 3.95901C23.5077 4.07466 24.181 4.36657 24.753 4.80827C25.3251 5.24998 25.7779 5.8275 26.0703 6.48847C26.3627 7.14943 26.4855 7.87294 26.4275 8.59336C26.3695 9.31379 26.1326 10.0084 25.7383 10.6141C25.3439 11.2198 24.8046 11.7175 24.1693 12.062C23.5339 12.4066 22.8226 12.587 22.0999 12.5871C21.8347 12.5871 21.5803 12.6925 21.3928 12.88C21.2052 13.0675 21.0999 13.3219 21.0999 13.5871C21.0999 13.8523 21.2052 14.1067 21.3928 14.2942C21.5803 14.4818 21.8347 14.5871 22.0999 14.5871C23.0645 14.5862 24.0161 14.3635 24.8809 13.9361C26.6019 14.1611 28.5579 18.9251 28.5579 21.4041C28.5579 21.6693 28.6632 21.9237 28.8508 22.1112C29.0383 22.2988 29.2927 22.4041 29.5579 22.4041C29.8231 22.4041 30.0775 22.2988 30.265 22.1112C30.4525 21.9237 30.5579 21.6693 30.5579 21.4041C30.5589 19.1401 29.0999 14.3921 26.6869 12.6131Z"
      fill={primary}
    />
    <path
      d="M17 25.056H12.708C12.4428 25.056 12.1884 24.9507 12.0009 24.7631C11.8134 24.5756 11.708 24.3212 11.708 24.056C11.708 23.7908 11.8134 23.5365 12.0009 23.3489C12.1884 23.1614 12.4428 23.056 12.708 23.056H17C17.2652 23.056 17.5196 23.1614 17.7071 23.3489C17.8947 23.5365 18 23.7908 18 24.056C18 24.3212 17.8947 24.5756 17.7071 24.7631C17.5196 24.9507 17.2652 25.056 17 25.056Z"
      fill={secondary}
    />
    <path
      d="M25 19.056H21.709C21.4438 19.056 21.1894 18.9507 21.0019 18.7631C20.8143 18.5756 20.709 18.3212 20.709 18.056C20.709 17.7908 20.8143 17.5365 21.0019 17.3489C21.1894 17.1614 21.4438 17.056 21.709 17.056H25C25.2652 17.056 25.5196 17.1614 25.7071 17.3489C25.8946 17.5365 26 17.7908 26 18.056C26 18.3212 25.8946 18.5756 25.7071 18.7631C25.5196 18.9507 25.2652 19.056 25 19.056Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_164"
        x1="11.708"
        y1="24.056"
        x2="18"
        y2="24.056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_99_164"
        x1="20.709"
        y1="18.056"
        x2="26"
        y2="18.056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
