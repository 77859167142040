import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { USER_TRAINING_ASSESSMENT_STATUS, USER_TRAINING_ASSESSMENT_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.primary.main,
  },
}))

const TrainerChip = ({
  className,
  label = USER_TRAINING_ASSESSMENT_STATUS_LABELS[USER_TRAINING_ASSESSMENT_STATUS.TRAINER],
}) => {
  const classes = useStyles()

  return <Chip label={label} classes={classes} className={className} />
}

export { TrainerChip }
