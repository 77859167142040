import React, { useState } from "react"
import { HexColorPicker, HexColorInput } from "react-colorful"
import { useDebounceCallback } from "@react-hook/debounce"
import { Box, TextField, InputAdornment, IconButton, Popover, makeStyles } from "@material-ui/core"
import { Brightness1 } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  hexColor: {
    padding: theme.spacing(2),
  },
}))

const ColorPicker = ({ name, label, helperText, color, classes, onChange }) => {
  const colorClasses = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnChange = (newColor) => {
    onChange && onChange(newColor)
  }

  const debouncedHandleOnChange = useDebounceCallback(handleOnChange, 200)
  const open = Boolean(anchorEl)
  const id = open ? name : undefined

  const InlineHexColorInput = (props) => {
    const { inputRef, ...colorProps } = props
    return <HexColorInput {...colorProps} color={color} onChange={handleOnChange} />
  }

  return (
    <>
      <TextField
        variant="outlined"
        classes={classes}
        fullWidth
        id={name}
        name={name}
        label={label}
        helperText={helperText}
        value={color}
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
          inputComponent: InlineHexColorInput,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="select color" onClick={handleClick} edge="end">
                <Brightness1 style={{ color }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box className={colorClasses.hexColor}>
          <HexColorPicker color={color || "000000"} onChange={debouncedHandleOnChange} />
        </Box>
      </Popover>
    </>
  )
}

export { ColorPicker }
