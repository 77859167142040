import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_718_2269)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 1H2C1.448 1 1 1.448 1 2V30C1 30.552 1.448 31 2 31H30C30.552 31 31 30.552 31 30V2C31 1.448 30.552 1 30 1ZM29 3V24H3V3H29ZM3 29V26H29V29H3Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.202 10.243C8.696 11.5 7.642 12.977 7.11 13.821C7.075 13.877 7.05 13.916 7.03 13.95C7.016 13.973 7.007 13.989 7.001 14V14.002C7.007 14.013 7.016 14.028 7.03 14.051C7.051 14.085 7.075 14.124 7.111 14.18C7.643 15.023 8.697 16.5 10.203 17.758C11.707 19.013 13.601 20.001 15.845 20.001C18.089 20.001 19.983 19.013 21.487 17.758C22.993 16.501 24.047 15.024 24.579 14.181C24.614 14.125 24.639 14.086 24.659 14.052C24.673 14.029 24.682 14.013 24.688 14.002V14C24.682 13.989 24.673 13.974 24.659 13.951C24.638 13.917 24.614 13.878 24.578 13.822C24.046 12.979 22.992 11.501 21.485 10.244C19.981 8.989 18.087 8.001 15.843 8.001C13.599 8.001 11.705 8.989 10.201 10.244L10.202 10.243ZM8.92 8.708C10.649 7.265 12.982 6 15.844 6C18.706 6 21.039 7.265 22.767 8.708C24.493 10.149 25.677 11.815 26.269 12.754C26.277 12.768 26.286 12.782 26.294 12.795C26.41 12.979 26.563 13.219 26.639 13.556C26.701 13.829 26.701 14.177 26.639 14.45C26.562 14.787 26.41 15.025 26.293 15.21C26.285 15.223 26.277 15.236 26.269 15.248C25.676 16.188 24.493 17.854 22.767 19.295C21.038 20.738 18.705 22.003 15.844 22.003C12.983 22.003 10.649 20.738 8.921 19.295C7.195 17.854 6.011 16.188 5.419 15.249C5.411 15.235 5.402 15.221 5.394 15.208C5.278 15.025 5.125 14.784 5.049 14.447C4.987 14.174 4.987 13.826 5.049 13.554C5.126 13.219 5.278 12.979 5.395 12.794C5.403 12.781 5.411 12.768 5.419 12.756C6.012 11.816 7.195 10.15 8.921 8.709L8.92 8.708Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 14C12 11.7907 13.7907 10 16 10C18.2093 10 20 11.7907 20 14C20 16.2093 18.2093 18 16 18C13.7907 18 12 16.2093 12 14ZM16 12C14.8953 12 14 12.8953 14 14C14 15.1047 14.8953 16 16 16C17.1047 16 18 15.1047 18 14C18 12.8953 17.1047 12 16 12Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_718_2269" x1="20" y1="18" x2="20" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
