import React from "react"
import MuiAvatar from "@material-ui/core/Avatar"
import { useTheme } from "@material-ui/core"
import { Avatar, Icon } from ".."
import { useAuth } from "../../services"

const OperandioFallbackAvatar = ({ from, className }) => {
  const {
    settings: { organisation },
  } = useAuth()
  const theme = useTheme()

  if (from) {
    return <Avatar {...from} className={className} />
  }
  if (organisation.logo) {
    return <Avatar avatar={{ key: organisation.logo }} fullName={organisation.name} className={className} />
  }
  return (
    <MuiAvatar style={{ backgroundColor: theme.palette.background.default }}>
      <Icon name="operandio" size={24} link={false} />
    </MuiAvatar>
  )
}

export default OperandioFallbackAvatar
