import { Box, makeStyles } from "@material-ui/core"
import { useState } from "react"
import { Icon } from ".."
import { preventDefault } from "../../utils"
import KnowledgePeekDialog from "../Knowledge/KnowledgePeekDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  link: {
    cursor: "pointer",
    display: "inline-block",
    color: theme.palette.text.link,
  },
  icon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 16,
    marginRight: 2,
    marginTop: -3,
  },
}))

const KnowledgePeekMention = ({ id, children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleToggleOpen = () => {
    setOpen(!open)
  }

  return (
    <Box onClick={preventDefault} className={classes.root}>
      {open && <KnowledgePeekDialog id={id} open={open} onClose={handleToggleOpen} />}

      <Box className={classes.link} onClick={handleToggleOpen} rel="noreferrer">
        <Icon name="article" className={classes.icon} />
        <span>{children}</span>
      </Box>
    </Box>
  )
}

export default KnowledgePeekMention
