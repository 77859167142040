import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2760)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.678 12.107C21.426 12.107 21.177 12.012 20.985 11.829L20.479 11.344C19.722 10.615 18.794 10.08 17.798 9.793L17.123 9.598C16.695 9.474 16.401 9.08201 16.401 8.63701V2.03601C16.401 1.73901 16.532 1.45901 16.76 1.26801C16.989 1.07801 17.291 1.00101 17.579 1.05101L18.678 1.25001C22.075 1.86301 25.186 3.65701 27.435 6.30301L28.16 7.15401C28.353 7.38001 28.435 7.67901 28.385 7.97201C28.335 8.26401 28.157 8.52001 27.9 8.66801L22.178 11.973C22.022 12.063 21.85 12.107 21.678 12.107ZM18.401 7.88501C19.672 8.25901 20.856 8.943 21.828 9.865L25.859 7.53601C23.93 5.297 21.287 3.77201 18.401 3.23201V7.88401V7.88501Z"
      fill={secondary}
    />
    <path
      d="M14.877 22.4022L14.202 22.2072C13.205 21.9202 12.279 21.3842 11.52 20.6562L11.014 20.1722C10.694 19.8652 10.208 19.8062 9.82299 20.0282L4.10098 23.3322C3.84398 23.4802 3.66598 23.7352 3.61598 24.0282C3.56498 24.3212 3.64799 24.6212 3.84099 24.8462L4.56598 25.6972C6.81398 28.3422 9.92398 30.1352 13.323 30.7492L14.422 30.9492C14.481 30.9602 14.541 30.9652 14.6 30.9652C14.832 30.9652 15.059 30.8842 15.241 30.7332C15.469 30.5432 15.6 30.2622 15.6 29.9652V23.3632C15.6 22.9182 15.306 22.5262 14.878 22.4022H14.877ZM13.599 28.7662C10.712 28.2272 8.06998 26.7032 6.14098 24.4632L10.173 22.1352C11.144 23.0562 12.327 23.7392 13.598 24.1142V28.7662H13.599Z"
      fill={primary}
    />
    <path
      d="M14.422 1.05111L13.324 1.25011C9.92599 1.86311 6.81499 3.65711 4.56699 6.30211L3.84099 7.15411C3.64799 7.38011 3.56699 7.67911 3.61699 7.97211C3.66699 8.26411 3.84599 8.52011 4.10299 8.66811L9.82499 11.9731C9.98099 12.0631 10.153 12.1071 10.325 12.1071C10.577 12.1071 10.827 12.0121 11.018 11.8291L11.523 11.3441C12.281 10.6181 13.208 10.0821 14.206 9.79411L14.88 9.59911C15.308 9.47511 15.602 9.08311 15.602 8.63811V2.03511C15.602 1.73811 15.471 1.45811 15.243 1.26711C15.014 1.07711 14.711 0.996106 14.424 1.05011L14.422 1.05111ZM13.6 7.88611C12.328 8.26211 11.145 8.94511 10.174 9.86511L6.14199 7.53611C8.07099 5.29811 10.713 3.77211 13.6 3.23311V7.88611Z"
      fill={primary}
    />
    <path
      d="M9.41998 19.3369C9.80598 19.1139 9.99798 18.6629 9.89098 18.2309L9.72198 17.5489C9.59598 17.0389 9.53198 16.5179 9.53198 15.9999C9.53198 15.4819 9.59598 14.9609 9.72198 14.4509L9.89098 13.7689C9.99798 13.3359 9.80598 12.8849 9.41998 12.6629L3.70098 9.36089C3.44498 9.21289 3.13498 9.18688 2.85698 9.28888C2.57898 9.39088 2.35998 9.61088 2.25998 9.89088L1.88298 10.9419C1.29798 12.5749 1.00098 14.2759 1.00098 16.0009C1.00098 17.7259 1.29798 19.4269 1.88298 21.0589L2.25998 22.1119C2.35998 22.3909 2.57898 22.6119 2.85698 22.7139C2.96798 22.7549 3.08498 22.7759 3.20098 22.7759C3.37398 22.7759 3.54698 22.7309 3.70098 22.6419L9.41998 19.3389V19.3369ZM2.99998 15.9999C2.99998 14.5329 3.24798 13.0849 3.73798 11.6919L7.76798 14.0189C7.61098 14.6699 7.53098 15.3359 7.53098 15.9989C7.53098 16.6619 7.61098 17.3269 7.76798 17.9789L3.73798 20.3069C3.24798 18.9139 2.99998 17.4649 2.99998 15.9979V15.9999Z"
      fill={primary}
    />
    <path
      d="M30.116 10.9419L29.739 9.88991C29.639 9.61091 29.42 9.38991 29.142 9.28791C28.864 9.18591 28.554 9.21191 28.298 9.35991L22.579 12.6619C22.193 12.8849 22.001 13.3359 22.108 13.7679L22.277 14.4499C22.403 14.9599 22.467 15.4809 22.467 15.9989C22.467 16.5169 22.403 17.0379 22.277 17.5479L22.108 18.2299C22.001 18.6629 22.193 19.1139 22.579 19.3359L28.298 22.6389C28.452 22.7279 28.625 22.7729 28.798 22.7729C28.914 22.7729 29.031 22.7519 29.142 22.7109C29.42 22.6089 29.639 22.3889 29.739 22.1089L30.116 21.0569C30.702 19.4229 30.999 17.7219 30.999 15.9979C30.999 14.2739 30.702 12.5729 30.116 10.9399V10.9419ZM28.26 20.3079L24.23 17.9799C24.387 17.3289 24.467 16.6629 24.467 15.9999C24.467 15.3369 24.387 14.6719 24.23 14.0199L28.26 11.6929C28.75 13.0859 28.999 14.5349 28.999 16.0009C28.999 17.4669 28.75 18.9159 28.26 20.3099V20.3079Z"
      fill={primary}
    />
    <path
      d="M27.898 23.3321L22.176 20.0281C21.791 19.8051 21.305 19.8651 20.985 20.1721L20.479 20.6571C19.721 21.3831 18.794 21.9191 17.796 22.2071L17.122 22.4021C16.694 22.5261 16.4 22.9181 16.4 23.3631V29.9651C16.4 30.2611 16.53 30.5421 16.758 30.7331C16.939 30.8841 17.167 30.9651 17.399 30.9651C17.459 30.9651 17.518 30.9601 17.577 30.9491L18.675 30.7501C22.074 30.1371 25.185 28.3431 27.432 25.6981L28.158 24.8461C28.351 24.6201 28.433 24.3211 28.383 24.0281C28.333 23.7351 28.155 23.4801 27.898 23.3321ZM18.399 28.7671V24.1151C19.672 23.7391 20.855 23.0561 21.825 22.1361L25.857 24.4641C23.929 26.7021 21.287 28.2271 18.399 28.7671Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_722_2760"
        x1="28.3994"
        y1="12.107"
        x2="28.3994"
        y2="1.03613"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
