import React from "react"
import { Box, Button, Divider, IconButton, MenuItem, Tooltip, makeStyles } from "@material-ui/core"
import { FlexBox, RowBox } from "../Boxes"
import {
  PROCESS_STEP_LOGIC_MULTIPLE_VALUE_RANGES,
  PROCESS_STEP_LOGIC_OUTCOME,
  PROCESS_STEP_LOGIC_OUTCOME_LABELS,
  PROCESS_STEP_LOGIC_RANGE,
} from "../../data"
import { Icon } from "../Icon"
import { InlineOutlinedInput } from "../TextField/InlineOutlinedInput"
import { FormatSymbol } from "../Format/FormatSymbol"
import { InlineOutlinedSelect } from "../OutlinedSelect/InlineOutlinedSelect"
import { initialStepLogicOutcomeState } from "./ProcessCreator"
import { formatEnumKeyForDisplay } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
    color: theme.palette.tertiary.main,
    backgroundColor: theme.palette.tertiary.background,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  add: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.tertiary.main,
    "&:hover, &:focus": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
      boxShadow: "none",
    },
  },
  addOutcome: {
    padding: 2,
    "&:hover, &:focus": {
      background: `rgba(${theme.palette.tertiary.mainRgb},.10)`,
    },
  },
  addIcon: {
    width: 18,
    height: 18,
    color: theme.palette.tertiary.main,
  },
  removeOutcome: {
    padding: 2,
    "&:hover, &:focus": {
      background: `rgba(${theme.palette.error.mainRgb},.10)`,
    },
  },
  removeIcon: {
    width: 18,
    height: 18,
  },
  ruleBox: {
    borderTop: `1px solid rgba(${theme.palette.tertiary.mainRgb},0.1)`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  divider: {
    backgroundColor: `rgba(${theme.palette.tertiary.mainRgb},0.1)`,
  },
}))

const removeValue = "__REMOVE__"

const ProcessStepCreatorLogic = ({ logic, format, onUpdate, onAdd }) => {
  const classes = useStyles()

  const handleChange = (index, propName, value) => {
    const newLogic = [...logic]
    newLogic[index][propName] = value
    onUpdate && onUpdate(newLogic)
  }

  const handleOutcomeChange = (index, outcomeIndex, value) => {
    if (value === removeValue) {
      return handleRemoveOutcome(index, outcomeIndex)
    }

    const newLogic = [...logic]
    newLogic[index].outcomes[outcomeIndex].type = value
    onUpdate && onUpdate(newLogic)
  }

  const handleAdd = () => {
    onAdd && onAdd()
  }

  const handleAddOutcome = (index) => {
    const newLogic = [...logic]
    newLogic[index].outcomes.push({
      ...JSON.parse(JSON.stringify(initialStepLogicOutcomeState)),
    })
    onUpdate && onUpdate(newLogic)
  }

  const handleRemoveOutcome = (index, outcomeIndex) => {
    const newLogic = [...logic]
    newLogic[index].outcomes.splice(outcomeIndex, 1)
    onUpdate && onUpdate(newLogic)
  }

  const handleRemove = (index) => {
    const newLogic = [...logic.filter((_, i) => i !== index)]
    onUpdate && onUpdate(newLogic)
  }

  if (!logic?.length > 0) {
    return null
  }

  return (
    <Box className={classes.root}>
      {logic.map((rule, index) => (
        <React.Fragment key={index}>
          <RowBox px={2} py={0.5} className={classes.ruleBox}>
            <Box>If answer is </Box>
            <Box mx={0.5}>
              <InlineOutlinedSelect
                value={rule.range}
                onChange={(event) => handleChange(index, "range", event.target.value)}
              >
                {Object.entries(PROCESS_STEP_LOGIC_RANGE).map(([key, value]) => (
                  <MenuItem key={value} value={value}>
                    {formatEnumKeyForDisplay(key)}
                  </MenuItem>
                ))}
              </InlineOutlinedSelect>
            </Box>
            <InlineOutlinedInput
              type="number"
              value={rule.lowerValue}
              onChange={(event) => handleChange(index, "lowerValue", Number(event.target.value))}
            />
            {PROCESS_STEP_LOGIC_MULTIPLE_VALUE_RANGES.includes(rule.range) && (
              <>
                <Box mx={0.5}>and</Box>
                <InlineOutlinedInput
                  type="number"
                  value={rule.upperValue}
                  onChange={(event) => handleChange(index, "upperValue", Number(event.target.value))}
                />
              </>
            )}
            <Box ml={0.5}>
              <FormatSymbol format={format} />, then
            </Box>
            {rule.outcomes.map((outcome, outcomeIndex) => (
              <FlexBox ml={0.5} key={outcomeIndex}>
                <InlineOutlinedSelect
                  value={outcome.type}
                  onChange={(event) => handleOutcomeChange(index, outcomeIndex, event.target.value)}
                >
                  {Object.values(PROCESS_STEP_LOGIC_OUTCOME).map((oc, i) => (
                    <MenuItem key={i} value={oc}>
                      {PROCESS_STEP_LOGIC_OUTCOME_LABELS[oc].toLowerCase()}
                    </MenuItem>
                  ))}

                  {outcomeIndex > 0 && <Divider className={classes.divider} />}
                  {outcomeIndex > 0 && <MenuItem value={removeValue}>Remove</MenuItem>}
                </InlineOutlinedSelect>
              </FlexBox>
            ))}
            {rule.outcomes.length < 2 && (
              <Box mx={0.5}>
                <Tooltip title="Add another outcome">
                  <IconButton className={classes.addOutcome} onClick={() => handleAddOutcome(index)} color="primary">
                    <Icon name="add" className={classes.addIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Box ml="auto">
              <Tooltip title="Remove this rule">
                <IconButton className={classes.removeOutcome} onClick={() => handleRemove(index)} color="secondary">
                  <Icon name="delete" className={classes.removeIcon} />
                </IconButton>
              </Tooltip>
            </Box>
          </RowBox>
        </React.Fragment>
      ))}
      <Divider className={classes.divider} />
      <RowBox px={2} py={0.5} ml={-0.5} alignContent="center">
        <Button variant="text" className={classes.add} onClick={handleAdd}>
          <Box mr={1}>Add another rule</Box>
          <Icon name="add" className={classes.addIcon} />
        </Button>
      </RowBox>
    </Box>
  )
}

export { ProcessStepCreatorLogic }
