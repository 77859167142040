import React, { useRef, useState } from "react"
import { IconButton, makeStyles, Menu, Tooltip, useTheme } from "@material-ui/core"
import { HelpOutline, LiveHelpOutlined, MailOutline } from "@material-ui/icons"
import Config from "react-global-configuration"
import { useCookies } from "react-cookie"
import { Trans } from "@lingui/macro"
import { Icon } from "../Icon"
import { HeaderMenuItem } from "./HeaderMenuItem"
import { useAuth } from "../../services"
import { triggerUserHelpBeacon } from "../../utils/helpscout"

const useStyles = makeStyles(() => ({
  menu: {
    minWidth: 307,
  },
}))

const HelpMenu = ({ className, size = 22 }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { principal, location, settings } = useAuth()
  const [{ hide_banners: hideBanners }, , removeCookie] = useCookies()
  const [helpEl, setHelpEl] = useState(null)
  const helpButtonEl = useRef(null)
  const {
    links: { help: helpLink },
  } = Config.get()

  const handleClick = (event) => {
    setHelpEl(event.currentTarget)
  }
  const handleHelpCenter = () => {
    window.open(helpLink, "_blank")
    handleClose()
  }
  const handleContactSupport = () => {
    triggerUserHelpBeacon(principal, location, settings)
    handleClose()
  }
  const handleBanners = () => {
    removeCookie("hide_banners")
    handleClose()
  }
  const handleClose = () => {
    setHelpEl(null)
  }

  return (
    <>
      <Tooltip title={<Trans>Help</Trans>}>
        <IconButton ref={helpButtonEl} aria-label={<Trans>Help</Trans>} onClick={handleClick} className={className}>
          <Icon name="help" style={{ width: size, height: size }} />
        </IconButton>
      </Tooltip>
      <Menu
        open={Boolean(helpEl)}
        anchorEl={helpEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: theme.spacing(1), horizontal: "right" }}
        classes={{ paper: classes.menu }}
      >
        <HeaderMenuItem
          autoFocus
          divider
          onClick={handleContactSupport}
          text={<Trans>Contact support</Trans>}
          icon={<MailOutline />}
        />
        <HeaderMenuItem divider onClick={handleHelpCenter} text={<Trans>Help Center</Trans>} icon={<HelpOutline />} />
        <HeaderMenuItem
          onClick={handleBanners}
          text={<Trans>Show help banners</Trans>}
          icon={<LiveHelpOutlined />}
          disabled={!hideBanners}
        />
      </Menu>
    </>
  )
}

export { HelpMenu }
