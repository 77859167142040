import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1005_2493)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.18 29.5001C14.64 29.5001 13.16 28.9401 12.01 27.9201C11.59 27.5501 11.56 26.9201 11.92 26.5101C12.29 26.0901 12.92 26.0601 13.33 26.4201C14.91 27.8101 17.46 27.8101 19.03 26.4201C19.44 26.0601 20.08 26.0901 20.44 26.5101C20.81 26.9201 20.77 27.5601 20.35 27.9201C19.2 28.9401 17.72 29.5001 16.18 29.5001Z"
      fill={secondary}
    />
    <path
      d="M26.51 20.45C25.25 18.38 24.61 15.74 24.61 12.6C24.61 10.31 23.74 8.15 22.15 6.53C21.24 5.6 20.15 4.91 18.96 4.49C18.99 4.33 19 4.17 19 4C19 2.35 17.65 1 16 1C14.35 1 13 2.35 13 4C13 4.21 13.03 4.41 13.07 4.61C12.01 5.04 11.04 5.68 10.21 6.53C8.62 8.15 7.75 10.31 7.75 12.6C7.75 15.74 7.11 18.38 5.85 20.45C4.56 22.58 4.32 22.96 4.36 23.5C4.4 24.01 4.59 24.36 5 24.68C5.43 25.01 5.92 25.01 7.99 25.01H24.37C26.44 25.01 26.93 25.01 27.36 24.68C27.77 24.37 27.96 24.02 28 23.51C28.04 22.98 27.81 22.59 26.51 20.46V20.45ZM15 4C15 3.45 15.45 3 16 3C16.55 3 17 3.45 17 4C17 4.01 17 4.03 17 4.04C16.73 4.01 16.46 4 16.18 4C15.79 4 15.4 4.04 15.02 4.09C15.02 4.06 15.01 4.03 15.01 4H15ZM24.37 23H7.99C7.5 23 7.02 23 6.65 23C6.88 22.61 7.2 22.07 7.56 21.49C9.01 19.1 9.75 16.11 9.75 12.6C9.75 10.86 10.44 9.15 11.64 7.93C12.85 6.69 14.47 6 16.18 6C16.6 6 17.01 6.04 17.42 6.12C17.42 6.12 17.44 6.12 17.45 6.12C18.68 6.37 19.81 6.99 20.72 7.92C21.92 9.15 22.61 10.85 22.61 12.59C22.61 16.1 23.35 19.09 24.8 21.48C25.15 22.06 25.48 22.59 25.71 22.99C25.34 22.99 24.86 22.99 24.37 22.99V23Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1005_2493"
        x1="11.6708"
        y1="27.8355"
        x2="20.6933"
        y2="27.8355"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
