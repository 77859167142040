import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Skeleton } from "@material-ui/lab"
import { FlexBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    background: ({ isThemed }) => (isThemed ? theme.palette.primary.backgroundDark : null),
  },
}))

const LoadingSkeleton = ({ quantity = 3, variant = "text", width = "100%", height = 20, isThemed, ...rest }) => {
  const classes = useStyles({ isThemed })
  const skeletonQuantity = Array.from({ length: quantity })

  return (
    <FlexBox justifyContent="center" {...rest} flexDirection="column">
      {skeletonQuantity.map((_, index) => (
        <Skeleton classes={{ root: classes.root }} key={index} variant={variant} width={width} height={height} />
      ))}
    </FlexBox>
  )
}

export { LoadingSkeleton }
