import React from "react"
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, Box, makeStyles } from "@material-ui/core"
import { Trans } from "@lingui/macro"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: theme.dimensions.dialogs.alerts.width,
    top: "inherit",
  },
  title: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  description: {
    color: theme.palette.text.primary,
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

const SubmitIncompleteAuditConfirmation = ({ open, onClose, onSubmitSkip, onSubmitIncomplete }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Box pb={1}>
        <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
          <Trans>Submit incomplete audit</Trans>
        </DialogTitle>
        <DialogContent classes={{ root: classes.content }}>
          <DialogContentText id="alert-dialog-description" className={classes.description}>
            <Trans>Some steps in this audit aren't yet complete. Would you like to skip all remaining steps?</Trans>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description-two" className={classes.description}>
            <Trans>Once submitted, the audit will be locked and you'll be unable to edit it.</Trans>
          </DialogContentText>
          <Box display="flex" flexDirection="column">
            <Button onClick={onSubmitSkip} variant="contained" color="primary">
              <Trans>Skip steps and submit</Trans>
            </Button>
            <Button onClick={onSubmitIncomplete} variant="contained" className={classes.button}>
              <Trans>Submit as incomplete</Trans>
            </Button>
            <Button onClick={onClose} className={classes.button}>
              <Trans>Cancel</Trans>
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export { SubmitIncompleteAuditConfirmation }
