import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1005_2514)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.02 20.0001C7.90998 20.0001 4.87998 18.3901 3.20998 15.5001C0.719983 11.2001 2.19998 5.69013 6.49998 3.21013C10.8 0.730134 16.31 2.21013 18.79 6.50013C21.27 10.8001 19.79 16.3101 15.5 18.7901C14.09 19.6101 12.54 19.9901 11.02 19.9901V20.0001ZM10.99 4.00013C9.79998 4.00013 8.59998 4.30013 7.49998 4.94013C4.15998 6.87013 3.00998 11.1601 4.93998 14.5001C6.86998 17.8401 11.16 18.9901 14.5 17.0601C17.84 15.1301 18.99 10.8401 17.06 7.50013C15.77 5.26013 13.41 4.00013 10.99 4.00013Z"
      fill={primary}
    />
    <path
      d="M15.33 9.49989C14.98 9.49989 14.65 9.31989 14.46 8.99989C13.93 8.06989 13.06 7.40989 12.03 7.13989C11.5 6.99989 11.18 6.44989 11.32 5.90989C11.46 5.37989 12.01 5.05989 12.55 5.19989C14.1 5.60989 15.39 6.60989 16.2 7.99989C16.48 8.47989 16.31 9.08989 15.83 9.36989C15.67 9.45989 15.5 9.49989 15.33 9.49989Z"
      fill={secondary}
    />
    <path
      d="M26.51 29.86C25.58 29.86 24.7 29.5 24.04 28.84L16.11 20.91C15.72 20.52 15.72 19.89 16.11 19.5C16.5 19.11 17.13 19.11 17.52 19.5L25.45 27.43C26.01 27.99 26.99 27.99 27.56 27.43C27.84 27.15 28 26.77 28 26.38C28 25.99 27.84 25.61 27.56 25.32L19.63 17.39C19.24 17 19.24 16.37 19.63 15.98C20.02 15.59 20.65 15.59 21.04 15.98L28.97 23.91C29.63 24.57 29.99 25.45 29.99 26.38C29.99 27.31 29.63 28.19 28.97 28.85C28.31 29.51 27.43 29.87 26.5 29.87L26.51 29.86Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1005_2514"
        x1="11.2871"
        y1="7.33344"
        x2="16.3352"
        y2="7.33344"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
