import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Icon } from ".."
import { NOTIFICATION_TYPE } from "../../data"
// import { Badge } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  normal: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "3px !important",
  },
  unconfirmed: {
    backgroundColor: theme.palette.error.main,
    borderRadius: 4,
    color: theme.palette.text.white,
    padding: "3px !important",
  },
}))

const NotificationIcon = ({ hasConfirm, confirmed, type }) => {
  const classes = useStyles()

  const className = (hasConfirm && confirmed) || !hasConfirm ? classes.normal : classes.unconfirmed

  switch (type) {
    case NOTIFICATION_TYPE.PROCESS:
      return <Icon name="process" className={className} />
    case NOTIFICATION_TYPE.TASK:
      return <Icon name="task" className={className} />
    case NOTIFICATION_TYPE.KNOWLEDGE:
      return <Icon name="knowledge" className={className} />
    case NOTIFICATION_TYPE.POST:
      return <Icon name="post" className={className} />
    case NOTIFICATION_TYPE.ACTION:
      return <Icon name="action" className={className} />
    case NOTIFICATION_TYPE.TRAINING:
      return <Icon name="training" className={className} />
    case NOTIFICATION_TYPE.ACCREDITATION:
      return <Icon name="accreditation-alt" className={className} />
    default:
      return <Icon name="notifications" className={className} />
  }
}

export default NotificationIcon
