import React, { forwardRef, useState } from "react"
import { Paper, Typography, Box, Divider } from "@material-ui/core"
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { LoadingSpinner, MentionOutput, RowBox } from ".."
import { Shine } from "./Shine"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    cursor: ({ cursor }) => cursor || "inherit",
    "&:hover": {
      boxShadow: theme.cards.hoverCard.boxShadow,
    },
    "&:focus": {
      boxShadow: theme.cards.hoverCard.boxShadow,
    },
    height: ({ fullHeight }) => (fullHeight ? "100%" : "auto"),
    padding: "1px",
  },
  inner: {
    opacity: ({ isScheduled }) => (isScheduled ? 0.3 : null),
    height: ({ fullHeight }) => (fullHeight ? "100%" : "auto"),
  },
  content: {
    minHeight: ({ contentMinHeight }) => contentMinHeight,
    height: ({ fullHeight }) => (fullHeight ? "100%" : "auto"),
  },
  contentInner: {
    width: "100%",
  },
  selected: {
    border: `2px solid ${theme.palette.grey[400]}`,
    padding: 0,
  },
  completed: {
    backgroundColor: theme.palette.success.background,
    border: `2px solid ${theme.palette.success.main}`,
    padding: 0,
  },
  overdue: {
    border: `2px solid ${theme.palette.error.main}`,
    padding: 0,
  },
  skipped: {
    backgroundColor: theme.palette.grey[100],
    border: `2px solid ${theme.palette.grey[400]}`,
    padding: 0,
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
    marginBottom: ({ hasDetail }) => (hasDetail ? theme.spacing(0.5) : 0),
  },
  detail: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  arrow: {
    color: theme.palette.text.primary,
  },
  divider: {
    marginLeft: ({ completed, skipped, overdue }) => (completed || skipped || overdue ? 0 : -1),
    marginRight: ({ completed, skipped, overdue }) => (completed || skipped || overdue ? 0 : -1),
    backgroundColor: ({ completed, skipped }) => theme.palette.grey[completed || skipped ? `300` : `200`],
  },
  footer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    color: theme.palette.text.secondary,
    minHeight: 56,
  },
}))

const ActionCard = forwardRef(function ActionCard(
  {
    variant = "normal",
    title,
    titleProps = {},
    titleIcon,
    titleWrapperProps = {},
    detail,
    selected = false,
    completed = false,
    overdue,
    skipped,
    loading = false,
    stub,
    children,
    innerChildren,
    rightChildren,
    footer,
    cardProps,
    isScheduled,
    fullHeight = false,
    cursor = "pointer",
    className = "",
    classes: userClasses = {},
    contentMinHeight = 65,
    elevation = 0,
    p = 2,
    cy = "ActionCard",
  },
  ref
) {
  const classes = useStyles({
    isScheduled,
    cursor,
    fullHeight,
    completed,
    skipped,
    overdue,
    contentMinHeight,
    hasDetail: !!detail,
  })
  const [expanded, setExpanded] = useState(false)

  const handleInteraction = (event) => {
    if (isScheduled) return false
    if (event.type === "click" || (event.type === "keypress" && event.key === "Enter" && !event.shiftKey)) {
      if (variant === "expand") setExpanded(!expanded)
      else if (cardProps && cardProps.onClick) cardProps.onClick(event)
    }
  }

  return (
    <Paper
      ref={ref}
      elevation={elevation}
      className={`${classes.root} ${selected ? classes.selected : ""}  ${completed ? classes.completed : ""} ${
        overdue && !completed ? classes.overdue : ""
      } ${skipped && completed ? classes.skipped : ""} ${className}`}
    >
      <Shine go={completed && !skipped} fullHeight={fullHeight}>
        <Box className={classes.inner}>
          <Box
            {...cardProps}
            onClick={handleInteraction}
            onKeyPress={handleInteraction}
            tabIndex={0}
            data-cy={`${cy}-card`}
          >
            <Box p={p} className={classes.content} display="flex" flexDirection="column" justifyItems="center">
              <Box display="flex" flexGrow={1} flexDirection="row" className={classes.contentInner}>
                {stub}
                <Box
                  {...{
                    flexGrow: 1,
                    flexDirection: "column",
                    alignSelf: "center",
                    alignItems: "center",
                    ...titleProps,
                  }}
                >
                  {title && (
                    <RowBox alignItems="flex-start" data-cy={`${cy}-title`}>
                      {titleIcon && <Box mr={1}>{titleIcon}</Box>}
                      <Box {...titleWrapperProps}>
                        {typeof title === "string" ? (
                          <Typography variant="h2" className={classes.title}>
                            <MentionOutput content={title} />
                          </Typography>
                        ) : (
                          title
                        )}

                        {detail && <Box className={classes.detail}>{detail}</Box>}
                      </Box>
                    </RowBox>
                  )}

                  {innerChildren}
                </Box>
                {rightChildren}
                {variant === "expand" && children && (
                  <Box display="flex" alignSelf="center" className={classes.arrow}>
                    {expanded && <KeyboardArrowUpIcon />}
                    {!expanded && <KeyboardArrowDownIcon />}
                  </Box>
                )}
                {variant === "navigate" && children && (
                  <Box display="flex" alignSelf="center" className={classes.arrow}>
                    {!loading && <KeyboardArrowRightIcon />}
                    {loading && <LoadingSpinner size="24px" delay={false} />}
                  </Box>
                )}
              </Box>
              {(variant !== "expand" || expanded) && children}
            </Box>
            {footer && (
              <>
                <Divider className={classes.divider} />
                <Box
                  display="flex"
                  flexDirection="row"
                  className={userClasses.footer || classes.footer}
                  alignItems="center"
                >
                  {footer}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Shine>
    </Paper>
  )
})

export { ActionCard, useStyles as useActionCardStyles }
