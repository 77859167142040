import { Box, Divider, Icon, MenuItem } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { t } from "@lingui/macro"
import { useQueryAccreditations } from "../../data"
import { toId } from "../../utils"
import { RowBox } from "../Boxes"
import { OutlinedSelect } from "../OutlinedSelect"

const AccreditationOutlinedSelect = ({
  cy = "OutlinedSelect-accreditation",
  id = "accreditation",
  label = t`Accreditation type`,
  onDataLoaded,
  hasNone = true,
  filterActiveAccreditations,
  disabled = false,
  ...rest
}) => {
  const { data, loading } = useQueryAccreditations()
  const [accreditations, setAccreditations] = useState([])

  useEffect(() => {
    if (data) {
      onDataLoaded && onDataLoaded(data.trainingCourses)
      if (!filterActiveAccreditations?.length) {
        setAccreditations([...data.accreditations])
        return
      }
      setAccreditations([
        ...data.accreditations.filter(
          (accreditation) => !filterActiveAccreditations.some((item) => toId(item) === toId(accreditation)),
        ),
      ])
    }
  }, [data, filterActiveAccreditations, onDataLoaded])

  if (data && !data.accreditations.length) {
    return null
  }

  return (
    <OutlinedSelect
      label={label}
      id={id}
      native={false}
      menuPortal={false}
      disabled={loading || disabled || !accreditations.length}
      data-cy={cy}
      {...rest}
    >
      {hasNone && <MenuItem value="">(None)</MenuItem>}
      {hasNone && <Divider />}
      {accreditations.map((accreditation, index) => (
        <MenuItem key={toId(accreditation)} data-cy={`${cy}-${index}`} value={toId(accreditation)}>
          <RowBox>
            <Icon className="material-icons-outlined">{accreditation.icon}</Icon>
            <Box ml={1}>{accreditation.name}</Box>
          </RowBox>
        </MenuItem>
      ))}
    </OutlinedSelect>
  )
}

export { AccreditationOutlinedSelect }
