import React, { useRef } from "react"
import {
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Popover,
  Divider,
  Typography,
  makeStyles,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core"
import { t } from "@lingui/macro"
import { PaperBox } from "../Boxes"
import { Icon, useSnackbar } from ".."
import { TooltipIconButton } from "../Buttons/TooltipIconButton"

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingRight: theme.spacing(9),
  },
  addressName: {
    fontWeight: 600,
  },
  copy: {
    right: theme.spacing(1),
  },
}))

const EmailAddress = ({ email }) => {
  if (!email) {
    return null
  }

  return (
    <Box>
      <a href={`mailto:${email}`}>{email}</a>
    </Box>
  )
}

const PhoneNumber = ({ phone, suffix }) => {
  if (!phone) {
    return null
  }

  return (
    <Box>
      <a href={`tel:${phone}`}>{phone}</a>
      {!!suffix && <> ({suffix})</>}
    </Box>
  )
}

const AddressLine = ({ line, ...props }) => {
  if (!line) {
    return null
  }

  return (
    <Typography {...props} variant="inherit">
      {line}
      <br />
    </Typography>
  )
}

const SupplierContactPanel = ({ variant = "paperbox", contact, popoverProps = {}, ...props }) => {
  const classes = useStyles()
  const contactInputRef = useRef(null)
  const addressInputRef = useRef(null)
  const { showMessage } = useSnackbar()

  const handleCopy = (inputRef) => {
    inputRef.current.select()
    document.execCommand("copy")
    showMessage({ message: t`Copied to your clipboard`, icon: <Icon name="clipboard" /> })
    inputRef.current.blur()
  }

  const address = contact.address

  const hasAddress =
    !!address.name || !!address.line1 || !!address.line2 || !!address.city || !!address.country || !!address.postcode

  const assembledContact = [
    contact.name,
    contact.email,
    contact.emailSecondary,
    contact.phone,
    contact.phoneEmergency,
    contact.fax,
    contact.notes,
  ]
    .filter((line) => !!line)
    .join("\r\n")

  const assembledAddress = [
    address.name,
    address.line1,
    address.line2,
    `${address.city} ${address.postcode}`.trim(),
    address.country,
  ]
    .filter((line) => !!line)
    .join("\r\n")

  const display = (
    <>
      <ListItem alignItems="flex-start" ContainerComponent="div" classes={{ root: classes.listItem }}>
        <ListItemAvatar>
          <Avatar alt={contact.name} />
        </ListItemAvatar>
        <ListItemText
          primary={contact.name}
          secondary={
            <>
              <EmailAddress email={contact.email} />
              <EmailAddress email={contact.emailSecondary} />
              <PhoneNumber phone={contact.phone} suffix="main" />
              <PhoneNumber phone={contact.phoneEmergency} suffix="emergency" />
              <PhoneNumber phone={contact.fax} suffix="fax" />
              {!!contact.notes && <Box>Notes: {contact.notes}</Box>}
            </>
          }
          secondaryTypographyProps={{ component: "div" }}
        />
        <ListItemSecondaryAction classes={{ root: classes.copy }}>
          <TooltipIconButton tooltip={t`Copy to clipboard`} onClick={() => handleCopy(contactInputRef)}>
            <Icon name="clipboard" />
          </TooltipIconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {hasAddress && <Divider />}
      {hasAddress && (
        <ListItem alignItems="flex-start" ContainerComponent="div" classes={{ root: classes.listItem }}>
          <ListItemAvatar />
          <ListItemText
            secondary={
              <>
                <AddressLine line={address.name} className={classes.addressName} />
                <AddressLine line={address.line1} />
                <AddressLine line={address.line2} />
                <AddressLine line={address.city} />
                <AddressLine line={`${address.country} ${address.postcode}`.trim()} />
              </>
            }
          />
          <ListItemSecondaryAction classes={{ root: classes.copy }}>
            <TooltipIconButton tooltip={t`Copy to clipboard`} onClick={() => handleCopy(addressInputRef)}>
              <Icon name="clipboard" />
            </TooltipIconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}

      <div style={{ position: "fixed", left: 0, bottom: 0, width: 1, height: 1, opacity: 0, overflow: "hidden" }}>
        <TextField inputRef={contactInputRef} value={assembledContact} multiline />
        {hasAddress && <TextField inputRef={addressInputRef} value={assembledAddress} multiline />}
      </div>
    </>
  )

  if (variant === "popover") {
    return <Popover {...popoverProps}>{display}</Popover>
  }

  return <PaperBox {...props}>{display}</PaperBox>
}

export { SupplierContactPanel }
