import React, { useEffect, useState } from "react"
import { Divider, makeStyles, MenuItem } from "@material-ui/core"
import { t } from "@lingui/macro"
import { useQueryIdentityProviders } from "../../data"
import { toId } from "../../utils"
import { OutlinedSelect } from "./OutlinedSelect"

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 125,
  },
}))

const IdentityProviderOutlinedSelect = ({
  onDataLoaded,
  disabled: disabledInput = false,
  value: valueInput,
  onChange,
  required,
  cy = "IdentityProviderOutlinedSelect",
  ...rest
}) => {
  const { data, loading } = useQueryIdentityProviders()
  const classes = useStyles()
  const [value, setValue] = useState(valueInput ?? "none")

  useEffect(() => {
    if (onDataLoaded && data) onDataLoaded(data)
  }, [data, onDataLoaded])

  const handleChange = (event) => {
    setValue(event.target.value)
    onChange && onChange(event)
  }

  const disabled = disabledInput || loading || !data?.identityProviders.length

  return (
    <OutlinedSelect
      label={t`Identity provider`}
      native={false}
      classes={classes}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      required={required}
      data-cy={cy}
      {...rest}
    >
      {!required && <MenuItem value="none">Not configured</MenuItem>}
      {!required && <Divider />}
      {data &&
        data.identityProviders.map((item, index) => (
          <MenuItem key={toId(item)} value={toId(item)} data-cy={`${cy}-identityprovider-${index}`}>
            {item.name}
          </MenuItem>
        ))}
    </OutlinedSelect>
  )
}

export { IdentityProviderOutlinedSelect }
