import React, { useEffect, useRef, useState } from "react"

import { Typography, Box, TextField, Tooltip, IconButton, InputBase, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import PropTypes from "prop-types"

import { EditOutlined } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { useAuth } from "../../services"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: theme.spacing(0.5),
    alignItems: "center",
    border: ({ canUpdate }) => (canUpdate ? `2px solid transparent` : 0),
    marginLeft: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25),
    display: "flex",
    "&&:hover": {
      backgroundColor: ({ canUpdate }) => (canUpdate ? theme.palette.action.hover : null),
    },
    cursor: ({ canUpdate }) => (canUpdate ? "text" : "default"),
  },
  display: {
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  titleEdit: {
    width: "100%",
    fontSize: ({ xs }) => (xs ? 16 : 18),
    marginLeft: theme.spacing(0.25),
    height: "100%",
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.spacing(0.5),
    "& .MuiInputBase-input": {
      paddingLeft: theme.spacing(0.25),
    },
  },
  titleEditIcon: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  title: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
    margin: 0,
    padding: 0,
    color: theme.palette.text.primary,
  },
  iconButton: {
    padding: 4,
  },
  doneButton: {
    color: theme.palette.success.main,
  },
}))

// TODO: Add variant prop to keep the component clean.
const InlineEditTextField = ({
  displayComponent = "span",
  displayVariant = "inherit",
  id,
  children,
  value,
  placeholder,
  className,
  editing,
  editable,
  titleIcon = null,
  editLabel,
  permission,
  onStart,
  onCancel,
  onEnd,
  isTextField = false,
  showEditingButtons = false,
  endOnBlur = true,
  ...props
}) => {
  const ref = useRef()
  const { hasPermission } = useAuth()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const canUpdate = editable || (permission && hasPermission(permission))
  const classes = useStyles({ canUpdate, xs })
  const [editValue, setEditValue] = useState(editing)
  const [newValue, setNewValue] = useState(value)
  const InputComponent = isTextField ? TextField : InputBase

  const handleEdit = () => {
    if (!canUpdate) return
    if (typeof editing === "undefined") setEditValue(true)
    if (onStart) onStart()
  }

  const handleValueChange = (event) => {
    setNewValue(event.target.value)
  }

  const handleEnd = () => {
    if (onEnd) onEnd(newValue, undo)
    if (typeof editing === "undefined") setEditValue(false)
  }

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEnd()
    }

    if (e.key === "Escape") {
      e.stopPropagation()
      handleCancel()
    }
  }

  const handleEditBlur = () => {
    if (!endOnBlur) {
      return
    }

    handleEnd()
  }

  const handleCancel = () => {
    setNewValue(value)
    if (typeof editing === "undefined") setEditValue(false)
    if (onCancel) onCancel()
  }

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget

    if (endOnBlur) {
      return
    }

    // Give browser time to focus the next element.
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container.
      if (!currentTarget.contains(document.activeElement)) {
        handleCancel()
      }
    })
  }

  // Temporary solution.
  const undo = () => {
    setNewValue(value)
  }

  useEffect(() => {
    setEditValue(editing)
  }, [editing])

  useEffect(() => {
    setNewValue(value)
  }, [value])

  return (
    <RowBox alignItems="center" onBlur={handleBlur} ref={ref} {...props}>
      {!editValue && (
        <RowBox alignItems="center" className={classes.wrapper} onClick={handleEdit}>
          <Typography
            id={id}
            component={displayComponent}
            variant={displayVariant}
            className={`${classes.display} ${isTextField ? classes.title : null} ${className}`}
          >
            {children || value || placeholder}
          </Typography>
          {titleIcon}
          {!showEditingButtons && canUpdate && <EditOutlined className={classes.titleEditIcon} />}
        </RowBox>
      )}
      {editValue && (
        <Box flexGrow={1} width="100%" mr={isTextField ? 1 : 0}>
          <InputComponent
            id={id}
            label={editLabel}
            variant="outlined"
            value={newValue}
            onChange={handleValueChange}
            fullWidth
            required
            autoFocus
            onBlur={handleEditBlur}
            onKeyDown={handleEditKeyDown}
            size="small"
            className={isTextField ? null : classes.titleEdit}
          />
        </Box>
      )}
      {showEditingButtons && (
        <RowBox flexGrow={1} alignItems="center" justifyContent="flex-end">
          {!editValue && (
            <Tooltip title="Edit tag">
              <IconButton onClick={handleEdit} color="default" className={classes.iconButton} disabled={!editable}>
                <Icon name="edit" />
              </IconButton>
            </Tooltip>
          )}
          {editValue && (
            <>
              <Tooltip title={<Trans>Done</Trans>}>
                <IconButton onClick={handleEnd} className={`${classes.iconButton} ${classes.doneButton}`}>
                  <Icon name="checkbox" />
                </IconButton>
              </Tooltip>
              <Tooltip title={<Trans>Cancel</Trans>}>
                <IconButton onClick={handleCancel} color="secondary" className={classes.iconButton}>
                  <Icon name="clear" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </RowBox>
      )}
    </RowBox>
  )
}

InlineEditTextField.propTypes = {
  /**
   * An additional icon after title text.
   */
  titleIcon: PropTypes.element,
  /**
   * Whether to be editable.
   */
  editing: PropTypes.bool,
  /**
   * If editable.
   */
  editable: PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string]),
  /**
   * Check if a user has a permission.
   */
  permission: PropTypes.string,
  /**
   * Callback fired when entering editable state.
   */
  onStart: PropTypes.func,
  /**
   * Callback fired when the cancel button is clicked.
   */
  onCancel: PropTypes.func,
  /**
   * Callback fired when exiting editable state.
   */
  onEnd: PropTypes.func,
  /**
   * Show input as text field component.
   */
  isTextField: PropTypes.bool,
  /**
   * Hide editing button when it is false.
   */
  showEditingButtons: PropTypes.bool,
  /**
   * End editing on blur.
   */
  endOnBlur: PropTypes.bool,
}

export { InlineEditTextField }
