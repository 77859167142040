import React from "react"
import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  frame: {
    ...theme.cards.viewerCard,
    padding: theme.spacing(2, 2),
    margin: theme.spacing(1, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 1),

      "& div": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
}))

const StepContentFrame = ({ children }) => {
  const classes = useStyles()

  return <Box className={classes.frame}>{children}</Box>
}

export { StepContentFrame }
