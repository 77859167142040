import React, { useState } from "react"
import { Box, makeStyles, IconButton, TextField } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { FieldSectionHeading, Icon, RowBox } from "../.."
import { CreatorContentMultiChoiceAnswer } from "./CreatorContentMultiChoiceAnswer"

const useStyles = makeStyles((theme) => ({
  fieldGroup: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    padding: theme.spacing(2),
  },
}))

const maxIncorrectAnswers = 10

const CreatorContentMultiChoice = ({ content, onUpdateContent }) => {
  const classes = useStyles()
  const [draftIncorrectAnswerText, setDraftIncorrectAnswerText] = useState("")

  const correctAnswer = content.answers.find((answer) => {
    return answer.isCorrect === true
  })

  const incorrectAnswers = content.answers
    .filter((answer) => {
      return answer.isCorrect !== true
    })
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1
      }
      if (a.id > b.id) {
        return 1
      }
      return 0
    })

  const handleQuestionChange = (event) => {
    onUpdateContent("question", event.target.value)
  }

  /**
   * Remove all "correct" Answers from the array (should only be one) then
   * append our new Answer to the end of the array
   */
  const handleCorrectAnswerChange = (event) => {
    const updatedAnswers = content.answers
      .filter((answer) => {
        return answer.isCorrect !== true
      })
      .concat([
        {
          ...correctAnswer,
          text: event.target.value,
        },
      ])

    onUpdateContent("answers", updatedAnswers)
  }

  const handleIncorrectAnswerChange = (changedAnswer, event) => {
    const updatedAnswers = content.answers
      .filter((answer) => {
        return answer.id !== changedAnswer.id
      })
      .concat([
        {
          ...changedAnswer,
          text: event.target.value,
        },
      ])

    onUpdateContent("answers", updatedAnswers)
  }

  const handleIncorrectAnswerDeleted = (deletedAnswer) => {
    const updatedAnswers = content.answers.filter((answer) => {
      return answer.id !== deletedAnswer.id
    })

    onUpdateContent("answers", updatedAnswers)
  }

  const handleDraftIncorrectAnswerTextChange = (event) => {
    setDraftIncorrectAnswerText(event.target.value)
  }

  const handleDraftIncorrectAnswerKeyPress = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAddIncorrectAnswer()
    }
  }

  const handleAddIncorrectAnswer = () => {
    const updatedAnswers = content.answers.concat([
      {
        id: `new_${content.answers.length.toString()}`,
        text: draftIncorrectAnswerText,
        isCorrect: false,
      },
    ])

    onUpdateContent("answers", updatedAnswers)
    setDraftIncorrectAnswerText("")
  }

  const correctAnswerMatchesIncorrect = Boolean(
    correctAnswer?.text && incorrectAnswers.find((answer) => answer.text === correctAnswer.text),
  )
  const draftIncorrectMatchesCorrect = Boolean(
    draftIncorrectAnswerText && draftIncorrectAnswerText === correctAnswer?.text,
  )

  return (
    <Box>
      <FieldSectionHeading mb={2}>
        <Trans>Quiz question &amp; correct answer</Trans>
      </FieldSectionHeading>

      <Box mb={2}>
        <TextField
          label={<Trans>What is the Question?</Trans>}
          fullWidth
          variant="outlined"
          kind="text"
          value={content.question}
          onChange={handleQuestionChange}
          required
        />
      </Box>

      <Box my={2}>
        <TextField
          label={<Trans>What is the correct Answer?</Trans>}
          fullWidth
          variant="outlined"
          kind="text"
          value={correctAnswer.text}
          onChange={handleCorrectAnswerChange}
          error={correctAnswerMatchesIncorrect}
          helperText={correctAnswerMatchesIncorrect ? <Trans>This answer matches an incorrect answer</Trans> : ""}
          required
        />
      </Box>

      <FieldSectionHeading mb={2}>
        <Trans>Incorrect answers</Trans>
      </FieldSectionHeading>

      <Box className={classes.fieldGroup} mt={2} pt={1}>
        {incorrectAnswers.length > 0 && (
          <Box mb={2}>
            {incorrectAnswers.map((answer) => (
              <CreatorContentMultiChoiceAnswer
                key={answer.id}
                answer={answer}
                onChange={handleIncorrectAnswerChange}
                onDeleted={handleIncorrectAnswerDeleted}
              />
            ))}
          </Box>
        )}

        {incorrectAnswers.length < maxIncorrectAnswers && (
          <>
            <FieldSectionHeading mb={2}>
              <Trans>Add incorrect answers ({maxIncorrectAnswers} maximum)</Trans>
            </FieldSectionHeading>

            <RowBox mt={2} alignItems="flex-start">
              <TextField
                fullWidth
                label={<Trans>Enter an incorrect answer then click +</Trans>}
                variant="outlined"
                kind="text"
                value={draftIncorrectAnswerText}
                onChange={handleDraftIncorrectAnswerTextChange}
                onKeyPress={handleDraftIncorrectAnswerKeyPress}
                error={draftIncorrectMatchesCorrect}
                helperText={draftIncorrectMatchesCorrect ? <Trans>This answer matches the correct answer</Trans> : ""}
              />
              <Box mt={0.5}>
                <IconButton
                  onClick={handleAddIncorrectAnswer}
                  disabled={!draftIncorrectAnswerText || draftIncorrectMatchesCorrect}
                  size="medium"
                >
                  <Icon name="add" />
                </IconButton>
              </Box>
            </RowBox>
          </>
        )}
      </Box>
    </Box>
  )
}

export { CreatorContentMultiChoice }
