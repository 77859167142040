import { useQuery, useLazyQuery, gql } from "@apollo/client"

const REPORT_JOB_DETAIL_PDF_QUERY = gql`
  query ReportJobDetailPdf($id: ID!, $filter: ReportJobExportOptions!) {
    report {
      job(id: $id) {
        detail {
          pdf(filter: $filter) {
            message
            fileName
            exportUrl
          }
        }
      }
    }
  }
`

const useQueryReportJobDetailPdf = (options) =>
  useQuery(REPORT_JOB_DETAIL_PDF_QUERY, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    ...options,
  })

const useLazyQueryReportJobDetailPdf = (options) =>
  useLazyQuery(REPORT_JOB_DETAIL_PDF_QUERY, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    ...options,
  })

export { useQueryReportJobDetailPdf, useLazyQueryReportJobDetailPdf }
