import React from "react"
import { Divider, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import { KNOWLEDGE_CONTENT_TYPENAMES } from "../../../data"
import { DownloadList } from "../../Uploader"
import { RowBox } from "../../Boxes"
import { KnowledgeArticleContentImage } from "./ArticleContentImage"
import { KnowledgeArticleContentVideo } from "./ArticleContentVideo"
import { KnowledgeArticleContentText } from "./ArticleContentText"
import { KnowledgeArticleContentMultichoice } from "./ArticleContentMultichoice"
import { useDeviceUtils } from "../../../utils"

const { TEXT, IMAGE, FILES, NOTE, VIDEO, MULTICHOICE } = KNOWLEDGE_CONTENT_TYPENAMES

const useContentStyles = makeStyles((theme) => ({
  description: {
    fontSize: 16,
    lineHeight: "20px",
    whiteSpace: "pre-wrap",
  },
  displayContent: {
    fontSize: 16,
    lineHeight: "24px",
    wordBreak: "break-word",
    marginBottom: theme.spacing(4),
  },
  displayHeading: {
    fontSize: 20,
    fontWeight: "600",
  },
}))

const ArticleContent = ({ knowledge, content, onArticleCompleted, canViewMultipleChoicesQuestion }) => {
  const classes = useContentStyles()

  const { __typename } = content

  // Render the content of the article depending on what the type is set to.
  return (() => {
    switch (__typename) {
      case TEXT:
        return <DisplayText classes={classes} content={content} />
      case IMAGE:
        return <DisplayImage classes={classes} content={content} />
      case NOTE:
        return <DisplayNote classes={classes} content={content} />
      case FILES:
        return <DisplayFiles classes={classes} content={content} />
      case VIDEO:
        return <DisplayVideo classes={classes} content={content} />
      case MULTICHOICE:
        // Only specific users should be able to see multichoice questions
        if (!canViewMultipleChoicesQuestion) return null

        return (
          <DisplayMultichoice
            classes={classes}
            knowledge={knowledge}
            content={content}
            onArticleCompleted={onArticleCompleted}
          />
        )
      default:
        throw new Error(`Unable to determine type of knowledge article content, was: ${__typename}`)
    }
  })()
}

const DisplayText = ({ classes, content: { id, heading, text } }) => (
  <Box key={id} id={id} className={classes.displayContent}>
    {heading && (
      <Box mb={2} className={classes.displayHeading}>
        {heading}
      </Box>
    )}
    <KnowledgeArticleContentText value={text} />
  </Box>
)

const DisplayImage = ({ classes, content }) => {
  const { canPostMessage, postImagesMessage } = useDeviceUtils()
  const { id, heading, uploads } = content

  const handleImageClick = (index) => {
    if (!canPostMessage()) return

    postImagesMessage(uploads, index)
  }

  if (!heading && !uploads?.length) {
    return null
  }

  return (
    <Box key={id} id={id} className={classes.displayContent}>
      {heading && (
        <Box mb={2} className={classes.displayHeading}>
          {heading}
        </Box>
      )}
      {uploads.map((upload, index) => (
        <KnowledgeArticleContentImage key={upload.id} upload={upload} onImageClick={() => handleImageClick(index)} />
      ))}
    </Box>
  )
}

const DisplayFiles = ({ classes, content }) => {
  const { id, heading, uploads } = content

  if (!heading && uploads.length === 0) {
    return null
  }

  return (
    <Box key={id} id={id} className={classes.displayContent}>
      {heading && (
        <>
          <Box mb={2} className={classes.displayHeading}>
            {heading}
          </Box>
          <Box mb={3}>
            <Divider />
          </Box>
        </>
      )}
      <DownloadList uploads={uploads} variant="cards" />
    </Box>
  )
}

const DisplayNote = ({ classes, content }) => {
  const { id, type, heading, text } = content

  if (!heading && !text) {
    return null
  }

  const getAlertType = () => {
    switch (type) {
      case "important":
        return "warning"
      case "danger":
        return "error"
      default:
        return "info"
    }
  }

  return (
    <Box key={id} id={id} className={classes.displayContent}>
      <Alert icon={false} severity={getAlertType()}>
        <AlertTitle>{heading}</AlertTitle>
        <span className={classes.displayContent}>{text}</span>
      </Alert>
    </Box>
  )
}

const DisplayVideo = ({ classes, content }) => {
  const { id, heading, url } = content

  if (!heading && !url) {
    return null
  }

  return (
    <Box key={id} id={id} className={classes.displayContent}>
      {heading && (
        <Box mb={2} className={classes.displayHeading}>
          {heading}
        </Box>
      )}

      {url && (
        <RowBox justifyContent="center">
          <KnowledgeArticleContentVideo value={url} />
        </RowBox>
      )}
    </Box>
  )
}

const DisplayMultichoice = ({ classes, knowledge, content, onArticleCompleted }) => {
  const { id, heading, answers } = content

  if (!heading && !answers) {
    return null
  }

  return (
    <Box key={id} id={id} className={classes.displayContent}>
      {heading && (
        <Box mb={2} className={classes.displayHeading}>
          {heading}
        </Box>
      )}

      {content && (
        <KnowledgeArticleContentMultichoice knowledge={knowledge} content={content} onCompleted={onArticleCompleted} />
      )}
    </Box>
  )
}

export { ArticleContent }
