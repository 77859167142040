import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_171)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C3.55228 2 4 2.44772 4 3V28H29C29.5523 28 30 28.4477 30 29C30 29.5523 29.5523 30 29 30H3C2.44772 30 2 29.5523 2 29V3C2 2.44772 2.44772 2 3 2Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16C6 15.4477 6.44772 15 7 15H11C11.5523 15 12 15.4477 12 16V26C12 26.5523 11.5523 27 11 27C10.4477 27 10 26.5523 10 26V17H8V26C8 26.5523 7.55228 27 7 27C6.44772 27 6 26.5523 6 26V16Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 20C22 19.4477 22.4477 19 23 19H27C27.5523 19 28 19.4477 28 20V26C28 26.5523 27.5523 27 27 27C26.4477 27 26 26.5523 26 26V21H24V26C24 26.5523 23.5523 27 23 27C22.4477 27 22 26.5523 22 26V20Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9C14 8.44772 14.4477 8 15 8H19C19.5523 8 20 8.44772 20 9V26C20 26.5523 19.5523 27 19 27C18.4477 27 18 26.5523 18 26V10H16V26C16 26.5523 15.5523 27 15 27C14.4477 27 14 26.5523 14 26V9Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_171" x1="20" y1="8" x2="20" y2="27" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
