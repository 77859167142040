import React from "react"
import { makeStyles, Box } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: "600",
    textAlign: "center",
    color: theme.palette.text.secondary,
    opacity: 0.7,
  },
}))

const NoItemsMessage = ({ children, className = "", p = 3, ...rest }) => {
  const classes = useStyles()

  return (
    <Box
      className={`${classes.root} ${className}`}
      display="flex"
      p={p}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      {...rest}
    >
      {children}
    </Box>
  )
}

export { NoItemsMessage }
