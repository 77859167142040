import { ListItemIcon, ListItemText, makeStyles, MenuItem } from "@material-ui/core"
import React, { forwardRef, memo } from "react"

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      background: theme.palette.action.hover,
    },
    "&:focus": {
      background: theme.palette.action.focus,
    },
  },
  menuUser: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: "600",
    color: theme.palette.text.primary,
  },
  menuLight: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const HeaderMenuItem = forwardRef(function HeaderMenuItem(
  { onClick, icon, text, children, disabled, divider, autoFocus },
  ref
) {
  const classes = useStyles()

  const handleClick = (event) => {
    onClick && onClick(event)
  }

  return (
    <MenuItem
      ref={ref}
      autoFocus={autoFocus}
      divider={divider ? true : undefined}
      className={classes.menuItem}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
      {!children && (
        <>
          <ListItemIcon className={classes.menuListItemIcon}>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </>
      )}
    </MenuItem>
  )
})

const MemoHeaderMenuItem = memo(HeaderMenuItem)

export { MemoHeaderMenuItem as HeaderMenuItem }
