import React, { useState } from "react"
import { Button, makeStyles } from "@material-ui/core"
import { SpeedDialAction } from "@material-ui/lab"
import { Trans, t } from "@lingui/macro"
import { FloatingSpeedDial, Icon, RequirePermissions } from ".."

const requires = "food_item_create"

const useSpeedDialActionStyles = makeStyles(() => ({
  staticTooltipLabel: {
    whiteSpace: "nowrap",
  },
}))

const DesktopCreateFoodItemsButton = ({ onCreate }) => {
  const handleCreate = () => {
    onCreate && onCreate()
  }

  return (
    <RequirePermissions operator="or" requires={requires} features="food">
      <Button onClick={handleCreate} variant="contained" color="primary" data-cy="Button-create-asset">
        <Trans>Create</Trans>
      </Button>
    </RequirePermissions>
  )
}

const MobileCreateFoodItemsButton = ({ creatorOpen, onCreate }) => {
  const classes = useSpeedDialActionStyles()
  const [openSpeedDial, setOpenSpeedDial] = useState(false)

  const handleCreate = () => {
    onCreate && onCreate()
  }

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  return (
    <RequirePermissions operator="or" requires={requires} features="food">
      {!creatorOpen && (
        <FloatingSpeedDial onClose={handleToggleSpeedDial} onOpen={handleToggleSpeedDial} open={openSpeedDial}>
          <SpeedDialAction
            tooltipTitle={t`Create a new food item`}
            icon={<Icon name="add" />}
            tooltipOpen
            onClick={handleCreate}
            classes={classes}
          />
        </FloatingSpeedDial>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateFoodItemsButton, MobileCreateFoodItemsButton }
