import React, { useState } from "react"
import { Box, Chip, Divider, makeStyles, Typography } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { FlexBox, PaperBox, RowBox } from "../Boxes"
import { LinearProgress } from "../LinearProgress"
import { USER_TRAINING_MODULE_STATUS } from "../../data"
import { Icon } from ".."
import TimeAgo from "../TimeAgo"
import { toId } from "../../utils"
import { TrainingScoreChip } from "../Chips/TrainingScoreChip"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(1),
    cursor: ({ completed }) => (completed ? "pointer" : "default"),
  },
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: ({ status }) =>
      status > USER_TRAINING_MODULE_STATUS.INCOMPLETE ? theme.palette.text.primary : theme.palette.text.faint,
  },
  progress: {
    width: 100,
  },
  expander: {
    cursor: "pointer",
  },
  completedChip: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  caption: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    textAlign: "right",
    whiteSpace: "nowrap",
  },
  iconInProgress: {
    fontSize: 18,
    fill: theme.palette.text.secondary,
  },
  iconCompleted: {
    fontSize: 18,
    fill: theme.palette.success.main,
  },
  iconNavigate: {
    fontSize: 18,
    fill: theme.palette.text.secondary,
  },
  iconAnswerTick: {
    fontSize: 16,
    fill: theme.palette.success.main,
    marginBottom: 2,
  },
  iconAnswerCross: {
    fontSize: 16,
    fill: theme.palette.error.main,
    marginBottom: 2,
  },
  activeModule: {
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      "&:last-child": {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
      },
    },
  },
  disabledModule: {
    color: theme.palette.text.faint,
  },
  answer: {
    paddingLeft: theme.spacing(5),
  },
  iconQuestion: {
    fontSize: 18,
  },
  knowledge: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))

const TrainingKnowledge = ({ activeKnowledge, moduleKnowledge, showAnswers }) => {
  const {
    knowledge: { id, title },
    answers,
    completedAt,
  } = moduleKnowledge

  const classes = useStyles()
  const completed = Boolean(completedAt)
  const disabled = !completed && toId(moduleKnowledge.knowledge) !== toId(activeKnowledge)
  const className = disabled ? classes.disabledModule : classes.activeModule

  return (
    <>
      <RowBox
        component={!disabled ? NavLink : null}
        to={`/knowledge/article/${id}`}
        pl={2}
        pr={1}
        py={1}
        className={className}
        data-cy="TrainingKnowledge"
      >
        {!disabled && (
          <RowBox mr={1}>
            {!completed && <Icon name="forward" className={classes.iconInProgress} />}
            {completed && <Icon name="training-module-complete" className={classes.iconCompleted} />}
          </RowBox>
        )}
        <Box>{title}</Box>
        {!disabled && (
          <RowBox ml="auto">
            {!completed && (
              <>
                <Box className={classes.caption}>
                  <Trans>View training</Trans>
                </Box>
                <Icon name="right" className={classes.iconNavigate} />
              </>
            )}
            {completed && (
              <Box className={classes.caption} pr={1}>
                <TimeAgo date={completedAt} />
              </Box>
            )}
          </RowBox>
        )}
      </RowBox>
      {showAnswers &&
        answers?.map((answer) => {
          return (
            <RowBox key={toId(answer.answer)} className={classes.answer} my={1}>
              <FlexBox mr={0.5} alignSelf="flex-end">
                {answer.isCorrect && <Icon name="tick" className={classes.iconAnswerTick} alt="Correct" />}
                {!answer.isCorrect && <Icon name="cross" className={classes.iconAnswerCross} alt="Incorrect" />}
              </FlexBox>
              <Box>
                <strong>Q. {answer.questionText}</strong>
                <br />
                A. {answer.answerText}
              </Box>
            </RowBox>
          )
        })}
    </>
  )
}

const TrainingModule = ({
  activeKnowledge,
  name,
  knowledges,
  status,
  percentComplete,
  score,
  completedAt,
  showAnswers,
}) => {
  const completed = status === USER_TRAINING_MODULE_STATUS.COMPLETE
  const active = status === USER_TRAINING_MODULE_STATUS.IN_PROGRESS
  const disabled = status === USER_TRAINING_MODULE_STATUS.INCOMPLETE
  const classes = useStyles({ status, completed })
  const [expanded, setExpanded] = useState(false)

  const handleToggleExpanded = () => {
    if (!completed) {
      return
    }
    setExpanded(!expanded)
  }

  return (
    <PaperBox className={classes.paper} completed={completed} onClick={handleToggleExpanded}>
      <Box p={2}>
        <RowBox>
          <Typography variant="h2" className={classes.title}>
            {name}
          </Typography>
          {!disabled && (
            <>
              {!completed && (
                <RowBox ml="auto">
                  <Box mr={1} className={classes.caption}>
                    {knowledges.filter((knowledge) => knowledge.completedAt).length} of {knowledges.length}
                  </Box>
                  <Box className={classes.progress}>
                    <LinearProgress value={percentComplete} />
                  </Box>
                </RowBox>
              )}
              {completed && (
                <RowBox ml="auto">
                  {showAnswers && score.total > 0 && (
                    <Box mx={1}>
                      <TrainingScoreChip tooltip={<Trans>Module score</Trans>} label={`${score.percent}%`} />
                    </Box>
                  )}
                  <Box className={classes.caption} pr={1}>
                    <TimeAgo date={completedAt} />
                  </Box>
                  <Chip label={<Trans>Complete</Trans>} size="small" className={classes.completedChip} />
                  {!expanded && <KeyboardArrowDown className={classes.expander} />}
                  {expanded && <KeyboardArrowUp className={classes.expander} />}
                </RowBox>
              )}
            </>
          )}
        </RowBox>
      </Box>
      {(active || expanded) && (
        <>
          <Divider />
          <Box>
            {knowledges.map((moduleKnowledge, index) => (
              <Box key={index} className={classes.knowledge}>
                <TrainingKnowledge
                  activeKnowledge={activeKnowledge}
                  moduleKnowledge={moduleKnowledge}
                  showAnswers={showAnswers}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </PaperBox>
  )
}

const TrainingCurriculum = ({ training, showAnswers = false }) => (
  <Box mb={3}>
    {training.status.modules.map((module) => (
      <TrainingModule
        key={toId(module)}
        activeKnowledge={training.status.activeKnowledge}
        showAnswers={showAnswers}
        {...module}
      />
    ))}
  </Box>
)

export { TrainingCurriculum }
