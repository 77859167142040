import React from "react"

import { TextField, Box, Hidden } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { KnowledgeArticleContentVideo } from "../Article/ArticleContentVideo"

const CreatorContentVideo = ({ content, onUpdateContent }) => {
  return (
    <>
      <TextField
        variant="outlined"
        value={content.url}
        fullWidth
        label={<Trans>Media URL</Trans>}
        onChange={(e) => onUpdateContent("url", e.target.value)}
        helperText={
          <>
            <Trans>Supported</Trans>: YouTube, Vimeo, SoundCloud, Mixcloud, Loom
          </>
        }
      />
      {content.url && (
        <Hidden smDown>
          <Box mt={1}>
            <KnowledgeArticleContentVideo value={content.url} />
          </Box>
        </Hidden>
      )}
    </>
  )
}

export { CreatorContentVideo }
