import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2599)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 11H7C6.448 11 6 10.553 6 10V6C6 5.447 6.448 5 7 5H16C16.552 5 17 5.447 17 6V10C17 10.553 16.552 11 16 11ZM8 9H15V7H8V9Z"
      fill={secondary}
    />
    <path
      d="M10 19H7C6.448 19 6 18.553 6 18V14C6 13.447 6.448 13 7 13H16C16.552 13 17 13.447 17 14C17 14.553 16.552 15 16 15H8V17H10C10.552 17 11 17.447 11 18C11 18.553 10.552 19 10 19Z"
      fill={secondary}
    />
    <path
      d="M10 27H7C6.448 27 6 26.553 6 26V22C6 21.447 6.448 21 7 21H10C10.552 21 11 21.447 11 22V26C11 26.553 10.552 27 10 27ZM8 25H9V23H8V25Z"
      fill={secondary}
    />
    <path
      d="M18 17H14C13.448 17 13 17.447 13 18C13 18.553 13.448 19 14 19H18C18.552 19 19 18.553 19 18C19 17.447 18.552 17 18 17Z"
      fill={primary}
    />
    <path
      d="M18 21H14C13.448 21 13 21.447 13 22C13 22.553 13.448 23 14 23H18C18.552 23 19 22.553 19 22C19 21.447 18.552 21 18 21Z"
      fill={primary}
    />
    <path
      d="M18 25H14C13.448 25 13 25.447 13 26C13 26.553 13.448 27 14 27H18C18.552 27 19 26.553 19 26C19 25.447 18.552 25 18 25Z"
      fill={primary}
    />
    <path
      d="M25 17H21C20.448 17 20 17.447 20 18C20 18.553 20.448 19 21 19H25C25.553 19 26 18.553 26 18C26 17.447 25.553 17 25 17Z"
      fill={primary}
    />
    <path
      d="M25 21H21C20.448 21 20 21.447 20 22C20 22.553 20.448 23 21 23H25C25.553 23 26 22.553 26 22C26 21.447 25.553 21 25 21Z"
      fill={primary}
    />
    <path
      d="M25 25H21C20.448 25 20 25.447 20 26C20 26.553 20.448 27 21 27H25C25.553 27 26 26.553 26 26C26 25.447 25.553 25 25 25Z"
      fill={primary}
    />
    <path
      d="M30 29V16C30 14.346 28.654 13 27 13H20V4C20 2.346 18.654 1 17 1H6C4.346 1 3 2.346 3 4V29H2C1.448 29 1 29.447 1 30C1 30.553 1.448 31 2 31H30C30.553 31 31 30.553 31 30C31 29.447 30.553 29 30 29ZM9 16V29H5V4C5 3.448 5.449 3 6 3H17C17.551 3 18 3.448 18 4V13H12C10.346 13 9 14.346 9 16ZM28 29H11V16C11 15.448 11.449 15 12 15H27C27.552 15 28 15.448 28 16V29Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_722_2599" x1="17" y1="11" x2="6" y2="11" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_722_2599" x1="17" y1="19" x2="6" y2="19" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint2_linear_722_2599" x1="11" y1="27" x2="6" y2="27" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
