import React, { createContext, useState } from "react"

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [customPrimaryColor, setCustomPrimary] = useState(null)
  const [customSecondaryColor, setCustomSecondary] = useState(null)

  const changePrimary = (value) => {
    setCustomPrimary(value)
  }

  const changeSecondary = (value) => {
    setCustomSecondary(value)
  }

  const contextValue = React.useMemo(
    () => ({
      customPrimaryColor,
      changePrimary,
      customSecondaryColor,
      changeSecondary,
    }),
    [customPrimaryColor, customSecondaryColor],
  )

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
}
