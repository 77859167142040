import React, { memo } from "react"
import { makeStyles } from "@material-ui/styles"
import { Button, Chip } from "@material-ui/core"
import { USER_TRAINING_ASSESSMENT_STATUS, USER_TRAINING_ASSESSMENT_STATUS_LABELS } from "../../data"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    cursor: "pointer",
  },
}))

const useStatusStyles = makeStyles((theme) => ({
  scheduled: {
    backgroundColor: theme.palette.scheduled.main,
  },
  competent: {
    backgroundColor: theme.palette.success.main,
  },
  needsTraining: {
    backgroundColor: theme.palette.error.main,
  },
  trained: {
    backgroundColor: theme.palette.pending.main,
  },
  none: {
    fill: theme.palette.text.faint,
    cursor: "pointer",
  },
}))

const UserTrainingAssessmentStatusChip = memo(function UserTrainingAssessmentStatusChip({ assessment, ...rest }) {
  const classes = useStyles()
  const statusClasses = useStatusStyles()

  const none = <Icon name="none" className={statusClasses.none} {...rest} />

  if (!assessment) {
    return none
  }

  switch (assessment.status) {
    case USER_TRAINING_ASSESSMENT_STATUS.SCHEDULED:
      return (
        <Chip
          label={USER_TRAINING_ASSESSMENT_STATUS_LABELS[USER_TRAINING_ASSESSMENT_STATUS.SCHEDULED]}
          classes={classes}
          className={statusClasses.scheduled}
          {...rest}
        />
      )
    case USER_TRAINING_ASSESSMENT_STATUS.COMPETENT:
      return (
        <Chip
          label={USER_TRAINING_ASSESSMENT_STATUS_LABELS[USER_TRAINING_ASSESSMENT_STATUS.COMPETENT]}
          classes={classes}
          className={statusClasses.competent}
          {...rest}
        />
      )
    case USER_TRAINING_ASSESSMENT_STATUS.NEEDS_TRAINING:
      return (
        <Chip
          label={USER_TRAINING_ASSESSMENT_STATUS_LABELS[USER_TRAINING_ASSESSMENT_STATUS.NEEDS_TRAINING]}
          classes={classes}
          className={statusClasses.needsTraining}
          {...rest}
        />
      )
    case USER_TRAINING_ASSESSMENT_STATUS.TRAINED:
      return (
        <Chip
          label={USER_TRAINING_ASSESSMENT_STATUS_LABELS[USER_TRAINING_ASSESSMENT_STATUS.TRAINED]}
          classes={classes}
          className={statusClasses.trained}
          {...rest}
        />
      )
    default:
      return none
  }
})

const UserTrainingAssessmentStatusChipButton = ({ onClick, ...rest }) => (
  <Button onClick={onClick}>
    <UserTrainingAssessmentStatusChip {...rest} />
  </Button>
)

export { UserTrainingAssessmentStatusChip, UserTrainingAssessmentStatusChipButton }
