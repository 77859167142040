import React, { useState } from "react"
import { Box, Paper, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Assignees } from "./Assignees"
import { AssigneeAvatars } from "./AssigneeAvatars"
import { ExpansionPanel, SectionTitle, InfoSection } from ".."
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  infoLight: {
    color: theme.palette.text.secondary,
  },
}))

const AssigneeDetailer = ({ users, groups, expansionClasses }) => {
  const classes = useStyles()
  const [expand, setExpand] = useState(false)

  return (
    <>
      <Hidden xsDown>
        <Box mb={5}>
          <SectionTitle>Assignees</SectionTitle>
          <Paper elevation={0}>
            <InfoSection variant="info">
              <Box mr={1}>
                <AssigneeAvatars {...{ users, groups }} />
              </Box>
              <Assignees {...{ users, groups }} className={classes.infoLight} />
            </InfoSection>
          </Paper>
        </Box>
      </Hidden>
      <Hidden smUp>
        <ExpansionPanel
          title="Assignees"
          description={<Assignees {...{ users, groups }} className={classes.infoLight} />}
          expanded={expand}
          onChange={() => setExpand(!expand)}
          icon={<Icon name="group" />}
          classes={expansionClasses}
        >
          <AssigneeAvatars {...{ users, groups }} />
          <Assignees {...{ users, groups }} className={classes.infoLight} />
        </ExpansionPanel>
      </Hidden>
    </>
  )
}

export { AssigneeDetailer }
