import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2708)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.626 7.361C5.66298 7.361 1.62598 11.398 1.62598 16.361C1.62598 21.324 5.66298 25.361 10.626 25.361C15.589 25.361 19.626 21.324 19.626 16.361C19.626 11.398 15.589 7.361 10.626 7.361ZM7.56698 22.648C7.59698 22.552 7.62598 22.454 7.62598 22.348C7.62598 22.271 7.63298 20.437 9.25098 19.143C9.26198 19.134 9.26698 19.121 9.27698 19.111C9.69998 19.264 10.15 19.361 10.626 19.361C11.102 19.361 11.552 19.263 11.975 19.11C11.986 19.119 11.99 19.133 12.002 19.142C13.612 20.43 13.627 22.252 13.626 22.345C13.624 22.452 13.652 22.551 13.682 22.648C12.757 23.1 11.723 23.36 10.626 23.36C9.52898 23.36 8.49298 23.099 7.56698 22.647V22.648ZM10.626 17.361C9.52298 17.361 8.62598 16.464 8.62598 15.361C8.62598 14.258 9.52298 13.361 10.626 13.361C11.729 13.361 12.626 14.258 12.626 15.361C12.626 16.464 11.729 17.361 10.626 17.361ZM15.517 21.359C15.34 20.449 14.876 19.092 13.66 17.938C14.254 17.24 14.626 16.348 14.626 15.361C14.626 13.155 12.832 11.361 10.626 11.361C8.41998 11.361 6.62598 13.155 6.62598 15.361C6.62598 16.347 6.99798 17.239 7.59198 17.937C6.37598 19.092 5.91198 20.449 5.73498 21.359C4.43598 20.087 3.62598 18.318 3.62598 16.361C3.62598 12.502 6.76698 9.361 10.626 9.361C14.485 9.361 17.626 12.502 17.626 16.361C17.626 18.318 16.816 20.088 15.517 21.359Z"
      fill={primary}
    />
    <path
      d="M29 30H5.99998C5.44698 30 4.99998 29.553 4.99998 29V26.5C4.99998 25.947 5.44698 25.5 5.99998 25.5C6.55298 25.5 6.99998 25.947 6.99998 26.5V28H28V4H6.99998V5.5C6.99998 6.053 6.55298 6.5 5.99998 6.5C5.44698 6.5 4.99998 6.053 4.99998 5.5V3C4.99998 2.447 5.44698 2 5.99998 2H29C29.553 2 30 2.447 30 3V29C30 29.553 29.553 30 29 30Z"
      fill={primary}
    />
    <path
      d="M25.233 9H20C19.447 9 19 8.553 19 8C19 7.447 19.447 7 20 7H25.233C25.786 7 26.233 7.447 26.233 8C26.233 8.553 25.786 9 25.233 9Z"
      fill={secondary}
    />
    <path
      d="M25.233 13H22C21.447 13 21 12.553 21 12C21 11.447 21.447 11 22 11H25.233C25.786 11 26.233 11.447 26.233 12C26.233 12.553 25.786 13 25.233 13Z"
      fill={secondary}
    />
    <path
      d="M25.233 17H23C22.447 17 22 16.553 22 16C22 15.447 22.447 15 23 15H25.233C25.786 15 26.233 15.447 26.233 16C26.233 16.553 25.786 17 25.233 17Z"
      fill={secondary}
    />
    <path
      d="M25.233 21H22C21.447 21 21 20.553 21 20C21 19.447 21.447 19 22 19H25.233C25.786 19 26.233 19.447 26.233 20C26.233 20.553 25.786 21 25.233 21Z"
      fill={secondary}
    />
    <path
      d="M25.233 25H20C19.447 25 19 24.553 19 24C19 23.447 19.447 23 20 23H25.233C25.786 23 26.233 23.447 26.233 24C26.233 24.553 25.786 25 25.233 25Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_722_2708" x1="26.233" y1="25" x2="19" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_722_2708" x1="26.233" y1="25" x2="19" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint2_linear_722_2708" x1="26.233" y1="25" x2="19" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint3_linear_722_2708" x1="26.233" y1="25" x2="19" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint4_linear_722_2708" x1="26.233" y1="25" x2="19" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
