import React, { useMemo, useState } from "react"
import { Box, Card, CardContent, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { t, Trans } from "@lingui/macro"
import { ActionHeader } from "./ActionHeader"
import { ActionContent } from "./ActionContent"
import { CommentList } from "./CommentList"
import { AddComment } from "./AddComment"
import { InfoSection, ActionCreator, RowBox, Icon, FormatDateTimeCompact, TimeAgo } from ".."
import { useJobUtils } from "../../utils"
import { ACTION_PRIORITY, ACTION_PRIORITY_LABELS } from "../../data"
import { ActionStatus } from "./ActionStatus"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const useStyles = makeStyles((theme) => ({
  action: {
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  actionOverdue: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  content: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  section: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  compactSection: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionHighlight: {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.grey[50],
  },
  sectionHighlightOverdue: {
    backgroundColor: theme.palette.error.background,
  },
}))

const Action = ({ action, onDelete, onCommentCreated, onStatusChange }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })
  const { canNavigateToJob, getNavigateToLink } = useJobUtils()
  const [edit, setEdit] = useState(false)
  const { location, job, jobStep: step } = action

  const handleEdit = () => {
    setEdit(true)
  }
  const handleClose = () => {
    setEdit(false)
  }

  const jobLink = useMemo(
    () => (job && canNavigateToJob(job) ? getNavigateToLink(job) : null),
    [canNavigateToJob, getNavigateToLink, job],
  )

  const hasLocation = Boolean(location)
  const hasJob = Boolean(job)
  const hasJobStep = Boolean(step)

  return (
    <>
      {edit && <ActionCreator open={edit} edit={action} job={action.job} step={action.jobStep} onClose={handleClose} />}

      <Card className={[classes.action, action.isOverdue ? classes.actionOverdue : undefined].join(" ")} elevation={0}>
        <CardContent className={classes.content}>
          <div className={`${classes.section} ${classes.sectionHeader}`}>
            <ActionHeader {...{ action, onEdit: handleEdit, onDelete }} />
          </div>
          <div className={classes.section}>
            <ActionContent action={action} />
          </div>
          <div className={classes.compactSection}>
            <InfoSection
              title={<Trans>Priority</Trans>}
              value={
                <RowBox>
                  <Box>{ACTION_PRIORITY_LABELS[action.priority]}</Box>
                </RowBox>
              }
              rightChildren={
                <Icon
                  color={action.priority === ACTION_PRIORITY.CRITICAL ? "secondary" : "inherit"}
                  name={`priority-${action.priority}`}
                  throwForUnmapped={false}
                />
              }
              px={0}
              compact
            />
          </div>
          {hasLocation && (
            <div className={classes.compactSection}>
              <InfoSection title={<Trans>Location</Trans>} value={location.name} px={0} compact />
            </div>
          )}
          {action.dueAt && (
            <div className={classes.compactSection}>
              <InfoSection
                title={<Trans>Due at</Trans>}
                value={
                  <>
                    <FormatDateTimeCompact value={action.dueAt} compactTodayTimeZone={location?.timeZone || null} /> (
                    <TimeAgo date={action.dueAt} />)
                  </>
                }
                px={0}
                compact
              />
            </div>
          )}
          {hasJob && (
            <div className={classes.compactSection}>
              <InfoSection
                title={<Trans>Job</Trans>}
                value={
                  <RowBox component={NavLink} to={jobLink || "/"}>
                    <Box>{job.displayName}</Box>
                  </RowBox>
                }
                px={0}
                compact
              />
            </div>
          )}
          {hasJobStep && (
            <div className={classes.compactSection}>
              <InfoSection title={<Trans>Step</Trans>} value={step.name} px={0} compact />
            </div>
          )}
          {action.tags.length > 0 && (
            <div className={classes.compactSection}>
              <InfoSection
                title={<Trans>Tags</Trans>}
                value={<TruncateNames names={action.tags.map((tag) => tag.name)} max={3} />}
                px={0}
                compact
              />
            </div>
          )}
          <div
            className={[
              classes.compactSection,
              classes.sectionHighlight,
              action.isOverdue ? classes.sectionHighlightOverdue : undefined,
            ].join(" ")}
          >
            <ActionStatus action={action} onChange={onStatusChange} />
          </div>
          <CommentList subject={action} />
          <div className={classes.section}>
            <AddComment action={action} onCommentCreated={onCommentCreated} placeholder={t`Write a note...`} />
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export { Action }
