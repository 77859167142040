import React, { memo } from "react"
import { Box, Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { t } from "@lingui/macro"
import { ActionCard } from "./ActionCard"
import { ActionCardInfo } from "./ActionCardInfo"
import { AuthorTools } from "../Author"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon, AdornmentIcon } from "../Icon"
import { AssigneeAvatars, Assignees } from "../Assignees"

const useAssigneesActionCardInfoStyles = makeStyles(() => ({
  assignees: {
    textAlign: "right",
  },
}))

const ActionCardFooterInfo = ({ value, icon, mr }) => (
  <RowBox mr={mr}>
    <Hidden mdUp>
      <ActionCardInfo>
        <AdornmentIcon name={icon} tooltip={value} />
      </ActionCardInfo>
    </Hidden>
    <Hidden smDown>
      <AdornmentIcon name={icon} />
      <Box ml={1} mr={0}>
        {value}
      </Box>
    </Hidden>
  </RowBox>
)

const AssigneesActionCardInfo = memo(function AssigneesActionCardInfo({ groups, compact }) {
  const classes = useAssigneesActionCardInfoStyles()

  if (!groups?.length) {
    return null
  }

  return (
    <>
      {!compact && (
        <Box className={classes.assignees}>
          <Assignees groups={groups} limit={1} />
        </Box>
      )}
      <Box ml={1}>
        <AssigneeAvatars groups={groups} limit={1} />
      </Box>
    </>
  )
})

const AccreditationActionCard = ({ accreditation, onEdit, onDelete, detail, ...rest }) => {
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))
  const { id, name, description, icon, locations, groups, expires, expiringWarningMonths } = accreditation

  const handleEdit = () => {
    if (onEdit) onEdit(accreditation)
  }
  const handleDelete = () => {
    if (onDelete) onDelete(accreditation)
  }

  return (
    <ActionCard
      key={id}
      title={name}
      detail={description || <>&nbsp;</>}
      stub={
        <ColumnBox mr={1} alignSelf="flex-start">
          <Icon name="custom" customName={icon} className="material-icons-outlined" style={{ fontSize: 24 }} />
        </ColumnBox>
      }
      cursor={null}
      rightChildren={
        <AuthorTools
          item={accreditation}
          admins={{
            edit: "accreditation_update",
            delete: "devices_delete",
          }}
          onEdit={handleEdit}
          subject="device"
          onDelete={handleDelete}
        />
      }
      footer={
        <>
          {!expires && <ActionCardFooterInfo value={t`Never expires`} icon="no-expiry" mr={small ? 1 : 3} />}
          {expires && <ActionCardFooterInfo value={t`Expires`} icon="date" mr={small ? 1 : 3} />}
          {expires && Boolean(expiringWarningMonths) && (
            <ActionCardFooterInfo
              value={`Warning ${expiringWarningMonths}mth${expiringWarningMonths > 1 ? "s" : ""} before`}
              icon="warning"
              mr={small ? 1 : 3}
            />
          )}
          <RowBox ml="auto">
            <AssigneesActionCardInfo locations={locations} groups={groups} compact={small} />
          </RowBox>
        </>
      }
      {...rest}
    />
  )
}

export { AccreditationActionCard }
