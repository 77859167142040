import React, { useEffect, useRef, useState } from "react"
import { Box, TextField } from "@material-ui/core"
import { t } from "@lingui/macro"
import { CreatorActions, CreatorMaster } from "../Creators"
import { mapToIds, toId, useFormUtils } from "../../utils"
import { MentionTextInput } from "../Mentions"
import { LocationsAssigner } from "../Assigners"
import { useMutationCreateFoodItem, useMutationUpdateFoodItem } from "../../data/food-items/useMutationFoodItem"
import { useQueryFoodItem } from "../../data"

const initialState = {
  name: "",
  description: "",
  // coolingProfile: {
  //   format: {
  //     unit: "",
  //     decimalPlaces: "",
  //   },
  //   items: [
  //     {
  //       elapsedTime: {
  //         hour: 0,
  //         minute: 0,
  //       },
  //       minTemperature: "",
  //       maxTemperature: "",
  //     },
  //   ],
  // },
  locations: [],
}

const FoodItemCreator = ({ open, id, isInline, onClose }) => {
  const { isValid, touched } = useFormUtils()
  const { data } = useQueryFoodItem({ variables: { id: toId(id) }, skip: !id })
  const descriptionRef = useRef(null)
  const initRef = useRef(false)

  const [createFoodItem] = useMutationCreateFoodItem()
  const [updateFoodItem] = useMutationUpdateFoodItem()
  const [loading, setLoading] = useState(!!id)

  const [name, setName] = useState(initialState.name)
  const [description, setDescription] = useState(initialState.description)
  // const [coolingProfile, setCoolingProfile] = useState(initialState.coolingProfile)
  // const [limits, setLimits] = useState(initialState.coolingProfile.items)
  const [locations, setLocations] = useState(initialState.locations)

  // const optionValue = useMemo(() => {
  //   return processFormats.find((item) => item.name === coolingProfile.format?.unit) || null
  // }, [coolingProfile.format?.unit])

  useEffect(() => {
    if (data?.foodItem) {
      initRef.current = true
      const source = data.foodItem
      setName(source.name)
      setLocations(source.locations)
      // setLimits(source.coolingProfile.items)
      // setCoolingProfile(source.coolingProfile)
      setDescription(source.description)
      setLoading(false)
    }
  }, [data])

  const handleSubmit = async () => {
    if (formValid()) {
      setLoading(true)
      // const { format } = coolingProfile

      // const items = limits.map(({ elapsedTime, minTemperature, maxTemperature }) => ({
      //   elapsedTime,
      //   minTemperature: Number(minTemperature),
      //   maxTemperature: Number(maxTemperature),
      // }))
      const variables = {
        input: {
          name,
          description,
          // coolingProfile: { format: deepRemoveTypenames(format), items: deepRemoveTypenames(items) },
          locations: mapToIds(locations).filter((locId) => locId !== "all"),
        },
      }

      try {
        if (id) {
          await updateFoodItem({ variables: { id: toId(id), ...variables } })
          setLoading(false)
        } else {
          await createFoodItem({ variables })
          setLoading(false)
        }
        handleClose()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleLocationsPickedChanged = (picked) => {
    const pickedLocationIds = mapToIds(picked)

    setLocations([...pickedLocationIds])
  }

  // const handleAddLimit = () => {
  //   setLimits([...limits, ...initialState.coolingProfile.items])
  // }

  // const handleLimitRemove = (index) => {
  //   setLimits((prev) => prev.filter((_, i) => i !== index))
  // }

  // const handleFormatUpdate = (propName, value) => {
  //   const newFormat = { ...coolingProfile.format, [propName]: value }
  //   setCoolingProfile((prev) => ({ ...prev, format: newFormat }))
  // }

  // const handleLimitChange = (index, key, val) => {
  //   setLimits((prev) => {
  //     const newLimits = [...prev]
  //     newLimits[index] = {
  //       ...newLimits[index],
  //       [key]: val,
  //     }
  //     return newLimits
  //   })
  // }

  // const isValidLimits = (listLimit) =>
  //   listLimit?.every(({ minTemperature, maxTemperature }) => {
  //     return minTemperature !== "" && maxTemperature !== ""
  //   })

  // const convertToMinutes = ({ hour, minute }) => hour * 60 + minute

  // const isValidElapsedTime = (listLimit) =>
  //   listLimit?.reduce((isAscending, { elapsedTime }, index, array) => {
  //     if (index < array.length - 1) {
  //       const minutes = convertToMinutes({ ...elapsedTime })
  //       const nextMinutes = convertToMinutes({ ...array[index + 1].elapsedTime })

  //       return isAscending && minutes < nextMinutes
  //     }
  //     return isAscending
  //   }, true)

  const formValid = () => isValid(name)
  // const formValid = () => isValid(name, limits, coolingProfile) && isValidLimits(limits) && isValidElapsedTime(limits)

  const isFormValid = formValid()

  // const unitTemperatures = processFormats.filter((item) => item.type === "Temperature")

  const form = (
    <>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          label={t`Name`}
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoFocus
          required
          data-cy="TextField-name"
          disabled={loading}
        />
      </Box>

      <Box mb={2}>
        <MentionTextInput
          inputRef={descriptionRef}
          label={t`Description`}
          value={description}
          locations={locations}
          height={80}
          onChange={(event) => setDescription(event.target.value)}
          fullWidth
          multiline
          onBlur={() => touched(description, setDescription)}
          cy="FoodItemCreator-content"
        />
      </Box>

      {/* 
      
      // Disabled until we launch cooling probe feature
      
      <Divider />

      <FieldSectionHeading mt={2}>Cooling Profile</FieldSectionHeading>

      <Box mb={2}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="responseFormatUnit"
              value={optionValue}
              getOptionLabel={(option) => option?.display || ""}
              getOptionSelected={(option) => option.name === optionValue.name}
              options={unitTemperatures || []}
              onChange={(event, value) => handleFormatUpdate("unit", value?.name || null)}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Unit" />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedSelect
              label="Decimal places"
              native={false}
              id="responseFormatDecimals"
              value={coolingProfile.format?.decimalPlaces}
              onChange={(e) => handleFormatUpdate("decimalPlaces", e.target.value === "" ? null : e.target.value)}
            >
              <MenuItem value="">No limit</MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </OutlinedSelect>
          </Grid>
        </Grid>
      </Box>

      {limits.length > 0 && (
        <List component="div">
          {limits.map((limit, index) => (
            <FoodItemLimitCreator
              key={toId(index)}
              loading={loading}
              limit={limit}
              onChange={(prop, val) => handleLimitChange(index, prop, val)}
              onDelete={() => handleLimitRemove(index)}
              timePickerProps={{ disabled: index === 0 }}
            />
          ))}
        </List>
      

      <Box mb={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleAddLimit}
          disabled={loading}
          data-cy="Button-add-limit"
        >
          <Icon name="add" /> Add limit
        </Button>
      </Box>

      )} */}

      {(initRef.current || !id) && (
        <Box mb={3}>
          <LocationsAssigner
            initialPicked={locations}
            onPickedChanged={handleLocationsPickedChanged}
            requireTimeZoneMatch={false}
            cy="FoodItemCreator-locations"
          />
        </Box>
      )}

      <CreatorActions
        subject={t`Food Item`}
        submitLoading={loading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject={t`Food Item`}
      form={form}
      isEdit={Boolean(id)}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { FoodItemCreator }
