import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_718_2310)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.04985 10.0498C5.78396 7.31574 10.2171 7.31574 12.9512 10.0498C15.6853 12.784 15.6853 17.2171 12.9512 19.9512C10.2171 22.6853 5.78396 22.6853 3.04985 19.9512C0.31574 17.2171 0.31574 12.784 3.04985 10.0498ZM11.5349 11.4662C9.58296 9.5143 6.41807 9.5143 4.46618 11.4662C2.5143 13.4181 2.5143 16.583 4.46618 18.5349C6.41807 20.4867 9.58296 20.4867 11.5349 18.5349C13.4867 16.583 13.4867 13.4181 11.5349 11.4662Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0709 18.8788C15.4614 18.4883 16.0946 18.4883 16.4851 18.8788L22.8491 25.2428C24.0204 26.4141 24.021 28.3146 22.8487 29.4855C21.6782 30.6544 19.7784 30.6575 18.6069 29.485C18.6068 29.4849 18.6071 29.4852 18.6069 29.485L12.2429 23.121C11.8524 22.7305 11.8524 22.0974 12.2429 21.7068C12.6334 21.3163 13.2666 21.3163 13.6571 21.7068L20.0211 28.0708C20.4097 28.4599 21.044 28.4613 21.4354 28.0704C21.825 27.6813 21.8256 27.0478 21.4349 26.657L15.0709 20.293C14.6804 19.9025 14.6804 19.2694 15.0709 18.8788Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 2C26.343 2 25 3.343 25 5C25 5.462 25.113 5.895 25.3 6.286L22.293 9.293C22.293 9.293 22.29 9.299 22.288 9.301C21.897 9.114 21.463 9 21 9C20.537 9 20.104 9.114 19.712 9.301C19.71 9.299 19.709 9.295 19.707 9.293L16.707 6.293C16.707 6.293 16.701 6.29 16.699 6.288C16.886 5.897 17 5.463 17 5C17 3.343 15.657 2 14 2C12.696 2 11.597 2.837 11.184 4H2C1.448 4 1 4.448 1 5C1 5.552 1.448 6 2 6H11.184C11.597 7.163 12.696 8 14 8C14.463 8 14.896 7.886 15.288 7.699C15.29 7.701 15.291 7.705 15.293 7.707L18.293 10.707C18.293 10.707 18.299 10.71 18.301 10.712C18.114 11.103 18 11.537 18 12C18 13.657 19.343 15 21 15C22.657 15 24 13.657 24 12C24 11.537 23.886 11.104 23.699 10.712C23.701 10.71 23.705 10.709 23.707 10.707L26.714 7.7C27.105 7.887 27.538 8 28 8C29.657 8 31 6.657 31 5C31 3.343 29.657 2 28 2ZM14 6C13.448 6 13 5.552 13 5C13 4.448 13.448 4 14 4C14.552 4 15 4.448 15 5C15 5.552 14.552 6 14 6ZM21 13C20.448 13 20 12.552 20 12C20 11.448 20.448 11 21 11C21.552 11 22 11.448 22 12C22 12.552 21.552 13 21 13ZM28 6C27.448 6 27 5.552 27 5C27 4.448 27.448 4 28 4C28.552 4 29 4.448 29 5C29 5.552 28.552 6 28 6Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_718_2310"
        x1="15.0018"
        y1="22.0018"
        x2="0.999268"
        y2="22.0018"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
