import React from "react"
import { makeStyles, Box } from "@material-ui/core"
import { SupplierTags } from "./SupplierTags"
import { ModuleHeading } from "../Headings"
import { FormatUrl } from "../Format/FormatUrl"

const useStyles = makeStyles((theme) => ({
  detail: theme.cards.viewerCard,
  detailInner: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  groups: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
}))

const SupplierPanel = ({
  supplier: { name, accountNumber, supplies, website, groups, locations, tags },
  variant,
  ...rest
}) => {
  const classes = useStyles()

  if (variant === "peek") {
    return (
      <Box className={classes.detail} {...rest}>
        <Box className={classes.detailInner}>
          <ModuleHeading noMargin>{name}</ModuleHeading>
          {accountNumber && <Box>{accountNumber}</Box>}
          {supplies && <Box>{supplies}</Box>}
          {website && (
            <Box>
              <FormatUrl url={website} linked />
            </Box>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.detail} {...rest}>
      <Box className={classes.detailInner}>
        <ModuleHeading noMargin>{name}</ModuleHeading>
        {groups && groups.length > 0 && (
          <Box className={classes.groups}>{groups.map((group) => group.name).join(", ")}</Box>
        )}
        {locations && locations.length > 0 && (
          <Box className={classes.locations}>{locations.map((location) => location.name).join(", ")}</Box>
        )}
        {website && (
          <Box mt={2}>
            <FormatUrl url={website} linked />
          </Box>
        )}
        {tags?.length > 0 && (
          <Box mt={2}>
            <SupplierTags tags={tags} max={3} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { SupplierPanel }
