import React, { useEffect, useState } from "react"
import {
  Box,
  IconButton,
  InputAdornment,
  Divider,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Done as DoneIcon, EditOutlined as EditIcon } from "@material-ui/icons"
import { Trans, t } from "@lingui/macro"
import { preventDefault, toId, useFormUtils, isPrefixFormat } from "../../utils"
import { RowBox } from "../Boxes"
import { PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { Icon } from "../Icon"
import { processFormats } from "../../data/processes/processFormats"
import { checkLogic } from "../../services/jobStepLogic"
import { ProcessCollapse } from "./ProcessCollapse"

const { NUMBER, TEXT, LONG_TEXT } = PROCESS_STEP_RESPONSE_TYPE

const useStyles = makeStyles((theme) => ({
  polaritySwitch: {
    marginRight: theme.spacing(-1),
    opacity: 1,
    "& svg #minus": {
      opacity: ({ value }) => (Math.sign(value) === 1 ? 1 : 0.4),
    },
    "& svg #plus": {
      opacity: ({ value }) => (Math.sign(value) === -1 ? 1 : 0.4),
    },
    "& svg #slash": {
      opacity: ({ value }) => (Math.sign(value) !== 0 ? 1 : 0.4),
    },
  },
}))

const OutlinedInputStep = ({
  expand,
  inputRef,
  complete,
  step,
  onResponseChange,
  onCompletedChange,
  onLogicResult,
  isScheduled,
  buttons,
  value,
  classes,
  collapseTimeout = "auto",
  hideEditAndSaveButtons = false,
  readOnly = false,
}) => {
  const { responseType } = step
  const { validate } = useFormUtils()
  const switchClasses = useStyles({ value })
  const [selected, setSelected] = useState(false)
  const [valid, setValid] = useState(true)
  const [validMessage, setValidMessage] = useState("")
  const [hasRequirements, setHasRequirements] = useState(false)
  const [requirementsMessages, setRequirementsMessages] = useState([])

  useEffect(() => {
    const logicResult = checkLogic(step, value)
    if (logicResult.hasRequirements) {
      setHasRequirements(true)
      setRequirementsMessages([...logicResult.messages])
    } else if (hasRequirements) {
      setHasRequirements(false)
      setRequirementsMessages([])
    }
    onLogicResult && onLogicResult(logicResult)
  }, [hasRequirements, onLogicResult, step, value])

  const handleSelected = () => {
    setSelected(true)
  }

  const handleDeselected = () => {
    setSelected(false)
  }

  // // TODO: disabled for now until we have a solution on submitting while clicking
  // // e.g. skip or Bluetooth. Would be used onBlur for the input field.
  // const handleUnselected = (event) => {
  //   setSelected(!selected)
  //   if (hideEditAndSaveButtons || !valid || hasRequirements || event.target.value === "") {
  //     return
  //   }
  //   handleCompletedChange(event)
  // }

  const handleResponseChange = (event) => {
    const { value: newValue } = event.target

    if (responseType === NUMBER) {
      // Only validate for standard numeric input
      if (!newValue || step.format.decimals === null) {
        const validateNumeric = validate.requiredNumeric(newValue)
        setValid(validateNumeric.validation)
        setValidMessage(validateNumeric.message)
      }

      // Check for decimal formats
      else {
        const validateDecimals = validate.requiredNumericDecimals(newValue, step.format.decimals)
        if (validateDecimals.validation === true) {
          setValid(true)
          setValidMessage(validateDecimals.message)
        } else {
          setValid(false)
          setValidMessage(validateDecimals.message)
        }
      }
    }

    if (responseType === TEXT || responseType === LONG_TEXT) {
      setValid(validate.required(newValue))
    }

    onResponseChange && onResponseChange(newValue)
  }

  const handleCompletedChange = (event) => {
    onCompletedChange && onCompletedChange(event)
  }

  const handleTogglePolarity = (event) => {
    event.preventDefault()
    try {
      if (!valid) {
        return
      }
      onResponseChange && onResponseChange(`${value * -1}`)
    } catch {
      // do nothing
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && valid && responseType !== LONG_TEXT) {
      handleCompletedChange(event)
    }
  }

  // Only currency and ph units should appear at the start of the input field
  const getStartAdornment = () => {
    if (responseType === NUMBER && step.format?.unit && isPrefixFormat(step.format.unit)) {
      const format = processFormats.find((item) => item.name === step.format.unit)
      return (
        <InputAdornment className={classes.adornments} position="start">
          {format.symbol}
        </InputAdornment>
      )
    }
    return null
  }

  // Polarity switcher is only required for steps with no unit or temperature units. All units aside from currency and acidity will appear at the end of the input field
  const getEndAdornment = () => {
    const hasNoFormat = step.format.unit === null
    const showSwitch = !complete && !readOnly && (step.format?.unit?.startsWith("temperature") || hasNoFormat)

    if (responseType !== NUMBER) {
      return null
    }

    const format = !hasNoFormat ? processFormats.find((item) => item.name === step.format.unit) : null
    return (
      <>
        {showSwitch && selected && (
          <IconButton
            onMouseDown={(event) => handleTogglePolarity(event)}
            disabled={isScheduled}
            className={switchClasses.polaritySwitch}
            data-cy={`ProcessStep-field-${responseType}-negative`}
          >
            <Icon name="positive-negative" />
          </IconButton>
        )}
        {format && step.format?.unit && !isPrefixFormat(step.format.unit) && (
          <InputAdornment className={classes.adornments} position="end">
            {format.symbol}
          </InputAdornment>
        )}
      </>
    )
  }

  const isError = (!valid || hasRequirements) && !complete && !!value

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />

      <RowBox p={2} alignItems="start">
        <FormControl variant="outlined" fullWidth error={isError}>
          <InputLabel htmlFor={toId(step)}>
            <Trans>Answer</Trans>
          </InputLabel>
          <OutlinedInput
            id={toId(step)}
            placeholder={responseType === NUMBER ? t`Enter number` : t`Enter text`}
            inputRef={inputRef}
            labelWidth={60}
            type="text"
            value={value || ""}
            onClick={preventDefault}
            onChange={handleResponseChange}
            onFocus={handleSelected}
            onBlur={handleDeselected}
            onKeyDown={handleKeyDown}
            className={complete ? classes.completedText : null}
            startAdornment={getStartAdornment()}
            endAdornment={getEndAdornment()}
            inputProps={{
              inputMode: responseType === NUMBER ? "decimal" : "text",
            }}
            fullWidth
            disabled={complete || isScheduled}
            multiline={complete || responseType === LONG_TEXT}
            data-cy={`ProcessStep-field-${responseType}`}
            readOnly={readOnly}
          />
          {(validMessage || requirementsMessages?.length > 0) && (
            <FormHelperText error id={`${toId(step)}-error`} component="div">
              {[validMessage, ...requirementsMessages].filter(Boolean).map((message, index) => (
                <Box key={index}>{message}</Box>
              ))}
            </FormHelperText>
          )}
        </FormControl>
        {!!buttons && (
          <Box ml={1} pt={0.5}>
            {buttons}
          </Box>
        )}
        {!hideEditAndSaveButtons && (
          <Box ml={1} pt={0.5}>
            {!complete && (
              <Box onClick={preventDefault}>
                <IconButton
                  onClick={handleCompletedChange}
                  disabled={!value || !valid || isScheduled || hasRequirements}
                  className={classes.incompletedButton}
                  data-cy={`ProcessStep-field-${responseType}-complete`}
                >
                  <DoneIcon />
                </IconButton>
              </Box>
            )}
            {complete && (
              <IconButton
                onClick={handleCompletedChange}
                className={classes.completedButton}
                disabled={isScheduled}
                data-cy={`ProcessStep-field-${responseType}-uncomplete`}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        )}
      </RowBox>
    </ProcessCollapse>
  )
}

export { OutlinedInputStep }
