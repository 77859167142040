import React from "react"
import { ListItem, makeStyles } from "@material-ui/core"
import TimeAgo from "../TimeAgo"

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    cursor: "pointer",
    margin: 0,
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundDark,
    },
    gap: theme.spacing(2),
    alignItems: "flex-start",
  },
  date: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginLeft: "auto",
    whiteSpace: "nowrap",
  },
}))

const SearchListItem = ({ children, date, ...rest }) => {
  const classes = useStyles()

  return (
    <ListItem {...rest} className={classes.listItem}>
      {children}
      {date && (
        <div className={classes.date}>
          <TimeAgo date={date} accurate />
        </div>
      )}
    </ListItem>
  )
}

export { SearchListItem }
