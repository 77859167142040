import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_145)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 10C5.20888 10 4.43552 9.76541 3.77772 9.32588C3.11992 8.88635 2.60723 8.26164 2.30448 7.53074C2.00173 6.79983 1.92252 5.99556 2.07686 5.21964C2.2312 4.44372 2.61216 3.73098 3.17157 3.17157C3.73098 2.61216 4.44372 2.2312 5.21964 2.07686C5.99556 1.92252 6.79983 2.00173 7.53074 2.30448C8.26164 2.60723 8.88635 3.11992 9.32588 3.77772C9.76541 4.43552 10 5.20888 10 6C10 7.06087 9.57857 8.07828 8.82843 8.82843C8.07828 9.57857 7.06087 10 6 10ZM6 4C5.60444 4 5.21776 4.1173 4.88886 4.33706C4.55996 4.55683 4.30362 4.86918 4.15224 5.23463C4.00087 5.60009 3.96126 6.00222 4.03843 6.39018C4.1156 6.77814 4.30608 7.13451 4.58579 7.41422C4.86549 7.69392 5.22186 7.8844 5.60982 7.96157C5.99778 8.03874 6.39992 7.99914 6.76537 7.84776C7.13082 7.69639 7.44318 7.44004 7.66294 7.11114C7.8827 6.78224 8 6.39556 8 6C8 5.46957 7.78929 4.96086 7.41422 4.58579C7.03914 4.21072 6.53043 4 6 4Z"
      fill={primary}
    />
    <path
      d="M10 30C9.20888 30 8.43552 29.7654 7.77772 29.3259C7.11992 28.8864 6.60723 28.2616 6.30448 27.5307C6.00173 26.7998 5.92252 25.9956 6.07686 25.2196C6.2312 24.4437 6.61216 23.731 7.17157 23.1716C7.73098 22.6122 8.44372 22.2312 9.21964 22.0769C9.99556 21.9225 10.7998 22.0017 11.5307 22.3045C12.2616 22.6072 12.8864 23.1199 13.3259 23.7777C13.7654 24.4355 14 25.2089 14 26C14 27.0609 13.5786 28.0783 12.8284 28.8284C12.0783 29.5786 11.0609 30 10 30ZM10 24C9.60444 24 9.21776 24.1173 8.88886 24.3371C8.55996 24.5568 8.30362 24.8692 8.15224 25.2346C8.00087 25.6001 7.96126 26.0022 8.03843 26.3902C8.1156 26.7781 8.30608 27.1345 8.58579 27.4142C8.86549 27.6939 9.22186 27.8844 9.60982 27.9616C9.99778 28.0387 10.3999 27.9991 10.7654 27.8478C11.1308 27.6964 11.4432 27.44 11.6629 27.1111C11.8827 26.7822 12 26.3956 12 26C12 25.4696 11.7893 24.9609 11.4142 24.5858C11.0391 24.2107 10.5304 24 10 24Z"
      fill={primary}
    />
    <path
      d="M8 20C7.20888 20 6.43552 19.7654 5.77772 19.3259C5.11992 18.8864 4.60723 18.2616 4.30448 17.5307C4.00173 16.7998 3.92252 15.9956 4.07686 15.2196C4.2312 14.4437 4.61216 13.731 5.17157 13.1716C5.73098 12.6122 6.44372 12.2312 7.21964 12.0769C7.99556 11.9225 8.79983 12.0017 9.53074 12.3045C10.2616 12.6072 10.8864 13.1199 11.3259 13.7777C11.7654 14.4355 12 15.2089 12 16C12 17.0609 11.5786 18.0783 10.8284 18.8284C10.0783 19.5786 9.06087 20 8 20ZM8 14C7.60444 14 7.21776 14.1173 6.88886 14.3371C6.55996 14.5568 6.30362 14.8692 6.15224 15.2346C6.00087 15.6001 5.96126 16.0022 6.03843 16.3902C6.1156 16.7781 6.30608 17.1345 6.58579 17.4142C6.86549 17.6939 7.22186 17.8844 7.60982 17.9616C7.99778 18.0387 8.39992 17.9991 8.76537 17.8478C9.13082 17.6964 9.44318 17.44 9.66294 17.1111C9.8827 16.7822 10 16.3956 10 16C10 15.4696 9.78929 14.9609 9.41422 14.5858C9.03914 14.2107 8.53043 14 8 14Z"
      fill={primary}
    />
    <path
      d="M28 17H16C15.7348 17 15.4804 16.8946 15.2929 16.7071C15.1054 16.5196 15 16.2652 15 16C15 15.7348 15.1054 15.4804 15.2929 15.2929C15.4804 15.1054 15.7348 15 16 15H28C28.2652 15 28.5196 15.1054 28.7071 15.2929C28.8946 15.4804 29 15.7348 29 16C29 16.2652 28.8946 16.5196 28.7071 16.7071C28.5196 16.8946 28.2652 17 28 17Z"
      fill={primary}
    />
    <path
      d="M30 27H18C17.7348 27 17.4804 26.8946 17.2929 26.7071C17.1054 26.5196 17 26.2652 17 26C17 25.7348 17.1054 25.4804 17.2929 25.2929C17.4804 25.1054 17.7348 25 18 25H30C30.2652 25 30.5196 25.1054 30.7071 25.2929C30.8946 25.4804 31 25.7348 31 26C31 26.2652 30.8946 26.5196 30.7071 26.7071C30.5196 26.8946 30.2652 27 30 27Z"
      fill={primary}
    />
    <path
      d="M26 6H14C13.7348 6 13.4804 5.89464 13.2929 5.70711C13.1054 5.51957 13 5.26522 13 5C13 4.73478 13.1054 4.48043 13.2929 4.29289C13.4804 4.10536 13.7348 4 14 4H26C26.2652 4 26.5196 4.10536 26.7071 4.29289C26.8946 4.48043 27 4.73478 27 5C27 5.26522 26.8946 5.51957 26.7071 5.70711C26.5196 5.89464 26.2652 6 26 6Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_145" x1="13" y1="5" x2="27" y2="5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
