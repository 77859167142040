import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { ACTION_STATUS, ACTION_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.status.blocked,
  },
}))

const BlockedChip = ({ label = ACTION_STATUS_LABELS[ACTION_STATUS.BLOCKED], className }) => {
  const classes = useStyles()

  return <Chip label={label} classes={classes} className={className} size="small" />
}

export { BlockedChip }
