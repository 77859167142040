import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_2326_3617)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 30H5.14C3.41 30 2 28.65 2 27V5C2 3.35 3.41 2 5.14 2H18C18.55 2 19 2.45 19 3C19 3.55 18.55 4 18 4H5.14C4.52 4 4 4.46 4 5V27C4 27.54 4.52 28 5.14 28H18C18.55 28 19 28.45 19 29C19 29.55 18.55 30 18 30Z"
      fill={primary}
    />
    <path
      d="M17 22H7C6.44772 22 6 22.4477 6 23C6 23.5523 6.44772 24 7 24H17C17.5523 24 18 23.5523 18 23C18 22.4477 17.5523 22 17 22Z"
      fill={secondary}
    />
    <path
      d="M15 17H7C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19H15C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17Z"
      fill={secondary}
    />
    <path
      d="M17 7H7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7Z"
      fill={secondary}
    />
    <path
      d="M22.63 3.22C22.33 2.98 21.92 2.93 21.57 3.1C21.22 3.27 21 3.62 21 4V29C21 29.55 21.45 30 22 30H26C26.55 30 27 29.55 27 29V22H29C29.55 22 30 21.55 30 21C30 20.52 29.92 9.12 22.63 3.22ZM25 28H23V22H25V28ZM26 20H23V6.35C26.95 10.85 27.78 17.5 27.95 20H26Z"
      fill={primary}
    />
    <path
      d="M15 12H7C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_2326_3617" x1="6" y1="23" x2="18" y2="23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
