import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_157)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 10C9.91015 10 7 12.9101 7 16.5C7 20.0899 9.91015 23 13.5 23C17.0899 23 20 20.0899 20 16.5C20 12.9101 17.0899 10 13.5 10ZM5 16.5C5 11.8056 8.80558 8 13.5 8C18.1944 8 22 11.8056 22 16.5C22 21.1944 18.1944 25 13.5 25C8.80558 25 5 21.1944 5 16.5Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 13.8451C28.7761 13.5986 29.4341 13.078 29.9213 12.4284C30.6061 11.5155 31 10.3 31 9C31 7.69998 30.6061 6.48455 29.9213 5.57157C29.2364 4.65829 28.2138 4 27 4C25.7862 4 24.7636 4.65829 24.0787 5.57157C23.3939 6.48455 23 7.69998 23 9C23 10.3 23.3939 11.5155 24.0787 12.4284C24.5659 13.078 25.2239 13.5986 26 13.8451V28C26 28.5523 26.4477 29 27 29C27.5523 29 28 28.5523 28 28V13.8451ZM25 9C25 8.09088 25.2776 7.30631 25.6787 6.77157C26.0795 6.23714 26.5569 6 27 6C27.4431 6 27.9205 6.23714 28.3213 6.77157C28.7224 7.30631 29 8.09088 29 9C29 9.90912 28.7224 10.6937 28.3213 11.2284C27.9205 11.7629 27.4431 12 27 12C26.5569 12 26.0795 11.7629 25.6787 11.2284C25.2776 10.6937 25 9.90912 25 9Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 6C7.70101 6 3 10.701 3 16.5C3 22.299 7.70101 27 13.5 27C17.4786 27 20.9419 24.7875 22.7241 21.521C22.9887 21.0362 23.5961 20.8576 24.0809 21.1222C24.5658 21.3867 24.7443 21.9941 24.4798 22.479C22.3612 26.362 18.2394 29 13.5 29C6.59644 29 1 23.4036 1 16.5C1 9.59644 6.59644 4 13.5 4C16.4412 4 19.1475 5.01703 21.2827 6.71782C21.7147 7.06193 21.7859 7.69107 21.4418 8.12306C21.0977 8.55504 20.4685 8.62628 20.0366 8.28218C18.2427 6.85327 15.9724 6 13.5 6Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6999 13.2858C18.0944 13.6723 18.1008 14.3055 17.7142 14.6999L12.8142 19.6999C12.6261 19.8919 12.3687 20 12.1 20C11.8313 20 11.5739 19.8919 11.3858 19.6999L9.28579 17.5571C8.89923 17.1626 8.90562 16.5295 9.30007 16.1429C9.69452 15.7564 10.3277 15.7628 10.7142 16.1572L12.1 17.5713L16.2858 13.3001C16.6723 12.9056 17.3055 12.8992 17.6999 13.2858Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_157" x1="9" y1="16.5" x2="18" y2="16.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
