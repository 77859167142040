import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2649)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 9H13C11.346 9 10 7.654 10 6C10 4.346 11.346 3 13 3H19C20.654 3 22 4.346 22 6C22 7.654 20.654 9 19 9ZM13 5C12.449 5 12 5.448 12 6C12 6.552 12.449 7 13 7H19C19.551 7 20 6.552 20 6C20 5.448 19.551 5 19 5H13Z"
      fill={secondary}
    />
    <path
      d="M26 24H20V23H24C25.654 23 27 21.654 27 20C27 18.346 25.654 17 24 17H20V16H21C22.654 16 24 14.654 24 13C24 11.346 22.654 10 21 10H11C9.346 10 8 11.346 8 13C8 14.654 9.346 16 11 16H12V17H8C6.346 17 5 18.346 5 20C5 21.654 6.346 23 8 23H12V24H6C4.346 24 3 25.346 3 27C3 28.654 4.346 30 6 30H26C27.654 30 29 28.654 29 27C29 25.346 27.654 24 26 24ZM10 13C10 12.448 10.449 12 11 12H21C21.551 12 22 12.448 22 13C22 13.552 21.551 14 21 14H11C10.449 14 10 13.552 10 13ZM14 16H18V17H14V16ZM7 20C7 19.448 7.449 19 8 19H24C24.551 19 25 19.448 25 20C25 20.552 24.551 21 24 21H8C7.449 21 7 20.552 7 20ZM14 23H18V24H14V23ZM26 28H6C5.449 28 5 27.552 5 27C5 26.448 5.449 26 6 26H26C26.551 26 27 26.448 27 27C27 27.552 26.551 28 26 28Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_722_2649" x1="22" y1="9" x2="10" y2="9" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
