import { Box, Button, Collapse, Hidden, makeStyles } from "@material-ui/core"
import React from "react"
import { Alert } from "@material-ui/lab"
import { useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { Icon, RowBox } from ".."
import { useMutationCompleteUserTrainingModuleKnowledge } from "../../data"
import { toId } from "../../utils"
import { ActionBlockquote } from "../Blockquotes"
import { LoadingSpinner } from "../LoadingSpinner"

const useStyles = makeStyles((theme) => ({
  button: {
    whiteSpace: "nowrap",
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}))

const TrainingArticleFooter = ({ userTraining, module, moduleKnowledge, articlesRequiringCompletion, content }) => {
  const classes = useStyles()
  const history = useHistory()
  const [completeUserTrainingModuleKnowledge, { loading }] =
    useMutationCompleteUserTrainingModuleKnowledge(userTraining)

  const handleContinue = async () => {
    await completeUserTrainingModuleKnowledge({
      variables: {
        userTraining: toId(userTraining),
        module: toId(module),
        moduleKnowledge: toId(moduleKnowledge),
      },
    })
    handleNavigateToTraining()
  }

  const currentKnowledgeIndex = module.knowledges.findIndex((el) => el.id === moduleKnowledge.id)
  const nextKnowledgeIndex = currentKnowledgeIndex + 1
  const nextArticle = nextKnowledgeIndex < module.knowledges.length ? module.knowledges[nextKnowledgeIndex] : null

  const handleNavigateToTraining = () => {
    if (nextArticle) history.push(`/knowledge/article/${toId(nextArticle.knowledge)}`)
    else history.push(`/knowledge/training/${toId(userTraining)}`)
  }

  if (!userTraining || !module || !moduleKnowledge) {
    return null
  }

  const completed = Boolean(moduleKnowledge.completedAt)

  // Find the items in the list of content that still require an answer. We do this
  // by filtering the list of content questions down based on the questions requiring
  // answer prop.
  //
  // We use this to display a messages next to the submission button on what the
  // user needs to do next to progress.
  const questionsRequiringAnswer = (() => {
    if (articlesRequiringCompletion.length === 0) {
      return []
    }

    return content.filter((question) => articlesRequiringCompletion.includes(question.id))
  })()

  return (
    <ActionBlockquote
      completed={completed}
      icon={<Icon name="training" />}
      title={`${userTraining.name} - ${module.name}`}
      body={
        <>
          {!completed && (
            <Box mb={2}>
              <span className="notranslate">
                <Trans>
                  Once you have read and understand the content of this module, click "Mark complete" to continue with
                  your training. You can return and refer to this content at any time through the Knowledge Base.
                </Trans>
              </span>
            </Box>
          )}
          {completed && (
            <Box mb={2}>
              <span className="notranslate">
                <Trans>
                  You have already indicated you have read and understood this training content. You can return and
                  refer to this content at any time through the Knowledge Base.
                </Trans>
              </span>
            </Box>
          )}
        </>
      }
      action={
        <>
          <Collapse in={questionsRequiringAnswer.length > 0}>
            {questionsRequiringAnswer.map((question) => (
              <Alert severity="error" icon={false} key={toId(question)} className={classes.alert}>
                <span className="notranslate">
                  <Trans>
                    Please answer the question: <strong>{question.question}</strong> before completing this module.
                  </Trans>
                </span>
              </Alert>
            ))}
          </Collapse>
          {!completed && (
            <>
              <Hidden xsDown>
                <RowBox justifyContent="space-between">
                  <Box>
                    <span className="notranslate">
                      <Trans>I acknowledge that I've read and understand this content</Trans>
                    </span>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    disabled={loading || articlesRequiringCompletion.length > 0}
                    className={classes.button}
                    data-cy="Button-continue"
                  >
                    {!loading && (
                      <span className="notranslate">
                        <Trans>Mark complete</Trans>
                      </span>
                    )}
                    {loading && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                </RowBox>
              </Hidden>
              <Hidden smUp>
                <Box mb={1}>
                  <span className="notranslate">
                    <Trans>I acknowledge that I've read and understand this content</Trans>
                  </span>
                </Box>
                <RowBox justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    disabled={loading || articlesRequiringCompletion.length > 0}
                    className={classes.button}
                    data-cy="Button-continue"
                  >
                    {!loading && (
                      <span className="notranslate">
                        <Trans>Mark complete</Trans>
                      </span>
                    )}
                    {loading && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                </RowBox>
              </Hidden>
            </>
          )}
          {completed && (
            <RowBox justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={handleNavigateToTraining}>
                <span className="notranslate">
                  <Trans>Continue</Trans>
                </span>
              </Button>
            </RowBox>
          )}
        </>
      }
      cy="TrainingArticleFooter"
    />
  )
}

export { TrainingArticleFooter }
