import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.scheduled.main,
  },
}))

const TrainingChip = ({ className }) => {
  const classes = useStyles()

  return <Chip label="Training" classes={classes} className={className} size="small" />
}

export { TrainingChip }
