import React, { useEffect, useRef, useState } from "react"
import { Box, makeStyles, useTheme, useMediaQuery, TextField, InputAdornment } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import SearchIcon from "@material-ui/icons/Search"
import Highlighter from "react-highlight-words"
import { PROCESS_STEP_RESPONSE_TYPE, useLazyQueryProcesses } from "../../data"
import TimeAgo from "../TimeAgo"
import { ColumnBox, PaperBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  searchInput: {
    height: 56,
  },
  highlight: {
    fontWeight: "600",
    marginLeft: 0,
  },
  subline: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
}))

const ProcessSearch = ({ placeholder = "Search processes...", onItemSelected }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()
  const [load, { data, loading }] = useLazyQueryProcesses()
  const [open, setOpen] = useState(false)
  const clearRef = useRef(false)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    load({
      variables: {
        filter: {
          set: "any",
          searchText,
          active: true,
        },
      },
    })
  }, [load, searchText])

  const handleInputChange = (event, newValue, reason) => {
    if (["input", "clear"].includes(reason)) {
      setSearchText(newValue)
    }
  }

  const handleChange = (event, option) => {
    if (option?.id) {
      clearRef.current = !clearRef.current
      onItemSelected && onItemSelected(option)
      if (option.steps.some((step) => step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS)) {
        setSearchText(option.name)
      } else setSearchText("")
    }
  }

  const options = data?.processes.list || []

  return (
    <Autocomplete
      key={clearRef.current}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={handleInputChange}
      inputValue={searchText}
      onChange={handleChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={({ name }) => name}
      options={options}
      loading={loading}
      PaperComponent={PaperBox}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          margin="none"
          variant="outlined"
          className={xs ? null : classes.searchInput}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            type: "text",
          }}
          data-cy="TrainingModuleSearch-input"
        />
      )}
      renderOption={(option) => (
        <ColumnBox data-cy="TrainingModuleSearch-option">
          <Box>
            <Highlighter
              highlightClassName={classes.highlight}
              searchWords={searchText.split(" ")}
              autoEscape
              textToHighlight={option.name}
            />
          </Box>
          <Box className={classes.subline}>
            {option.stepCount} steps, {option.createdAt !== option.updatedAt ? "updated" : "created"}{" "}
            <TimeAgo date={option.updatedAt} />
          </Box>
        </ColumnBox>
      )}
    />
  )
}

export { ProcessSearch }
