import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2625)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.002 25H6.00201C5.45001 25 5.00201 24.553 5.00201 24V17C5.00201 16.447 5.45001 16 6.00201 16H16.002C16.554 16 17.002 16.447 17.002 17V24C17.002 24.553 16.554 25 16.002 25ZM7.00201 23H15.002V18H7.00201V23Z"
      fill={secondary}
    />
    <path
      d="M28.002 1H4.002C2.348 1 1.002 2.346 1.002 4V7.49C1.002 7.49 1 7.496 1 7.5C1 7.513 1.002 7.525 1.002 7.537V28C1.002 29.654 2.348 31 4.002 31H28.002C29.656 31 31.002 29.654 31.002 28V4C31.002 2.346 29.656 1 28.002 1ZM29.002 4V7.5C29.002 9.43 27.357 11 25.335 11C23.313 11 21.67 9.431 21.668 7.503C21.668 7.502 21.668 7.501 21.668 7.5V3H28.001C28.552 3 29.001 3.448 29.001 4H29.002ZM12.335 7.5V3H19.669V7.495C19.669 7.495 19.669 7.498 19.669 7.5C19.669 9.43 18.024 11 16.002 11C13.98 11 12.337 9.431 12.335 7.503C12.335 7.502 12.335 7.501 12.335 7.5ZM4.002 3H10.335V7.495C10.335 7.495 10.335 7.498 10.335 7.5C10.335 9.43 8.69 11 6.668 11C4.646 11 3.014 9.44 3.003 7.518V4C3.003 3.448 3.451 3 4.002 3ZM21.002 29V18H25.002V29H21.002ZM28.002 29H27.002V17C27.002 16.447 26.554 16 26.002 16H20.002C19.45 16 19.002 16.447 19.002 17V29H4.002C3.451 29 3.002 28.552 3.002 28V11.687C3.991 12.504 5.27 13 6.667 13C8.601 13 10.311 12.056 11.334 10.617C12.357 12.055 14.068 13 16.001 13C17.934 13 19.645 12.056 20.668 10.617C21.691 12.055 23.401 13 25.335 13C26.733 13 28.013 12.503 29.002 11.686V28C29.002 28.552 28.553 29 28.002 29Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_722_2625"
        x1="17.002"
        y1="25"
        x2="17.002"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
