import { Box, Divider, Hidden, makeStyles } from "@material-ui/core"
import moment from "moment"
import React, { useState } from "react"
import { Trans } from "@lingui/macro"
import { useMutationUpdateUserAccreditation, USER_ACCREDITATIONS_STATUS } from "../../data"
import { mapToIds, toId } from "../../utils"
import { ColumnBox, PaperBox, RowBox } from "../Boxes"
import { ExpiryDatePicker } from "../DateTimePickers"
import { Icon } from "../Icon"
import TimeAgo from "../TimeAgo"
import { DownloadList, Uploader, UploadingImagesList } from "../Uploader"
import { PersonAccreditationItemStatus } from "./PersonAccreditationItemStatus"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 12,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    fontWeight: "normal",
  },
  divider: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    backgroundColor: theme.palette.grey[200],
  },
  buttonIcon: {
    fontSize: 20,
  },
  completedButtonText: {},
  attentionButtonText: {
    color: theme.palette.error.main,
  },
}))

const ManageAccreditationActionCard = ({ userAccreditation, cy = "ManageAccreditationActionCard" }) => {
  const [updateUserAccreditation] = useMutationUpdateUserAccreditation()
  const [isUploading, setIsUploading] = useState(false)
  const [uploading, setUploading] = useState([])

  const {
    accreditation: { name, description, icon, expires },
    expiresAt,
    uploads,
    status,
  } = userAccreditation
  const classes = useStyles()

  const completed = status === USER_ACCREDITATIONS_STATUS.COMPLIANT

  const handleUpdateExpiresAt = (value) => {
    updateUserAccreditation({
      variables: {
        id: toId(userAccreditation),
        expiresAt: moment(value).toDate(),
      },
    })
  }

  const handleRemoveUpload = (id) => {
    updateUserAccreditation({
      variables: {
        id: toId(userAccreditation),
        uploads: mapToIds([...uploads.filter((upload) => toId(upload) !== toId(id))]),
      },
    })
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleAfterUpload = (uploaded) => {
    setIsUploading(false)
    updateUserAccreditation({
      variables: {
        id: toId(userAccreditation),
        uploads: mapToIds([...uploads, ...uploaded.map((item) => item.upload)]),
      },
    })
  }
  const handleUploadError = () => {
    setIsUploading(false)
  }

  const hasExpiresAt = Boolean(expiresAt)
  const hasUploads = Boolean(uploads.length)

  return (
    <Box mb={2} cy={cy}>
      <PaperBox completed={completed} cursor="inherit" px={2} py={2} pb={1.5}>
        <RowBox className={classes.title} alignItems="flex-start" mb={1}>
          <Icon name="custom" customName={icon} className="material-icons-outlined" />
          <ColumnBox ml={1}>
            <Box>{name}</Box>
            {description && (
              <Box className={classes.caption} mb={1}>
                {description}
              </Box>
            )}

            <RowBox alignItems="center">
              <Box className={classes.caption}>
                {status === USER_ACCREDITATIONS_STATUS.WARNING && (
                  <Trans>
                    Expires <TimeAgo date={expiresAt} />
                  </Trans>
                )}
                {status === USER_ACCREDITATIONS_STATUS.EXPIRED && (
                  <Trans>
                    Expired <TimeAgo date={expiresAt} />
                  </Trans>
                )}
              </Box>
            </RowBox>
          </ColumnBox>
          <RowBox ml="auto">
            {expires && (
              <Hidden xsDown>
                <Box mx={1}>
                  <ExpiryDatePicker
                    value={expiresAt}
                    onChange={handleUpdateExpiresAt}
                    buttonTextClassName={hasExpiresAt ? classes.completedButtonText : classes.attentionButtonText}
                    data-cy={`${cy}-expiry`}
                  />
                </Box>
              </Hidden>
            )}
            <PersonAccreditationItemStatus userAccreditation={userAccreditation} />
          </RowBox>
        </RowBox>
        {expires && (
          <Hidden smUp>
            <Divider className={classes.divider} />
            <RowBox py={1.5} flexGrow={1} justifyContent="center">
              <ExpiryDatePicker
                value={expiresAt}
                onChange={handleUpdateExpiresAt}
                buttonTextClassName={hasExpiresAt ? classes.completedButtonText : classes.attentionButtonText}
              />
            </RowBox>
          </Hidden>
        )}
        {hasUploads && (
          <>
            <Divider className={classes.divider} />
            <Box pb={2}>
              <UploadingImagesList
                uploaded={uploads}
                uploading={uploading}
                onRemoveUpload={handleRemoveUpload}
                gridItemProps={{ md: 2, sm: 3, xs: 4 }}
                height={100}
                pt={2}
              />
              <DownloadList uploads={uploads} onRemove={handleRemoveUpload} variant="boxes" pt={2} />
            </Box>
          </>
        )}
        <Divider className={classes.divider} />
        <RowBox pt={1.5} flexGrow={1} justifyContent="center">
          <Box mr={1}>
            <Uploader
              onBeforeUpload={handleBeforeUpload}
              onProgress={handleUploadProgress}
              onAfterUpload={handleAfterUpload}
              onError={handleUploadError}
              icon={<Icon name="photo" />}
              documents={false}
              disabled={isUploading}
              variant="button"
              buttonText={
                <RowBox className={hasUploads ? classes.completedButtonText : classes.attentionButtonText}>
                  <Icon name="camera" className={classes.buttonIcon} />
                  <Box ml={0.5}>
                    <Trans>Attach photos</Trans>
                  </Box>
                </RowBox>
              }
              buttonProps={{
                variant: "outlined",
                className: classes.button,
                color: "secondary",
              }}
            />
          </Box>
          <Box>
            <Uploader
              onBeforeUpload={handleBeforeUpload}
              onProgress={handleUploadProgress}
              onAfterUpload={handleAfterUpload}
              onError={handleUploadError}
              images={false}
              disabled={isUploading}
              variant="button"
              buttonText={
                <RowBox className={hasUploads ? classes.completedButtonText : classes.attentionButtonText}>
                  <Icon name="attach-file" className={classes.buttonIcon} />
                  <Box ml={0.5}>
                    <Trans>Attach files</Trans>
                  </Box>
                </RowBox>
              }
              buttonProps={{
                variant: "outlined",
                className: classes.button,
                color: "secondary",
              }}
            />
          </Box>
        </RowBox>
      </PaperBox>
    </Box>
  )
}

export { ManageAccreditationActionCard }
