import React, { useEffect, useState } from "react"
import { Toolbar, Container, Button, Box, Menu, MenuItem, ListItemIcon, ListSubheader } from "@material-ui/core"
import Clock from "react-live-clock"
import Config from "react-global-configuration"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { Trans, t } from "@lingui/macro"
import { useAuth } from "../../services"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from "../Icon"
import {
  useMutationPauseTimesheet,
  useMutationResumeTimesheet,
  useMutationStartTimesheet,
  useQueryTimesheet,
  TIMESHEET_STATUS,
  useQueryTimesheetGroups,
} from "../../data"
import { FormatDateTimeCompact, FormatTime } from "../Format"
import { IntervalRenderer } from "../IntervalRenderer"
import { EndShiftDialog } from "./EndShiftDialog"
import { toId } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { ErrorBoundary } from "../ErrorBoundary"

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.dimensions.punchClock.height,
    minHeight: theme.dimensions.punchClock.height,
    backgroundColor: ({ status }) => theme.palette.punchClock[status].backgroundColor,
    color: theme.palette.text.primary,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  statusText: {
    fontSize: 16,
    lineHeight: "16px",
    fontWeight: 600,
  },
  statusSecondaryText: {
    fontSize: 12,
    lineHeight: "13px",
  },
  timeClock: {
    fontWeight: 600,
    marginLeft: 4,
  },
  startButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
    "&:focus": {
      boxShadow: `0px 0px 0px 3px rgba(${theme.palette.success.mainRgb},0.5)`,
    },
  },
  pauseButton: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
    "&:focus": {
      boxShadow: `0px 0px 0px 3px rgba(${theme.palette.warning.mainRgb},0.5)`,
    },
  },
  resumeButton: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
    "&:focus": {
      boxShadow: `0px 0px 0px 3px rgba(${theme.palette.success.mainRgb},0.5)`,
    },
  },
}))

const useShiftButtonStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.white,
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const ShiftButton = ({ text, icon = "time", className = "", loading, ...rest }) => {
  const classes = useShiftButtonStyles()

  return (
    <Button variant="contained" className={`${classes.root} ${className}`} {...rest}>
      {!loading && (
        <>
          {icon && <Icon name="time" />}
          <Box ml={icon ? 0.5 : 0}>{text}</Box>
        </>
      )}
      {loading && <LoadingSpinner size="24px" delay={false} />}
    </Button>
  )
}

const StartShiftButton = ({ text, icon = "time", className = "", loading, shift, onClick, ...rest }) => {
  const classes = useShiftButtonStyles()
  const { location } = useAuth()
  const { data: groupsData } = useQueryTimesheetGroups({
    variables: { location: toId(location) },
  })
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)

  const handleClick = (event) => {
    if (!shift && groupsData?.timesheetGroups.length === 1) {
      onClick && onClick(groupsData.timesheetGroups[0])
      return
    }
    if (!shift) {
      return handleToggleCloseMenu(event)
    }

    onClick && onClick()
  }
  const handleMenuClick = (group) => {
    onClick && onClick(group)
  }
  const handleToggleCloseMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  return (
    <>
      <Button variant="contained" onClick={handleClick} className={`${classes.root} ${className}`} {...rest}>
        {!loading && (
          <>
            {icon && <Icon name="time" />}
            <Box ml={icon ? 0.5 : 0}>{text}</Box>
          </>
        )}
        {loading && <LoadingSpinner size="24px" delay={false} />}
      </Button>
      <Menu
        anchorEl={showCreateOptionsEl}
        keepMounted
        open={showCreateOptions}
        onClose={handleToggleCloseMenu}
        className={classes.menu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ListSubheader>Select work area</ListSubheader>
        {groupsData?.timesheetGroups.map((group) => (
          <MenuItem key={toId(group)} onClick={() => handleMenuClick(group)} data-cy="MenuItem-shift-group">
            <ListItemIcon className={classes.menuListItemIcon}>
              <Icon name="group" />
            </ListItemIcon>
            <>{group.name}</>
          </MenuItem>
        ))}
        {!groupsData?.timesheetGroups.length && (
          <MenuItem data-cy="MenuItem-no-shift-groups">No areas configured</MenuItem>
        )}
      </Menu>
    </>
  )
}

const { NOT_SCHEDULED, SCHEDULED, ACTIVE, PAUSED, ENDED } = TIMESHEET_STATUS

const pollInterval = 1000 * 10

const shouldShow = (data, groupsData) =>
  data && groupsData && Boolean(data.timesheet || data.shift || groupsData.timesheetGroups?.length)

const PunchClock = ({ onLoaded }) => {
  const [status, setStatus] = useState(NOT_SCHEDULED)
  const classes = useStyles({ status })
  const { location, authed } = useAuth()
  const { clientKiosk: kiosk } = Config.get()
  const {
    data,
    refetch,
    loading: timesheetLoading,
    stopPolling,
    startPolling,
  } = useQueryTimesheet({
    variables: { location: toId(location) },
    pollInterval,
  })
  const { data: groupsData, loading: groupsLoading } = useQueryTimesheetGroups({
    variables: { location: toId(location) },
    pollInterval,
  })
  const [start, { loading: startLoading }] = useMutationStartTimesheet()
  const [pause, { loading: pauseLoading }] = useMutationPauseTimesheet()
  const [resume, { loading: resumeLoading }] = useMutationResumeTimesheet()
  const [showEnd, setShowEnd] = useState(false)

  useEffect(() => {
    if (data && groupsData) {
      setStatus(data.timesheet ? data.timesheet.status : data.shift ? SCHEDULED : NOT_SCHEDULED)
      const show = shouldShow(data, groupsData)
      onLoaded && onLoaded(show)
    }
  }, [data, groupsData, onLoaded])

  const handleStart = async (group) => {
    await start({
      variables: {
        location: toId(location),
        group: toId(shift?.group, true) || toId(group),
        shift: data?.shift ? toId(shift) : null,
      },
    })
    await refetch()
  }

  const handleEnd = () => {
    setShowEnd(true)
    stopPolling()
  }
  const handleEnded = () => {
    setShowEnd(false)
    startPolling(pollInterval)
  }
  const handlePause = async () => {
    await pause()
    await refetch()
  }
  const handleResume = async () => {
    await resume()
    await refetch()
  }
  const handleCloseEnd = () => {
    setShowEnd(false)
    startPolling(pollInterval)
  }

  if (!authed || !kiosk || !shouldShow(data, groupsData)) {
    return null
  }

  const loading = timesheetLoading || startLoading || pauseLoading || resumeLoading || groupsLoading

  const timesheet = data?.timesheet
  const shift = data?.shift
  const nextShift = data?.nextShift

  return (
    <ErrorBoundary>
      {showEnd && <EndShiftDialog open onEnded={handleEnded} onClose={handleCloseEnd} />}

      <Toolbar className={classes.root} disableGutters>
        <Container>
          <RowBox>
            <RowBox flexGrow={1}>
              <ColumnBox>
                <Box className={classes.statusText} mb={0.75}>
                  {status === NOT_SCHEDULED && <Trans>No scheduled shift today</Trans>}
                  {status === SCHEDULED && <Trans>Scheduled shift</Trans>}
                  {status === ACTIVE && <Trans>On shift</Trans>}
                  {status === PAUSED && <Trans>On break</Trans>}
                  {status === ENDED && <Trans>Shift complete!</Trans>}
                </Box>
                <Box className={classes.statusSecondaryText}>
                  {status === NOT_SCHEDULED && <Trans>You can still start an unscheduled shift</Trans>}
                  {status === SCHEDULED && Boolean(shift) && (
                    <>
                      {shift.group?.name || shift.otherGroupName || t`Unknown area`} |{" "}
                      <FormatDateTimeCompact value={moment(shift.startsAt)} /> -{" "}
                      <FormatDateTimeCompact
                        value={moment(shift.endsAt)}
                        compactTodayTimeZone={location ? location.timeZone : null}
                      />
                    </>
                  )}
                  {status === ACTIVE && Boolean(timesheet) && (
                    <>
                      {timesheet.group?.name || shift?.otherGroupName || t`Unknown area`} |{" "}
                      <FormatDateTimeCompact value={moment(timesheet?.startedAt || moment())} /> <Trans>start</Trans>
                    </>
                  )}
                  {status === ACTIVE && !timesheet && (
                    <>
                      {shift?.group?.name || t`Starting shift`} | <FormatDateTimeCompact value={moment()} />{" "}
                      <Trans>start</Trans>
                    </>
                  )}
                  {status === PAUSED && Boolean(shift) && (
                    <>
                      {shift.group?.name || shift?.otherGroupName || t`Unknown area`} |{" "}
                      <FormatDateTimeCompact value={moment(shift.startsAt)} /> -{" "}
                      <FormatDateTimeCompact value={moment(shift.endsAt)} />
                    </>
                  )}
                  {status === PAUSED && !shift && (
                    <>
                      {timesheet?.group?.name || t`Loading`} |{" "}
                      <FormatDateTimeCompact value={moment(timesheet?.startedAt || moment())} /> <Trans>start</Trans>
                    </>
                  )}
                  {status === ENDED && Boolean(nextShift) && (
                    <>
                      Next shift:{" "}
                      {nextShift.location?.name ||
                        nextShift.otherLocationName ||
                        nextShift.otherGroupName ||
                        t`Unknown location`}{" "}
                      | <FormatDateTimeCompact value={moment(nextShift.startsAt)} /> -{" "}
                      <FormatTime value={moment(nextShift.endsAt)} />
                    </>
                  )}
                  {status === ENDED && !nextShift && <Trans>Next shift not yet available, check back later</Trans>}
                </Box>
              </ColumnBox>
            </RowBox>
            <RowBox>
              <RowBox>
                {[NOT_SCHEDULED, SCHEDULED].includes(status) && (
                  <Trans context="Contraction of it is">
                    It's
                    <Box className={classes.timeClock}>
                      <Clock timezone={location.timeZone} ticking format="h:mma" />
                    </Box>
                  </Trans>
                )}
                {status === ACTIVE && Boolean(timesheet) && (
                  <>
                    <Box mr={0.5}>
                      <IntervalRenderer delay={60000}>
                        {() => (
                          <strong>{(moment().diff(moment(timesheet.startedAt), "minutes") / 60).toFixed(2)}</strong>
                        )}
                      </IntervalRenderer>
                    </Box>
                    <Trans context="hours worked">hrs worked</Trans>
                  </>
                )}
                {status === PAUSED && Boolean(timesheet) && (
                  <>
                    <Trans context="Time spent on a break">Time on break</Trans>:
                    <IntervalRenderer delay={5000}>
                      {() => {
                        const minutes = timesheet.secondsOnBreak / 60
                        if (minutes < 1) {
                          return (
                            <Box ml={0.5}>
                              <strong>
                                <Trans>a few seconds</Trans>
                              </strong>
                            </Box>
                          )
                        }
                        return (
                          <>
                            <Box mx={0.5}>
                              <strong>{Math.round(minutes)}</strong>
                            </Box>
                            min
                          </>
                        )
                      }}
                    </IntervalRenderer>
                  </>
                )}
              </RowBox>
              {status === ACTIVE && (
                <ShiftButton
                  text={t`Take break`}
                  icon=""
                  className={classes.pauseButton}
                  onClick={handlePause}
                  disabled={loading}
                  loading={pauseLoading}
                />
              )}
              {status === PAUSED && (
                <ShiftButton
                  text={t`Resume shift`}
                  icon=""
                  className={classes.resumeButton}
                  onClick={handleResume}
                  disabled={loading}
                  loading={resumeLoading}
                />
              )}
              {[NOT_SCHEDULED, SCHEDULED].includes(status) && (
                <StartShiftButton
                  text={t`Start shift`}
                  className={classes.startButton}
                  onClick={handleStart}
                  shift={shift}
                  disabled={loading}
                  loading={startLoading}
                />
              )}
              {status === ACTIVE && (
                <ShiftButton text={t`End shift`} color="secondary" onClick={handleEnd} disabled={loading} />
              )}
            </RowBox>
          </RowBox>
        </Container>
      </Toolbar>
    </ErrorBoundary>
  )
}

export { PunchClock }
