import React, { useMemo, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Chip, Divider, Switch, useMediaQuery } from "@material-ui/core"
import moment from "moment"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import { useAuth } from "../../services"
import FooterButton from "../Buttons/ActionCardFooterButton"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { toId, useActionUtils } from "../../utils"
import { Avatar } from "../Avatar"
import { Caption } from "../Headings"
import { ACTION_STATUS, useLazyQueryJobStepActions } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { Icon } from "../Icon"
import TimeAgo from "../TimeAgo"
import CenteredPopover from "../Popovers/CenteredPopover"
import { NotificationFromAvatar } from "../NotificationList/NotificationFrom"

const useStyles = makeStyles((theme) => ({
  actionRaisedIcon: {
    color: theme.palette.raised.main,
  },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popover: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw",
      maxHeight: 300,
    },
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
  },
  list: {
    backgroundColor: theme.palette.grey[50],
    maxHeight: 400,
    overflowY: "auto",
    width: "100%",
    flexDirection: "column-reverse",
  },
  row: {
    color: theme.palette.text.primary,
  },
  author: {
    width: 30,
    height: 30,
  },
  text: {
    fontSize: 12,
    wordBreak: "break-word",
    wordWrap: "break-word",
    minWidth: "1%",
  },
  switch: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  resolvedChip: {
    fontWeight: "600",
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
  },
  badge: {
    "&>.MuiBadge-dot": {
      marginLeft: -9,
      backgroundColor: theme.palette.error.main,
    },
  },
}))

const isActionResolved = (item) => item.status === ACTION_STATUS.RESOLVED

const RaiseActionFooterButton = ({ job, step, isPreview, isScheduled, onCreateAction, required }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()
  const { getNavigateToLink } = useActionUtils()
  const [jobStepActions, { data, loading }] = useLazyQueryJobStepActions()
  const [open, setOpen] = useState(false)
  const [showResolved, setShowResolved] = useState(false)
  const xs = useMediaQuery((theme) => theme.breakpoints.down("xs"))

  const { hasResolvedActions, unresolvedActions } = step

  const actions = useMemo(
    () =>
      data?.job.step.actions
        ? [...data.job.step.actions]
            .filter((item) => showResolved || !isActionResolved(item))
            .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1))
        : [],
    [data, showResolved],
  )

  const handleToggleResolved = () => {
    setShowResolved(!showResolved)
  }

  const handleOpen = async () => {
    if (hasResolvedActions || unresolvedActions?.length) {
      // show popover
      setOpen(true)
      // load actions for list
      await jobStepActions({ variables: { job: toId(job), step: toId(step) } })
      return
    }
    // no actions -> straight to create one
    onCreateAction && onCreateAction(step)
  }

  const handleRaiseAction = () => {
    onCreateAction && onCreateAction(step)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (!hasFeature("actions")) {
    return null
  }

  const hasUnresolvedActions = Boolean(unresolvedActions?.length)

  const noUnresolvedActionsInData = !actions.find((item) => !isActionResolved(item))

  return (
    <Box mr={xs ? 1 : 1.5}>
      {!hasUnresolvedActions && (
        <FooterButton
          icon="action"
          label={<Trans>Raise action</Trans>}
          onClick={handleOpen}
          disabled={isPreview || isScheduled}
          preview={isPreview}
          badgeProps={{
            invisible: !required,
            className: classes.badge,
          }}
        />
      )}
      {hasUnresolvedActions && (
        <FooterButton
          iconClassName={classes.actionRaisedIcon}
          icon="action-raised"
          label={t`Action raised`}
          onClick={handleOpen}
        />
      )}
      <CenteredPopover open={open} onClose={handleClose}>
        <Box className={classes.popover}>
          <Box className={classes.list} width="100%">
            {/* {loading && (
              <Box display="flex" justifyContent="center" py={1}>
                <LoadingSpinner delay={false} />
              </Box>
            )} */}
            {!loading &&
              actions.map((item, index) => {
                const resolved = isActionResolved(item)

                return (
                  <React.Fragment key={toId(item)}>
                    {index > 0 && <Divider />}
                    <RowBox
                      component={NavLink}
                      to={getNavigateToLink(item)}
                      p={1}
                      flexGrow={1}
                      alignItems="center"
                      className={classes.row}
                    >
                      <Box mr={1}>
                        <Avatar {...item.author} className={classes.author} />
                      </Box>
                      <ColumnBox flexGrow={1}>
                        <FlexBox flexGrow={1} className={classes.text}>
                          {item.title}
                        </FlexBox>
                        <Caption mb={0}>
                          <TimeAgo date={item.createdAt} />
                        </Caption>
                      </ColumnBox>
                      {resolved && (
                        <Box ml={1}>
                          <Chip label={<Trans>Resolved</Trans>} className={classes.resolvedChip} size="small" />
                        </Box>
                      )}
                      <RowBox ml={resolved ? 0.25 : 1} alignItems="center">
                        <Icon name="right" />
                      </RowBox>
                    </RowBox>
                  </React.Fragment>
                )
              })}
            {!loading && hasUnresolvedActions && noUnresolvedActionsInData && (
              <>
                {Boolean(actions.length) && <Divider />}
                <RowBox p={1} flexGrow={1} alignItems="center" className={classes.row}>
                  <Box mr={1}>
                    <NotificationFromAvatar className={classes.author} />
                  </Box>
                  <ColumnBox flexGrow={1}>
                    <FlexBox flexGrow={1} className={classes.text}>
                      <Trans>This step has unresolved actions assigned to other users</Trans>
                    </FlexBox>
                  </ColumnBox>
                </RowBox>
              </>
            )}
          </Box>
          <RowBox p={1} justifyContent={hasResolvedActions ? "space-between" : "flex-end"}>
            {hasResolvedActions && (
              <RowBox className={classes.switch}>
                <Switch size="small" color="primary" checked={showResolved} onChange={handleToggleResolved} />
                <Box ml={0.25}>
                  <Trans>Show resolved</Trans>
                </Box>
              </RowBox>
            )}
            <Button color="default" disabled={loading} size="small" onClick={handleRaiseAction}>
              {!loading && (
                <RowBox>
                  <Icon name="action" />
                  <Box ml={0.5}>
                    <Trans>Raise new action</Trans>
                  </Box>
                </RowBox>
              )}
              {loading && <LoadingSpinner size="24px" delay={false} />}
            </Button>
          </RowBox>
        </Box>
      </CenteredPopover>
    </Box>
  )
}

export default RaiseActionFooterButton
