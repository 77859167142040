import { memo, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { t } from "@lingui/macro"
import { LinkButton } from ".."

const useStyles = makeStyles((theme) => ({
  button: {
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    padding: 0,
    lineHeight: "inherit",
    verticalAlign: "inherit",
  },
}))

const ShowMore = memo(function ShowMore({ text = t`Show More`, children }) {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  if (!show) {
    return (
      <LinkButton onClick={() => setShow(true)} className={classes.button}>
        {text}
      </LinkButton>
    )
  }

  return <>{children}</>
})

export { ShowMore }
