import { useMutation, gql } from "@apollo/client"

const ORGANISATION_FIELDS = `
    id
    name
    industry
    logo { key }
    settings { 
    theme 
      { 
        appIcon { key }
        appLogo { key }
        appName
        appLogoLandscape
        primaryColor 
        secondaryColor 
      } 
    }
`

const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisation($id: ID!, $name: String!, $industry: String, $logo: ID, $settings: OrganisationSettingsInput) {
    updateOrganisation(id: $id, name: $name, industry: $industry, logo: $logo, settings: $settings) {
      ${ORGANISATION_FIELDS}
    } 
  }
`

const useMutationUpdateOrganisation = () => useMutation(UPDATE_ORGANISATION_MUTATION)

export { useMutationUpdateOrganisation }
