import React from "react"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { Badge, Box } from "@material-ui/core"
import { ActionCard } from "./ActionCard"
import { BlockedChip, CancelledChip, InProgressChip, TimeAgo, UnconfirmedChip } from ".."
import NotificationMessage from "../NotificationList/NotificationMessage"
import { useNotificationUtils } from "../../utils"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { ACTION_PRIORITY, ACTION_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  status: {
    display: "flex",
    gap: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  dueAt: {
    color: theme.palette.text.secondary,
  },
  message: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "18px",
    "&>strong": {
      color: theme.palette.primary.main,
    },
    marginBottom: 8,
  },
  actionRaisedIcon: {
    color: theme.palette.raised.main,
  },
  badgeDot: {
    cursor: "pointer",
    height: 12,
    minWidth: 12,
    borderRadius: 6,
    marginTop: 12,
    marginLeft: 2,
    "&:before": {
      content: "''",
      position: "absolute",
      height: 24,
      width: 24,
      borderRadius: 12,
      backgroundColor: ({ badgeColor }) => badgeColor || theme.palette.raised.main,
      opacity: 0,
      animation: "beacon 1.2s infinite",
    },
  },
}))

const PriorityIcon = ({ priority }) => {
  const classes = useStyles()

  if (!priority) {
    return null
  }

  if (priority === ACTION_PRIORITY.CRITICAL) {
    return (
      <Badge
        color="error"
        variant="dot"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        overlap="rectangular"
        classes={{
          dot: classes.badgeDot,
        }}
      >
        <Icon
          color={priority === ACTION_PRIORITY.CRITICAL ? "secondary" : "inherit"}
          name={`priority-${priority}`}
          throwForUnmapped={false}
        />
      </Badge>
    )
  }

  return <Icon name={`priority-${priority}`} throwForUnmapped={false} />
}

const UnresolvedActionActionCard = ({ notification }) => {
  const classes = useStyles()
  const { getNavigateToLink } = useNotificationUtils()

  if (!notification.action) {
    return null
  }

  const { title, status, dueAt, priority } = notification.action

  return (
    <ActionCard
      title={title || ""}
      detail={<NotificationMessage notification={notification} className={classes.message} showMessage={false} />}
      cardProps={{ component: NavLink, to: { pathname: getNavigateToLink(notification) } }}
      overdue={dueAt && new Date(dueAt) < new Date()}
      rightChildren={
        <Box mt={-0.5} mr={-0.5}>
          <Icon name="action-raised" className={classes.actionRaisedIcon} />
        </Box>
      }
    >
      <RowBox className={classes.status}>
        <PriorityIcon priority={priority} />
        {(() => {
          if (status === ACTION_STATUS.IN_PROGRESS) {
            return <InProgressChip className={classes.chip} />
          }
          if (status === ACTION_STATUS.BLOCKED) {
            return <BlockedChip className={classes.chip} />
          }
          if (status === ACTION_STATUS.CANCELLED) {
            return <CancelledChip className={classes.chip} />
          }
          return <UnconfirmedChip label="Unresolved" className={classes.chip} />
        })()}

        {!!dueAt && (
          <Box className={classes.dueAt}>
            Due <TimeAgo date={dueAt} />
          </Box>
        )}
      </RowBox>
    </ActionCard>
  )
}

export { UnresolvedActionActionCard }
