import React from "react"
import { Trans } from "@lingui/macro"
import { Content, AreaHeader, NotificationList } from "../../components"
import { useQueryNotifications } from "../../data"

const Notifications = () => {
  const result = useQueryNotifications()

  return (
    <>
      <AreaHeader title={<Trans>Notifications</Trans>} titleIcon="notifications-dark" />

      <Content full start>
        <NotificationList popover={false} {...result} limit={null} spinnerSize={60} />
      </Content>
    </>
  )
}

export default Notifications
