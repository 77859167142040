import { Button, Box } from "@material-ui/core"
import React, { useState } from "react"
import { Trans } from "@lingui/macro"
import { Caption, FieldSectionHeading, Icon, LoadingSpinner, useSnackbar, Checkbox } from ".."
import { useMutationIntegrationRunOperation, useMutationIntegrationToggleScheduledOperation } from "../../data"
import { toId } from "../../utils"
import { ColumnBox } from "../Boxes"

const IntegrationOperation = ({ integration, operation: { id, name, description, method, canSchedule } }) => {
  const [runOperation, { loading }] = useMutationIntegrationRunOperation()
  const [toggleScheduledOperation, { loading: toggleLoading }] = useMutationIntegrationToggleScheduledOperation()
  const [run, setRun] = useState(false)
  const snackbar = useSnackbar()

  const checked = integration?.scheduledOperations?.find((operation) => toId(operation) === toId(id))

  const handleClick = async () => {
    if (!run) {
      setRun(true)
      return
    }
    const response = await runOperation({ variables: { id: toId(integration), method } })
    setRun(false)
    if (response?.data?.integration.runOperation) {
      snackbar.showMessage({
        icon: <Icon name="time" />,
        message: `Operation started, see Events tab for result`,
      })
    }
  }

  const handleAutoSchedule = async () => {
    await toggleScheduledOperation({ variables: { id: toId(integration), operationId: toId(id) } })
  }

  return (
    <ColumnBox mb={3}>
      <FieldSectionHeading mb={0}>{name}</FieldSectionHeading>
      <Caption mb={1}>{description}</Caption>

      <Box display="flex" justifyContent="flex-start" sx={{ gap: 10 }}>
        <Button variant="contained" onClick={handleClick} disabled={loading}>
          {!loading && !run && <>Run this operation</>}
          {!loading && run && <>Click again to run this operation!</>}
          {loading && <LoadingSpinner size="24px" delay={false} />}
        </Button>

        {canSchedule && (
          <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
            <Checkbox color="primary" checked={checked} onClick={handleAutoSchedule} disabled={toggleLoading} />
            <Trans>Run automatically</Trans>
          </Box>
        )}
      </Box>
    </ColumnBox>
  )
}

const IntegrationOperations = ({ integration }) => {
  const { integrationProvider } = integration
  if (!integrationProvider?.operations?.length) {
    return null
  }

  return integrationProvider.operations.map((operation) => (
    <IntegrationOperation key={operation.method} integration={integration} operation={operation} />
  ))
}

export { IntegrationOperations }
