import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { Hidden, Chip, Box } from "@material-ui/core"
import { WebVersion, useAuth } from "../../services"
import { MobileBottomNav } from "../MobileBottomNav"

const useStyles = makeStyles((theme) => ({
  version: {
    display: "flex",
    marginRight: 15,
    position: "fixed",
    right: 15,
    bottom: 15,
    color: theme.palette.text.primary,
    fontSize: 12,
    opacity: 0.7,
    width: 150,
    height: 100,
  },
  mobileFooterSpacer: {
    height: theme.dimensions.footer.mobileBottomNav.height,
  },
}))

const displayVersionForMs = 5000

const showVersionOnLoad = false

const Footer = () => {
  const classes = useStyles()
  const auth = useAuth()
  const [showVersion, setShowVersion] = useState(false)

  return (
    <Box onDoubleClick={() => setShowVersion(!showVersion)}>
      <DisplayVersion forceShow={showVersion} />
      {auth.authed && (
        <>
          <Hidden smUp>
            <div className={classes.mobileFooterSpacer} />
          </Hidden>
          <Hidden smUp>
            <MobileBottomNav />
          </Hidden>
        </>
      )}
    </Box>
  )
}

const DisplayVersion = ({ forceShow }) => {
  const classes = useStyles()
  const [showVersion, setShowVersion] = useState(showVersionOnLoad)

  useEffect(() => {
    if (showVersionOnLoad) {
      setTimeout(() => {
        setShowVersion(false)
      }, displayVersionForMs)
    }
  }, [])

  return (
    <>
      {(showVersion || forceShow) && (
        <Box className={classes.version}>
          <Box display="flex" alignSelf="flex-end">
            <Chip label={`v${WebVersion}`} />
          </Box>
        </Box>
      )}
    </>
  )
}

export default Footer
