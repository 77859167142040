import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_191)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C3 4.23858 5.23858 2 8 2H26C28.7614 2 31 4.23858 31 7V19C31 21.7614 28.7614 24 26 24H14C13.4477 24 13 23.5523 13 23C13 22.4477 13.4477 22 14 22H26C27.6569 22 29 20.6569 29 19V7C29 5.34315 27.6569 4 26 4H8C6.34315 4 5 5.34315 5 7V10C5 10.5523 4.55228 11 4 11C3.44772 11 3 10.5523 3 10V7Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 14H3V28H10V14ZM3 12C1.89543 12 1 12.8954 1 14V28C1 29.1046 1.89543 30 3 30H10C11.1046 30 12 29.1046 12 28V14C12 12.8954 11.1046 12 10 12H3Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2929 7.29289C17.6834 6.90237 18.3166 6.90237 18.7071 7.29289L22.7071 11.2929C23.0976 11.6834 23.0976 12.3166 22.7071 12.7071C22.3166 13.0976 21.6834 13.0976 21.2929 12.7071L17.2929 8.70711C16.9024 8.31658 16.9024 7.68342 17.2929 7.29289Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289L21.7071 16.2929C22.0976 16.6834 22.0976 17.3166 21.7071 17.7071C21.3166 18.0976 20.6834 18.0976 20.2929 17.7071L11.2929 8.70711C10.9024 8.31658 10.9024 7.68342 11.2929 7.29289Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_191" x1="23" y1="7" x2="23" y2="13" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient id="paint1_linear_99_191" x1="22" y1="7" x2="22" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
