import { useMutation, gql } from "@apollo/client"
import { LOCATION_FIELDS } from "../locations"
import { INTEGRATION_FIELDS } from "./useQueryIntegrations"
import { ADMINABLE_USERS_FIELDS } from "../users/fragments/adminableUserFieldsFragment"
import { GROUP_FIELDS } from "../groups/fragments/groupFieldsFragment"

const INTEGRATION_PROVIDER_MUTATION_ENABLE = gql`
  mutation IntegrationProviderEnable($id: ID!) {
    integrationProvider(id: $id) {
      enable {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const INTEGRATION_MUTATION_ENABLE_EVENT = gql`
  mutation IntegrationEnableEvent($id: ID!, $event: String!) {
    integration(id: $id) {
      enableEvent(event: $event) {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const INTEGRATION_MUTATION_DISABLE_EVENT = gql`
  mutation IntegrationDisableEvent($id: ID!, $event: String!) {
    integration(id: $id) {
      disableEvent(event: $event) {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const INTEGRATION_MUTATION_TOGGLE_SCHEDULED_OPERATION = gql`
  mutation IntegrationToggleScheduledOperation($id: ID!, $operationId: ID!) {
    integration(id: $id) {
      toggleScheduledOperation(operationId: $operationId) {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const INTEGRATION_MUTATION_RUN_OPERATION = gql`
  mutation IntegrationRunOperation($id: ID!, $method: String!) {
    integration(id: $id) {
      runOperation(method: $method) {
        message
      }
    }
  }
`

const PAUSE_INTEGRATION_MUTATION = gql`
  mutation IntegrationPause($id: ID!) {
    integration(id: $id) {
      pause {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const RESUME_INTEGRATION_MUTATION = gql`
  mutation IntegrationResume($id: ID!) {
    integration(id: $id) {
      resume {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const DISABLE_INTEGRATION_MUTATION = gql`
  mutation IntegrationDisable($id: ID!) {
    integration(id: $id) {
      disable
    }
  }
`

const SET_ATTRIBUTE = gql`
  mutation IntegrationSetAttribute($id: ID!, $key: String!, $value: String) {
    integration(id: $id) {
      setAttribute(key: $key, value: $value) {
        ...IntegrationFields
      }
    }
  }
  ${INTEGRATION_FIELDS}
`

const SET_LOCATION_ATTRIBUTE = gql`
  mutation IntegrationSetLocationAttribute($id: ID!, $location: ID!, $key: String!, $value: String) {
    integration(id: $id) {
      setLocationAttribute(location: $location, key: $key, value: $value) {
        ...LocationFields
      }
    }
  }
  ${LOCATION_FIELDS}
`

const SET_LOCATION_GROUP_ATTRIBUTE = gql`
  mutation IntegrationSetLocationGroupAttribute($id: ID!, $location: ID!, $group: ID!, $key: String!, $value: String) {
    integration(id: $id) {
      setLocationGroupAttribute(location: $location, group: $group, key: $key, value: $value) {
        ...GroupFields
      }
    }
  }
  ${GROUP_FIELDS}
`

const SET_GROUP_ATTRIBUTE = gql`
  mutation IntegrationSetGroupAttribute($id: ID!, $group: ID!, $key: String!, $value: String) {
    integration(id: $id) {
      setGroupAttribute(group: $group, key: $key, value: $value) {
        ...GroupFields
      }
    }
  }
  ${GROUP_FIELDS}
`

const SET_USER_ATTRIBUTE = gql`
  mutation IntegrationSetUserAttribute($id: ID!, $user: ID!, $key: String!, $value: String) {
    integration(id: $id) {
      setUserAttribute(user: $user, key: $key, value: $value) {
        ...AdminableUsersFields
      }
    }
  }
  ${ADMINABLE_USERS_FIELDS}
`

const useMutationIntegrationEnable = () => useMutation(INTEGRATION_PROVIDER_MUTATION_ENABLE)

const useMutationIntegrationEnableEvent = () => useMutation(INTEGRATION_MUTATION_ENABLE_EVENT)

const useMutationIntegrationDisableEvent = () => useMutation(INTEGRATION_MUTATION_DISABLE_EVENT)

const useMutationIntegrationRunOperation = () => useMutation(INTEGRATION_MUTATION_RUN_OPERATION)

const useMutationIntegrationToggleScheduledOperation = () =>
  useMutation(INTEGRATION_MUTATION_TOGGLE_SCHEDULED_OPERATION)

const useMutationPauseIntegration = () => useMutation(PAUSE_INTEGRATION_MUTATION)

const useMutationResumeIntegration = () => useMutation(RESUME_INTEGRATION_MUTATION)

const useMutationDisableIntegration = () => useMutation(DISABLE_INTEGRATION_MUTATION)

const useMutationIntegrationSetAttribute = () => useMutation(SET_ATTRIBUTE)

const useMutationIntegrationSetLocationAttribute = () => useMutation(SET_LOCATION_ATTRIBUTE)

const useMutationIntegrationSetLocationGroupAttribute = () => useMutation(SET_LOCATION_GROUP_ATTRIBUTE)

const useMutationIntegrationSetGroupAttribute = () => useMutation(SET_GROUP_ATTRIBUTE)

const useMutationIntegrationSetUserAttribute = () => useMutation(SET_USER_ATTRIBUTE)

export {
  useMutationIntegrationEnable,
  useMutationIntegrationEnableEvent,
  useMutationIntegrationDisableEvent,
  useMutationIntegrationRunOperation,
  useMutationIntegrationToggleScheduledOperation,
  useMutationPauseIntegration,
  useMutationResumeIntegration,
  useMutationDisableIntegration,
  useMutationIntegrationSetAttribute,
  useMutationIntegrationSetLocationAttribute,
  useMutationIntegrationSetLocationGroupAttribute,
  useMutationIntegrationSetGroupAttribute,
  useMutationIntegrationSetUserAttribute,
}
