import React, { useState, useEffect, useRef } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Close } from "@material-ui/icons"
import { useDebounce } from "@react-hook/debounce"
import { Trans, t } from "@lingui/macro"
import { LoadingSpinner } from ".."
import { LinkButton } from "../LinkButton"
import { useFocus } from "../../utils"
import { ListVirtualizer } from "../Lists/ListVirtualizer"

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: ({ wide }) => theme.dimensions.dialogs.pickers[wide ? "wideWidth" : "width"],
    marginLeft: ({ xs }) => (xs ? 0 : null),
    marginRight: ({ xs }) => (xs ? 0 : null),
    height: 600,
  },
  dialogContent: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
  },
  titleSearch: {
    marginRight: 0,
    padding: theme.spacing(2.5, 3, 2, 3),
  },
  description: {
    fontSize: 12,
  },
  actions: {
    padding: `19px ${theme.spacing(3)}px`,
  },
  buttons: {
    color: theme.palette.primary.main,
  },
  dialogGrid: {
    height: 48,
    width: 48,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    "&&>div": {
      height: 40,
      width: 40,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  listItem: {
    padding: theme.spacing(0.5, 2, 0.5, 0),
    "&:first-child": {
      paddingTop: theme.spacing(1),
    },
  },
  fullHeight: {
    height: "100%",
  },
}))

const getData = (text, config, depth, { picked, subPicked }) => {
  const searchTextLower = text.toLowerCase()
  if (depth > 1 && picked) {
    return picked.data
  }
  if (config.onSearch) return config.onSearch(searchTextLower, config.data, { picked, subPicked })

  const result =
    config.data &&
    (searchTextLower
      ? config.data.filter((d) => JSON.stringify(d).toLowerCase().includes(searchTextLower))
      : config.data)
  if (result && config.limit) return result.slice(0, config.limit)
  return result
}

const PickerDialog = ({
  open,
  onClose,
  title,
  description,
  config,
  initialPicked,
  initialSubPicked,
  disableInitialPicked,
  cy = "PickerDialog",
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs, wide: config.wide })
  const { loading, error, data, getListItem, search, onPickedChanged, closeText, hasClear } = config
  const [picked, setPicked] = useState([])
  const [subPicked, setSubPicked] = useState([])
  const [searchText, setSearchText] = useState("")
  const [filteredData, setFilteredData] = useDebounce(null, 150)
  const [depth, setDepth] = useState(1)
  const [inputRef, setInputFocus] = useFocus()
  const initRef = useRef(false)

  const isSingle = (depth > 1 ? picked : config).type === "single"

  const configDepth = config.depth || 1

  const getSource = () => (depth === 2 ? [subPicked, setSubPicked] : [picked, setPicked]) // max 2 levels for now

  // need to clean this up / make unlimited depth
  const [source] = getSource()
  const sourceHasValue = source != null || (source && source.length)

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      initialPicked && setPicked(initialPicked)
      initialSubPicked && setSubPicked(initialSubPicked)
    }
  }, [initialPicked, initialSubPicked])

  useEffect(() => {
    if (!(config && config.search && config.search.required) && !filteredData && config.data && !loading && open) {
      setFilteredData(getData("", config, depth, { picked, subPicked }))
    }
    if (config.search && !picked.length) setInputFocus()
  }, [loading, config, setFilteredData, filteredData, depth, picked, subPicked, setInputFocus, open])

  const handleToggleChecked = (e, item) => {
    const [destination, setDestination] = getSource()
    if (isSingle) {
      setDestination(item)
      onPickedChanged({
        picked: depth === 1 ? item : picked,
        subPicked: depth > 1 ? item : subPicked,
      })
    } else {
      let newPicked = []
      if (e.target.checked) newPicked = [...destination, item]
      else newPicked = [...destination.filter((p) => p.id !== item.id)]
      setDestination(newPicked)
      onPickedChanged({
        picked: depth === 1 ? newPicked : picked,
        subPicked: depth > 1 ? newPicked : subPicked,
      })
    }
  }

  const isChecked = (d) => {
    if (!source) return false
    if (isSingle) return source.id === d.id
    return source.filter((p) => p.id === d.id).length > 0
  }

  const handleSelectClick = () => {
    if (depth < configDepth && picked.data && picked.data.length) {
      setDepth(depth + 1)
      setFilteredData(picked.data)
    } else {
      config.onComplete && config.onComplete({ picked, subPicked })
      handleClose()
    }
  }

  const handleBackClick = () => {
    setSubPicked(picked.type === "single" ? null : [])
    setPicked(config.type === "single" ? null : [])
    setDepth(1)
  }

  const handleSearchTextChange = (e) => {
    const { value } = e.target
    setSearchText(value)
    setFilteredData(() => getData(value, config, depth, { picked, subPicked }))
  }

  const handleClear = () => {
    onPickedChanged({ picked: depth === 1 ? disableInitialPicked || [] : picked, subPicked: [] })
    if (depth === 1) setPicked(disableInitialPicked || [])
    setSubPicked([])
    if (search) setSearchText("")
    // setFilteredData(() => getData("", config, depth, { picked, subPicked }))
  }

  const handleClearSearchText = () => {
    setSearchText("")
    setFilteredData(() => getData("", config, depth, { picked, subPicked }))
  }

  const handleClose = () => {
    setFilteredData(null)
    onClose && onClose()
  }

  const handleShouldUpdateDialogState = ({ picked: newPicked }) => {
    setPicked([...newPicked])
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      classes={{ paper: classes.dialog }}
      data-cy={cy}
    >
      <DialogTitle id="form-dialog-title" className={classes.title}>
        {config.getDynamicTitle ? config.getDynamicTitle({ picked, subPicked, depth }) : title}
      </DialogTitle>
      {description && (
        <Box px={3}>
          <Typography variant="caption">{description}</Typography>
        </Box>
      )}
      {search && (
        <DialogTitle className={classes.titleSearch}>
          <TextField
            label={loading ? t`Loading...` : search.placeholder}
            value={searchText}
            onChange={handleSearchTextChange}
            variant="outlined"
            disabled={loading}
            fullWidth
            inputRef={inputRef}
            InputProps={{
              endAdornment: searchText ? (
                <IconButton title="Filters" size="small" onClick={handleClearSearchText}>
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent || null}>
        <Box className={classes.fullHeight}>
          {loading && <LoadingSpinner />}
          {error && `Error! ${error.message}`}
          {filteredData && filteredData.length > 0 && (
            <>
              {config.layout === "grid" && (
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                  justifyItems="center"
                  data-cy={`${cy}-grid`}
                >
                  {filteredData.map((d, index) => (
                    <Box className={classes.dialogGrid} key={index} m={1}>
                      {getListItem(d, index, isChecked(d), handleToggleChecked, {
                        picked,
                        subPicked,
                        onPickedChanged: config.onPickedChanged,
                        onShouldUpdateDialogState: handleShouldUpdateDialogState,
                      })}
                    </Box>
                  ))}
                </Box>
              )}
              {config.layout !== "grid" && (
                <ListVirtualizer
                  items={filteredData}
                  itemContent={(index, d) =>
                    getListItem(d, index, isChecked(d), handleToggleChecked, {
                      picked,
                      subPicked,
                      onPickedChanged: config.onPickedChanged,
                      onShouldUpdateDialogState: handleShouldUpdateDialogState,
                    })
                  }
                  components={{
                    Item: ({ children, ...props }) => {
                      return (
                        <Box {...props} className={classes.listItem}>
                          {children}
                        </Box>
                      )
                    },
                  }}
                />
              )}
            </>
          )}
          {filteredData && filteredData.length === 0 && (
            <Box display="flex" flexGrow={1} justifyContent="center" mt={1}>
              <strong>
                {searchText
                  ? t`No matching items`
                  : data && data.length && config.search
                    ? t`Enter a search term`
                    : t`No items`}
              </strong>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Box mr="auto">
          {hasClear && ((depth === 1 && picked.length) || (depth === 2 && subPicked.length)) && (
            <LinkButton className={classes.buttons} onClick={handleClear}>
              <Trans>Clear</Trans>
            </LinkButton>
          )}
          {depth < configDepth && (
            <LinkButton className={classes.buttons} onClick={handleClose}>
              <Trans>Cancel</Trans>
            </LinkButton>
          )}
        </Box>
        {configDepth > 1 && depth === configDepth && (
          <LinkButton className={classes.buttons} onClick={handleBackClick}>
            <Trans>Back</Trans>
          </LinkButton>
        )}
        <LinkButton
          disabled={!sourceHasValue}
          className={classes.buttons}
          onClick={handleSelectClick}
          data-cy={cy ? `${cy}-close` : null}
        >
          {config.getDynamicCloseText
            ? config.getDynamicCloseText({ picked, subPicked, depth })
            : closeText || t`Select`}
        </LinkButton>
      </DialogActions>
    </Dialog>
  )
}

export default PickerDialog
