import React, { useState } from "react"
import { Box, Button, Grid, makeStyles, Slide, Tooltip } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { useParams, useHistory } from "react-router-dom"
import { CreatorMaster } from "../Creators"
import { Icon } from "../Icon"
import { Caption } from "../Headings"
import { LoadingSpinner } from "../LoadingSpinner"
import { toId, useMountEffect } from "../../utils"
import { IntegrationProviderInfo } from "./IntegrationProviderInfo"
import { useIntegrationProviderConnector } from "../../utils/useIntegrationProviderConnector"
import { useMutationIntegrationEnable, useQueryIntegrationProviders, useQueryIntegrations } from "../../data"

const useStyles = makeStyles(() => ({
  contact: {
    textAlign: "center",
  },
}))

const IntegrationProviderConnector = ({ open, onClose, onConnected, integrationProvider }) => {
  const classes = useStyles()
  const history = useHistory()
  const { refetch: refetchIntegrations } = useQueryIntegrations()
  const { refetch: refetchProviders } = useQueryIntegrationProviders()
  const { startConnectionRequest, checkConnectionResult, completeConnectionResult } = useIntegrationProviderConnector()
  const [enableManualIntegration] = useMutationIntegrationEnable()
  const { integrationProviderId } = useParams()
  const [connecting, setConnecting] = useState(false)
  const [reconnect, setReconnect] = useState(false)
  const [enabling, setEnabling] = useState(false)
  const [error, setError] = useState(null)

  useMountEffect(() => {
    const result = checkConnectionResult()
    if (result.code) {
      if (result.reconnect) {
        setReconnect(true)
      }
      setConnecting(true)
      completeConnectionResult(integrationProviderId, handleConnected, handleConnectError)
    }
  })

  const handleConnect = () => {
    setConnecting(true)
    startConnectionRequest(integrationProvider)
  }

  const handleConnected = (integration) => {
    onConnected && onConnected(integration)
  }

  const handleConnectError = (error) => {
    setError(error)
    setConnecting(false)
  }

  const handleEnable = async () => {
    setEnabling(true)
    const result = await enableManualIntegration({ variables: { id: integrationProviderId } })
    if (result.data) {
      await refetchProviders()
      await refetchIntegrations()
      history.push(`/account/integrations/${toId(result.data.integrationProvider.enable)}`)
      return
    }
    setError("Failed to enable integration")
    setEnabling(false)
  }

  const handleClose = (update) => {
    onClose && onClose(update)
  }

  if (!integrationProvider) {
    return null
  }

  const { name, link, connect, canEnable } = integrationProvider

  const form = (
    <Box mb={2}>
      {error && connect && (
        <Slide in>
          <Box mb={2}>
            <Alert severity="error">
              <AlertTitle>Failed to complete connection</AlertTitle>
              An error occurred while attempting to connect {name} to your Operandio account. Our technicians are
              automatically notified of the error and will work to resolve it quickly where applicable. Please contact
              Operandio support through the help menu for any assistance in the mean time. Please also refer to any
              other relevant error that may have been shown.
            </Alert>
          </Box>
        </Slide>
      )}
      {error && !connect && (
        <Slide in>
          <Box mb={2}>
            <Alert severity="error">
              <AlertTitle>Failed to enable integration</AlertTitle>
              An error occurred while attempting to enable {name} in your Operandio account. Our technicians are
              automatically notified of the error and will work to resolve it quickly where applicable. Please contact
              Operandio support through the help menu for any assistance in the mean time. Please also refer to any
              other relevant error that may have been shown.
            </Alert>
          </Box>
        </Slide>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <IntegrationProviderInfo integrationProvider={integrationProvider} />
        </Grid>
        <Grid item xs={12} md={4}>
          {canEnable && connect && (
            <Tooltip title={`Connect to ${name} and authorise access from Operandio`}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConnect}
                  fullWidth
                  disabled={connecting || (reconnect && error)}
                >
                  {!connecting && <>Connect {name}</>}
                  {connecting && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              </span>
            </Tooltip>
          )}
          {canEnable && !connect && (
            <Tooltip title={`Enable and configure ${name}`}>
              <span>
                <Button variant="contained" color="primary" onClick={handleEnable} fullWidth disabled={enabling}>
                  {!enabling && <>Enable {name}</>}
                  {enabling && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              </span>
            </Tooltip>
          )}
          {link && (
            <Box mt={canEnable ? 1 : 0}>
              <Tooltip title={`Visit external link: ${link}`}>
                <Button variant="contained" href={link} target="_blank" fullWidth>
                  <Icon name="external-link" />
                  <Box ml={1}>Visit website</Box>
                </Button>
              </Tooltip>
            </Box>
          )}
          {!canEnable && (
            <Box mt={1} justifyItems="center" className={classes.contact}>
              <Caption>Contact us for further details on enabling this integration</Caption>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )

  return <CreatorMaster open={open} title={name} form={form} onClose={handleClose} />
}

export { IntegrationProviderConnector }
