import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_323)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.53 22.6C10.2648 22.6 10.0104 22.4946 9.82291 22.3071C9.63537 22.1195 9.53001 21.8652 9.53001 21.6V18.154C7.33173 18.0523 5.26043 17.0948 3.75886 15.486C2.25729 13.8773 1.44459 11.745 1.49448 9.54491C1.54437 7.34485 2.45289 5.25158 4.02585 3.71256C5.5988 2.17353 7.71139 1.31087 9.91201 1.30897H15.058C17.2664 1.30629 19.3875 2.17058 20.9651 3.71588C22.5427 5.26119 23.4507 7.36401 23.4936 9.57193C23.5366 11.7799 22.7112 13.9164 21.1949 15.522C19.6787 17.1275 17.5928 18.0737 15.386 18.157L11.237 22.306C11.1443 22.399 11.0341 22.4729 10.9128 22.5233C10.7915 22.5738 10.6614 22.5998 10.53 22.6ZM9.91201 3.30897C8.20747 3.30897 6.57274 3.98609 5.36744 5.19139C4.16214 6.39669 3.48502 8.03142 3.48502 9.73597C3.48502 11.4405 4.16214 13.0752 5.36744 14.2805C6.57274 15.4858 8.20747 16.163 9.91201 16.163H10.53C10.7952 16.163 11.0496 16.2683 11.2371 16.4559C11.4247 16.6434 11.53 16.8977 11.53 17.163V19.184L14.258 16.456C14.4455 16.2684 14.6998 16.163 14.965 16.163H15.058C16.7626 16.163 18.3973 15.4858 19.6026 14.2805C20.8079 13.0752 21.485 11.4405 21.485 9.73597C21.485 8.03142 20.8079 6.39669 19.6026 5.19139C18.3973 3.98609 16.7626 3.30897 15.058 3.30897H9.91201Z"
      fill={primary}
    />
    <path
      d="M23.036 30.69C22.7708 30.6899 22.5165 30.5845 22.329 30.397L19.035 27.1C17.8819 27.0357 16.7623 26.6888 15.7748 26.09C14.7872 25.4912 13.9621 24.6587 13.372 23.666C13.2483 23.4385 13.2177 23.1719 13.2865 22.9223C13.3554 22.6727 13.5184 22.4595 13.7412 22.3277C13.964 22.1958 14.2293 22.1555 14.4812 22.2152C14.7332 22.2749 14.9521 22.4301 15.092 22.648C15.5336 23.3952 16.1615 24.0151 16.9143 24.4472C17.6671 24.8792 18.5191 25.1086 19.387 25.113C19.6743 25.1124 19.952 25.2166 20.168 25.406L22.036 27.272V26.117C22.036 25.8518 22.1414 25.5974 22.3289 25.4099C22.5164 25.2223 22.7708 25.117 23.036 25.117H23.536C24.7475 25.1195 25.9184 24.6804 26.8296 23.882C27.7408 23.0836 28.3298 21.9805 28.4865 20.7792C28.6431 19.5779 28.3567 18.3606 27.6807 17.3553C27.0048 16.3499 25.9856 15.6252 24.814 15.317C24.6841 15.2858 24.5617 15.229 24.4541 15.1498C24.3465 15.0706 24.2559 14.9707 24.1876 14.8559C24.1193 14.7411 24.0747 14.6137 24.0564 14.4814C24.0381 14.3491 24.0465 14.2144 24.0811 14.0854C24.1157 13.9563 24.1758 13.8356 24.2579 13.7301C24.34 13.6247 24.4423 13.5368 24.5589 13.4716C24.6755 13.4064 24.804 13.3653 24.9368 13.3506C25.0696 13.3358 25.204 13.3479 25.332 13.386C26.9302 13.8092 28.3264 14.7859 29.272 16.1422C30.2175 17.4984 30.6511 19.1462 30.4956 20.7922C30.34 22.4382 29.6054 23.9755 28.4225 25.1306C27.2396 26.2857 25.6852 26.9836 24.036 27.1V29.69C24.036 29.8878 23.9772 30.0813 23.8672 30.2458C23.7572 30.4102 23.6009 30.5384 23.418 30.614C23.297 30.6644 23.1671 30.6903 23.036 30.69Z"
      fill={primary}
    />
    <path
      d="M15.012 13.032H7.90002C7.6348 13.032 7.38044 12.9266 7.19291 12.7391C7.00537 12.5515 6.90002 12.2972 6.90002 12.032C6.90002 11.7667 7.00537 11.5124 7.19291 11.3249C7.38044 11.1373 7.6348 11.032 7.90002 11.032H15.01C15.2752 11.032 15.5296 11.1373 15.7171 11.3249C15.9047 11.5124 16.01 11.7667 16.01 12.032C16.01 12.2972 15.9047 12.5515 15.7171 12.7391C15.5296 12.9266 15.2752 13.032 15.01 13.032H15.012Z"
      fill={primary}
    />
    <path
      d="M24.013 21.033H18.9C18.6348 21.033 18.3804 20.9276 18.1929 20.7401C18.0054 20.5525 17.9 20.2982 17.9 20.033C17.9 19.7678 18.0054 19.5134 18.1929 19.3259C18.3804 19.1383 18.6348 19.033 18.9 19.033H24.011C24.2762 19.033 24.5306 19.1383 24.7181 19.3259C24.9057 19.5134 25.011 19.7678 25.011 20.033C25.011 20.2982 24.9057 20.5525 24.7181 20.7401C24.5306 20.9276 24.2762 21.033 24.011 21.033H24.013Z"
      fill={primary}
    />
    <path
      d="M18.012 9.03198H7.90002C7.63481 9.03198 7.38045 8.92663 7.19292 8.73909C7.00538 8.55155 6.90002 8.2972 6.90002 8.03198C6.90002 7.76677 7.00538 7.51241 7.19292 7.32488C7.38045 7.13734 7.63481 7.03198 7.90002 7.03198H18.01C18.2752 7.03198 18.5296 7.13734 18.7171 7.32488C18.9047 7.51241 19.01 7.76677 19.01 8.03198C19.01 8.2972 18.9047 8.55155 18.7171 8.73909C18.5296 8.92663 18.2752 9.03198 18.01 9.03198H18.012Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_323"
        x1="6.90002"
        y1="8.03198"
        x2="19.01"
        y2="8.03198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
