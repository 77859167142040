import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1004_2460)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29 2H26C26 1.45 25.55 1 25 1C24.45 1 24 1.45 24 2H8C8 1.45 7.55 1 7 1C6.45 1 6 1.45 6 2H3C2.45 2 2 2.45 2 3V29C2 29.55 2.45 30 3 30H29C29.55 30 30 29.55 30 29V3C30 2.45 29.55 2 29 2ZM6 4V6C6 6.55 6.45 7 7 7C7.55 7 8 6.55 8 6V4H24V6C24 6.55 24.45 7 25 7C25.55 7 26 6.55 26 6V4H28V10H4V4H6ZM4 28V12H28V28H4Z"
      fill={primary}
    />
    <path
      d="M11 19H7C6.45 19 6 18.55 6 18V15C6 14.45 6.45 14 7 14H11C11.55 14 12 14.45 12 15V18C12 18.55 11.55 19 11 19ZM8 17H10V16H8V17Z"
      fill={primary}
    />
    <path
      d="M25 19H21C20.45 19 20 18.55 20 18V15C20 14.45 20.45 14 21 14H25C25.55 14 26 14.45 26 15V18C26 18.55 25.55 19 25 19ZM22 17H24V16H22V17Z"
      fill={primary}
    />
    <path
      d="M18 19H14C13.45 19 13 18.55 13 18V15C13 14.45 13.45 14 14 14H18C18.55 14 19 14.45 19 15V18C19 18.55 18.55 19 18 19ZM15 17H17V16H15V17Z"
      fill={primary}
    />
    <path
      d="M11 26H7C6.45 26 6 25.55 6 25V22C6 21.45 6.45 21 7 21H11C11.55 21 12 21.45 12 22V25C12 25.55 11.55 26 11 26ZM8 24H10V23H8V24Z"
      fill={primary}
    />
    <path
      d="M25 26H21C20.45 26 20 25.55 20 25V22C20 21.45 20.45 21 21 21H25C25.55 21 26 21.45 26 22V25C26 25.55 25.55 26 25 26ZM22 24H24V23H22V24Z"
      fill={primary}
    />
    <path
      d="M18 26H14C13.45 26 13 25.55 13 25V22C13 21.45 13.45 21 14 21H18C18.55 21 19 21.45 19 22V25C19 25.55 18.55 26 18 26ZM15 24H17V23H15V24Z"
      fill={primary}
    />
    <path
      d="M20 8H12C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6H20C20.55 6 21 6.45 21 7C21 7.55 20.55 8 20 8Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_1004_2460" x1="11" y1="7" x2="21" y2="7" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
