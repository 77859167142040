import React from "react"

const Icon = ({ primary = "#1A1B43" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 13C5.35 13 4 14.35 4 16C4 17.65 5.35 19 7 19C8.65 19 10 17.65 10 16C10 14.35 8.65 13 7 13Z"
      fill={primary}
    />
    <path
      d="M13 16C13 14.35 14.35 13 16 13C17.65 13 19 14.35 19 16C19 17.65 17.65 19 16 19C14.35 19 13 17.65 13 16Z"
      fill={primary}
    />
    <path
      d="M22 16C22 14.35 23.35 13 25 13C26.65 13 28 14.35 28 16C28 17.65 26.65 19 25 19C23.35 19 22 17.65 22 16Z"
      fill={primary}
    />
  </svg>
)

export default Icon
