import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import { Chart, PieSeries, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { EventTracker, HoverState, Palette } from "@devexpress/dx-react-chart"
import { RowBox, ColumnBox } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    minWidth: 100,
    cursor: ({ clickable }) => (clickable ? "pointer" : "default"),
  },
  text: {
    maxWidth: 100,
    fontSize: 12,
  },
  value: {
    color: ({ complete, userCount }) =>
      !userCount ? theme.palette.chart.background : complete ? theme.palette.success.main : theme.palette.info.main,
    position: "absolute",
    textAlign: "center",
    fontSize: ({ size }) => Math.max(Math.floor(size * 0.2), 16),
    fontWeight: 600,
    marginTop: ({ height }) => height / 2 - 10,
    zIndex: 1,
  },
}))

const CircularTrainingCompletionChart = ({
  text,
  data,
  mr = 2,
  mb = 1,
  width = 100,
  height = 100,
  showCount = false,
  ...props
}) => {
  const { percentComplete, percentInProgress, percentOverdue, complete, inProgress, overdue, userCount } = data
  const classes = useStyles({
    clickable: !!props.onClick,
    complete: !percentInProgress && !percentOverdue,
    userCount,
    height,
  })
  const theme = useTheme()

  const colours = [
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.error.light,
    theme.palette.chart.background,
  ]

  const chartData = [
    { argument: "Completed", value: percentComplete, count: complete },
    { argument: "In progress", value: percentInProgress, count: inProgress },
    { argument: "Overdue", value: percentOverdue, count: overdue },
  ]

  if (!userCount) {
    chartData.push({ argument: "None", value: 100 })
  }

  const TooltipContent = ({ targetItem: { point } }) => {
    const { argument, value, count } = chartData[point]
    const percentage = Math.floor(value)

    return (
      <Box>
        <Box>
          <strong>{argument}</strong>
        </Box>
        <Box>
          {showCount && count ? `(${count}) ` : ""}
          {percentage}% of people
        </Box>
      </Box>
    )
  }

  return (
    <>
      <ColumnBox alignItems="center" justifyContent="flex-start" mr={mr} mb={mb} className={classes.root} {...props}>
        <ColumnBox>
          <Chart width={width} height={height} data={chartData}>
            <Palette scheme={colours} />
            <PieSeries valueField="value" argumentField="argument" innerRadius={0.75} />
            <EventTracker />
            {Boolean(userCount) && <Tooltip contentComponent={TooltipContent} />}
            <HoverState />
          </Chart>
        </ColumnBox>
        <Box className={classes.value}>
          {!!userCount && <>{Math.floor(percentComplete)}%</>}
          {!userCount && <>None</>}
        </Box>
        <RowBox alignItems="center" justifyContent="center">
          <Box className={classes.text}>{text}</Box>
        </RowBox>
      </ColumnBox>
    </>
  )
}
export { CircularTrainingCompletionChart }
