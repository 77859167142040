import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_186)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99963 27C4.44735 27 3.99963 27.4477 3.99963 28C3.99963 28.5523 4.44735 29 4.99963 29C5.55192 29 5.99963 28.5523 5.99963 28C5.99963 27.4477 5.55192 27 4.99963 27ZM1.99963 28C1.99963 26.3431 3.34278 25 4.99963 25C6.65649 25 7.99963 26.3431 7.99963 28C7.99963 29.6569 6.65649 31 4.99963 31C3.34278 31 1.99963 29.6569 1.99963 28Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.9998 27C26.4475 27 25.9998 27.4477 25.9998 28C25.9998 28.5523 26.4475 29 26.9998 29C27.552 29 27.9998 28.5523 27.9998 28C27.9998 27.4477 27.552 27 26.9998 27ZM23.9998 28C23.9998 26.3431 25.3429 25 26.9998 25C28.6566 25 29.9998 26.3431 29.9998 28C29.9998 29.6569 28.6566 31 26.9998 31C25.3429 31 23.9998 29.6569 23.9998 28Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9998 27C15.4475 27 14.9998 27.4477 14.9998 28C14.9998 28.5523 15.4475 29 15.9998 29C16.552 29 16.9998 28.5523 16.9998 28C16.9998 27.4477 16.552 27 15.9998 27ZM12.9998 28C12.9998 26.3431 14.3429 25 15.9998 25C17.6566 25 18.9998 26.3431 18.9998 28C18.9998 29.6569 17.6566 31 15.9998 31C14.3429 31 12.9998 29.6569 12.9998 28Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C12.134 3 9 6.13401 9 10C9 11.9608 9.80618 13.7333 11.1052 15.0041C11.1449 14.7981 11.1996 14.5682 11.2751 14.3221C11.5265 13.5018 12.0138 12.4852 12.9514 11.5897C12.358 10.8919 12 9.98776 12 9C12 6.79086 13.7909 5 16 5C18.2091 5 20 6.79086 20 9C20 9.98776 19.642 10.8919 19.0486 11.5897C19.9862 12.4852 20.4735 13.5018 20.7249 14.3221C20.8004 14.5682 20.8551 14.7981 20.8948 15.0041C22.1938 13.7333 23 11.9608 23 10C23 6.13401 19.866 3 16 3ZM19 15.9851C19 15.9851 19 15.9827 19 15.9805C18.9999 15.9728 18.9997 15.9571 18.9988 15.9341C18.997 15.8881 18.9926 15.8133 18.9818 15.7156C18.9601 15.5193 18.9132 15.2358 18.8127 14.9082C18.6123 14.2542 18.2063 13.4457 17.3753 12.7809C17.3686 12.7755 17.3619 12.77 17.3554 12.7645C16.9321 12.9169 16.4758 13 16 13C15.5242 13 15.0679 12.9169 14.6446 12.7645C14.6381 12.77 14.6314 12.7755 14.6247 12.7809C13.7937 13.4457 13.3877 14.2542 13.1873 14.9082C13.0868 15.2358 13.0399 15.5193 13.0182 15.7156C13.0074 15.8133 13.003 15.8881 13.0012 15.9341C13.0003 15.9571 13.0001 15.9728 13 15.9805L13 15.9845L12.9999 15.9816L12.9999 15.9794L13 15.9845C13.0017 16.0956 12.9851 16.2034 12.9531 16.3039C13.8742 16.7499 14.9079 17 16 17C17.0921 17 18.1258 16.7499 19.0469 16.3039C19.0149 16.2034 18.9983 16.0962 19 15.9851ZM7 10C7 5.02944 11.0294 1 16 1C20.9706 1 25 5.02944 25 10C25 14.6331 21.4992 18.4485 16.9985 18.9452C16.9995 18.9634 17 18.9816 17 19V20H27.0004C27.5527 20 28.0004 20.4477 28.0004 21V23C28.0004 23.5523 27.5527 24 27.0004 24C26.4481 24 26.0004 23.5523 26.0004 23V22H17V23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23V22H5.99963V23C5.99963 23.5523 5.55192 24 4.99963 24C4.44735 24 3.99963 23.5523 3.99963 23V21C3.99963 20.4477 4.44735 20 4.99963 20H15V19C15 18.9816 15.0005 18.9634 15.0015 18.9452C10.5008 18.4485 7 14.6331 7 10ZM16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_186"
        x1="7.99963"
        y1="25"
        x2="7.99963"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_99_186"
        x1="29.9998"
        y1="25"
        x2="29.9998"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_99_186"
        x1="18.9998"
        y1="25"
        x2="18.9998"
        y2="31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
