import React, { useState } from "react"
import { Box, Typography, Button, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Trans } from "@lingui/macro"
import { useAuth } from "../../services/useAuth"
import { Icon, LogoFull, RowBox } from "../../components"
import { PublicContainer } from "./PublicContainer"
import { LoginDeviceBusy } from "./LoginDeviceBusy"
import { useMountEffect } from "../../utils"

const useStyles = makeStyles((theme) => ({
  spinner: {
    height: "100vh",
  },
  container: {
    width: "100%",
  },
  root: {
    textAlign: "center",
  },
  buttonBox: {
    width: "100%",
  },
  button: {
    color: theme.palette.text.primary,
  },
}))

const LoginDevice = () => {
  const classes = useStyles()
  const { getDeviceLoginLink } = useAuth()

  const [loading, setLoading] = useState(true)

  useMountEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 10000)

    return () => clearTimeout(timeout)
  })

  const handleRetry = () => {
    window.location.reload(true)
  }

  const handleCancel = () => {
    window.location = getDeviceLoginLink()
  }

  if (loading) {
    return <LoginDeviceBusy />
  }

  return (
    <PublicContainer>
      <Container component="main" className={classes.root}>
        <Box pt={5} px={2}>
          <Box mb={3}>
            <LogoFull width="197" />
          </Box>

          <Typography paragraph align="center">
            <strong>
              <Trans>Unable to connect</Trans>
            </strong>
          </Typography>
          <Typography paragraph align="center">
            <Trans>Please ensure you have an active internet connection and try again.</Trans>
          </Typography>
          <RowBox mt={7} mb={5} className={classes.buttonBox}>
            <Button type="button" fullWidth size="large" className={classes.button} onClick={handleRetry}>
              <Icon name="retry" />
              <Box ml={1}>
                <Trans>Retry</Trans>
              </Box>
            </Button>
          </RowBox>
          <Button type="button" fullWidth size="large" className={classes.button} onClick={handleCancel}>
            <Icon name="back" />
            <Box ml={1}>
              <Trans>Back</Trans>
            </Box>
          </Button>
        </Box>
      </Container>
    </PublicContainer>
  )
}

export { LoginDevice }
