import React, { useState } from "react"
import moment from "moment"
import { Box, Button, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ExpandMoreOutlined } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import { FormatDate, FormatTime } from "../Format"
import TimeAgo from "../TimeAgo"
import { useJobUtils } from "../../utils"
import { InfoSection } from ".."
import { ProcessAssignees } from "./ProcessAssignees"
import { ColumnBox } from "../Boxes"
import { useAuth } from "../../services"
import { Assignees } from "../Assignees"
import { ProcessCollapse } from "./ProcessCollapse"

const useStyles = makeStyles((theme) => ({
  author: {
    width: 30,
    height: 30,
    fontSize: 12,
  },
  expansionContent: {
    width: "100%",
  },
  assignees: {
    width: "100%",
  },
  detailsButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: "600",
    padding: theme.spacing(1, 2),
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  detailsIcon: {
    transform: ({ expand }) => (expand ? "rotate(180deg)" : null),
    transformOrigin: "center",
    transition: "0.2s all",
  },
}))

const JobMoreInformation = ({ job }) => {
  const { getRepeatDisplayText, isJobAfterToday } = useJobUtils()
  const { hasPermission } = useAuth()
  const [expand, setExpand] = useState(false)
  const {
    availableFrom,
    dueAt,
    updatedAt,
    status: { completed },
    location: { timeZone },
    author,
  } = job

  const scheduled = isJobAfterToday(availableFrom)
  const canUpdate = hasPermission("job_update_all")
  const isProcess = Boolean(job.process)
  const classes = useStyles({ expand, canUpdate })

  return (
    <>
      <Divider light />
      <ProcessCollapse in={expand}>
        <ColumnBox flexGrow={1}>
          <Box className={classes.expansionContent}>
            <InfoSection
              title={<Trans context="Available from a point in time">Available from</Trans>}
              value={
                <Box display="flex">
                  <FormatDate value={availableFrom} />
                  {moment(availableFrom).isAfter(moment(availableFrom).startOf("day")) && (
                    <Box ml={0.5}>
                      <Trans context="Available from a point in time">
                        (from <FormatTime value={availableFrom} timeZone={timeZone} />)
                      </Trans>
                    </Box>
                  )}
                </Box>
              }
              compact
            />
            <InfoSection title={<Trans>Created by</Trans>} value={author.fullName} compact />
            {dueAt && (
              <InfoSection
                title={<Trans>Due at</Trans>}
                value={
                  <Box display="flex" flexDirection="row">
                    <FormatTime
                      value={dueAt}
                      showOverdue={!completed}
                      overdueSuffix={<Trans>(Overdue)</Trans>}
                      timeZone={timeZone}
                    />
                  </Box>
                }
                compact
              />
            )}

            {isProcess && (
              <InfoSection title={<Trans>Repeats</Trans>} value={getRepeatDisplayText(job.repeat)} compact />
            )}

            {updatedAt && (
              <InfoSection title={<Trans>Last updated</Trans>} value={<TimeAgo date={updatedAt} />} compact />
            )}

            {!canUpdate && (
              <InfoSection
                title={<Trans>Assignees</Trans>}
                value={<Assignees {...{ users: job.users, groups: job.groups, limit: 2 }} />}
                compact
              />
            )}
          </Box>

          {canUpdate && (
            <Box className={classes.assignees}>
              <ProcessAssignees job={job} disabled={scheduled} />
            </Box>
          )}
        </ColumnBox>
      </ProcessCollapse>
      <Button className={classes.detailsButton} onClick={() => setExpand(!expand)}>
        {!expand ? <Trans>Show more information</Trans> : <Trans>Show less information</Trans>}
        <ExpandMoreOutlined className={classes.detailsIcon} />
      </Button>
    </>
  )
}

export { JobMoreInformation }
