import React from "react"
import { Box, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  regionRoot: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  regionTitle: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: "600",
  },
}))

const RegionSwitcherCard = ({ region, ...props }) => {
  const classes = useStyles()

  if (region.id === "none") {
    return null
  }

  return (
    <Box className={classes.regionRoot} px={2} {...props}>
      <Typography variant="h2" className={classes.regionTitle}>
        {region?.name}
      </Typography>
    </Box>
  )
}

export { RegionSwitcherCard }
