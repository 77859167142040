import { Box, Tooltip, makeStyles } from "@material-ui/core"
import React from "react"
import { ColumnBox, RowBox } from "../Boxes"
import { Icon } from ".."

const useStyles = makeStyles((theme) => ({
  externalLink: {
    display: "inline-block",
  },
  externalLinkIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 16,
    height: 16,
    marginLeft: 2,
    marginTop: -3,
  },
  externalLinkTooltipUrl: {
    color: theme.palette.text.white,
    maxWidth: 250,
    whiteSpace: "pre-wrap",
  },
}))

const FormatUrl = ({ url, linked }) => {
  const classes = useStyles()
  const handleExternalClick = (event) => {
    event.stopPropagation()
    return true
  }

  if (!url) {
    return null
  }

  const urlFormatted = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0]

  const urlWithProtocol = url.startsWith("http") ? url : `https://${url}`

  if (linked) {
    return (
      <Tooltip
        title={
          <RowBox>
            <ColumnBox>
              <Box>Visit external site</Box>
              <Box className={classes.externalLinkTooltipUrl}>{urlWithProtocol}</Box>
            </ColumnBox>
          </RowBox>
        }
      >
        <a
          target="_blank"
          href={urlWithProtocol}
          className={classes.externalLink}
          onClick={handleExternalClick}
          rel="noreferrer"
        >
          <span>{urlFormatted}</span>
          <Icon name="external-link" className={classes.externalLinkIcon} />
        </a>
      </Tooltip>
    )
  }

  return <span>{urlFormatted}</span>
}

export { FormatUrl }
