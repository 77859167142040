import React from "react"
import { makeStyles, Box } from "@material-ui/core"
import { AssetTags } from "./AssetTags"
import { ModuleHeading } from "../Headings"

const useStyles = makeStyles((theme) => ({
  detail: theme.cards.viewerCard,
  detailInner: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  groups: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
}))

const AssetPanel = ({ layout = "portrait", asset: { name, groups, locations, tags }, ...rest }) => {
  const classes = useStyles({ layout })

  return (
    <>
      <Box className={classes.detail} {...rest}>
        <Box className={classes.detailInner}>
          <ModuleHeading noMargin>{name}</ModuleHeading>
          {groups && groups.length > 0 && (
            <Box className={classes.groups}>{groups.map((group) => group.name).join(", ")}</Box>
          )}
          {locations && locations.length > 0 && (
            <Box className={classes.locations}>{locations.map((location) => location.name).join(", ")}</Box>
          )}
          {tags?.length > 0 && (
            <Box mt={2}>
              <AssetTags tags={tags} max={3} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export { AssetPanel }
