import React from "react"
import { Container, Box, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ErrorBoundary } from "../ErrorBoundary"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: ({ split }) => (split ? "flex" : "block"),
    flexGrow: 1,
    flexDirection: "column",
    flex: 1,
    background: theme.palette.background.default,
  },
  split: {
    flexGrow: 1,
    background: `linear-gradient(to right, ${theme.palette.primary.backgroundLight} 0%, ${theme.palette.primary.backgroundLight} 50%, ${theme.palette.background.default} 50%, ${theme.palette.background.default} 100%);`,
    paddingBottom: 40,
  },
  content: {
    paddingTop: ({ pt }) => pt,
    flexGrow: ({ split }) => (split ? 1 : "none"),
  },
}))

const Content = ({ split, children, pt: ptInput, pb = 5, disableGutters = false, start = false }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const pt = typeof ptInput !== "undefined" ? theme.spacing(ptInput) : xs ? 16 : md ? 24 : 40
  const classes = useStyles({ split, pt })

  return (
    <ErrorBoundary>
      <Box className={classes.root} justifyContent="center">
        <Box className={split ? classes.split : null} pb={pb} id={start ? "content" : ""}>
          <Container className={classes.content} disableGutters={disableGutters}>
            {children || <span />}
          </Container>
        </Box>
      </Box>
    </ErrorBoundary>
  )
}

export default Content
