import React from "react"
import { Typography, Box, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { t } from "@lingui/macro"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  master: {
    minHeight: 80,
    borderTop: ({ topBorder }) => (topBorder ? `1px solid ${theme.palette.grey[200]}` : "none"),
    borderBottom: ({ bottomBorder }) => (bottomBorder ? `1px solid ${theme.palette.grey[200]}` : "none"),
    cursor: ({ disabled }) => (disabled ? "inherit" : "pointer"),
    "&:hover": {
      backgroundColor: ({ disabled }) => (disabled ? "inherit" : theme.palette.grey[50]),
    },
    color: ({ disabled }) => theme.palette.text[disabled ? "disabled" : "primary"],
    gap: theme.spacing(1),
  },
  icon: {
    margin: theme.spacing(3, 2, 0, 2),
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "20px",
  },
  value: {
    fontSize: 16,
    lineHeight: "20px",
    whiteSpace: "pre-wrap",
  },
  noValue: {
    color: ({ disabled }) => theme.palette.text[disabled ? "disabled" : "secondary"],
  },
  arrow: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  tooltipWrapper: {
    lineHeight: "100%",
  },
  tooltipHelpIcon: {
    marginLeft: theme.spacing(0.5),
  },
}))

const AssignerDisplay = ({
  icon,
  title,
  placeholder,
  value,
  onClick,
  className,
  topBorder = true,
  bottomBorder = true,
  disabled = false,
  tooltip = false,
  cy,
}) => {
  const classes = useStyles({ topBorder, bottomBorder, disabled })

  const handleClick = (event) => {
    if (!disabled && onClick) onClick(event)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      className={`${classes.master} ${className}`}
      onClick={handleClick}
      data-cy={cy}
    >
      <Box className={classes.icon}>{icon}</Box>
      <Box display="flex" flexGrow={1} flexDirection="column" mt={1} mb={1} alignSelf="center">
        <Box display="flex" flexDirection="row">
          <Typography className={classes.title}>{title}</Typography>
          {tooltip && (
            <Tooltip title={tooltip}>
              <span className={classes.tooltipWrapper}>
                <Icon className={classes.tooltipHelpIcon} name="help" fontSize="small" />
              </span>
            </Tooltip>
          )}
        </Box>
        <Typography className={classes.value}>
          {value || <span className={classes.noValue}>{placeholder || t`Nothing selected`}</span>}
        </Typography>
      </Box>
      <Box className={classes.arrow}>
        <Box ml="auto">
          <ChevronRightIcon />
        </Box>
      </Box>
    </Box>
  )
}

export { AssignerDisplay }
