import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_722_2520)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3ZM1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0825 9.00342C12.4898 9.03713 12.8356 9.31531 12.9558 9.70593L16.2764 20.4978L19.1254 15.5044C19.3034 15.1925 19.6349 15 19.994 15H26C26.5523 15 27 15.4477 27 16C27 16.5523 26.5523 17 26 17H20.5748L16.8686 23.4956C16.671 23.8419 16.2865 24.0377 15.8902 23.994C15.4939 23.9502 15.1615 23.6752 15.0442 23.2941L11.7782 12.6796L9.89443 16.4472C9.72504 16.786 9.37877 17 9 17H5C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15H8.38197L11.1056 9.5528C11.2883 9.18725 11.6752 8.96971 12.0825 9.00342Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_722_2520" x1="27" y1="24" x2="4" y2="24" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
