import React from "react"
import { Box, ListItem } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import NavLinkItem from "../NavLinkItem"

const useStyles = makeStyles((theme) => ({
  navLinkListItem: {
    padding: 0,
    display: "block",
    "&:hover": {
      background: theme.palette.primary.backgroundDark,
      borderRadius: 4,
      textDecoration: "none",
    },
    "&:focus": {
      background: theme.palette.primary.backgroundDark,
      borderRadius: 4,
      textDecoration: "none",
    },
    background: ({ active, variant }) =>
      active && variant === "horizontal"
        ? theme.palette.primary.backgroundDark
        : !active && variant === "horizontal"
          ? theme.palette.primary.backgroundMed
          : "transparent",
  },
  navLinkItem: {
    display: "flex",
    flexGrow: 1,
    fontSize: 16,
    fontWeight: 600,
    padding: theme.spacing(1, 2, 1, 2),
  },
  navLinkItemText: {
    color: ({ color }) => color || theme.palette.primary.main,
    borderLeft: ({ active, color, variant }) =>
      active && variant !== "horizontal" ? `solid 3px ${color || theme.palette.primary.main}` : null,
    paddingLeft: ({ active, variant }) => (active && variant !== "horizontal" ? theme.spacing(1.25) : 0),
    whiteSpace: "nowrap",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

const SubNavigationListItem = ({
  variant,
  nested,
  active,
  color,
  badgeVariant = "badge",
  badgeCount,
  badgeShowZero = true,
  text: textInput,
  ...props
}) => {
  const classes = useStyles({ active, color, variant })
  const theme = useTheme()

  const text =
    badgeVariant === "text" && (badgeCount || (badgeShowZero && typeof badgeCount !== "undefined"))
      ? `${textInput} (${badgeCount})`
      : textInput

  return (
    <ListItem component={nested ? Box : ListItem} classes={{ root: classes.navLinkListItem }} disableGutters>
      <NavLinkItem
        className={classes.navLinkItem}
        badgeStyleProps={{
          color: theme.palette.text.white,
          backgroundColor: theme.palette.primary.main,
        }}
        text={<span className={classes.navLinkItemText}>{text}</span>}
        badgeCount={badgeVariant === "badge" ? badgeCount : null}
        badgeShowZero={badgeShowZero}
        {...props}
      />
    </ListItem>
  )
}

export { SubNavigationListItem, useStyles as useSubNavigationListItemStyles }
