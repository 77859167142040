import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_423_3290)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 15H19C18.447 15 18 14.553 18 14V6C18 5.447 18.447 5 19 5H26C26.553 5 27 5.447 27 6V14C27 14.553 26.553 15 26 15ZM20 13H25V7H20V13Z"
      fill={secondary}
    />
    <path
      d="M30 1H15C14.448 1 14 1.447 14 2V30C14 30.553 14.448 31 15 31H30C30.553 31 31 30.553 31 30V2C31 1.447 30.553 1 30 1ZM29 29H16V3H29V29Z"
      fill={primary}
    />
    <path
      d="M19 19H26C26.553 19 27 18.553 27 18C27 17.447 26.553 17 26 17H19C18.447 17 18 17.447 18 18C18 18.553 18.447 19 19 19Z"
      fill={primary}
    />
    <path
      d="M19 23H26C26.553 23 27 22.553 27 22C27 21.447 26.553 21 26 21H19C18.447 21 18 21.447 18 22C18 22.553 18.447 23 19 23Z"
      fill={primary}
    />
    <path
      d="M19 27H26C26.553 27 27 26.553 27 26C27 25.447 26.553 25 26 25H19C18.447 25 18 25.447 18 26C18 26.553 18.447 27 19 27Z"
      fill={primary}
    />
    <path
      d="M11 7H2C1.448 7 1 7.447 1 8V17C1 17.553 1.448 18 2 18H3V20H2C1.448 20 1 20.447 1 21V30C1 30.553 1.448 31 2 31H11C11.552 31 12 30.553 12 30V21C12 20.447 11.552 20 11 20H10V18H11C11.552 18 12 17.553 12 17V8C12 7.447 11.552 7 11 7ZM10 29H3V22H10V29ZM8 20H5V18H8V20ZM10 16H3V9H10V16Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_423_3290" x1="18" y1="10" x2="27" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
