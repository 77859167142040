import { Box, Button, Chip, Collapse, IconButton, Tooltip, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { produce } from "immer"
import { Trans } from "@lingui/macro"
import { PROCESS_SCHEDULE_AFTER, PROCESS_SCHEDULE_DUE, PROCESS_SCHEDULE_UNTIL } from "../../data"
import { isScheduleValid, makeTemporaryId, toId, useMountEffect } from "../../utils"
import ProcessSchedule from "./ProcessSchedule"
import { FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"
import { FlexBox, RowBox } from "../Boxes"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  multipleSchedules: {
    cursor: "pointer",
    position: "sticky",
    top: -theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    zIndex: theme.zIndex.modal + 10,
  },
}))

const initialScheduleState = {
  name: "",
  after: {
    type: PROCESS_SCHEDULE_AFTER.DATE,
    date: null,
  },
  due: {
    type: PROCESS_SCHEDULE_DUE.NONE,
    period: "",
  },
  until: {
    type: PROCESS_SCHEDULE_UNTIL.NONE,
    period: "",
  },
  repeat: null,
  hasEveryone: false,
  users: [],
  groups: [],
  locations: [],
  inactive: false,
}

const makeInitialSchedule = (name, userLocations) =>
  produce(initialScheduleState, (draft) => {
    draft.id = makeTemporaryId()
    draft.name = name
    if (userLocations.length === 1) {
      draft.locations.push(toId(userLocations[0]))
    }
  })

const ProcessSchedules = ({ initialName, schedules, subject, disabled, onChange }) => {
  const classes = useStyles()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const [items, setItems] = useState([])
  const [initial, setInitial] = useState(false)
  const [expanded, setExpanded] = useState(true)

  useMountEffect(() => {
    if (schedules?.length) {
      setItems(
        schedules.map((schedule) => ({
          ...schedule,
          hasEveryone:
            schedule.hasEveryone != null
              ? schedule.hasEveryone
              : schedule.users.length === 0 && schedule.groups.length === 0,
          expanded: schedules.length === 1,
        })),
      )
    } else {
      setInitial(true)
      setItems([{ ...makeInitialSchedule(initialName, userLocations) }])
    }
  })

  useEffect(() => {
    if (initial) {
      const newItems = produce(items, (draft) => {
        draft[0].name = initialName
      })
      setItems(newItems)
    }
  }, [initial, initialName, items])

  const handleChange = (index, newValue) => {
    const newItems = produce(items, (draft) => {
      draft[index] = { ...newValue, expanded: draft[index].expanded }
    })

    setItems(newItems)
    onChange && onChange(newItems)
  }

  const handleClick = () => {
    if (initial) {
      setInitial(false)
    }
  }

  const handleAdd = () => {
    const newItems = produce(items, (draft) => {
      draft.forEach((item) => {
        item.expanded = false // collapse all
      })
      draft.push({ ...makeInitialSchedule(initialName, userLocations) })
    })

    setItems(newItems)
    onChange && onChange(newItems)
    setExpanded(true)
  }

  const handleDuplicate = (index) => {
    const newItems = produce(items, (draft) => {
      draft.forEach((item) => {
        item.expanded = !isScheduleValid(item) // collapse all valid
      })

      const newItem = {
        ...draft[index],
        id: makeTemporaryId(),
        name: `${draft[index].name} (copy)`,
      }

      draft.splice(index + 1, 0, newItem)
    })

    setItems(newItems)
    onChange && onChange(newItems)
  }

  const handleRemove = (index) => {
    if (items.length < 2) {
      return
    }

    const newItems = produce(items, (draft) => {
      draft.splice(index, 1)
      if (draft.length === 1) {
        draft[0].expanded = true // expand the only remaining item
      }
    })

    setItems(newItems)
    onChange && onChange(newItems)
  }

  const handleToggleExpanded = () => {
    if (expanded) {
      // collapse all valid if collapsing all
      const newItems = produce(items, (draft) => {
        draft.forEach((item) => {
          item.expanded = false
        })
      })
      setItems(newItems)
    }

    setExpanded(!expanded)
  }

  const handleToggleScheduleExpanded = (index) => {
    const newItems = produce(items, (draft) => {
      draft.forEach((item, itemIndex) => {
        if (index === itemIndex) {
          draft[index].expanded = !draft[index].expanded
        } else if (isScheduleValid(item)) {
          draft[itemIndex].expanded = false // collapse all other valid
        }
      })
    })

    setItems(newItems)
  }

  const hasMoreThanOne = items.length > 1

  const hasAllValidSchedules = items.every(isScheduleValid)

  return (
    <>
      <Box className={classes.multipleSchedules}>
        <Collapse in={hasMoreThanOne}>
          <RowBox onClick={handleToggleExpanded} mb={1}>
            <FlexBox flexGrow={1}>
              <FieldSectionHeading size="large" mb={0}>
                Multiple Schedules ({schedules.length})
              </FieldSectionHeading>
            </FlexBox>
            {!hasAllValidSchedules && !expanded && (
              <Box mx={1}>
                <Chip label="1 Incomplete" color="secondary" size="small" icon={<Icon name="edit" />} />
              </Box>
            )}
            <Box>
              <Tooltip title={expanded ? "Collapse schedules" : "Expand schedules"}>
                <IconButton size="small">
                  <Icon name={expanded ? "collapse" : "expand"} />
                </IconButton>
              </Tooltip>
            </Box>
          </RowBox>
        </Collapse>
      </Box>

      <Collapse in={expanded}>
        {items.map((schedule, index) => (
          <Box key={toId(schedule)} mt={index > 0 ? 3 : 0}>
            <ProcessSchedule
              number={index + 1}
              subject={subject}
              schedule={schedule}
              onChange={(newValue) => handleChange(index, newValue)}
              onClick={handleClick}
              disabled={disabled}
              collapsible={hasMoreThanOne}
              expanded={schedule.expanded}
              onToggleExpanded={() => handleToggleScheduleExpanded(index)}
              onAdd={handleAdd}
              onDuplicate={() => handleDuplicate(index)}
              removable={hasMoreThanOne}
              onRemove={() => handleRemove(index)}
            />
          </Box>
        ))}
      </Collapse>

      {hasAllValidSchedules && items.length > 1 && (
        <Box mt={expanded ? 3 : 0} mb={3}>
          <Button variant="contained" fullWidth data-cy="Button-add-schedule" onClick={handleAdd} disabled={disabled}>
            <Icon name="add" /> <Trans>Add additional schedule</Trans>
          </Button>
        </Box>
      )}
    </>
  )
}

export default ProcessSchedules
