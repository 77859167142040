import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_168)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15C11 13.8954 11.8954 13 13 13H19C20.1046 13 21 13.8954 21 15V28C21 29.1046 20.1046 30 19 30H13C11.8954 30 11 29.1046 11 28V15ZM19 15H13V28H19V15Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C14.3174 8 12.7976 8.66143 11.6922 9.72167C11.2937 10.104 10.6606 10.0908 10.2783 9.69223C9.89602 9.29366 9.9092 8.66064 10.3078 8.27833C11.7731 6.87281 13.7843 6 16 6C18.2157 6 20.2269 6.87281 21.6922 8.27833C22.0908 8.66064 22.104 9.29366 21.7217 9.69223C21.3394 10.0908 20.7063 10.104 20.3078 9.72167C19.2024 8.66143 17.6827 8 16 8Z"
      fill={primary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0063 4C13.1216 4 10.5322 5.05773 8.66839 6.73246C8.25759 7.10159 7.62532 7.06782 7.25619 6.65701C6.88705 6.24621 6.92083 5.61395 7.33163 5.24481C9.56527 3.23773 12.6318 2 16.0063 2C19.3804 2 22.4495 3.2376 24.6729 5.26028C25.0815 5.63193 25.1114 6.26439 24.7397 6.67292C24.3681 7.08145 23.7356 7.11136 23.3271 6.73972C21.4783 5.05786 18.8914 4 16.0063 4Z"
      fill={primary}
    />
    <path
      d="M15 20.0464V17.9536C15 17.7007 15.1054 17.4581 15.2929 17.2793C15.4804 17.1005 15.7348 17 16 17C16.2652 17 16.5196 17.1005 16.7071 17.2793C16.8946 17.4581 17 17.7007 17 17.9536V20.0464C17 20.2993 16.8946 20.5419 16.7071 20.7207C16.5196 20.8995 16.2652 21 16 21C15.7348 21 15.4804 20.8995 15.2929 20.7207C15.1054 20.5419 15 20.2993 15 20.0464Z"
      fill={secondary}
    />
    <defs>
      <linearGradient id="paint0_linear_99_168" x1="17" y1="17" x2="17" y2="21" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)
export default Icon
