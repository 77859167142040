import React, { useState } from "react"
import Config from "react-global-configuration"
import { Box, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import { FormatBytes } from "../Format"
import { DisplayImage } from "./DisplayImage"
import { preventDefault, useDeviceUtils } from "../../utils"
import { ImagesViewerModal } from "."

const useStyle = makeStyles((theme) => ({
  tile: {
    background: theme.palette.grey[100],
    borderRadius: 4,
    cursor: "pointer",
    fontWeight: "700",
  },
  more: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
  },
  backing: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
  },
  thumbnail: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    borderRadius: 4,
  },
  light: {
    color: theme.palette.text.secondary,
  },
}))

const ImagesViewer = ({
  uploads: allUploads,
  showSize = false,
  max = null,
  width = 120,
  height = 100,
  onOpen,
  onClose,
}) => {
  const { clientKiosk, clientDevice } = Config.get()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const fullWidth = xs ? 310 : 600
  const { canPostMessage, postImagesMessage } = useDeviceUtils()
  const classes = useStyle({ fullWidth, width, height })
  const [open, setOpen] = useState(null)
  const isOpen = open !== null

  const uploads = allUploads && allUploads.length > 0 && allUploads.filter((upload) => upload.fileGroup === "image")

  const handleImageClick = (index) =>
    (clientKiosk || clientDevice) && canPostMessage() ? handleImageClickDevice(index) : handleImageClickDefault(index)

  const handleImageClickDefault = (index) => {
    if (!uploads || index < 0 || index > uploads.length - 1) return
    setOpen(index)
    onOpen && onOpen(index)
  }

  const handleImageClickDevice = (index) => {
    postImagesMessage(uploads, index)
  }

  const handleClose = () => {
    setOpen(null)
    onClose && onClose()
  }

  return (
    <>
      {!!uploads?.length && (
        <Box display="flex" flexDirection="row" flexWrap="wrap" mt={0.5}>
          {uploads.slice(0, max || uploads.length).map((upload, index) => {
            const { id, fileName, fileSize } = upload

            return (
              <Box
                key={id}
                display="flex"
                flexDirection="column"
                mr={0.5}
                mt={0.5}
                p={0.25}
                className={classes.tile}
                onClick={preventDefault}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  className={classes.backing}
                  onClick={() => handleImageClick(index)}
                >
                  <DisplayImage
                    upload={upload}
                    width={width * 2}
                    height={height * 2}
                    className={classes.thumbnail}
                    alt={fileName}
                  />
                </Box>
                {showSize && (
                  <Box alignSelf="center">
                    <small>
                      <FormatBytes bytes={fileSize} brackets className={classes.light} />
                    </small>
                  </Box>
                )}
              </Box>
            )
          })}
          {!!max && uploads.length > max && (
            <Box
              display="flex"
              flexDirection="column"
              mr={0.5}
              mt={0.5}
              p={0.25}
              className={classes.tile}
              onClick={preventDefault}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                className={classes.more}
                onClick={() => handleImageClick(max)}
              >
                +{uploads.length - max}
              </Box>
            </Box>
          )}
        </Box>
      )}

      {isOpen && <ImagesViewerModal uploads={uploads} startIndex={open} onClose={handleClose} />}
    </>
  )
}

export { ImagesViewer }
