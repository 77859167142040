import React, { memo } from "react"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom"
import { Box, Hidden, useTheme, useMediaQuery } from "@material-ui/core"
import { t, Plural } from "@lingui/macro"
import { ActionCard } from "./ActionCard"
import { Assignees, AdornmentIcon } from ".."
import { useDateUtils, useJobUtils, getResponseStepsCount } from "../../utils"
import { AssigneeAvatars } from "../Assignees"
import { FormatHumanTime } from "../Format"
import { ActionCardInfo } from "./ActionCardInfo"
import { PROCESS_REPEAT, PROCESS_SCHEDULE_UNTIL, PROCESS_STEP_RESPONSE_TYPE, PROCESS_TYPE } from "../../data"
import { RowBox } from "../Boxes"
import TimeAgo from "../TimeAgo"
import { useAuth } from "../../services"
import { SingleStepProcessActionCard } from "./SingleStepProcessActionCard"
import { AuditStatusChip } from "../Chips/AuditStatusChip"

const mrInfo = 3

const useStyles = makeStyles((theme) => ({
  overdue: {},
  overdueIcon: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.error.main,
  },
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  assigneesRight: {
    textAlign: "right",
  },
  rightChildren: {
    height: 40,
    marginLeft: ({ xs }) => (xs ? 0 : theme.spacing(1)),
    overflow: "visible",
  },
  progress: {
    width: 140,
  },
  progressXs: {},
}))

const ProcessStepsActionCardInfo = memo(function ProcessStepsActionCardInfo({ job, count, ...rest }) {
  if (job?.type === PROCESS_TYPE.AUDIT) {
    return null
  }

  const suffix = <Plural value={count} one="step" other="steps" />

  return (
    <RowBox {...rest}>
      <Hidden mdUp>
        <ActionCardInfo>
          <AdornmentIcon name="steps" tooltip={`${count} ${suffix}`} />
        </ActionCardInfo>
      </Hidden>
      <Hidden smDown>
        <AdornmentIcon name="steps" tooltip={t`Steps`} />
        <Box ml={1} mr={mrInfo}>
          {count} {suffix}
        </Box>
      </Hidden>
    </RowBox>
  )
})

const ProcessRepeatActionCardInfo = memo(function ProcessRepeatActionCardInfo({ job, ...rest }) {
  const { getRepeatDisplayText, getRepeatDisplayShortText } = useJobUtils()

  if (job.repeat.type === PROCESS_REPEAT.NEVER) return null

  const repeatText = getRepeatDisplayText(job.repeat)

  return (
    <RowBox {...rest}>
      <Hidden mdUp>
        <ActionCardInfo>
          <AdornmentIcon name="repeat" tooltip={t`Repeats: ${repeatText}`} />
        </ActionCardInfo>
      </Hidden>
      <Hidden smDown>
        <AdornmentIcon name="repeat" tooltip={t`Repeats: ${repeatText}`} />
        <Box ml={1} mr={mrInfo} title={repeatText}>
          {getRepeatDisplayShortText(job.repeat)}
        </Box>
      </Hidden>
    </RowBox>
  )
})

const HumanTimeActionCardInfo = memo(function HumanTimeActionCardInfo({ adhoc, date, availableUntil, ...rest }) {
  const { getJobPinningTypeDescription } = useJobUtils()
  const pinned = availableUntil?.type !== PROCESS_SCHEDULE_UNTIL.NONE
  const pinnedUntil = availableUntil?.at

  return (
    <RowBox {...rest}>
      <Hidden mdUp>
        <ActionCardInfo>
          {!adhoc && !pinned && (
            <AdornmentIcon
              name="time"
              tooltip={
                <>
                  {t`Start`}: <FormatHumanTime value={date} />
                </>
              }
            />
          )}
          {pinned && pinnedUntil && <AdornmentIcon name="pinned" tooltip={<TimeAgo date={pinnedUntil} />} />}
          {pinned && !pinnedUntil && (
            <AdornmentIcon name="pinned" tooltip={getJobPinningTypeDescription(availableUntil.type)} />
          )}
        </ActionCardInfo>
      </Hidden>
      <Hidden smDown>
        {!adhoc && !pinned && (
          <AdornmentIcon
            name="time"
            tooltip={
              <>
                {t`Start`}: <FormatHumanTime value={date} />
              </>
            }
          />
        )}
        {pinned && <AdornmentIcon name="pinned" tooltip={getJobPinningTypeDescription(availableUntil.type)} />}
        <Box ml={1} mr={mrInfo}>
          {!adhoc && !pinned && <FormatHumanTime value={date} />}
          {pinned && pinnedUntil && <TimeAgo date={pinnedUntil} />}
          {pinned && !pinnedUntil && getJobPinningTypeDescription(availableUntil.type)}
        </Box>
      </Hidden>
    </RowBox>
  )
})

const DueTimeActionCardInfo = ({ job, forceComplete, ...rest }) => {
  const classes = useStyles()
  const { isJobOverdue } = useJobUtils()
  const { isBeforeToday } = useDateUtils()
  const {
    dueAt,
    status: { completed },
  } = job
  if (!dueAt) return null

  const isOverdue = isJobOverdue(job)
  const isOverdueAndBeforeToday = isOverdue && !forceComplete && isBeforeToday(job.dueAt)

  return (
    <RowBox {...rest}>
      <Hidden mdUp>
        <ActionCardInfo>
          <AdornmentIcon
            name="due"
            className={isOverdue && !forceComplete ? classes.overdueIcon : ""}
            tooltip={<>Due: {isOverdueAndBeforeToday ? <TimeAgo date={dueAt} /> : <FormatHumanTime value={dueAt} />}</>}
          />
        </ActionCardInfo>
      </Hidden>
      <Hidden smDown>
        <AdornmentIcon
          name="due"
          className={isOverdue && !forceComplete ? classes.overdueIcon : ""}
          tooltip={<>Due: {isOverdueAndBeforeToday ? <TimeAgo date={dueAt} /> : <FormatHumanTime value={dueAt} />}</>}
        />
        <Box ml={1} mr={mrInfo}>
          {isOverdueAndBeforeToday && <TimeAgo date={dueAt} overdue />}
          {!isOverdueAndBeforeToday && <FormatHumanTime value={dueAt} showOverdue={!completed} />}
        </Box>
      </Hidden>
    </RowBox>
  )
}

const AssigneesActionCardInfo = memo(function AssigneesActionCardInfo({
  job: { users, groups },
  compact,
  align = "right",
  ...rest
}) {
  const classes = useStyles()

  const right = align === "right"

  const assignees = !compact && (
    <Box className={right ? classes.assignees : ""}>
      <Assignees {...{ users, groups, limit: 1 }} />
    </Box>
  )
  const avatars = <AssigneeAvatars {...{ users, groups, limit: 2 }} />

  if (align === "left") {
    return (
      <RowBox {...rest}>
        {avatars}
        <Box ml={1}>{assignees}</Box>
      </RowBox>
    )
  }

  return (
    <RowBox {...rest}>
      {assignees}
      <Box ml={1}>{avatars}</Box>
    </RowBox>
  )
})

const ProcessDetailText = ({ job }) => {
  const { formatShortDate } = useDateUtils()

  const formattedDate = formatShortDate(job.createdAt, job.location.timeZone)

  // Ad hoc jobs
  if (job.adhoc) {
    if (job.title) {
      return `${formattedDate}: ${job.title}`
    }
  }

  // Recurring jobs
  if (!job.adhoc && job.repeat.type !== PROCESS_REPEAT.NEVER) {
    if (job.scheduleName !== job.process.name) {
      return `${formattedDate}: ${job.scheduleName}`
    }
  }

  return `${formattedDate}`
}

const ProcessActionCard = (props) => {
  const { job, date, compact, navigable = true, onCantNavigate, cy } = props
  const {
    type,
    availableFrom,
    availableUntil,
    adhoc,
    status: { completed, submitted, steps },
  } = job

  const theme = useTheme()
  const { getNavigateToLink, isJobAfterToday, isJobOverdue } = useJobUtils()
  const { hasFeature } = useAuth()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  const isAudit = type === PROCESS_TYPE.AUDIT
  const isScheduled = isJobAfterToday(availableFrom)
  const isOverdue = !completed && isJobOverdue(job)

  const handleCantNavigateClick = () => {
    onCantNavigate && onCantNavigate()
  }

  if (
    steps.length === 1 &&
    steps[0].responseType === PROCESS_STEP_RESPONSE_TYPE.CHECKBOX &&
    hasFeature("process_single_step")
  ) {
    return <SingleStepProcessActionCard {...props} />
  }

  return (
    <ActionCard
      title={job.process.name}
      detail={<ProcessDetailText job={job} />}
      completed={(job.type === PROCESS_TYPE.PROCESS && completed) || (isAudit && submitted)}
      overdue={isOverdue}
      cardProps={
        navigable
          ? { component: NavLink, to: { pathname: getNavigateToLink(job, date) }, "data-cy": cy }
          : { onClick: handleCantNavigateClick, "data-cy": cy }
      }
      isScheduled={isScheduled}
      rightChildren={
        <RowBox mr="auto" alignSelf="center" alignItems="center" className={classes.rightChildren}>
          <Hidden xsDown>
            <Box>
              <AuditStatusChip job={job} overdue={isOverdue} />
            </Box>
          </Hidden>
        </RowBox>
      }
      footer={
        <>
          <RowBox>
            <ProcessStepsActionCardInfo job={job} count={getResponseStepsCount(steps)} />
            <ProcessRepeatActionCardInfo job={job} />
            <HumanTimeActionCardInfo
              adhoc={adhoc}
              date={availableFrom}
              availableUntil={availableUntil}
              tooltip={t`Start`}
            />
            <DueTimeActionCardInfo job={job} />
          </RowBox>
          <RowBox ml="auto">
            <AssigneesActionCardInfo job={job} compact={compact} />
          </RowBox>
        </>
      }
      cy={cy}
    >
      <Hidden smUp>
        <Box mt={1}>
          <AuditStatusChip job={job} overdue={isOverdue} />
        </Box>
      </Hidden>
    </ActionCard>
  )
}

export {
  ProcessActionCard,
  HumanTimeActionCardInfo,
  DueTimeActionCardInfo,
  AssigneesActionCardInfo,
  ProcessStepsActionCardInfo,
  ProcessRepeatActionCardInfo,
  ProcessDetailText,
}
