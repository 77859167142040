import React, { useState, useEffect } from "react"
import { Button, Box, Tooltip, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Avatar, Icon } from ".."
import { Uploader } from "./Uploader"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  content: {
    display: "flex",
    alignItems: ({ xs }) => (xs ? "flex-start" : "center"),
    flexDirection: ({ xs }) => (xs ? "column" : "row"),
    gap: theme.spacing(2),
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
  },
  tooltipWrapper: {
    lineHeight: "100%",
  },
  tooltipHelpIcon: {
    marginLeft: theme.spacing(0.5),
  },
  avatar: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    fontSize: 24,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  image: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
  },
}))

const UploaderProfileImage = ({ width = 80, height = 80, avatarProps, title, description, tooltip, onChange }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ width, height, xs })
  const [avatar, setAvatar] = useState(null)

  useEffect(() => {
    setAvatar(avatarProps)
  }, [avatarProps])

  const handleAfterUpload = (uploads) => {
    if (uploads && uploads.length && onChange) onChange(uploads[0])
  }

  const handleClear = () => {
    if (onChange) onChange(null)
  }

  return (
    <Box className={classes.root}>
      {title && (
        <Box className={classes.title}>
          <Box>{title}</Box>
          {tooltip && (
            <Tooltip title={tooltip}>
              <span className={classes.tooltipWrapper}>
                <Icon className={classes.tooltipHelpIcon} name="help" fontSize="small" />
              </span>
            </Tooltip>
          )}
        </Box>
      )}
      <Box display="flex" alignItems="center" className={classes.content}>
        <Box p={1.5} className={classes.image}>
          {avatar && <Avatar {...avatar} className={classes.avatar} />}
        </Box>
        <Box className={classes.details}>
          <Box className={classes.actions}>
            <Uploader
              onAfterUpload={handleAfterUpload}
              images
              documents={false}
              multiple={false}
              variant="button"
              buttonText={<Trans>Update</Trans>}
              buttonProps={{ variant: "contained" }}
            />
            <Button variant="text" color="secondary" onClick={handleClear}>
              <Trans>Remove</Trans>
            </Button>
          </Box>
          <Box>{description}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export { UploaderProfileImage }
