import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_422_3212)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4211 15.1727C27.6361 15.6814 27.398 16.2681 26.8893 16.4831L19.6923 19.5251C19.1836 19.7401 18.5969 19.5021 18.3819 18.9934C18.1669 18.4846 18.405 17.8979 18.9137 17.6829L26.1107 14.6409C26.6194 14.4259 27.2061 14.664 27.4211 15.1727Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6071 20.3627C24.8221 20.8715 24.584 21.4582 24.0752 21.6731L19.6922 23.5251C19.1835 23.7401 18.5968 23.5019 18.3818 22.9932C18.1669 22.4845 18.405 21.8978 18.9138 21.6828L23.2968 19.8308C23.8055 19.6159 24.3922 19.854 24.6071 20.3627Z"
      fill={secondary}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6015 1.08284C15.8557 0.972388 16.1443 0.972388 16.3985 1.08284L30.3985 7.16584C30.7637 7.32453 31 7.68479 31 8.083V24C31 24.4 30.7616 24.7616 30.3939 24.9191L16.3939 30.9191C16.1424 31.027 15.8576 31.027 15.6061 30.9191L1.60608 24.9191C1.2384 24.7616 1 24.4 1 24V8.083C1 7.68479 1.23627 7.32453 1.60149 7.16584L15.6015 1.08284ZM3 9.59954V23.3406L15 28.4835V14.7424L3 9.59954ZM17 14.7424V28.4835L29 23.3406V9.59954L17 14.7424ZM27.4614 8.07028L22.9522 6.11107L11.587 11.0492L16 12.9144L27.4614 8.07028ZM9.04297 9.97401L20.4429 5.02075L16 3.09032L4.53862 8.07028L9.04297 9.97401Z"
      fill={primary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_422_3212"
        x1="18.3027"
        y1="17.083"
        x2="27.5003"
        y2="17.083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_422_3212"
        x1="18.3027"
        y1="21.678"
        x2="24.6863"
        y2="21.678"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
