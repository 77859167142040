import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_148)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.609 16C25.6113 14.2901 25.1572 12.6105 24.2938 11.1346C23.4304 9.65875 22.1888 8.43995 20.6971 7.60402C19.2055 6.76808 17.5178 6.34527 15.8082 6.37922C14.0987 6.41316 12.4291 6.90263 10.9718 7.79712C9.51452 8.69162 8.32228 9.95874 7.51811 11.4677C6.71394 12.9768 6.32696 14.673 6.3971 16.3815C6.46723 18.09 6.99194 19.7488 7.91708 21.1868C8.84223 22.6248 10.1343 23.79 11.66 24.562V30C11.66 30.2652 11.7654 30.5196 11.9529 30.7071C12.1404 30.8946 12.3948 31 12.66 31H19.34C19.6052 31 19.8596 30.8946 20.0471 30.7071C20.2346 30.5196 20.34 30.2652 20.34 30V24.562C21.9229 23.7602 23.253 22.5359 24.183 21.0247C25.1129 19.5135 25.6065 17.7744 25.609 16ZM18.344 29H13.664V25.309C15.1984 25.709 16.8096 25.709 18.344 25.309V29ZM16.004 23.609C14.4989 23.6098 13.0274 23.1642 11.7756 22.3286C10.5238 21.493 9.54787 20.3049 8.97134 18.9146C8.39482 17.5243 8.24357 15.9943 8.53671 14.518C8.82986 13.0417 9.55423 11.6856 10.6182 10.621C11.6822 9.5565 13.038 8.83142 14.5141 8.5375C15.9902 8.24358 17.5203 8.39403 18.9109 8.96982C20.3015 9.54561 21.4901 10.5209 22.3264 11.7723C23.1626 13.0236 23.609 14.4949 23.609 16C23.6069 18.0174 22.8045 19.9515 21.378 21.378C19.9515 22.8045 18.0174 23.6069 16 23.609H16.004Z"
      fill={primary}
    />
    <path
      d="M16 4.778C15.7348 4.778 15.4804 4.67264 15.2929 4.48511C15.1054 4.29757 15 4.04322 15 3.778V2C15 1.73478 15.1054 1.48043 15.2929 1.29289C15.4804 1.10536 15.7348 1 16 1C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V3.778C17 4.04322 16.8946 4.29757 16.7071 4.48511C16.5196 4.67264 16.2652 4.778 16 4.778Z"
      fill={primary}
    />
    <path
      d="M30 17H28.221C27.9558 17 27.7014 16.8946 27.5139 16.7071C27.3264 16.5196 27.221 16.2652 27.221 16C27.221 15.7348 27.3264 15.4804 27.5139 15.2929C27.7014 15.1054 27.9558 15 28.221 15H30C30.2652 15 30.5196 15.1054 30.7071 15.2929C30.8946 15.4804 31 15.7348 31 16C31 16.2652 30.8946 16.5196 30.7071 16.7071C30.5196 16.8946 30.2652 17 30 17Z"
      fill={primary}
    />
    <path
      d="M3.77699 17H2C1.73478 17 1.48043 16.8946 1.29289 16.7071C1.10536 16.5196 1 16.2652 1 16C1 15.7348 1.10536 15.4804 1.29289 15.2929C1.48043 15.1054 1.73478 15 2 15H3.77699C4.04221 15 4.29656 15.1054 4.4841 15.2929C4.67164 15.4804 4.77699 15.7348 4.77699 16C4.77699 16.2652 4.67164 16.5196 4.4841 16.7071C4.29656 16.8946 4.04221 17 3.77699 17Z"
      fill={primary}
    />
    <path
      d="M9.893 6.414C9.7173 6.41417 9.54464 6.36805 9.39244 6.28027C9.24023 6.19249 9.11385 6.06616 9.026 5.914L8.136 4.374C8.07034 4.26021 8.02774 4.1346 8.01062 4.00434C7.9935 3.87409 8.00221 3.74174 8.03624 3.61485C8.07027 3.48795 8.12897 3.36901 8.20897 3.2648C8.28897 3.16059 8.38871 3.07316 8.5025 3.0075C8.61629 2.94184 8.7419 2.89923 8.87216 2.88212C9.00242 2.865 9.13477 2.87371 9.26166 2.90774C9.38855 2.94177 9.50748 3.00046 9.61169 3.08047C9.7159 3.16047 9.80334 3.26021 9.869 3.374L10.758 4.914C10.8457 5.06594 10.8919 5.23829 10.892 5.41374C10.892 5.58919 10.8459 5.76157 10.7583 5.91356C10.6706 6.06555 10.5445 6.1918 10.3926 6.27964C10.2408 6.36748 10.0685 6.41382 9.893 6.414Z"
      fill={primary}
    />
    <path
      d="M26.589 10.888C26.3689 10.888 26.1549 10.8153 25.9803 10.6813C25.8056 10.5473 25.6801 10.3594 25.6231 10.1468C25.5662 9.9342 25.5809 9.70871 25.6652 9.50534C25.7494 9.30197 25.8984 9.13207 26.089 9.022L27.629 8.133C27.8586 8.00248 28.1304 7.96806 28.3853 8.03725C28.6401 8.10644 28.8572 8.27362 28.9893 8.50231C29.1213 8.73099 29.1575 9.00262 29.09 9.25792C29.0225 9.51321 28.8568 9.73145 28.629 9.865L27.089 10.754C26.937 10.8418 26.7645 10.888 26.589 10.888Z"
      fill={primary}
    />
    <path
      d="M3.88001 24C3.65988 24 3.44591 23.9273 3.27127 23.7933C3.09664 23.6593 2.9711 23.4714 2.91413 23.2588C2.85715 23.0462 2.87193 22.8207 2.95616 22.6173C3.0404 22.414 3.18938 22.2441 3.38001 22.134L4.92 21.245C5.14956 21.1145 5.42143 21.0801 5.67627 21.1493C5.93111 21.2184 6.14824 21.3856 6.28027 21.6143C6.41231 21.843 6.44854 22.1146 6.38104 22.3699C6.31355 22.6252 6.14781 22.8434 5.92 22.977L4.38001 23.866C4.22799 23.9538 4.05555 24 3.88001 24Z"
      fill={primary}
    />
    <path
      d="M5.42101 10.887C5.24542 10.8874 5.07288 10.8411 4.92101 10.753L3.38001 9.865C3.1502 9.73239 2.98248 9.51392 2.91374 9.25765C2.84501 9.00139 2.8809 8.72831 3.01351 8.4985C3.14612 8.26869 3.36458 8.10097 3.62085 8.03224C3.87712 7.96351 4.1502 7.99939 4.38001 8.132L5.92101 9.021C6.11164 9.13107 6.26062 9.30097 6.34485 9.50434C6.42908 9.70771 6.44386 9.9332 6.38689 10.1458C6.32992 10.3584 6.20438 10.5463 6.02974 10.6803C5.85511 10.8143 5.64113 10.887 5.42101 10.887Z"
      fill={primary}
    />
    <path
      d="M22.116 6.413C21.9405 6.41282 21.7682 6.36648 21.6164 6.27864C21.4645 6.1908 21.3384 6.06455 21.2507 5.91256C21.1631 5.76057 21.117 5.58819 21.117 5.41274C21.1171 5.23729 21.1633 5.06494 21.251 4.913L22.14 3.373C22.2726 3.14319 22.4911 2.97547 22.7473 2.90674C23.0036 2.83801 23.2767 2.87389 23.5065 3.0065C23.7363 3.13911 23.904 3.35758 23.9728 3.61385C24.0415 3.87011 24.0056 4.14319 23.873 4.373L22.983 5.913C22.8952 6.06516 22.7688 6.19149 22.6165 6.27927C22.4643 6.36705 22.2917 6.41317 22.116 6.413Z"
      fill={primary}
    />
    <path
      d="M28.118 23.991C27.9424 23.9914 27.7699 23.9451 27.618 23.857L26.078 22.969C25.8482 22.8364 25.6805 22.6179 25.6117 22.3617C25.543 22.1054 25.5789 21.8323 25.7115 21.6025C25.8441 21.3727 26.0626 21.205 26.3189 21.1362C26.5751 21.0675 26.8482 21.1034 27.078 21.236L28.618 22.125C28.8086 22.2351 28.9576 22.405 29.0418 22.6083C29.1261 22.8117 29.1409 23.0372 29.0839 23.2498C29.0269 23.4624 28.9014 23.6503 28.7267 23.7843C28.5521 23.9183 28.3381 23.991 28.118 23.991Z"
      fill={primary}
    />
    <path
      d="M14.251 22.007C14.0305 22.0074 13.8161 21.9349 13.6411 21.8009C13.4661 21.6668 13.3403 21.4787 13.2833 21.2657C13.2263 21.0528 13.2412 20.827 13.3258 20.6234C13.4104 20.4198 13.5598 20.2499 13.751 20.14L17.251 18.126C17.3648 18.0604 17.4905 18.0178 17.6208 18.0007C17.7511 17.9836 17.8835 17.9923 18.0105 18.0264C18.1374 18.0605 18.2564 18.1192 18.3606 18.1993C18.4649 18.2794 18.5523 18.3792 18.618 18.493C18.6836 18.6069 18.7262 18.7326 18.7433 18.8629C18.7604 18.9932 18.7517 19.1256 18.7176 19.2525C18.6835 19.3795 18.6248 19.4985 18.5447 19.6027C18.4646 19.7069 18.3648 19.7944 18.251 19.86L14.751 21.874C14.5989 21.9615 14.4264 22.0073 14.251 22.007Z"
      fill={secondary}
    />
    <path
      d="M14.251 18.007C14.0305 18.0074 13.8161 17.9349 13.6411 17.8009C13.4661 17.6668 13.3403 17.4787 13.2833 17.2657C13.2263 17.0528 13.2412 16.827 13.3258 16.6234C13.4104 16.4198 13.5598 16.2499 13.751 16.14L17.251 14.126C17.4809 13.9934 17.7541 13.9576 18.0105 14.0264C18.2668 14.0952 18.4854 14.2631 18.618 14.493C18.7506 14.723 18.7864 14.9962 18.7176 15.2525C18.6488 15.5089 18.4809 15.7274 18.251 15.86L14.751 17.874C14.5989 17.9615 14.4264 18.0073 14.251 18.007Z"
      fill={secondary}
    />
    <path
      d="M14.251 14.007C14.0305 14.0073 13.8161 13.9349 13.6411 13.8008C13.4661 13.6667 13.3403 13.4786 13.2833 13.2657C13.2263 13.0527 13.2412 12.8269 13.3258 12.6233C13.4104 12.4198 13.5598 12.2498 13.751 12.14L17.251 10.126C17.3648 10.0603 17.4904 10.0177 17.6206 10.0006C17.7509 9.98348 17.8832 9.99218 18.0101 10.0262C18.137 10.0602 18.256 10.1189 18.3602 10.1989C18.4644 10.2789 18.5518 10.3787 18.6175 10.4925C18.6831 10.6063 18.7257 10.7319 18.7429 10.8621C18.76 10.9924 18.7513 11.1247 18.7172 11.2516C18.6832 11.3785 18.6245 11.4975 18.5445 11.6017C18.4645 11.7059 18.3648 11.7933 18.251 11.859L14.751 13.873C14.5991 13.961 14.4266 14.0073 14.251 14.007Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_148"
        x1="13.2493"
        y1="19.9996"
        x2="18.7518"
        y2="19.9996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_99_148"
        x1="13.2493"
        y1="15.9996"
        x2="18.7518"
        y2="15.9996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_99_148"
        x1="13.2493"
        y1="11.9995"
        x2="18.7514"
        y2="11.9995"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
