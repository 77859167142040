import React from "react"
import { makeStyles, useTheme } from "@material-ui/styles"

const useStyles = makeStyles(() => ({
  image: {
    opacity: ({ opacity }) => opacity,
    animationName: ({ delay }) => (delay ? "fadeInOpacityLight" : null),
    animationIterationCount: 1,
    animationTimingFunction: "ease-in",
    animationDuration: "1s",
  },
}))

const LoadingSpinner = ({ size = "36px", className, opacity = 0.3, delay = true }) => {
  const classes = useStyles({ opacity, delay })
  const theme = useTheme()

  return (
    <svg
      className={`${classes.image} ${className}`}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={theme.palette.grey[600]}
        strokeWidth="7"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="rotate(338.489 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="0.6s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
}

export { LoadingSpinner }
