import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/browser"
import { RouterOutlined, CodeOutlined, Security, HourglassEmptyOutlined } from "@material-ui/icons"
import { Snackbar, Chip, makeStyles, Box, Button } from "@material-ui/core"
import Config from "react-global-configuration"
import { t } from "@lingui/macro"
import { Icon } from "../Icon"
import { useSnackbar, useSnackbarChipStyles } from "../SnackbarProvider"
import { LogoFull } from "../Logo"
import { ColumnBox, FlexBox } from "../Boxes"

const useSnackbarStyles = makeStyles(() => ({
  root: {
    opacity: 0.9,
  },
}))

const getErrorsUI = (error) => {
  const { graphQLErrors, networkError } = error

  if (graphQLErrors) {
    const codes = graphQLErrors.map((error) => error.extensions?.code).filter((code) => code)

    graphQLErrors.forEach((error) => console.log("[ErrorsUI]", error.message))

    if (codes.includes("FORBIDDEN") || codes.includes("PERMISSION_DENIED")) {
      return {
        type: "message",
        icon: <Security />,
        label: t`Insufficient permission level to perform this action`,
        color: "primary",
        autoHideDuration: 5000,
      }
    }

    if (codes.includes("UPDATE_IN_PROGRESS")) {
      return {
        type: "custom",
        icon: <Icon name="update" />,
        label: t`App temporarily unavailable for upgrade. Tap here to retry.`,
        chipProps: { style: { cursor: "pointer", backgroundColor: "#fd9317" } },
        onClick: () => window.location.reload(true),
        keepOpen: true,
        upgrade: true,
      }
    }

    if (codes.includes("OPERATION_DEPRECATED")) {
      return {
        type: "custom",
        icon: <Icon name="update" />,
        label: t`Compulsory update required. Tap here to reload.`,
        chipProps: { style: { cursor: "pointer", backgroundColor: "#fd9317" } },
        onClick: () => window.location.reload(true),
        keepOpen: true,
      }
    }

    if (codes.includes("OPERATION_DENIED")) {
      return {
        type: "message",
        icon: <Icon name="delete-failed" />,
        label: graphQLErrors.find((error) => error.extensions?.code === "OPERATION_DENIED")?.message,
        color: "secondary",
        autoHideDuration: 5000,
      }
    }

    if (codes.includes("NOT_FOUND")) {
      return {
        type: "message",
        icon: <Icon name="not-found" />,
        label: graphQLErrors.find((error) => error.extensions?.code === "NOT_FOUND")?.message,
        color: "primary",
        autoHideDuration: 5000,
      }
    }

    if (codes.includes("RESOURCE_LOCKED")) {
      return {
        type: "message",
        icon: <HourglassEmptyOutlined />,
        label: t`Resource locked by another process/user, try again`,
        color: "secondary",
        autoHideDuration: 5000,
      }
    }

    if (codes.includes("BAD_GATEWAY")) {
      return {
        type: "message",
        icon: <Icon name="integration" />,
        label: graphQLErrors.find((error) => error.extensions?.code === "BAD_GATEWAY")?.message,
        color: "secondary",
        autoHideDuration: 5000,
      }
    }

    if (codes.includes("BAD_USER_INPUT")) {
      return { ignore: true }
    }

    return {
      type: "message",
      icon: <CodeOutlined />,
      label: t`Problem occurred requesting a resource`,
      color: "secondary",
      autoHideDuration: null,
    }
  }

  if (networkError?.statusCode === 429) {
    Sentry.captureMessage("User was rate limited")
    return {
      type: "message",
      icon: <Icon name="rate-limit" />,
      label: t`You've reached our rate limit, please try again`,
      color: "primary",
      autoHideDuration: 2000,
    }
  }

  return {
    type: "message",
    icon: <RouterOutlined />,
    label: t`Reconnecting`,
    color: "primary",
    autoHideDuration: 2000,
  }
}

const ConnectivityError = ({ server }) => {
  const chipClasses = useSnackbarChipStyles()
  const snackbarClasses = useSnackbarStyles()
  const snackbar = useSnackbar()
  const [error, setError] = useState(false)
  const { showGraphQLErrorNotification } = Config.get("errors")

  const handleClose = () => {
    setError(null)
  }

  useEffect(() => {
    server.registerErrorHandler("ConnectivityError", (errorInfo) => {
      const { graphQLErrors, networkError } = errorInfo
      if (Array.isArray(graphQLErrors)) {
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              return // ignore
            default:
              if (showGraphQLErrorNotification) setError(errorInfo)
          }
        }
      }

      if (networkError) {
        console.log("[NETWORK]", `[ERROR]: ${networkError}`)
        setError(errorInfo)
      }
    })
    return () => {
      server.removeErrorHandler("ConnectivityError")
    }
  }, [server, showGraphQLErrorNotification])

  if (!error) {
    return null
  }

  const { type, icon, label, color, autoHideDuration, ignore, chipProps, onClick, keepOpen, upgrade } =
    getErrorsUI(error)

  if (ignore) {
    return null
  }

  if (type === "custom") {
    if (upgrade) {
      return <Upgrade />
    }

    snackbar.showMessage({
      message: label,
      icon,
      chipProps,
      onClick,
      keepOpen,
    })

    return null
  }

  return (
    <Snackbar
      classes={snackbarClasses}
      open={error != null}
      autoHideDuration={autoHideDuration}
      onClick={handleClose}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Chip icon={icon} label={label} color={color} classes={chipClasses} />
    </Snackbar>
  )
}

const useUpgradeStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    zIndex: 999999,
    backgroundColor: theme.palette.background.default,
  },
}))

const Upgrade = () => {
  const classes = useUpgradeStyles()

  return (
    <Box className={classes.root}>
      <FlexBox justifyContent="center" alignItems="center" height="100%" mt={-10}>
        <ColumnBox alignItems="center">
          <LogoFull />
          <ColumnBox alignItems="center" mt={2} mb={3} style={{ textAlign: "center" }}>
            An important upgrade is in progress. Apologies for the inconvenience.
            <br />
            <br />
            We'll be right back. Please retry in 10 minutes.
          </ColumnBox>
          <Button
            variant="contained"
            color="primary"
            style={{ paddingLeft: 90, paddingRight: 90 }}
            onClick={() => window.location.reload(true)}
          >
            Retry
          </Button>
        </ColumnBox>
      </FlexBox>
    </Box>
  )
}

export default ConnectivityError
