import React from "react"
import { Box, ListItem, Tooltip, makeStyles } from "@material-ui/core"
import { ColumnBox, RowBox } from "../Boxes"
import { FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"
import { toId } from "../../utils"
import { DeleteConfirmIconButton } from "../Buttons"

const useAttributeListItemStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
  },
  block: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1, 2),
    borderRadius: 4,
  },
  text: {
    padding: 4,
  },
}))

const IntegrationMappingAttributeListItem = ({
  source,
  label,
  displayPropName = "name",
  item,
  itemAttribute,
  attribute,
  onDelete,
}) => {
  const classes = useAttributeListItemStyles()

  const handleDelete = async () => {
    onDelete && onDelete()
  }

  return (
    <ListItem disableGutters alignItems="flex-start">
      <RowBox flexGrow={1}>
        <Box mr={1}>
          <Icon name={`integration-${source}`} />
        </Box>
        <ColumnBox flexGrow={1} className={classes.block}>
          <FieldSectionHeading mb={0}>
            <Tooltip title={attribute.description}>
              <span>{attribute.name}</span>
            </Tooltip>
          </FieldSectionHeading>
          {itemAttribute.value}
        </ColumnBox>
        <RowBox mx={1}>
          <Icon name="forward" />
        </RowBox>
        <ColumnBox flexGrow={1} className={classes.block}>
          <FieldSectionHeading mb={0}>
            <Tooltip title={toId(itemAttribute)}>
              <span>{label}</span>
            </Tooltip>
          </FieldSectionHeading>
          {item[displayPropName]}
        </ColumnBox>
        <Box ml={1}>
          <DeleteConfirmIconButton onDelete={handleDelete} />
        </Box>
      </RowBox>
    </ListItem>
  )
}

export { IntegrationMappingAttributeListItem }
