import React, { useState, useMemo } from "react"
import { Box, Divider, IconButton, makeStyles, Popover, TextField, useMediaQuery, useTheme } from "@material-ui/core"
import moment from "moment"
import Config from "react-global-configuration"
import { Plural, Trans, t } from "@lingui/macro"
import {
  Icon,
  Caption,
  FormatDateTimeCompact,
  DeleteConfirmIconButton,
  Uploader,
  UploadingImagesList,
  UploadingList,
  DownloadList,
  ImagesViewer,
} from ".."
import { useMakeJobStepOptimistic, useMutationJobStepAddNote, useMutationJobStepDeleteNote } from "../../data"
import { useAuth } from "../../services"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { Avatar } from "../Avatar"
import FooterButton from "../Buttons/ActionCardFooterButton"
import { toId, preventDefault, useJobUtils, mapToIds } from "../../utils"
import { ProcessCollapse } from "./ProcessCollapse"

const useStyles = makeStyles((theme) => ({
  noNotesIcon: {
    fontSize: 24,
  },
  hasNotesIcon: {
    color: theme.palette.raised.secondary,
  },
  badge: {
    "&>.MuiBadge-dot": {
      backgroundColor: theme.palette.raised.secondary,
    },
  },
  popover: {
    minWidth: 300,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw",
      maxHeight: 300,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 450,
    },
  },
  list: {
    backgroundColor: theme.palette.grey[50],
    maxHeight: 400,
    overflowY: "auto",
    width: "100%",
    flexDirection: "column-reverse",
  },
  divider: {
    width: "100%",
  },
  row: {
    width: "100%",
    cursor: "pointer",
  },
  author: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  formWrapper: {
    width: "100%",
  },
  form: {
    width: "100%",
  },
  formUploading: {
    width: "100%",
  },
  text: {
    fontSize: 12,
    wordBreak: "break-word",
    wordWrap: "break-word",
    minWidth: "1%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(0.5),
  },
}))

const AddNoteFooterButton = ({ job, step, isPreview, isScheduled, required }) => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasFeature, location } = useAuth()
  const { canDeleteNote } = useJobUtils()
  const [addNote] = useMutationJobStepAddNote(job, step)
  const [deleteNote, { loading: deleteLoading }] = useMutationJobStepDeleteNote(job, step)
  const { makeJobStepAddNoteOptimisticResponse } = useMakeJobStepOptimistic()
  const [text, setText] = useState("")
  const [show, setShow] = useState(null)
  const [showDeleting, setShowDeleting] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { clientKiosk, clientDevice } = Config.get()
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState([])

  const notes = useMemo(
    () =>
      step.notes ? [...step.notes].sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1)) : [],
    [step.notes],
  )

  const open = Boolean(anchorEl)
  const hasNotes = Boolean(notes?.length)
  const isValid = Boolean(text?.trim() || uploads.length > 0)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFocus = () => {
    if (show) {
      setShow(null)
      setShowDeleting(false)
    }
  }

  const handleAdd = async () => {
    if (!isValid) {
      return
    }

    const variables = {
      job,
      step: toId(step),
      text,
      uploads: mapToIds(uploads),
    }

    setText("")
    setUploads([])
    setUploading([])
    await addNote({
      variables,
      optimisticResponse: makeJobStepAddNoteOptimisticResponse({
        ...variables,
        uploads: uploads.map((u) => ({ ...u, fileSize: u.loaded })),
        step,
      }),
    })
  }

  const handleEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleAdd()
    }
  }

  const handleShow = (note) => {
    setShow(show === toId(note) || !canDeleteNote(note) ? null : toId(note))
    setShowDeleting(false)
  }

  const handleDeleting = () => setShowDeleting(true)

  const handleDelete = async (note) => {
    const variables = {
      job,
      step: toId(step),
      note: toId(note),
    }

    await deleteNote({
      variables,
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!hasFeature("process_step_notes")) {
    return null
  }

  const handleItemUploaded = (item) => {
    setUploads((state) => [...state, item])
    setUploading([...uploading.filter((upload) => toId(upload) !== toId(item))])
  }

  const handleDeviceUploaded = (items) => {
    setUploads((prev) => [...prev, ...items])
  }

  const handleRemoveUpload = (id) => {
    const newUploads = uploads.filter((upload) => toId(upload) !== id)
    setUploads([...newUploads])
    setUploading([...uploading.filter((upload) => toId(upload) !== id)])
  }

  const hasNativeCamera = clientDevice || clientKiosk

  const notesMessage = <Plural value={notes.length} one="# note" other="# notes" />

  return (
    <Box>
      <FooterButton
        icon={hasNotes ? "note-added" : "note"}
        iconClassName={hasNotes ? classes.hasNotesIcon : classes.noNotesIcon}
        label={hasNotes ? notesMessage : <Trans>Add note/media</Trans>}
        onClick={handleOpen}
        badgeProps={{
          invisible: !required,
          className: classes.badge,
        }}
        badgeColor={theme.palette.raised.secondary}
        disabled={isPreview || isScheduled}
        preview={isPreview}
        data-cy="AddNoteFooterButton-button"
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: xs ? "center" : "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: xs ? "center" : "left",
        }}
      >
        <ColumnBox className={classes.popover}>
          {hasNotes && (
            <ColumnBox className={classes.list} data-cy="AddNoteFooterButton-list">
              {notes.map((item, index) => (
                <React.Fragment key={toId(item)}>
                  {index > 0 && <Divider className={classes.divider} />}
                  <Box p={1} className={classes.row} onClick={() => handleShow(item)}>
                    <RowBox alignItems="flex-start">
                      <Box mr={1}>
                        <Avatar {...item.author} className={classes.author} />
                      </Box>
                      <RowBox flexGrow={1} className={classes.text}>
                        {item.text}
                      </RowBox>
                      <ColumnBox ml={1} alignItems="flex-end" mr={1}>
                        <ProcessCollapse in={show !== toId(item)}>
                          <Caption mb={0} mt={1}>
                            <FormatDateTimeCompact
                              value={item.createdAt}
                              compactTodayTimeZone={location?.timeZone || null}
                            />
                          </Caption>
                        </ProcessCollapse>
                        <ProcessCollapse in={show === toId(item)}>
                          <FlexBox alignSelf="flex-end" onClick={preventDefault}>
                            <DeleteConfirmIconButton
                              deleting={showDeleting}
                              onDeleting={handleDeleting}
                              onDelete={() => handleDelete(item)}
                              loading={deleteLoading}
                            />
                          </FlexBox>
                        </ProcessCollapse>
                      </ColumnBox>
                    </RowBox>
                    {item.uploads.length > 0 ? (
                      <Box pt={1}>
                        <ImagesViewer uploads={item.uploads} height={80} width={100} />
                        <DownloadList uploads={item.uploads} variant="chips" />
                      </Box>
                    ) : null}
                  </Box>
                </React.Fragment>
              ))}
            </ColumnBox>
          )}
          <ColumnBox className={classes.formWrapper}>
            <RowBox p={1} className={classes.form}>
              <FlexBox mr={1} flexGrow={1}>
                <TextField
                  size="small"
                  placeholder={t`Note`}
                  variant="outlined"
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                  onKeyPress={handleEnter}
                  onFocus={handleFocus}
                  data-cy="AddNoteFooterButton-note"
                  fullWidth
                  autoFocus
                />
              </FlexBox>
              {hasNativeCamera && (
                <Uploader
                  reference={`${toId(step)}_note`}
                  icon={<Icon name="camera" />}
                  cameraOnly
                  onDeviceUploaded={handleDeviceUploaded}
                  size="small"
                />
              )}
              <Uploader onItemUploaded={handleItemUploaded} size="small" />
              <IconButton
                onClick={handleAdd}
                color="primary"
                className={classes.submit}
                data-cy="AddNoteFooterButton-add"
                disabled={!isValid}
                size="small"
              >
                <Icon name="done" />
              </IconButton>
            </RowBox>
            {uploads.length > 0 && (
              <Box pb={1} px={1} className={classes.formUploading}>
                <UploadingImagesList
                  uploaded={uploads}
                  uploading={uploading}
                  height={80}
                  onRemoveUpload={handleRemoveUpload}
                />
                <UploadingList
                  uploaded={uploads}
                  uploading={uploading}
                  onRemoveUpload={handleRemoveUpload}
                  images={false}
                />
              </Box>
            )}
          </ColumnBox>
        </ColumnBox>
      </Popover>
    </Box>
  )
}

export default AddNoteFooterButton
