import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_99_174)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 7.00098H12.163C12.4032 7.80664 12.9254 8.49893 13.634 8.95124C14.3427 9.40356 15.1905 9.58568 16.0224 9.46431C16.8543 9.34294 17.6147 8.92618 18.1646 8.29024C18.7145 7.65431 19.0171 6.84169 19.0171 6.00098C19.0171 5.16027 18.7145 4.34765 18.1646 3.71171C17.6147 3.07577 16.8543 2.65902 16.0224 2.53764C15.1905 2.41627 14.3427 2.59839 13.634 3.05071C12.9254 3.50303 12.4032 4.19531 12.163 5.00098H3C2.73478 5.00098 2.48043 5.10633 2.29289 5.29387C2.10536 5.48141 2 5.73576 2 6.00098C2 6.26619 2.10536 6.52055 2.29289 6.70808C2.48043 6.89562 2.73478 7.00098 3 7.00098ZM15.5 4.50098C15.7967 4.50098 16.0867 4.58895 16.3334 4.75377C16.58 4.91859 16.7723 5.15286 16.8858 5.42695C16.9993 5.70104 17.0291 6.00264 16.9712 6.29361C16.9133 6.58458 16.7704 6.85186 16.5607 7.06164C16.3509 7.27142 16.0836 7.41428 15.7926 7.47215C15.5017 7.53003 15.2001 7.50033 14.926 7.3868C14.6519 7.27326 14.4176 7.08101 14.2528 6.83433C14.088 6.58766 14 6.29765 14 6.00098C14 5.60315 14.158 5.22162 14.4393 4.94032C14.7206 4.65901 15.1022 4.50098 15.5 4.50098Z"
      fill={primary}
    />
    <path
      d="M21 7.00098H29C29.2652 7.00098 29.5196 6.89562 29.7071 6.70808C29.8946 6.52055 30 6.26619 30 6.00098C30 5.73576 29.8946 5.48141 29.7071 5.29387C29.5196 5.10633 29.2652 5.00098 29 5.00098H21C20.7348 5.00098 20.4804 5.10633 20.2929 5.29387C20.1054 5.48141 20 5.73576 20 6.00098C20 6.26619 20.1054 6.52055 20.2929 6.70808C20.4804 6.89562 20.7348 7.00098 21 7.00098Z"
      fill={primary}
    />
    <path
      d="M29 15.001H26C25.7348 15.001 25.4804 15.1063 25.2929 15.2939C25.1054 15.4814 25 15.7358 25 16.001C25 16.2662 25.1054 16.5205 25.2929 16.7081C25.4804 16.8956 25.7348 17.001 26 17.001H29C29.2652 17.001 29.5196 16.8956 29.7071 16.7081C29.8946 16.5205 30 16.2662 30 16.001C30 15.7358 29.8946 15.4814 29.7071 15.2939C29.5196 15.1063 29.2652 15.001 29 15.001Z"
      fill={primary}
    />
    <path
      d="M9.5 22.501C8.74896 22.5037 8.01878 22.7483 7.41771 23.1986C6.81664 23.649 6.37666 24.281 6.163 25.001H3C2.73478 25.001 2.48043 25.1063 2.29289 25.2939C2.10536 25.4814 2 25.7358 2 26.001C2 26.2662 2.10536 26.5205 2.29289 26.7081C2.48043 26.8956 2.73478 27.001 3 27.001H6.163C6.34974 27.6212 6.70526 28.1773 7.18984 28.6071C7.67442 29.0369 8.26896 29.3236 8.90705 29.4349C9.54514 29.5463 10.2016 29.4781 10.8032 29.2379C11.4047 28.9976 11.9276 28.5949 12.3134 28.0746C12.6993 27.5543 12.9328 26.937 12.988 26.2916C13.0432 25.6462 12.9178 24.9982 12.6259 24.4199C12.3341 23.8417 11.8871 23.356 11.3351 23.0171C10.7831 22.6783 10.1477 22.4995 9.5 22.501ZM9.5 27.501C9.20333 27.501 8.91332 27.413 8.66665 27.2482C8.41997 27.0834 8.22771 26.8491 8.11418 26.575C8.00065 26.3009 7.97095 25.9993 8.02882 25.7083C8.0867 25.4174 8.22956 25.1501 8.43934 24.9403C8.64912 24.7305 8.91639 24.5877 9.20736 24.5298C9.49833 24.4719 9.79994 24.5016 10.074 24.6152C10.3481 24.7287 10.5824 24.9209 10.7472 25.1676C10.912 25.4143 11 25.7043 11 26.001C11 26.3988 10.842 26.7803 10.5607 27.0616C10.2794 27.3429 9.89782 27.501 9.5 27.501Z"
      fill={primary}
    />
    <path
      d="M29 25.001H15C14.7348 25.001 14.4804 25.1063 14.2929 25.2939C14.1054 25.4814 14 25.7358 14 26.001C14 26.2662 14.1054 26.5205 14.2929 26.7081C14.4804 26.8956 14.7348 27.001 15 27.001H29C29.2652 27.001 29.5196 26.8956 29.7071 26.7081C29.8946 26.5205 30 26.2662 30 26.001C30 25.7358 29.8946 25.4814 29.7071 25.2939C29.5196 25.1063 29.2652 25.001 29 25.001Z"
      fill={primary}
    />
    <path
      d="M3 17.001H17.163C17.4032 17.8066 17.9254 18.4989 18.634 18.9513C19.3427 19.4036 20.1905 19.5857 21.0224 19.4643C21.8543 19.3429 22.6147 18.9262 23.1646 18.2903C23.7145 17.6543 24.0171 16.8417 24.0171 16.001C24.0171 15.1603 23.7145 14.3477 23.1646 13.7117C22.6147 13.0758 21.8543 12.659 21.0224 12.5377C20.1905 12.4163 19.3427 12.5984 18.634 13.0507C17.9254 13.503 17.4032 14.1953 17.163 15.001H3C2.73478 15.001 2.48043 15.1063 2.29289 15.2939C2.10536 15.4814 2 15.7358 2 16.001C2 16.2662 2.10536 16.5206 2.29289 16.7081C2.48043 16.8956 2.73478 17.001 3 17.001ZM20.5 14.501C20.7967 14.501 21.0867 14.589 21.3334 14.7538C21.58 14.9186 21.7723 15.1529 21.8858 15.427C21.9993 15.701 22.0291 16.0026 21.9712 16.2936C21.9133 16.5846 21.7704 16.8519 21.5607 17.0616C21.3509 17.2714 21.0836 17.4143 20.7926 17.4722C20.5017 17.53 20.2001 17.5003 19.926 17.3868C19.6519 17.2733 19.4176 17.081 19.2528 16.8343C19.088 16.5877 19 16.2977 19 16.001C19 15.6032 19.158 15.2216 19.4393 14.9403C19.7206 14.659 20.1022 14.501 20.5 14.501Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_99_174"
        x1="2"
        y1="16.001"
        x2="24.0171"
        y2="16.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
