import React from "react"
import { TextField, Link } from "@material-ui/core"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"

const CreatorContentText = ({ content, onUpdateContent }) => {
  const configLinks = Config.get("links")

  const handleChange = (e) => {
    onUpdateContent("text", e.target.value)
  }

  return (
    <TextField
      variant="outlined"
      value={content.text}
      fullWidth
      size="small"
      label={<Trans>Text</Trans>}
      multiline
      minRows={10}
      onChange={(e) => handleChange(e)}
      data-cy="CreatorContent-TextField-text"
      helperText={
        <Trans>
          View the{" "}
          <Link
            href={`${configLinks.help}/article/33-styling-formatting-text-content-using-markdown`}
            target="_blank"
            rel="noopener"
            underline="none"
          >
            formatting guide
          </Link>
        </Trans>
      }
    />
  )
}

export { CreatorContentText }
