import { ListItem, ListItemAvatar, ListItemIcon, ListItemText } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { ErrorBoundary } from "../ErrorBoundary"
import { useNotificationUtils } from "../../utils"
import { NOTIFICATION_TYPE } from "../../data"
import NotificationFrom from "./NotificationFrom"
import NotificationIcon from "./NotificationIcon"
import NotificationMessage from "./NotificationMessage"
import TimeAgo from "../TimeAgo"
import { Icon } from "../Icon"

const NotificationListItem = ({ notification, onClick, classes }) => {
  const { getNavigateToLink } = useNotificationUtils()
  const handleClick = () => {
    onClick && onClick(notification)
  }

  const hasLink = notification.type !== NOTIFICATION_TYPE.GENERAL

  return (
    <ErrorBoundary>
      <ListItem
        className={classes.item}
        alignItems="center"
        component={hasLink ? NavLink : "div"}
        to={{ pathname: getNavigateToLink(notification) }}
        onClick={() => handleClick(notification)}
      >
        <ListItemAvatar className={classes.itemAvatar}>
          <NotificationFrom notification={notification} className={classes.avatar} />
        </ListItemAvatar>
        <ListItemIcon className={classes.itemIcon}>
          <NotificationIcon {...notification} />
        </ListItemIcon>
        <ListItemText
          className={classes.text}
          primary={<NotificationMessage notification={notification} className={classes.itemMessage} />}
          secondary={<TimeAgo date={notification.createdAt} className={classes.age} />}
        />
        {hasLink && (
          <ListItemIcon className={classes.itemLinkIcon}>
            <Icon name="right" />
          </ListItemIcon>
        )}
      </ListItem>
    </ErrorBoundary>
  )
}

export { NotificationListItem }
