import React from "react"
import { Box, IconButton, ListItem, ListItemText, makeStyles, Tooltip } from "@material-ui/core"
import { Icon } from "../Icon"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    top: 0,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.default,
    zIndex: 1000,
    color: theme.palette.text.secondary,
    height: 50,
  },
  text: {
    fontSize: 13,
    fontWeight: 500,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 190,
  },
  icon: {
    width: 42,
  },
  button: {
    color: theme.palette.text.secondary,
  },
}))

const RegionPickerListItem = ({
  item: region,
  hasButtons = true,
  onRegionSelect,
  onRegionClear,
  picked,
  onPickedChanged,
  onShouldUpdateDialogState,
}) => {
  const classes = useStyles()

  const handleRegionSelect = () => {
    onRegionSelect && onRegionSelect(region, picked, onPickedChanged, onShouldUpdateDialogState)
  }

  const handleRegionClear = () => {
    onRegionClear && onRegionClear(region, picked, onPickedChanged, onShouldUpdateDialogState)
  }

  return (
    <ListItem disableGutters className={classes.root}>
      <RowBox alignItems="center" justifyContent="center" className={classes.icon}>
        <Icon name="region" fontSize="small" />
      </RowBox>
      <ListItemText primary={region.name} primaryTypographyProps={{ className: classes.text }} />
      {hasButtons && (
        <Box ml={0.5} display="flex">
          <Tooltip title="All">
            <IconButton onClick={() => handleRegionSelect(region)} size="small" className={classes.button}>
              <Icon name="tick" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="None">
            <IconButton onClick={() => handleRegionClear(region)} size="small" className={classes.button}>
              <Icon name="cancel" fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </ListItem>
  )
}

export default RegionPickerListItem
