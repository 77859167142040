import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 80,
    borderTop: ({ topBorder }) => (topBorder ? `1px solid ${theme.palette.grey[200]}` : "none"),
    borderBottom: ({ bottomBorder }) => (bottomBorder ? `1px solid ${theme.palette.grey[200]}` : "none"),
    cursor: ({ disabled }) => (disabled ? "inherit" : "pointer"),
    "&:hover": {
      backgroundColor: ({ disabled }) => (disabled ? "inherit" : theme.palette.grey[50]),
    },
    color: ({ disabled }) => theme.palette.text[disabled ? "disabled" : "primary"],
  },
  arrow: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
}))

const GroupedAssignerDisplay = ({
  children,
  onClick,
  className,
  topBorder = true,
  bottomBorder = true,
  disabled = false,
  cy,
  ...rest
}) => {
  const classes = useStyles({ topBorder, bottomBorder, disabled })

  const handleClick = (event) => {
    if (!disabled && onClick) onClick(event)
  }

  return (
    <RowBox className={`${classes.root} ${className}`} onClick={handleClick} data-cy={cy} {...rest}>
      <Box display="flex" flexGrow={1} flexDirection="column" mt={1} mb={1} alignSelf="center">
        {children}
      </Box>
      <Box className={classes.arrow}>
        <Box ml="auto">
          <ChevronRightIcon />
        </Box>
      </Box>
    </RowBox>
  )
}

export { GroupedAssignerDisplay }
